import { Environment } from '@/constants';
import { CONSTANT } from '@/helpers';
import EmbedBanner from './components/EmbedBanner'
import LocalBanner from './components/LocalBanner'

const ConfigurationBanner = () => {
  return process.env.REACT_APP_NODE_ENV !== Environment.Test &&
  CONSTANT.isEmbedded ? <EmbedBanner /> : <LocalBanner />
};

export default ConfigurationBanner;
