import { ReactNode, memo } from 'react';
import { StyledChartCustomTitle } from './styled';

interface IChartCustomTitle {
  children: ReactNode;
  xTitle: string;
  yTitle: string;
}

const ChartCustomTitle = ({ children, xTitle, yTitle }: IChartCustomTitle) => {
  return (
    <StyledChartCustomTitle xTitle={xTitle}>
      <h1 className="y">{yTitle}</h1>
      {children}
    </StyledChartCustomTitle>
  );
};

export default memo(ChartCustomTitle);
