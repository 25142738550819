import { Badge, ProgressBar, Text } from '@shopify/polaris';
import { StyledYourAccount } from './styled';
import { calcPercent, capitalizeFirstLetter } from '@/helpers';
import { useGetUserPlanQuery } from '@/redux/api/api.caller';
import { AccountPlan } from '@/constants/enum';
import { ProgressPercent } from '../../config';
import { useMemo } from 'react';

const YourAccount = () => {
  const { data: userPlanData } = useGetUserPlanQuery({});

  const yourAccountInfo = useMemo(() => {
    if (userPlanData && userPlanData.data) {
      const { plan, isOldUser, quota, credit } = userPlanData.data;

      const progressPercent = calcPercent(credit, Number(quota));
      const isOldEnterpriseAccount = isOldUser && plan === AccountPlan.Enterprise;

      const renderProgressBarColor = () => {
        if (progressPercent < ProgressPercent.Warning) {
          return 'primary';
        }
        if (progressPercent < ProgressPercent.Critical) {
          return 'highlight';
        }
        return 'critical';
      };

      return {
        plan,
        credit,
        progressPercent,
        isOldEnterpriseAccount,
        quota,
        progressBarColor: renderProgressBarColor(),
      };
    }
    return null;
  }, [userPlanData]);

  return (
    yourAccountInfo &&
    (() => {
      const { credit, isOldEnterpriseAccount, plan, progressBarColor, progressPercent, quota } = yourAccountInfo;
      const quotaText = isOldEnterpriseAccount ? (
        <>
          Your quota: <Badge tone="success">{capitalizeFirstLetter(quota)}</Badge>
        </>
      ) : (
        <>
          Quota: {credit}/{quota}
        </>
      );

      return (
        <StyledYourAccount>
          <Text variant="bodySm" as="p">
            Plan: <strong>{capitalizeFirstLetter(plan)}</strong> - {quotaText}
          </Text>
          {!isOldEnterpriseAccount && (
            <div className="shipment-statistics">
              <ProgressBar progress={progressPercent} tone={progressBarColor as any} size="small" />
            </div>
          )}
        </StyledYourAccount>
      );
    })()
  );
};

export default YourAccount;
