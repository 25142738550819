import { Icon } from '@shopify/polaris';
import { CircleDownMajor, CircleRightMajor, CircleUpMajor } from '@shopify/polaris-icons';

export const renderIconDirection = (changingValue: number) => {
  if (changingValue === 0) {
    return <Icon source={CircleRightMajor} tone="info" />;
  }
  if (changingValue > 0) {
    return <Icon source={CircleUpMajor} tone="success" />;
  }
  return <Icon source={CircleDownMajor} tone="critical" />;
};
