import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from '@/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { PolarisVizProvider } from '@shopify/polaris-viz';
import App from './App';
import '@shopify/polaris/build/esm/styles.css';
import '@shopify/polaris-viz/build/esm/styles.css';
import './index.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Crisp } from 'crisp-sdk-web';
import { onFCP, onLCP } from 'web-vitals';
import { getLocalStorageItem, getTabByPath, setLocalStorageItem } from './helpers';
import { LocalStorageKey } from './constants';

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <PolarisVizProvider
              themes={{
                Light: {
                  legend: {
                    backgroundColor: 'white',
                  },
                },
              }}
            >
              <App />
            </PolarisVizProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);

onFCP((fcp) => {
  const reloadFCPCount = getLocalStorageItem(LocalStorageKey.FCP_COUNT);
  console.log('fcp is running', fcp.value);
  if (fcp.value > 1500 && !Number(reloadFCPCount)) {
    console.log('app is reloading');
    setLocalStorageItem(LocalStorageKey.FCP_COUNT, '1');
    document.location.reload();
  } else {
    setLocalStorageItem(LocalStorageKey.FCP_COUNT, '0');

    const auth = getLocalStorageItem(LocalStorageKey.TOKEN);
    let storeId = '';
    if (auth) {
      const dataAuth = JSON.parse(auth).data;
      const dataAuthParser = JSON.parse(dataAuth);
      storeId = dataAuthParser?.shop;
    }
    const { pathname } = window.location;
    const tab = getTabByPath(pathname);
    const path = `${pathname}${tab ? `?tab=${tab}` : ''}`;

    onLCP((lcp) => {
      console.log(`LCP: ${lcp.value}`, path);
      console.log(`Store: ${storeId} - LCP: ${lcp.value}`);
      fetch(`${process.env.REACT_APP_BASE_URL}/s/api/logs/lcp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ value: lcp.value, storeId, path }),
      })
        .then((response) => response.json())
        .then(() => console.log('LCP data successfully sent', lcp, storeId))
        .catch((error) => console.error('Error sending LCP data', error));
    });
  }
});
