export enum TagTypesKey {
  PLAN = 'Plan',
  DASHBOARD = 'Dashboard',
  REVIEW_TABLE = 'ReviewTable',
  SHIPMENT = 'Shipment',
  ANALYTICS = 'Analytics',
  NOTIFICATION = 'Notification',
  SETTINGS = 'Settings',
  COURIER_MAPPING = 'CourierMapping',
  BLACKLISTING = 'Blacklisting',
  EDD = 'EstimatedDeliveryData',
  SMS = 'SMS',
  TRACKING_PAGE = 'Tracking Page',
  TRACKING_LINK = 'Tracking Link',
  ORDER_LOOKUP = 'Order Lookup',
  WELCOME = 'Welcome',
}
