import { useState } from 'react';
import { ScrollUpIcon } from '@/icons';
import { StyledScrollUp } from './styled';

const ScrollToTop = () => {
  const [isScrollUpActive, setScrollUpActive] = useState<boolean>(false);

  const handleScrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  window.onscroll = function () {
    const { scrollY } = window;
    if (scrollY > 500) {
      return setScrollUpActive(true);
    }
    return setScrollUpActive(false);
  };

  return (
    <StyledScrollUp className={isScrollUpActive ? 'active' : ''}>
      <ScrollUpIcon onClick={handleScrollToTop} />
    </StyledScrollUp>
  );
};

export default ScrollToTop;
