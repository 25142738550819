import { CustomCard } from '@/components';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const MarketingClick = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.marketingClick.categories.length) {
      const { categories, series } = data.data.marketingClick;
      return [
        {
          name: 'Marketing click',
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: series[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);
  return (
    <CustomCard title="MARKETING CLICK">
      <div style={{ height: '480px' }}>
        {isLoading ? <SkeletonBodyText lines={25} /> : <LineChart theme="Light" data={chartData} isAnimated />}
      </div>
    </CustomCard>
  );
};

export default MarketingClick;
