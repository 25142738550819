import styled from 'styled-components';

export const StyledAdvancedTranslationTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .link_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.15rem 0;
  }
`;
