import { memo } from 'react';
import { StyledAssetCard } from './styled';
import { Button, Text, TextField } from '@shopify/polaris';
import UploadFile from '@/components/UploadFile';
import { heightPercent } from '@/helpers';

interface AssetCardProps {
  assetNo: string;
  fileUrl: string;
  fileSize: string;
  onUploadFile: (file: File) => void;
  destinationValue: string;
  onChangeDestination: (value: string) => void;
  onBlurDestination?: () => void;
  disabledBtn: boolean;
  loadingBtn: boolean;
  onClickBtn: () => void;
  error: string;
  imageError: { key: string; message: string };
  keyFile?: string;
}

const AssetCard = ({
  assetNo,
  fileUrl,
  fileSize,
  onUploadFile,
  destinationValue,
  onChangeDestination,
  onBlurDestination,
  disabledBtn,
  loadingBtn,
  onClickBtn,
  error,
  imageError,
  keyFile,
}: AssetCardProps) => {
  const percentByHeight = heightPercent(fileSize);

  return (
    <StyledAssetCard>
      <Text as="h6" variant="headingSm">
        {assetNo}
      </Text>
      <UploadFile
        url={fileUrl}
        percentByHeight={percentByHeight}
        label={`${fileSize}; Less than 1MB; Accepts .jpg, .jpeg, .png and .gif.`}
        onSave={onUploadFile}
        keyFile={keyFile}
        imageError={imageError}
      >
        <div className="input-container">
          <TextField
            label="Destination URL"
            placeholder="https://example.com"
            value={destinationValue || ''}
            onChange={onChangeDestination}
            autoComplete="off"
            error={error}
            onBlur={onBlurDestination}
          />
          <div>
            <Button fullWidth disabled={disabledBtn} onClick={onClickBtn} variant="primary" loading={loadingBtn}>
              Publish
            </Button>
          </div>
        </div>
      </UploadFile>
    </StyledAssetCard>
  );
};

export default memo(AssetCard);
