import { CustomCard } from '@/components';
import { InlineGrid } from '@shopify/polaris';
import { helpCenterData } from './config';
import HelpCard from './components';
import { StyledHelpCenter } from './styled';

const HelpCenter = () => {
  return (
    <CustomCard title="Help center">
      <StyledHelpCenter>
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
          {helpCenterData.map(({ icon, title, description, url, onClick }, index) => (
            <HelpCard key={index} icon={icon} title={title} description={description} url={url} onClick={onClick} />
          ))}
        </InlineGrid>
      </StyledHelpCenter>
    </CustomCard>
  );
};

export default HelpCenter;
