import styled from 'styled-components';

export const StyledAppInfoCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  .app-container {
    display: flex;
    gap: 1rem;

    img {
      border-radius: 0.5rem;
    }

    .review-info {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .bfs-badge {
      background-color: #e0f0ff;
      padding: 0rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      border-radius: 0.25rem;
      width: fit-content;

      svg {
        width: 0.625rem;
      }
    }
  }
`;
