import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const BannerTemplateStyled = styled.div`
  .banner-template-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner-img {
    width: auto;
    height: 183px;
  }
  .banner-template-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  }

  @media screen and (max-width: ${PolarisBreakPoint.LG}) {
    .banner-img {
      width: 100%;
      height: auto;
    }
  }
`;
