import { BlockStack, Box, SkeletonBodyText, SkeletonDisplayText, SkeletonTabs, Tabs, useBreakpoints } from '@shopify/polaris';
import { StyledCustomizeContent } from './styled';
import { customizationTypeTabsConfig } from './config';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { customizeTypeTabsSelector, trackingPageIsLoadingSelector } from '@/redux/features/trackingPage.slice';
import { CustomizationTypeTabs } from '@/constants/trackingPage';
import { ErrorBanner } from '@/components';
import { memo } from 'react';

interface ICustomizeContentProps {
  error: string;
}
const CustomizeContent = ({ error }: ICustomizeContentProps) => {
  const { mdDown } = useBreakpoints();
  const selectedTab = useSelector(customizeTypeTabsSelector);
  const dispatch = useDispatch();
  const isLoading = useSelector(trackingPageIsLoadingSelector);

  const tabIndex = Object.values(CustomizationTypeTabs).findIndex((tab) => tab === selectedTab);

  const handleChangeTab = (selectedTabIndex: number) => {
    dispatch(trackingPageSlice.actions.handleChangeCustomizeTypeTabs(Object.values(CustomizationTypeTabs)[selectedTabIndex]));
  };

  return (
    <StyledCustomizeContent $isMdDown={mdDown}>
      <Box borderColor="border" borderInlineEndWidth="025" borderBlockEndWidth="025" borderInlineStartWidth="025">
        <div className="left-content">
          {isLoading ? (
            <SkeletonTabs count={3} />
          ) : (
            <Tabs fitted tabs={customizationTypeTabsConfig} selected={tabIndex} onSelect={handleChangeTab} />
          )}
          <Box background="bg-surface">
            <div className="customize-container">
              {isLoading ? (
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={30} />
                </BlockStack>
              ) : (
                <div className="customize-item">
                  <ErrorBanner isVisible={!!error}>{error}</ErrorBanner>
                  {customizationTypeTabsConfig.find((tab) => tab.id === selectedTab)?.component}
                </div>
              )}
            </div>
          </Box>
        </div>
      </Box>
    </StyledCustomizeContent>
  );
};

export default memo(CustomizeContent);
