import { ChartCustomTitle, CustomCard } from '@/components';
import { useGetAnalyticsChartShipmentQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataPoint, LineChart } from '@shopify/polaris-viz';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const TotalShipmentOvertime = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartShipmentQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const chartData: DataPoint[] = useMemo(() => {
    if (data && data.data.shipmentByVolume.categories.length) {
      const { categories, series } = data.data.shipmentByVolume;
      return categories.map((item, index) => ({
        key: item,
        value: series[index],
      }));
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="TOTAL SHIPMENT OVER TIME">
      <div style={{ height: '400px' }}>
        {isLoading ? (
          <SkeletonBodyText lines={20} />
        ) : (
          <ChartCustomTitle xTitle="Time" yTitle="Shipment">
            <LineChart
              data={[
                {
                  data: chartData,
                  name: 'Shipment',
                  color: 'lightseagreen',
                },
              ]}
              theme="Light"
              isAnimated
            />
          </ChartCustomTitle>
        )}
      </div>
    </CustomCard>
  );
};

export default TotalShipmentOvertime;
