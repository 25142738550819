import styled from 'styled-components';

export const StyledRecommendedApps = styled.div<{ $isClose: boolean }>`
  .Polaris-Box {
    height: 100%;
  }

  transition: all 0.75s;
  overflow-y: hidden;
  max-width: 100%;
  max-height: ${({ $isClose }) => ($isClose ? '0' : '45rem')};

  .cursor-pointer {
    cursor: pointer;
  }
`;
