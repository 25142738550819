import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledTemplateEditor = styled.div`
  .save-template-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  [id*='editor'] {
    overflow: auto;
  }

  @media screen and (max-width: ${PolarisBreakPoint.LG}) {
    [id*='editor'] {
      overflow: auto;
    }
  }
`;
