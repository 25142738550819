import { CustomBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';
import { isLockFeature } from '@/helpers';
import { handleToastMutation } from '@/helpers/toast';
import { useUpdatePlanMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ChristmasHeadingBannerProps {
  isClose: boolean;
  onClose: () => void;
}

const ChristmasHeadingBanner = ({ isClose, onClose }: ChristmasHeadingBannerProps) => {
  const dispatch = useDispatch();
  const { subscription } = useSelector(accountSelector);
  const christmasBanner = useSelector(christmasBannerSelector);
  const [updatePlan, { isLoading }] = useUpdatePlanMutation();

  const handleUpgradeToPro = () => {
    updatePlan({
      plan: AccountPlan.Professional,
      subscription,
    }).then((res) => {
      if ('data' in res && res.data && res.data.data) {
        window.open(res.data.data.confirmationUrl, 'updatePlan');
      } else {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
      }
    });
  };

  return isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && christmasBanner.isShow ? (
    <CustomBanner
      isClose={isClose}
      maxHeight="20rem"
      tone="info"
      title="30-Day free trial of advanced features"
      action={{ content: 'Upgrade to Pro', onAction: handleUpgradeToPro, loading: isLoading }}
      onDismiss={onClose}
    >
      Enjoy a 30-day free trial of 3 features: Estimated Delivery Date, Blacklisting, and Advanced Translation. The effective
      start date of the offer is <strong>{christmasBanner.startDate}</strong>. The trial will expire on{' '}
      <strong>{christmasBanner.endDate}</strong>. These features are available in the Professional version. Upgrade if you wish to
      continue using them.
    </CustomBanner>
  ) : (
    <></>
  );
};

export default memo(ChristmasHeadingBanner);
