import { Text, Tooltip } from '@shopify/polaris';

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const camelToRegularText = (str: string) => capitalizeFirstLetter(camelToSnakeCase(str).split('_').join(' '));
export const snakeToStartCase = (string: string) => {
  const result = string
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
  return result;
};

export const camelCaseToStartCase = (string: string) => {
  const result = string.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const spliceContent = (content: string, characterQuantity: number) => {
  if (content && content.length > characterQuantity) {
    return (
      <Tooltip content={content} width="wide">
        <Text variant="bodySm" as="h6">
          {`${content.slice(0, characterQuantity)}...`}
        </Text>
      </Tooltip>
    );
  }
  return (
    <Text variant="bodySm" as="h6">
      {content}
    </Text>
  );
};
