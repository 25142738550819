import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NotificationType } from '@/constants/notification';

export interface INotificationsState {
  selectedTab: NotificationType;
}

const initialState: INotificationsState = {
  selectedTab: NotificationType.Email,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    handleChangeTab: (state, action: PayloadAction<NotificationType>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const notificationsTabSelectedSelector = createSelector(
  (state: RootState) => state.notifications,
  (state) => state.selectedTab,
);
