import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const SmsCreditStyled = styled.div`
  .calc-input-container {
    display: flex;
    align-items: center;

    .calc-title {
      width: 6.5rem;
    }

    .calc-input {
      width: calc(100% - 6.5rem);
    }
  }
  .Polaris-Labelled--hidden {
    width: 100%;
  }
  .balance-report-card {
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.25);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ml-64 {
    padding-left: 4rem;
  }
  .w-100 {
    width: 100%;
  }
  .fz-16 {
    font-size: 1rem;
  }
  .credit-balance {
    padding-block: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 360px;
  }
  @media only screen and (max-width: ${PolarisBreakPoint.LG}) {
    .credit-balance {
      height: 100%;
    }
  }

  .chart-container {
    width: 100%;
    height: 360px;
    .donut-chart {
      height: 100%;
      width: 100%;
      ._Container_3h6ct_1 {
        display: grid;
        justify-content: space-between;
        grid-template-columns: auto auto;
        margin: 0;
        gap: 0.25rem;
        ._Legend_12i9x_150 {
          padding: 0.125rem 0;
        }
      }
    }
  }
`;
