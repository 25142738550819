import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Frame } from '@shopify/polaris';
import Sidebar from './Sidebar';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import { StyledContentLayout } from './styled';
import planSlice, { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserPlanQuery } from '@/redux/api/api.caller';
import { CONSTANT } from '@/helpers';
import dayjs from 'dayjs';
import { DiscountType } from '@/types/account';
import { PATH } from '@/constants';

const AdminLayout = () => {
  const dispatch = useDispatch();
  const account = useSelector(accountSelector);
  const christmasBanner = useSelector(christmasBannerSelector);
  const { pathname } = useLocation();

  const { data } = useGetUserPlanQuery(
    {},
    {
      skip: pathname !== PATH.DASHBOARD,
    },
  );

  useEffect(() => {
    if (data && data.data) {
      const { plan, subscription, isOldPaidUser, isOldUser, isEnabledReview, discount } = data.data;
      const featureDiscount = discount.find((item) => item.type === DiscountType.Feature);

      dispatch(
        planSlice.actions.handleChangePlan({
          ...account,
          plan,
          subscription,
          isOldPaidUser,
          isOldUser,
          isEnabledReview,
        }),
      );

      dispatch(
        planSlice.actions.handleChangeChristmasBanner({
          ...christmasBanner,
          startDate: dayjs(featureDiscount?.startTime).format('MMM DD, YYYY'),
          endDate: dayjs(featureDiscount?.expireTime).format('MMM DD, YYYY'),
          formattedEndDate: new Date(featureDiscount?.expireTime || ''),
          isShow: !!featureDiscount,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Frame navigation={!CONSTANT.isEmbedded && pathname !== PATH.TRACKING_PAGE_CONFIGURATION && <Sidebar />}>
      <StyledContentLayout>
        <div className="main-content">
          <Outlet />
        </div>
        {pathname !== PATH.TRACKING_PAGE_CONFIGURATION && <Footer />}
        <ScrollToTop />
      </StyledContentLayout>
    </Frame>
  );
};

export default AdminLayout;
