export const cleanObject = (obj: any) => {
  const clone = { ...obj };
  for (const [key, value] of Object.entries(clone)) {
    if (typeof value !== 'boolean' && !value) {
      delete clone[key];
    }
    if (value === 'all') {
      delete clone[key];
    }
  }
  return clone;
};

export const transformOptions = <T extends object>(obj: T) =>
  Object.entries(obj).map(([key, value]) => ({
    value,
    label: key,
  }));

export const isEmptyObject = (obj: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
};

export const splitObjects = <T extends object>(obj: T, start: number, end: number) => {
  const props = Object.entries(obj);
  return props.slice(start, end).reduce((splitObj, [key, value]) => {
    (splitObj as any)[key] = value;
    return splitObj
  }, {})
}
