import { SkeletonBodyText, SkeletonBodyTextProps } from '@shopify/polaris';
import { StyledSkeletonText } from './styled';
import { memo } from 'react';

interface SkeletonTextProps extends SkeletonBodyTextProps {
  width?: string;
  height: string;
}

const SkeletonText = ({ width = '100%', height, ...props }: SkeletonTextProps) => {
  return (
    <StyledSkeletonText height={height} width={width}>
      <SkeletonBodyText lines={1} {...props} />
    </StyledSkeletonText>
  );
};

export default memo(SkeletonText);
