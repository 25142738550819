import { ReactNode, memo } from 'react';
import { StyledCustomTable } from './styled';
import {
  Divider,
  EmptySearchResult,
  IndexTable,
  IndexTableProps,
  Pagination,
  PaginationProps,
  SkeletonBodyText,
} from '@shopify/polaris';
import { getPaginationInfo } from '@/helpers';
import { PageSize } from '@/constants/enum';

interface CustomTableProps {
  filter?: JSX.Element | ReactNode;
  tableProps: Omit<IndexTableProps, 'children'>;
  children?: IndexTableProps['children'];
  page?: number;
  pageSize?: PageSize;
  totalResult?: number;
  paginationProps?: PaginationProps;
  isLoading?: boolean;
  skeletonLines?: number;
}

const CustomTable = ({
  filter,
  tableProps,
  paginationProps,
  page = 1,
  pageSize = PageSize.Five,
  totalResult,
  children,
  isLoading = false,
  skeletonLines = 10,
}: CustomTableProps) => {
  const { from, to, isNotFirstPage, isNotLastPage } = getPaginationInfo(page, pageSize, totalResult || 0);

  return (
    <StyledCustomTable>
      {filter}
      <IndexTable
        resourceName={{
          singular: 'record',
          plural: 'records',
        }}
        emptyState={
          isLoading ? (
            <SkeletonBodyText lines={skeletonLines} />
          ) : (
            <div className="no-data">
              <EmptySearchResult
                title={`No ${tableProps.resourceName?.plural} found`}
                description="Try changing the filters or search term"
                withIllustration
              />
            </div>
          )
        }
        {...tableProps}
      >
        {isLoading ? (
          <IndexTable.Row id="loading" position={0}>
            {tableProps.headings.map((_, index) => (
              <IndexTable.Cell key={index}>
                <SkeletonBodyText lines={skeletonLines} />
              </IndexTable.Cell>
            ))}
          </IndexTable.Row>
        ) : (
          children
        )}
      </IndexTable>
      {paginationProps && totalResult !== 0 && (
        <>
          <Divider />
          <Pagination
            label={
              totalResult &&
              `Showing ${from} ${totalResult > 1 ? `to ${to}` : ''} of ${totalResult} ${totalResult > 1 ? 'results' : 'result'}`
            }
            hasNext={isNotLastPage && !isLoading}
            hasPrevious={isNotFirstPage && !isLoading}
            {...paginationProps}
          />
        </>
      )}
    </StyledCustomTable>
  );
};

export default memo(CustomTable);
