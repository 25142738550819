import styled from 'styled-components';

export const StyledBalanceReportCard = styled.div`
  position: relative;
  color: white;
  border-radius: 0.8rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.25);
  .card-decoration {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .card-title {
    padding: 0.5rem 1rem;
    background-color: hsla(0, 0%, 100%, 0.3);
    border-radius: 0.8rem;
  }
  .card-content {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 2.5rem);
    min-height: 10rem;
  }
`;
