import { IndexFiltersProps, TabProps } from '@shopify/polaris';

export const sortOptions: IndexFiltersProps['sortOptions'] = [
  { label: 'Send time', value: 'updatedAt asc', directionLabel: 'Ascending' },
  { label: 'Send time', value: 'updatedAt desc', directionLabel: 'Descending' },
];

export const trackingStatusTab: TabProps[] = [
  { content: 'All', id: 'all' },
  { content: 'Pending', id: 'pending' },
  { content: 'In transit', id: 'in_transit' },
  { content: 'Info received', id: 'information_received' },
  { content: 'Delivered', id: 'delivered' },
  { content: 'Out for Delivery', id: 'out_for_delivery' },
  { content: 'Exception', id: 'exception' },
  { content: 'Expired', id: 'expired' },
  { content: 'Failed Attempt', id: 'fail_attempt' },
];
