import styled from 'styled-components';

export const StyledIntegrationCard = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  .integration-title {
    .how-to-integrate {
      display: flex;
      gap: 1rem;
      align-items: center;

      .Polaris-Icon {
        min-width: 1.25rem;
      }
    }
  }
`;
