import { useEffect } from 'react';
import { StyledGoogleTranslation } from './styled';
import { useSelector } from 'react-redux';
import { languageSelector } from '@/redux/features/trackingPage.slice';

const googleTranslateElementInit = () => {
  new (window as any).google.translate.TranslateElement(
    {
      pageLanguage: 'en',
      autoDisplay: false,
    },
    'google_translate_element',
  );
};

const GoogleTranslation = () => {
  const {
    languageConfig: { googleTranslateExtension, googleTranslatePosition },
  } = useSelector(languageSelector);
  useEffect(() => {
    const scriptId = 'google-translate-script';
    if (!document.getElementById(scriptId)) {
      const addScript = document.createElement('script');
      addScript.id = scriptId;
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      const timer = setTimeout(() => {
        document.body.appendChild(addScript);
        (window as any).googleTranslateElementInit = googleTranslateElementInit;
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <StyledGoogleTranslation
      id="google_translate_element"
      $position={googleTranslatePosition.toLowerCase()}
      $isOpen={googleTranslateExtension}
      $tooltipText="No selection in preview mode"
    >
      <div className="disable_google_translate" />
    </StyledGoogleTranslation>
  );
};

export default GoogleTranslation;
