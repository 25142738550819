import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const CollapsibleStyled = styled.div<{ isOpen: boolean; paddingTitle: string; paddingChildren: string }>`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: ${({ paddingTitle }) => paddingTitle};
    background-color: ${({ isOpen }) => (isOpen ? BgColorPalette.SECONDARY_ACTIVE : 'inherit')};

    &:hover {
      background-color: ${BgColorPalette.SECONDARY_HOVER};
    }
  }
  .Polaris-Collapsible {
    -webkit-backface-visibility: initial;
    backface-visibility: initial;
  }
  .Polaris-Button__Icon {
    width: 100%;
    .Polaris-Icon {
      width: 100%;
    }
  }
  .children {
    padding-block: ${({ paddingChildren }) => paddingChildren};
  }
`;
