import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledShipmentStepper = styled.div`
  li {
    display: flex;
    width: 100%;
    margin: 0;
  }

  li:first-child {
    time:after {
      background-color: ${BgColorPalette.SUCCESS};
    }
  }

  time {
    position: relative;
    padding: 0 1.5em;

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      transform: translateX(50%);
      border-radius: 50%;
      background-color: white;
      border: 2px #ccc solid;
      width: 1rem;
      height: 1rem;
    }
  }

  span {
    padding: 0 2rem 1rem 2rem;
    position: relative;
    width: 60%;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -1px;
      height: 100%;
      border-right: 2px #ccc solid;
    }
  }

  .time-info {
    width: 40%;
    text-align: right;
    padding-right: 2rem;
    color: #637381;
  }
`;
