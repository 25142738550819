export enum ShipmentStatus {
  All = 'all',
  Delivered = 'delivered',
  InTransit = 'in_transit',
  OutForDelivery = 'out_for_delivery',
  InformationReceived = 'information_received',
  Exception = 'exception',
  FailedAttempt = 'failed_attempt',
  Expired = 'expired',
  Pending = 'pending',
  CourierNotSupported = 'courier_not_supported',
  OverQuota = 'over_quota',
}
