export const uploadFileConfig = {
  trackingForm: [
    {
      key: 'asset1',
      title: 'Asset 1',
      size: '255px x 255px',
    },
    {
      key: 'asset2',
      title: 'Asset 2',
      size: '255px x 255px',
    },
    {
      key: 'asset3',
      title: 'Asset 3',
      size: '255px x 540px',
    },
  ],
  trackingResult: [
    {
      key: 'asset4',
      title: 'Asset 4',
      size: '460px x 370px',
    },
    {
      key: 'asset5',
      title: 'Asset 5',
      size: '460px x 370px',
    },
  ],
};
