import styled from 'styled-components';

export const StyledShipmentInfo = styled.div`
  .shipment-header {
    .header-loading {
      height: 7.75rem;
    }

    .shipment-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .shipment-status {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    .Polaris-Text--root.Polaris-Text--headingSm.Polaris-Text--semibold,
    .Polaris-Text--root.Polaris-Text--headingMd.Polaris-Text--semibold {
      margin-block: 0.75rem;
    }
  }

  .shipment-description {
    padding-block: 1rem;
  }

  .shipment-items {
    margin-top: 1rem;

    .product-list {
      max-height: 30rem;
      overflow-y: auto;

      .product-loading {
        height: 8rem;
        padding: 1rem;
      }
    }
  }

  .shipment-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 1rem;
  }
`;
