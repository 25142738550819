import { CustomCard, DonutChartCustom } from '@/components';
import { ShipmentByStatusPalette } from '@/constants';
import { calcPercent } from '@/helpers';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText, Text } from '@shopify/polaris';
import { DataSeries, DonutChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { noDataUI } from '@/constants/empty';

const MarketingAssets = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data && data.data.marketingClick.marketingAsset.series.find((item) => item !== 0)) {
      const { categories, series, total } = data.data.marketingClick.marketingAsset;
      return categories.map((item, index) => ({
        name: `${item} (${calcPercent(series[index], total)}%)`,
        color: Object.values(ShipmentByStatusPalette)[index],
        data: [
          {
            key: item,
            value: series[index],
          },
        ],
      }));
    }
    return [];
  }, [data]);

  const renderUIByData = () => {
    if (isLoading) {
      return <SkeletonBodyText lines={25} />;
    }
    if (!chartData.length) {
      return noDataUI;
    }
    return (
      <DonutChartCustom>
        <DonutChart data={chartData} theme="Light" legendPosition="bottom" showLegend />
      </DonutChartCustom>
    );
  };

  return (
    <CustomCard
      title="MARKETING ASSETS PERFORMANCE"
      tooltip={
        <>
          <Text as="h6" variant="headingSm">
            Marketing assets performance
          </Text>
          <p>
            We calculate the percentages that each asset position contributing to the total clicks. The assets order is counted
            from left to right, then from top to bottom
          </p>
          <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/marketing_performance.webp`} alt="Marketing assets performance" width={190} />
        </>
      }
    >
      {renderUIByData()}
    </CustomCard>
  );
};

export default MarketingAssets;
