export enum AnalyticsTabs {
  ShipmentReport = 'shipment_report',
  CourierReport = 'courier_report',
  TrackingPageReport = 'tracking_page_report',
  ReviewDashboard = 'review_dashboard',
  NPSReport = 'NPS_report',
}

export enum EmailTabs {
  EmailAccount = 'email_account',
  EmailTemplates = 'email_templates',
  EmailHistory = 'email_history',
}

export enum TrackingPageTabs {
  SetUp = 'set_up',
  LookAndFeel = 'look_and_feel',
  TrackingOptions = 'tracking_options',
  Language = 'language',
  Edd = 'edd',
  CustomStatus = 'custom_status',
  Blacklist = 'blacklist',
  ProductRecommendation = 'product_recommendation',
  Review = 'review',
  Social = 'social',
  HtmlCss = 'html_css',
  NoInfo = 'no_info',
}
