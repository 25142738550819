import { BlockStack, InlineGrid } from '@shopify/polaris';
import NPSReportOverview from './components/NPSReportOverview';
import NetPromoterScore from './components/NetPromoterScore';
import ResponsesOverTime from './components/ResponsesOverTime';

const NPSReport = () => {
  return (
    <BlockStack gap="400">
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap="400">
        <NPSReportOverview />
      </InlineGrid>
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
        <NetPromoterScore />
        <ResponsesOverTime />
      </InlineGrid>
    </BlockStack>
  );
};

export default NPSReport;
