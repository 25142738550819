import { StyledIntegrationCard } from './styled';
import { Button, ButtonGroup, Icon, Link, Text } from '@shopify/polaris';
import { ExternalMinor, GrammarMajor } from '@shopify/polaris-icons';
import { ReactNode, memo } from 'react';

interface IntegrationCardProps {
  avatar: string;
  avatarAlt?: string;
  title: string;
  description: string;
  platform?: 'Avada' | 'PageFly';
  platformLink?: string;
  buttonGroup?: ReactNode;
  getAppLink: string;
}

const IntegrationCard = ({
  avatar,
  avatarAlt = 'Logo',
  title,
  description,
  platform,
  platformLink,
  buttonGroup,
  getAppLink,
}: IntegrationCardProps) => {
  return (
    <StyledIntegrationCard>
      <img src={avatar} alt={avatarAlt} width={100} />
      <div className="integration-title">
        <Text variant="headingSm" as="h6">
          {title}
        </Text>
        <div className="mt-8" />
        <p>{description}</p>
        <div className="mt-8" />
        {platform && (
          <div className="how-to-integrate">
            <Icon source={GrammarMajor} tone="subdued" />
            <Link url={platformLink}>{`How to integrate Omega Order Tracking with ${platform}?`}</Link>
          </div>
        )}
        <div className="mt-16" />
        <ButtonGroup variant="segmented">
          {buttonGroup}
          <Link url={getAppLink}>
            <Button icon={ExternalMinor}>Get App</Button>
          </Link>
        </ButtonGroup>
      </div>
    </StyledIntegrationCard>
  );
};

export default memo(IntegrationCard);
