import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledOrderDescription = styled.div`
  .order-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const StyleOrderModal = styled.div`
  .order-section {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }

    p {
      color: #6d7175;
      padding-bottom: 0.25rem;
    }

    .business-days {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
      .business-days {
        gap: 1rem;
        justify-content: flex-start;
      }
    }
  }
`;
