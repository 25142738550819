import { useState, useEffect, MutableRefObject } from 'react';

function useOnScreen(ref: MutableRefObject<Element | null>, rootMargin: string = '0px'): boolean {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const currentElement = ref?.current;

    if (currentElement) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        {
          rootMargin,
        },
      );

      observer.observe(currentElement);

      return () => {
        observer.unobserve(currentElement);
      };
    }
  });

  return isVisible;
}

export default useOnScreen;
