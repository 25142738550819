import styled from 'styled-components';

export const StyledLocalConfiguration = styled.div<{ $isMdDown: boolean }>`
  .nav-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
  }

  .content-container {
    display: ${({ $isMdDown }) => ($isMdDown ? 'block' : 'flex')};
  }

  .Polaris-FullscreenBar {
    width: 100vw;
  }
`;
