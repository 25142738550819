import SmsHistoryTable from './component/SmsHistoryTable';
import SmsProgress from './component/SmsProgress';
import { BlockStack } from '@shopify/polaris';

const SmsHistory = () => {
  return (
    <BlockStack gap="400">
      <SmsProgress />
      <SmsHistoryTable />
    </BlockStack>
  );
};

export default SmsHistory;
