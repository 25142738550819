export const handleSendChristmasMessage = () => {
  try {
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', 'I want to claim the offer for Order Tracking app']]);
    $crisp.push(['set', 'session:data']);
  } catch (error) {}
};

export const handleSendMoreShipmentMessage = () => {
  try {
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', 'I want to discuss for more shipments volumn for Order Tracking app']]);
    $crisp.push(['set', 'session:data']);
  } catch (error) {}
};

export const handleOpenChat = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {}
};

export const handleCrossSellReturn = () => {
  try {
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', "I'd like to support courier integration. Let's discuss!"]]);
    $crisp.push(['set', 'session:data']);
  } catch (error) {}
};
