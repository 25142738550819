import { Button } from '@shopify/polaris';
import { ControlBarStyled } from './styled';
import { memo, useEffect } from 'react';
import useEventListener from '@/hooks/useEventListener';

interface Props {
  hidden: boolean;
  actionGroup: Array<{
    content: string;
    onAction: () => void;
    primary?: boolean;
    disabled?: boolean;
    loading?: boolean;
  }>;
  changeFields?: any;
}

const ControlBar = ({ actionGroup, hidden, changeFields }: Props) => {
  let ctrlOrCommandPressed = false;

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = 'Changes you made may not be saved.';
  };

  const handKeyDownSearchOnPage = (e: KeyboardEvent) => {
    if (e.ctrlKey || e.key === 'Meta') {
      ctrlOrCommandPressed = true;
    }
    if (ctrlOrCommandPressed && e.key === 's') {
      e.preventDefault();
      ctrlOrCommandPressed = false;
      const saveAction: any = actionGroup.find((item) => item.content === 'Save' || item.primary);
      return saveAction.onAction();
    }
  };

  useEventListener('keydown', handKeyDownSearchOnPage);

  useEffect(() => {
    if (changeFields) {
      window.addEventListener('beforeunload', alertUser);
    } else {
      window.removeEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeFields]);

  return !hidden ? (
    <ControlBarStyled>
      {actionGroup.map((item, index) => {
        return (
          <Button onClick={item.onAction} {...item} key={index}>
            {item.content}
          </Button>
        );
      })}
    </ControlBarStyled>
  ) : null;
};

export default memo(ControlBar);
