import { CustomCard } from '@/components';
import { calcTotal } from '@/helpers';
import { useGetReviewAnalyticQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { useAppSelector } from '@/redux/store';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';

const AdditionalComment = () => {
  const datePicker = useAppSelector(datePickerSelector);
  const { data, isLoading } = useGetReviewAnalyticQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.additionalComment.categories.length) {
      const { categories, series } = data.data.additionalComment;
      return [
        {
          name: 'Additional comment',
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: series[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);

  return (
    <CustomCard title={`ADDITIONAL COMMENT (${calcTotal(data?.data.additionalComment.series || [])})`}>
      <div className="chart-container">
        {isLoading ? (
          <SkeletonBodyText lines={27} />
        ) : (
          <div style={{ height: '400px' }}>
            <LineChart theme="Light" data={chartData} isAnimated />
          </div>
        )}
      </div>
    </CustomCard>
  );
};

export default AdditionalComment;
