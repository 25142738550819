import { handleToastMutation } from '@/helpers/toast';
import { useLazyGetUserPlanQuery, useUpdateDiscountCodeMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { accountSelector } from '@/redux/features/plan.slice';
import { DiscountType, IUpdateDiscountCode } from '@/types/account';
import { Modal, TextField } from '@shopify/polaris';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface DiscountCodeProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenConfirmModal: () => void;
}

const DiscountCode = ({ isOpen, onClose, onOpenConfirmModal }: DiscountCodeProps) => {
  const dispatch = useDispatch();
  const [getUserPlan] = useLazyGetUserPlanQuery();
  const { plan } = useSelector(accountSelector);
  const [discount, setDiscount] = useState<{ code: string; error: string }>({ code: '', error: '' });
  const [updateDiscountCode, updateDiscountCodeData] = useUpdateDiscountCodeMutation();

  const handleChange = (value: string) => {
    setDiscount({
      ...discount,
      code: value,
      error: value === '' ? 'You have not entered the discount code' : '',
    });
  };

  const handleApplyDiscountCode = () => {
    const payload: IUpdateDiscountCode = {
      discountCode: discount.code,
      plan,
    };
    updateDiscountCode(payload).then((data: any) => {
      const discountType = data?.data?.type;
      getUserPlan({ screen: 'plan' });
      onClose();
      setDiscount((prev) => ({ ...prev, code: '' }));
      discountType === DiscountType.Feature
        ? onOpenConfirmModal()
        : dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Apply discount code"
      primaryAction={{
        content: 'Apply',
        onAction: handleApplyDiscountCode,
        disabled: discount.code === '',
        loading: updateDiscountCodeData.isLoading,
      }}
    >
      <Modal.Section>
        <TextField
          autoComplete="off"
          label="Discount code:"
          helpText={
            <span>
              <b>Note: </b>
              <p style={{ display: 'inline' }}>
                Discount code does not apply to yearly plans (yearly plans receive 20% discount by default).
              </p>
            </span>
          }
          value={discount.code}
          onChange={handleChange}
          error={discount.error}
        />
      </Modal.Section>
    </Modal>
  );
};

export default memo(DiscountCode);
