export const calcTotal = (data: Array<number>) => {
  const result = data.reduce((total, item) => total + item, 0);
  return result;
};

export const calcPercent = (numerator: number, denominator: number): number => {
  if (!denominator) return 0;
  const result = (numerator * 100) / denominator;
  return Number(result.toFixed(1));
};

export const currencyFormatter = (currency?: string) =>
  new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency || 'VND',
  });

export const heightPercent = (fileSize: string) => {
  const widthToNumber = parseInt(fileSize.split(' x ')[0].split('p')[0].trim(), 10);
  const heightToNumber = parseInt(fileSize.split(' x ')[1].split('p')[0].trim(), 10);
  return heightToNumber / widthToNumber;
}
