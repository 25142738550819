import styled from 'styled-components';

export const StyledIntegration = styled.div`
  .Polaris-FormLayout {
    margin-top: 1rem;
    .eye-icon {
      svg {
        cursor: pointer;
      }
    }
  }

  .connected-result {
    min-width: 20rem;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    display: flex;
    gap: 1rem;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;

    .left-content {
      display: flex;
      gap: 0.5rem;

      .app-id {
        min-width: 3rem;
      }
    }
  }
`;
