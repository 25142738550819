import { AccountPlan } from '@/constants/enum';
import store, { RootState } from '@/redux/store';

export interface SubscriptionPlansProps {
  plan: AccountPlan;
  prePlan?: AccountPlan;
  displayPlan: string;
  description: string;
  quota: number | string;
  benefits: Array<string>;
}

export const getSubscriptionPlans = (): Array<SubscriptionPlansProps> => {
  const {
    plan: {
      account: { isOldUser },
    },
  } = store.getState() as RootState;

  return [
    {
      plan: AccountPlan.Starter,
      displayPlan: AccountPlan.Starter.toUpperCase(),
      description: 'Best for small business',
      quota: isOldUser ? 75 : 35,
      benefits: [
        'Notifications',
        'Branded tracking page',
        'Google translation',
        'Live chat support',
        '400+ courier integrations',
      ],
    },
    {
      plan: AccountPlan.Basic,
      prePlan: AccountPlan.Starter,
      displayPlan: AccountPlan.Basic.toUpperCase(),
      description: 'Best for medium business',
      quota: 600,
      benefits: ['SMS notifications', 'Product upsell', 'Analytics', 'Priority Support', 'CSV export', 'A bonus of $1 for SMS'],
    },

    {
      plan: AccountPlan.Professional,
      prePlan: AccountPlan.Basic,
      displayPlan: AccountPlan.Professional.toUpperCase(),
      description: 'Best for large business',
      quota: 3000,
      benefits: [
        'Estimated Delivery Date',
        'Blacklisting & Hide shipping locations',
        'Advanced translation',
        'Courier mapping',
        'Custom tracking page',
        'Custom carrier integration',
        'A bonus of $2 for SMS',
      ],
    },
    {
      plan: AccountPlan.Enterprise,
      prePlan: AccountPlan.Professional,
      displayPlan: AccountPlan.Enterprise.toUpperCase(),
      description: 'Best for Shopify Plus',
      quota: isOldUser ? 'Unlimited' : 10000,
      benefits: ['Custom domain for tracking page', 'Custom analytics', 'Dedicated onboarding support', 'A bonus of $3 for SMS'],
    },
  ];
};
