export const newVersionDetail = [
  {
    title: '🚀 Improved Performance',
    description: 'Faster load times and smoother interactions. <br /> Optimized for speed and efficiency.',
  },
  {
    title: '🎯 Enhanced Features',
    description: 'Enjoy a host of new features and enhancements designed to make your experience even better.',
  },
  {
    title: '🌐 Greater Reliability',
    description: 'Dependable notification delivery with Amazon services.',
  },
  {
    title: '🔧 Streamlined Setup',
    description: 'No more configuring external email accounts. <br /> Quick and easy setup process.',
  },
  {
    title: '🔒 Enhanced Security',
    description: 'Stronger security measures to protect your data. <br/> Your information is kept secure.',
  },
  {
    title: '🔮 Future-Proofing',
    description: 'Ongoing updates and improvements to stay ahead.',
  },
];
