import { Card, Icon, OptionList, OptionListProps } from '@shopify/polaris';
import { StyledCustomPage } from './styled';
import { memo, useEffect } from 'react';
import { IConfigTab } from '@/types/tab';
import { PolarisBreakPoint } from '@/constants/enum';

interface CustomPageProps extends OptionListProps {
  tabsConfig: Array<IConfigTab>;
  navResponsiveWidth?: string;
  isShowLockFeature?: boolean;
  selectedTab?: string;
}

const CustomPage = ({
  tabsConfig,
  navResponsiveWidth = PolarisBreakPoint.LG,
  isShowLockFeature,
  selectedTab,
  ...props
}: CustomPageProps) => {
  const listTab = tabsConfig.map((tab) => {
    const label = (
      <div className="tab-item" key={tab.value}>
        <Icon source={tab.icon} />
        <b className="tab-label">{tab.label}</b>
      </div>
    );
    return {
      label,
      value: tab.value,
    };
  });

  useEffect(() => {
    if (selectedTab) {
      window.scrollTo(0, 0);
    }
  }, [selectedTab]);

  return (
    <StyledCustomPage navResponsiveWidth={navResponsiveWidth}>
      <div className="page-tabs">
        <Card>
          <OptionList options={listTab} {...props} />
        </Card>
      </div>
      <div className="page-content">{tabsConfig.find((item) => item.value === props.selected[0])?.component}</div>
    </StyledCustomPage>
  );
};

export default memo(CustomPage);
