export enum SenderEmailType {
  NoReply = 'no_reply',
  YourEmail = 'your_email',
}

export enum TrackingStatus {
  'All' = 'all',
  'Pending' = 'pending',
  'Information received' = 'information_received',
  'In transit' = 'in_transit',
  'Delivered' = 'delivered',
  'Out for Delivery' = 'out_for_delivery',
  'Exception' = 'exception',
  'Expired' = 'expired',
  'Failed Attempt' = 'failed_attempt',
}

export enum EmailProvider {
  'Gmail' = 'gmail',
  'EasyName' = 'easyname',
  'Outlook, Hotmail and Live' = 'hotmail',
  'Bluehost' = 'bluehost',
  'Yahoo' = 'yahoo',
  'AOL' = 'aol',
  'zoho' = 'zoho',
  'Office 365' = 'office',
  'Amazon SES' = 'ses',
  'Other' = 'other',
}

export enum SenderInfoAction {
  Edit = 'edit',
  Disconnect = 'disconnect',
  Connect = 'connect',
}

export enum VariableTags {
  TrackingSummary = 'tracking_summary',
  StoreName = 'store_name',
  TrackingNumber = 'tracking_number',
  Courier = 'courier',
  OrderName = 'order_name',
  TrackingUrl = 'tracking_url',
  ProductList = 'product_list',
  OrderDate = 'order_date',
  ShippingAddress = 'shipping_address',
  StoreEmail = 'store_email',
  FirstName = 'first_name',
  TotalPrice = 'total_price',
  EstimatedDeliveryDate = 'estimated_delivery_date',
}
