import { TrackingStatus, optionsPerPage } from '@/constants';
import emailSlice, { emailHistorySelector } from '@/redux/features/email.slice';
import { ChoiceList, IndexFilters, useSetIndexFiltersMode } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { sortOptions, trackingStatusTab } from './config';
import { PageSize, SortDirection } from '@/constants/enum';

const EmailHistoryFilter = () => {
  const { mode, setMode } = useSetIndexFiltersMode();
  const dispatch = useDispatch();
  const historyState = useSelector(emailHistorySelector);
  const selectedEmailHistoryTab = trackingStatusTab.findIndex((item) => item.id === historyState.status) || 0;

  const handleUpdateSort = (value: string[]) => {
    const formattedValue = value.toString().split(' ');
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({
        ...historyState,
        sortBy: formattedValue[0],
        sortOrder: formattedValue[1].toUpperCase() as SortDirection,
      }),
    );
  };

  const handleChangeTable = (key: string) => (value: string[]) => {
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({
        ...historyState,
        page: 1,
        [key]: value[0],
      }),
    );
  };

  const handleChangeInputSearch = (value: string) => {
    dispatch(emailSlice.actions.handleChangeEmailHistory({ ...historyState, page: 1, search: value }));
  };

  const handleClearAll = () => {
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({
        ...historyState,
        page: 1,
        pageSize: PageSize.Five,
        search: '',
        status: TrackingStatus.All,
        sortBy: 'updatedAt',
        sortOrder: SortDirection.DESC,
      }),
    );
  };

  const handleSelectTabs = (selectedTabIndex: number) => {
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({
        ...historyState,
        page: 1,
        status: (trackingStatusTab.find((_, index) => index === selectedTabIndex)?.id as TrackingStatus) || TrackingStatus.All,
      }),
    );
  };

  const appliedFilters = [
    {
      onRemove: () => handleChangeTable('pageSize')(['5']),
      key: 'pageSize',
      label: `Per page: ${historyState.pageSize}`,
    },
  ];

  const filters = [
    {
      key: 'pageSize',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={optionsPerPage}
          selected={[String(historyState.pageSize)]}
          onChange={handleChangeTable('pageSize')}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <IndexFilters
      mode={mode}
      setMode={setMode}
      cancelAction={{ onAction: () => {} }}
      canCreateNewView={false}
      sortOptions={sortOptions}
      sortSelected={[`${historyState.sortBy} ${historyState.sortOrder.toLowerCase()}`]}
      onSort={handleUpdateSort}
      queryPlaceholder="Tracking number, Order ID"
      queryValue={historyState.search}
      onQueryChange={handleChangeInputSearch}
      onQueryClear={() => handleChangeInputSearch('')}
      appliedFilters={appliedFilters}
      filters={filters}
      onClearAll={handleClearAll}
      tabs={trackingStatusTab}
      selected={selectedEmailHistoryTab}
      onSelect={handleSelectTabs}
    />
  );
};

export default EmailHistoryFilter;
