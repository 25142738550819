import { tokenSelector } from '@/redux/features/auth.slice';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const { isAuth } = useSelector(tokenSelector);

  return isAuth ? <Outlet /> : <>Not Authenticated</>;
};

export default ProtectedRoute;
