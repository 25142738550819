import { CSSProperties, ReactNode, memo } from 'react';
import { StyledRow } from './styled';

interface RowProps {
  column: number;
  children: ReactNode;
  gap?: string;
  style?: CSSProperties;
  className?: string;
}

const Row = ({ column, children, gap = '1rem', style, className }: RowProps) => {
  return (
    <StyledRow column={column} gap={gap} style={style} className={className}>
      {children}
    </StyledRow>
  );
};

export default memo(Row);
