import { ChartCustomTitle, CustomCard } from '@/components';
import { ShipmentByStatusPalette } from '@/constants';
import { useGetAnalyticsChartShipmentQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const Top3Destinations = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartShipmentQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });
  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.shipmentByDestination.categories.length) {
      const { categories, series } = data.data.shipmentByDestination;
      let otherCombinedPercent: number = 100;
      const chartDataCustom: DataSeries[] = series.map((item, index) => {
        otherCombinedPercent -= +item.percent;
        return {
          name: `${item?.name} (${item?.percent}%)`,
          color: Object.values(ShipmentByStatusPalette)[index],
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: item.series[index],
          })),
        };
      });
      chartDataCustom.push({
        name: `OtherCombined (${
          otherCombinedPercent === 100 ? 0 : +otherCombinedPercent.toFixed(1) === 0.0 ? 0 : otherCombinedPercent.toFixed(1)
        }%)`,
        data: [],
      });
      return chartDataCustom;
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="TOP 3 DESTINATIONS">
      <div style={{ height: '400px' }}>
        {isLoading ? (
          <SkeletonBodyText lines={20} />
        ) : (
          <ChartCustomTitle xTitle="Time" yTitle="Percent">
            <LineChart data={chartData} theme="Light" isAnimated />
          </ChartCustomTitle>
        )}
      </div>
    </CustomCard>
  );
};

export default Top3Destinations;
