import styled from 'styled-components';

export const StyledNavigation = styled.div<{ isShowLockIcon: boolean }>`
  .Polaris-Navigation__PrimaryNavigation {
    justify-content: space-between;
  }

  .Polaris-Navigation__Section {
    li {
      padding-block: 2px;
    }

    &:first-child {
      li:nth-child(3) {
        .Polaris-Navigation__ItemInnerWrapper {
          .Polaris-Navigation__SecondaryActions {
            display: ${({ isShowLockIcon }) => (isShowLockIcon ? 'none' : '')};
          }
        }
      }
    }
  }
`;
