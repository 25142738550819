import { LINK } from '@/constants';

const Bfcm2024 = () => {
  return (
    <div onClick={() => window.open(LINK.LINK_BFCM, '_blank')} style={{ marginBottom: '0.75rem' }}>
      <img
        src={LINK.THUMBNAIL_BFCM}
        alt="bfcm-2024"
        style={{
          borderRadius: '0.5rem',
          cursor: 'pointer',
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default Bfcm2024;
