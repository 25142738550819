import ContentCollapsible from '../ContentCollapsible';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { collapsibleSelector } from '@/redux/features/trackingPage.slice';
import { linkCollapsibleConfig } from './config';
import { CollapsibleLink } from '@/constants/trackingPage';

const CustomizeLink = () => {
  const collapsible = useSelector(collapsibleSelector);
  const dispatch = useDispatch();

  const handleToggle = (id: CollapsibleLink) => {
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        text: {
          key: collapsible.text.key === id ? '' : id,
          isCollapse: collapsible.text.key !== id,
        },
      }),
    );
  };

  return (
    <>
      {linkCollapsibleConfig.map(({ id, title, component }) => {
        const { key, isCollapse } = collapsible.text;

        return (
          <ContentCollapsible key={id} title={title} isOpen={isCollapse && key === id} onToggle={() => handleToggle(id)}>
            {component}
          </ContentCollapsible>
        );
      })}
    </>
  );
};

export default CustomizeLink;
