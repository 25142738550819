import { currencyFormatter } from '@/helpers';
import { IShipmentLineItems } from '@/types/shipment';

export const resyncOrderInfo = [
  {
    content: '60 days',
    dayQuantity: 60,
  },
  {
    content: '30 days',
    dayQuantity: 30,
  },
  {
    content: '14 days',
    dayQuantity: 14,
  },
  {
    content: '7 days',
    dayQuantity: 7,
  },
  {
    content: 'Today',
    dayQuantity: 1,
  },
];

export const calcSubtotalPrice = (lineItems: IShipmentLineItems[], currency?: string) =>
  currencyFormatter(currency).format(lineItems.reduce((prevValue, { price }) => prevValue + Number(price), 0));
