import { TrackingPagePreviewFocus } from '@/constants/trackingPage';

export const footerLinkConfig = {
  social: [
    {
      key: 'facebook',
      title: 'Facebook',
      placeHolder: 'https://www.facebook.com',
      id: TrackingPagePreviewFocus.Facebook,
    },
    {
      key: 'twitter',
      title: 'Twitter (X)',
      placeHolder: 'https://www.twitter.com',
      id: TrackingPagePreviewFocus.Twitter,
    },
    {
      key: 'instagram',
      title: 'Instagram',
      placeHolder: 'https://www.instagram.com',
      id: TrackingPagePreviewFocus.Instagram,
    },
    {
      key: 'tiktok',
      title: 'Tiktok',
      placeHolder: 'https://www.tiktok.com',
      id: TrackingPagePreviewFocus.Tiktok,
    },
    {
      key: 'pinterest',
      title: 'Pinterest',
      placeHolder: 'https://www.pinterest.com',
      id: TrackingPagePreviewFocus.Pinterest,
    },
  ],
  policy: [
    {
      key: 'support',
      title: 'Support contact',
      placeHolder: 'Email or URL',
      id: TrackingPagePreviewFocus.support,
    },
    {
      key: 'returnPage',
      title: 'Returns',
      placeHolder: 'https://www.store.com/return',
      id: TrackingPagePreviewFocus.return,
    },
    {
      key: 'termPage',
      title: 'Terms page',
      placeHolder: 'https://www.store.com/terms',
      id: TrackingPagePreviewFocus.term,
    },
    {
      key: 'privacyPage',
      placeHolder: 'https://www.store.com/privacy',
      title: 'Privacy page',
      id: TrackingPagePreviewFocus.privacy,
    },
  ],
};
