import { CustomCard, ImagePreview } from '@/components';
import { StyledUpdateTheme } from './styled';
import { Link } from '@shopify/polaris';
import { Stepper } from '@/components';
import { LINK } from '@/constants';
import { useSelector } from 'react-redux';
import { tokenSelector } from '@/redux/features/auth.slice';
import { CopyIcon } from '@/icons';

const copiedCodeTemplate = `<script>
            var scripts = 'https://apps.synctrack.io/order-tracking/script_tags/script.js';
            var script = document.createElement("script");
            script.src = scripts + "?v=" + Date.now();
            document.body.appendChild(script);
        </script>`;

const UpdateTheme = () => {
  const { shop } = useSelector(tokenSelector);

  const codeTemplate = (
    <>
      <code>{'<script>'}</code>
      <br />
      <div className="code-content">
        <code>
          var scripts = 'https://apps.synctrack.io/order-tracking/script_tags/script.js';
          <br />
          var script = document.createElement("script");
          <br />
          script.src = scripts + "?v=" + Date.now();
          <br />
          document.body.appendChild(script);
        </code>
        <br />
      </div>
      <code>{'</script>'}</code>
    </>
  );

  return (
    <StyledUpdateTheme>
      <CustomCard title="Update Theme 2.0">
        <p>
          If you are using the app in Theme 2.0, we will show you how to add script tag into your theme. You can follow these
          steps below:
        </p>
        <div className="content">
          <Stepper title="Copy the code below">
            <div className="code-template">
              <div className="template-layout">
                <span>{codeTemplate}</span>
                <CopyIcon copiedText={copiedCodeTemplate} />
              </div>
            </div>
          </Stepper>
          <Stepper
            title={
              <div style={{ marginTop: 8 }}>
                Go to Settings ={'> '}
                <Link url={LINK.CHECKOUT_SETTINGS(shop.split('.')[0])}>Checkout</Link>
              </div>
            }
          />
          <Stepper title="Scroll down to “Order status page” section => Paste Scripts code => Click Save">
            <ImagePreview imageSrc={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/settings/update_theme.webp`} imageAlt="Update theme" />
          </Stepper>
        </div>
      </CustomCard>
    </StyledUpdateTheme>
  );
};

export default UpdateTheme;
