import { IConfigTab } from '@/types/tab';
import { CalendarTimeMinor, CartDownMajor, PagePlusMajor, StoreStatusMajor, TextBlockMajor } from '@shopify/polaris-icons';
import EstimatedDeliveryDate from './components/EDD';
import CustomStatus from './components/CustomStatus';
import ProductRecommendation from './components/ProductRecommendation';
import Blacklisting from './components/Blacklisting';
import { TrackingPageTabs } from '@/constants';
import EmbedTrackingPage from './components/EmbedTrackingPage';
export const tabsConfigPage: Array<IConfigTab> = [
  {
    value: TrackingPageTabs.SetUp,
    label: 'Tracking page set up',
    component: <EmbedTrackingPage />,
    icon: PagePlusMajor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Edd,
    label: 'Estimated Delivery Date',
    component: <EstimatedDeliveryDate />,
    icon: CalendarTimeMinor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Blacklist,
    label: 'Blacklisting',
    component: <Blacklisting />,
    icon: TextBlockMajor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.CustomStatus,
    label: 'Custom status',
    component: <CustomStatus />,
    icon: StoreStatusMajor,
  },
  {
    value: TrackingPageTabs.ProductRecommendation,
    label: 'Product Recommendation',
    component: <ProductRecommendation />,
    icon: CartDownMajor,
  },
];

export const isNotChangeData = (oldData: string[], currentData: string[]) => {
  return (
    [...oldData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('') ===
    [...currentData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('')
  );
};
