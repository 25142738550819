import styled from 'styled-components';

interface Props {
  background: string;
  textColor: string;
  borderRadius: number;
}

export const LookAndFeelStyleStyled = styled.div`
  .Polaris-ColorPicker__HuePicker {
    display: none;
  }
  .Polaris-ColorPicker__MainColor {
    width: 100%;
  }
`;

export const CustomBtnStyled = styled.div<Props>`
  text-align: center;
  line-height: 2rem;
  height: 2rem;
  background-color: ${(props) => props.background};
  color: ${(props) => props.textColor};
  border-radius: ${(props) => `${props.borderRadius}px`};
`;
