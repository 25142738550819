import { memo, useState } from 'react';
import { StyledImage, StyledImagePreview } from './styled';
import { CancelMajor, ViewMajor } from '@shopify/polaris-icons';
import { Button, Icon, Text } from '@shopify/polaris';

interface ImagePreviewProps {
  type?: 'text' | 'image';
  imageSrc: string;
  imageAlt?: string;
  imageWidth?: string | number;
  imageHeight?: string;
  text?: string;
}

const ImagePreview = ({
  type = 'image',
  imageSrc,
  imageAlt = 'Clickable preview',
  text = 'View image',
  imageWidth,
  imageHeight,
}: ImagePreviewProps) => {
  const [isOpenPreview, setOpenPreview] = useState<boolean>(false);

  const handleClosePreview = () => setOpenPreview(false);

  const handleOpenPreview = () => setOpenPreview(true);

  const handleClickImage = (e: any) => e.stopPropagation();

  document.onkeydown = (e) => {
    if (e.key === 'Escape' && isOpenPreview) {
      handleClosePreview();
    }
  };

  return (
    <>
      {type === 'image' ? (
        <StyledImage style={{ width: imageWidth, height: imageHeight }} onClick={handleOpenPreview}>
          <img src={imageSrc} alt={imageAlt} width="100%" height="100%" />
          <div className="preview-text">
            <Icon source={ViewMajor} tone="base" />
            <Text as="h6" variant="bodyMd">
              Preview
            </Text>
          </div>
        </StyledImage>
      ) : (
        <Button variant="plain" onClick={handleOpenPreview} icon={ViewMajor}>
          {text}
        </Button>
      )}
      <StyledImagePreview tabIndex={0} isOpen={isOpenPreview}>
        <div className="preview-header">
          <div onClick={handleClosePreview} className="close-icon">
            <Icon source={CancelMajor} tone="base" />
          </div>
        </div>
        <div className="preview-wrapper" onClick={handleClosePreview}>
          <img onClick={handleClickImage} src={imageSrc} alt="preview" />
        </div>
      </StyledImagePreview>
    </>
  );
};

export default memo(ImagePreview);
