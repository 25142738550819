import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledPreviewFooter = styled.div`
  .omg-social-media {
    .footer-focus {
      padding: 0.3rem 0;
      border-radius: 0.25rem;
      cursor: pointer;
      &:hover {
        outline: 1px ${BgColorPalette.FOCUS} dashed;
      }
      &.active {
        outline: 1px ${BgColorPalette.FOCUS} solid;
      }
    }
  }

  .omg-policy {
    div {
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      &:hover {
        outline: 1px ${BgColorPalette.FOCUS} dashed;
      }
      &.active {
        outline: 1px ${BgColorPalette.FOCUS} solid;
      }
    }
  }
`;
