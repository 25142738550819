import { InlineGrid, Text } from '@shopify/polaris';
import { StyledBalanceReportCard } from './styled';
import { memo } from 'react';

interface BalanceReportCardProps {
  bgImage: string;
  title: string;
  topLeftText: string | number;
  topRightText: string;
  bottomLeftText: string | number;
  bottomRightText: string;
}

const BalanceReportCard = ({
  bgImage,
  title,
  bottomLeftText,
  bottomRightText,
  topLeftText,
  topRightText,
}: BalanceReportCardProps) => {
  return (
    <StyledBalanceReportCard style={{ backgroundImage: bgImage }}>
      <div className="card-decoration">
        <svg width="172" height="74" viewBox="0 0 172 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.5847 54.748C24.4322 55.2293 7.88136 67.7832 0 74H186L184.424 0C170.5 3.20867 139.658 12.3935 127.678 23.4634C112.703 37.3008 92.2119 51.7397 88.2712 46.9267C84.3305 42.1137 74.8729 34.2925 71.7203 34.8942C68.5678 35.4958 65.4153 42.1137 53.5932 54.748C41.7712 67.3823 42.5593 63.7724 40.9831 63.1707C39.4068 62.5691 31.5254 54.1464 27.5847 54.748Z"
            fill="url(#paint0_linear_3446_16152)"
            fillOpacity="0.5"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3446_16152"
              x1="89.8792"
              y1="-10.1452"
              x2="92.7325"
              y2="74.0091"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="card-title">
        <Text as="h6" variant="headingMd">
          {title}
        </Text>
      </div>
      <div className="card-content">
        <InlineGrid columns={2}>
          <Text as="h6" variant="headingMd">
            ${topLeftText || '0.00'}
          </Text>
          <Text as="h6" variant="bodyMd">
            {topRightText}
          </Text>
        </InlineGrid>
        <div className="mt-16">
          <InlineGrid columns={2}>
            <Text as="h6" variant="headingMd">
              {bottomLeftText || '0'}
            </Text>
            <Text as="h6" variant="bodyMd">
              {bottomRightText}
            </Text>
          </InlineGrid>
        </div>
      </div>
    </StyledBalanceReportCard>
  );
};

export default memo(BalanceReportCard);
