import { SortDirection } from '@/constants/enum';

export enum SortBy {
  courier = 'courier',
  customerName = 'customer_name',
  orderName = 'orderName',
  question_1 = 'question_1',
  question_2 = 'question_2',
  question_3 = 'question_3',
  averageRating = 'average_rating',
  createdAt = 'created_at',
}
export interface ITableItem {
  id: number;
  orderName: string;
  customerName: string;
  courier: string;
  reasons: string;
  additionalComment: string;
  question_1: number;
  question_2: number;
  question_3: number;
  averageRating: number;
  displayComment: boolean;
  createdAt: Date;
}

export interface IGetReviewTable {
  reviews: Array<ITableItem>;
  currentPage: number;
  perPage: number;
  totalPage: number;
  totalResult: number;
}

export interface IGetReviewTableParams {
  averageRating?: string;
  search?: string;
  sortBy?: SortBy;
  sortDirection?: SortDirection;
  displayComment?: boolean;
  perPage: number;
  page: number;
  startDate: string;
  endDate: string;
}

// Create
export interface ICreateReviewTableParams {
  averageRating: string;
  customerName: string;
  additionalComment: string;
  question_1: string;
  question_2: string;
  question_3: string;
  isFake: true;
}

export interface IUpdateDisplayReviewTableParams {
  reviews: Array<{
    id: number;
    displayComment: boolean;
  }>;
}
