import { Link, TextField } from '@shopify/polaris';
import { LINK } from '@/constants';
import { useSelector } from 'react-redux';
import { tokenSelector } from '@/redux/features/auth.slice';
import { CustomCard, Stepper } from '@/components';
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { CopyIcon } from '@/icons';
import ConfigurationBanner from '../ConfigurationBanner';

const EmbedTrackingPage = () => {
  const { shop } = useSelector(tokenSelector);

  return (
    <>
      <div className="mb-16">
        <ConfigurationBanner />
      </div>
      <CustomCard
        title="Embed tracking page on your store navigation"
        actionGroup={[
          {
            content: 'Watch video',
            variant: 'plain',
            icon: PlayCircleMajor,
            url: 'https://www.youtube.com/watch?v=B5cJ-Az3sTs&list=PL74Buw3DNN_bpLJpGtODRG8yPzBGNufq1&index=2',
          },
        ]}
      >
        <Stepper title="Copy tracking link below">
          <TextField
            label=""
            labelHidden
            value={LINK.ORDER_TRACKING_APP(shop)}
            autoComplete="off"
            disabled
            connectedRight={<CopyIcon copiedText={LINK.ORDER_TRACKING_APP(shop)} />}
          />
        </Stepper>
        <Stepper
          title={
            <>
              Go to <Link url={LINK.SHOPIFY_MENU(shop)}>Store Navigation</Link> choose a menu and add this link as a menu item
            </>
          }
        />
      </CustomCard>
    </>
  );
};

export default EmbedTrackingPage;
