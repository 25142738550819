import { Autocomplete } from '@shopify/polaris';
import { memo, useEffect, useState } from 'react';

interface Props {
  handleSelected: (value: Array<string>) => void;
  baseOptions: Array<{ label: string; value: string }>;
  label?: string;
  prefix?: JSX.Element;
  placeholder: string;
  style?: React.CSSProperties;
  selected: Array<string>;
  allowMultiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
}

function CustomAutocomplete({ ...props }: Props) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(props.baseOptions);
  useEffect(() => {
    setInputValue(props.allowMultiple ? '' : props.baseOptions.find((item) => item.value === props.selected[0])?.label || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.baseOptions, props.selected]);
  useEffect(() => {
    setOptions(props.baseOptions);
  }, [props.baseOptions]);
  const updateText = (value: string) => {
    setInputValue(value);
    const filterOptions = props.baseOptions.filter((option) => option.label.toUpperCase().includes(value.toUpperCase()));
    return setOptions(filterOptions);
  };

  const updateSelection = (selected: string[]) => {
    const baseOptions = [...props.baseOptions];
    props.handleSelected(selected);
    setInputValue(props.allowMultiple ? '' : baseOptions.find((item) => item.value === selected[0])?.label || '');
  };

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={props.label}
      labelHidden={!props.label}
      value={inputValue}
      prefix={props.prefix}
      placeholder={props.placeholder}
      autoComplete="off"
      disabled={props.disabled}
      error={props.error}
    />
  );

  return (
    <div style={props.style}>
      <Autocomplete
        allowMultiple={props.allowMultiple}
        options={options}
        selected={props.selected}
        onSelect={updateSelection}
        textField={textField}
        loading={props.loading}
      />
    </div>
  );
}

export default memo(CustomAutocomplete);
