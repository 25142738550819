import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

interface GoogleTranslationProps {
  $position: string;
  $isOpen: boolean;
  $tooltipText: string;
}

export const StyledGoogleTranslation = styled.div<GoogleTranslationProps>`
  position: absolute;
  display: ${({ $isOpen }) => ($isOpen ? 'inherit' : 'none')};
  top: ${({ $position }) => $position.includes('top') && '2rem'};
  bottom: ${({ $position }) => $position.includes('bottom') && '2rem'};
  right: ${({ $position }) => $position.includes('right') && '2rem'};
  left: ${({ $position }) => $position.includes('left') && '2rem'};

  .disable_google_translate {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:hover {
    &:before {
      content: ${({ $tooltipText }) => ($tooltipText ? `'${$tooltipText}'` : 'none')};
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      z-index: 100000;
      background-color: ${BgColorPalette.PRIMARY};
      color: white;
      text-align: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      display: ${({ $tooltipText }) => ($tooltipText ? 'block' : 'none')};
    }

    &:after {
      content: ' ';
      position: absolute;
      top: -1px;
      left: 50%;
      margin-left: -0.25rem;
      border-width: 0.25rem;
      border-style: solid;
      border-color: ${BgColorPalette.PRIMARY} transparent transparent transparent;
    }
  }

  select {
    border: 1px solid #ccc;
    padding: 0.25rem 0.5rem;
  }
`;
