import { PATH } from './path';

export enum LocalStorageKey {
  TOKEN = 'persist:auth',
  OMEGA_SHOP = 'omega-order-tracking-shop',
  LOGIN_QUERY_STRING = 'login-query-string',
  ANALYTICS_PERSIST = 'persist:analytics',
  TRACKING_PAGE_PERSIST = 'persist:trackingPage',
  SETTING_PERSIST = 'persist:settings',
  NOTIFICATIONS_PERSIST = 'persist:notifications',
  FCP_COUNT = 'fcp-count',
}

export const pathHaveTab: {
  path: string;
  storeKey: LocalStorageKey;
  tabKey: string;
}[] = [
  {
    path: PATH.ANALYTICS,
    storeKey: LocalStorageKey.ANALYTICS_PERSIST,
    tabKey: 'selectedTab',
  },
  {
    path: PATH.TRACKING_PAGE,
    storeKey: LocalStorageKey.TRACKING_PAGE_PERSIST,
    tabKey: 'tabSelected',
  },
  {
    path: PATH.SETTINGS,
    storeKey: LocalStorageKey.SETTING_PERSIST,
    tabKey: 'tabSelected',
  },
  {
    path: PATH.NOTIFICATIONS,
    storeKey: LocalStorageKey.NOTIFICATIONS_PERSIST,
    tabKey: 'selectedTab',
  },
];
