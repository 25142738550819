import { LINK, PATH } from '@/constants';
import Analytics from '@/pages/Analytics';
import Dashboard from '@/pages/Dashboard';
import Notifications from '@/pages/Notifications';
import EmailTemplatesDetail from '@/pages/Notifications/Email/components/EmailTemplatesDetail';
import SubscriptionPlans from '@/pages/Plan';
import Settings from '@/pages/Settings';
import Shipments from '@/pages/Shipments';
import ShipmentDetail from '@/pages/Shipments/components/ShipmentDetail';
import TrackingLogs from '@/pages/Shipments/components/TrackingLogs';
import TrackingPage from '@/pages/TrackingPage';
import Configuration from '@/pages/TrackingPage/components/Configuration';
import TransitTimeEditor from '@/pages/TrackingPage/components/EDD/components/TransitTimeEditor';
import TransitTimeEditorDetail from '@/pages/TrackingPage/components/EDD/components/TransitTimeEditorDetail';
import store, { RootState } from '@/redux/store';
import { IRouter } from '@/types/route';
import {
  AnalyticsMajor,
  CustomersMajor,
  DomainNewMajor,
  HomeMajor,
  NotificationMajor,
  SettingsMajor,
  ShipmentMajor,
  ViewMinor,
} from '@shopify/polaris-icons';

const getRouteList = (): Array<IRouter> => {
  const {
    auth: {
      data: { shop },
    },
  } = store.getState() as RootState;

  return [
    {
      name: 'Dashboard',
      path: PATH.DASHBOARD,
      element: <Dashboard />,
      icon: HomeMajor,
    },
    {
      name: 'Analytics',
      icon: AnalyticsMajor,
      path: PATH.ANALYTICS,
      element: <Analytics />,
    },
    {
      name: 'Shipments',
      path: PATH.SHIPMENT,
      element: <Shipments />,
      icon: ShipmentMajor,
    },
    {
      path: PATH.TRACKING_PAGE_CONFIGURATION,
      element: <Configuration />,
      hiddenInSidebar: true,
    },
    // {
    //   name: 'Review Table',
    //   path: PATH.REVIEW_TABLE,
    //   element: <ReviewTable />,
    //   icon: StarOutlineMinor,
    //   hiddenInSidebar: !isEnabledReview,
    // },
    {
      name: 'Tracking Page',
      path: PATH.TRACKING_PAGE,
      element: <TrackingPage />,
      icon: DomainNewMajor,
      actionIcon: {
        accessibilityLabel: 'View',
        url: LINK.CUSTOMER_SHOP(shop),
        icon: ViewMinor,
      },
    },
    {
      path: PATH.TRANSIT_TIME,
      element: <TransitTimeEditor />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.TRANSIT_TIME_DETAIL,
      element: <TransitTimeEditorDetail />,
      hiddenInSidebar: true,
    },
    {
      name: 'Settings',
      path: PATH.SETTINGS,
      element: <Settings />,
      icon: SettingsMajor,
    },
    {
      name: 'Notifications',
      icon: NotificationMajor,
      path: PATH.NOTIFICATIONS,
      element: <Notifications />,
    },
    {
      path: PATH.EMAIL_TEMPLATE,
      element: <EmailTemplatesDetail />,
      hiddenInSidebar: true,
    },
    {
      name: 'Subscription Plans',
      path: PATH.PLAN,
      element: <SubscriptionPlans />,
      icon: CustomersMajor,
    },
    {
      path: PATH.SHIPMENT_DETAIL,
      element: <ShipmentDetail />,
      hiddenInSidebar: true,
    },
    {
      path: PATH.TRACKING_LOGS,
      element: <TrackingLogs />,
      hiddenInSidebar: true,
    },
  ];
};

export default getRouteList;
