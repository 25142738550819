import styled from 'styled-components';

export const StyledEmailHistoryModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .history-item {
    display: flex;
    gap: 1rem;

    .title {
      width: 8rem;
    }
  }
`;
