import dashboardPageSlice, { haveClosedBannerSelector } from '@/redux/features/dashboard.slice';
import { useDispatch, useSelector } from 'react-redux';
import ChristmasHeadingBanner from '../ChristmasHeadingBanner';

const HeadingBanner = () => {
  const dispatch = useDispatch();
  const haveClosedBanner = useSelector(haveClosedBannerSelector);

  const handleCloseBanner = () => {
    dispatch(dashboardPageSlice.actions.handleCloseBanner(true));
  };

  return <ChristmasHeadingBanner isClose={haveClosedBanner} onClose={handleCloseBanner} />;
};

export default HeadingBanner;
