import { memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const ExternalLink = ({ ...props }: LinkProps) => {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#005bd3' }}
      onClick={(e) => e.stopPropagation()}
      {...props}
    />
  );
};

export default memo(ExternalLink);
