
export const configStatus = [
  {
    src: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/firstStatus.svg`,
    value: '1',
  },
  {
    src: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/secondStatus.svg`,
    value: '2',
  },
  {
    src: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/thirdStatus.svg`,
    value: '3',
  },
  {
    src: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/fourthStatus.svg`,
    value: '4',
  },

  {
    src: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/fivethStatus.svg`,
    value: '5',
  },
];
