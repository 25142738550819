import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';


export const StyledTopCouriersByStatus = styled.div`
  .chart-left {
    height: 320px;
    padding-left: 0.75rem;
  }
    .chart-right {
    height: 320px;
    padding-right: 0.75rem;
  }

   @media only screen and (max-width: ${PolarisBreakPoint.LG}) and (min-width: ${PolarisBreakPoint.XS}) {
    .chart-left {
        padding: 0 0.75rem;
    }
     .chart-right {
        padding: 0 0.75rem;
    }
   }
`;

