import { SVGProps } from 'react';

const QueuedIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10.5" cy="11" r="10.5" fill="#FEB330" />
      <path
        d="M7.21875 11.9375C7.73652 11.9375 8.15625 11.5178 8.15625 11C8.15625 10.4822 7.73652 10.0625 7.21875 10.0625C6.70098 10.0625 6.28125 10.4822 6.28125 11C6.28125 11.5178 6.70098 11.9375 7.21875 11.9375Z"
        fill="white"
      />
      <path
        d="M13.7812 11.9375C14.299 11.9375 14.7188 11.5178 14.7188 11C14.7188 10.4822 14.299 10.0625 13.7812 10.0625C13.2635 10.0625 12.8438 10.4822 12.8438 11C12.8438 11.5178 13.2635 11.9375 13.7812 11.9375Z"
        fill="white"
      />
      <path
        d="M10.5 11.9375C11.0178 11.9375 11.4375 11.5178 11.4375 11C11.4375 10.4822 11.0178 10.0625 10.5 10.0625C9.98223 10.0625 9.5625 10.4822 9.5625 11C9.5625 11.5178 9.98223 11.9375 10.5 11.9375Z"
        fill="white"
      />
      <path
        d="M10.5 17.5625C9.20206 17.5625 7.93327 17.1776 6.85407 16.4565C5.77488 15.7354 4.93374 14.7105 4.43704 13.5114C3.94034 12.3122 3.81038 10.9927 4.0636 9.71972C4.31682 8.44672 4.94183 7.2774 5.85961 6.35961C6.7774 5.44183 7.94672 4.81682 9.21972 4.5636C10.4927 4.31038 11.8122 4.44034 13.0114 4.93704C14.2105 5.43374 15.2354 6.27488 15.9565 7.35407C16.6776 8.43327 17.0625 9.70206 17.0625 11C17.0605 12.7399 16.3685 14.4079 15.1382 15.6382C13.9079 16.8685 12.2399 17.5605 10.5 17.5625ZM10.5 5.375C9.38748 5.375 8.29995 5.7049 7.37492 6.32299C6.44989 6.94107 5.72892 7.81957 5.30318 8.84741C4.87744 9.87524 4.76604 11.0062 4.98309 12.0974C5.20013 13.1885 5.73586 14.1908 6.52253 14.9775C7.3092 15.7641 8.31148 16.2999 9.40262 16.5169C10.4938 16.734 11.6248 16.6226 12.6526 16.1968C13.6804 15.7711 14.5589 15.0501 15.177 14.1251C15.7951 13.2001 16.125 12.1125 16.125 11C16.1233 9.50869 15.5301 8.07896 14.4756 7.02445C13.421 5.96993 11.9913 5.37674 10.5 5.375Z"
        fill="white"
      />
    </svg>
  );
};

export default QueuedIcon;
