import { Tabs } from '@shopify/polaris';
import { tabs } from './config';
import { useDispatch, useSelector } from 'react-redux';
import smsSlice, { tabSelectedSelector } from '@/redux/features/sms.slice';
import { StyledSMS } from './styled';
import { UpgradeBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';
import { accountSelector } from '@/redux/features/plan.slice';

const SMSNotifications = () => {
  const dispatch = useDispatch();
  const tabSelected = useSelector(tabSelectedSelector);
  const { plan } = useSelector(accountSelector);

  const handleTabChange = (tab: number) => {
    dispatch(smsSlice.actions.handleTabSelected(tab));
  };

  return (
    <StyledSMS>
      <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange}>
        <div style={{ marginTop: '1rem' }}>
          <UpgradeBanner usedFeature={AccountPlan.Basic} isOpen={plan === AccountPlan.Starter} />
          {tabs[tabSelected].component}
        </div>
      </Tabs>
    </StyledSMS>
  );
};

export default SMSNotifications;
