import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledSwitchToNewVersion = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 100;
  overflow: hidden;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  right: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  .popup-container {
    background-color: white;
    width: 100%;
    border-radius: 1rem;
    margin-bottom: 5rem;

    .popup-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      svg {
        cursor: pointer;
      }
    }

    .popup-content {
      .content-title {
        max-width: 75rem;
        margin: auto;
        padding: 1rem;
      }

      .content-image {
        background-color: ${BgColorPalette.PRIMARY};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-block: 0.5rem;
      }

      .content-description {
        max-width: 75rem;
        margin: auto;
        padding: 1rem;

        .version-highlight {
          display: flex;
          gap: 5rem;
          margin: 1rem;

          .divider {
            width: 0.5rem;
            background-color: ${BgColorPalette.SECONDARY_ACTIVE};
            border-radius: 2.5rem;
          }

          .highlight-list {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 50%;

            .highlight-item {
              display: flex;
              flex-direction: column;
              gap: 0.25rem;
            }
          }
        }

        .Polaris-Text--root.Polaris-Text--headingMd {
          text-align: center;
        }

        .attention-description {
          margin-block: 0.5rem;
        }
      }

      .Polaris-ButtonGroup {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
  }
`;
