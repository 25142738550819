import styled from 'styled-components';

export const StyledNPSReportOverview = styled.div`
 .analytics-container {
    padding: 1rem 0;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Polaris-InlineGrid {
    .Polaris-ShadowBevel {
      height: 100%;
    }
  }
`;
