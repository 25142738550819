import { BgColorPalette } from '@/constants';
import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const CourierMappingStyled = styled.div`
  .list-sortable {
    cursor: move;
    padding: 0.5rem 1rem;
    border: 1px solid ${BgColorPalette.SECONDARY_HOVER};
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 2rem;
      height: 2rem;
    }
    .left-item {
      display: flex;
      align-items: center;
    }
    .Polaris-Button--iconOnly {
      padding: rem;
    }
  }
  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Polaris-Box {
    .Polaris-Icon--colorWarning {
      display: none;
    }
  }
  .mt-20 {
    margin-top: 1.25rem;
  }
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
  .courier-mapping {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    .group-textfield {
      display: flex;
      gap: 1rem;
      align-items: flex-end;

      .arrow-icon {
        padding-block: 0.375rem;
      }
    }
    .group-btn {
      display: flex;
      gap: 0.5rem;
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    .courier-mapping {
      align-items: center;

      .group-textfield {
        align-items: center;
        flex-direction: column;
        gap: 0.25rem;
        .Polaris-Labelled--hidden,
        .Polaris-Labelled__LabelWrapper {
          min-width: 13rem;
        }
        .arrow-icon {
          padding-block: 0;
        }
      }
      .group-btn {
        flex-wrap: wrap;
      }
    }
  }

  @media only screen and (min-width: ${PolarisBreakPoint.XL}) {
    .courier-mapping {
      .group-textfield {
        gap: 2rem;
        .Polaris-Labelled--hidden,
        .Polaris-Labelled__LabelWrapper {
          min-width: 15rem;
        }
      }
    }
  }
`;
