import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';


export const StyledDonutChartCustom = styled.div`
  height: 100%;
  padding: 0 12px 12px;
  ._Container_3h6ct_1 {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
    margin: 0;
    gap: 0.25rem;
    ._Legend_12i9x_150  {
      padding: 0.125rem 0;
    }
  }
  @media only screen and (max-width: ${PolarisBreakPoint.LG}) and (min-width: ${PolarisBreakPoint.MD}) {
    ._Container_3h6ct_1{
    grid-template-columns: auto auto auto;
    }
  }
`;


