import { useGetListCountriesQuery } from '@/redux/api/api.caller';
import trackingPageSlice, { blacklistingSelector } from '@/redux/features/trackingPage.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Tag, Text } from '@shopify/polaris';
import { StyledTextField } from '../../styled';
import { useMemo, useState } from 'react';
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types';

const AreasToBeReplacedTextField = () => {
  const dispatch = useDispatch();
  const blacklisting = useSelector(blacklistingSelector);
  const [isFocusAutoComplete, setIsFocusAutoComplete] = useState<boolean>(false);
  const { data, isLoading } = useGetListCountriesQuery(undefined, { skip: !isFocusAutoComplete });

  const transformedOptions: OptionDescriptor[] = useMemo(() => {
    if (data) {
      const initOptions = data.data.map((option) => ({
        value: option,
        label: option,
      }));
      return initOptions.filter((option) =>
        option.value.toLowerCase().includes(blacklisting.areasToBeReplaced.search.toLowerCase()),
      );
    }
    return [];
  }, [blacklisting.areasToBeReplaced.search, data]);

  const handleUpdateText = (value: string) => {
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklisting({
        ...blacklisting,
        areasToBeReplaced: { ...blacklisting.areasToBeReplaced, search: value },
      }),
    );
  };

  const handleSelectAutocomplete = (tags: Array<string>) => {
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklisting({
        ...blacklisting,
        areasToBeReplaced: { selected: tags, search: '' },
      }),
    );
  };

  const handleRemoveTag = (tag: string) => {
    const removedTag = blacklisting.areasToBeReplaced.selected.filter((option) => option !== tag);
    dispatch(
      trackingPageSlice.actions.handleChangeBlacklisting({
        ...blacklisting,
        areasToBeReplaced: { ...blacklisting.areasToBeReplaced, selected: removedTag },
      }),
    );
  };

  const customTextField = (
    <div onFocus={() => setIsFocusAutoComplete(true)}>
      <Autocomplete.TextField
        label={
          <Text variant="headingSm" as="h6">
            Country or region to be replaced
          </Text>
        }
        placeholder="Country or region to be replaced"
        autoComplete="off"
        value={blacklisting.areasToBeReplaced.search}
        onChange={handleUpdateText}
      />
    </div>
  );

  return (
    <StyledTextField>
      <Autocomplete
        listTitle="Suggested Tags"
        allowMultiple={true}
        options={transformedOptions}
        selected={blacklisting.areasToBeReplaced.selected}
        textField={customTextField}
        onSelect={handleSelectAutocomplete}
        loading={isLoading}
      />

      <div className="tag-list">
        {blacklisting.areasToBeReplaced.selected &&
          blacklisting.areasToBeReplaced.selected.length > 0 &&
          blacklisting.areasToBeReplaced.selected.map((tag: string) => (
            <Tag key={tag} onRemove={() => handleRemoveTag(tag)}>
              {tag}
            </Tag>
          ))}
      </div>
    </StyledTextField>
  );
};

export default AreasToBeReplacedTextField;
