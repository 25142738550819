import { PATH } from '@/constants';
import CustomBanner from '../CustomBanner';
import { useNavigate } from 'react-router-dom';
import { BannerProps } from '@shopify/polaris';
import { AccountPlan } from '@/constants/enum';
import { capitalizeFirstLetter } from '@/helpers';
import { memo } from 'react';

interface UpgradeBannerProps extends BannerProps {
  isOpen?: boolean;
  usedFeature: AccountPlan;
  isHiddenTitle?: boolean;
}

const UpgradeBanner = ({ isOpen = true, usedFeature, isHiddenTitle = false, ...props }: UpgradeBannerProps) => {
  const navigate = useNavigate();

  const handleUpgradeNow = () => {
    navigate(PATH.PLAN);
  };

  return (
    <CustomBanner
      isClose={!isOpen}
      maxHeight="15rem"
      tone="warning"
      title={isHiddenTitle ? '' : 'Upgrade to use this feature'}
      action={{
        content: 'Upgrade Now',
        onAction: handleUpgradeNow,
      }}
      {...props}
    >
      This feature is only <strong>available</strong> on the paid plan. You need to <strong>upgrade</strong> to the{' '}
      <strong>{capitalizeFirstLetter(usedFeature)} or higher plan</strong> to use this feature.
    </CustomBanner>
  );
};

export default memo(UpgradeBanner);
