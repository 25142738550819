import { ChartCustomTitle, CustomCard } from '@/components';
import { ShipmentByStatusPalette } from '@/constants';
import { camelToRegularText } from '@/helpers';
import { useGetAnalyticsChartShipmentQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ShipmentByTransitDay = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartShipmentQuery({
    fromDate: start.toISOString(),
    toDate: end.toISOString(),
  });
  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.shipmentByDays.categories.length) {
      const { categories, series } = data.data.shipmentByDays;
      const keyDeliveries = Object.keys(series);
      const valueDeliveries = Object.values(series);
      return keyDeliveries.map((item, index) => ({
        name: camelToRegularText(item),
        color: Object.values(ShipmentByStatusPalette)[index],
        data: categories.map((subItem, subIndex) => ({
          key: subItem,
          value: valueDeliveries[index][subIndex],
        })),
      }));
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="SHIPMENT BY IN TRANSIT DAY">
      <div style={{ height: '550px' }}>
        {isLoading ? (
          <SkeletonBodyText lines={28} />
        ) : (
          <ChartCustomTitle xTitle="In transit day" yTitle="Shipment">
            <LineChart data={chartData} theme="Light" isAnimated />
          </ChartCustomTitle>
        )}
      </div>
    </CustomCard>
  );
};

export default ShipmentByTransitDay;
