import { LINK } from '@/constants';
import styled from 'styled-components';

export const StyledCrossSellReturnBanner = styled.div`
  padding-bottom: 1rem;
  .Polaris-LegacyCard__Section {
    padding: 0;
    background-image: ${`url(${LINK.RETURN_DRIVE_START_BACKGROUND})`};
    background-position: center;
  }

  .Polaris-CalloutCard__Content {
    padding: 0.75rem 0 1rem 1.25rem;
  }

  .Polaris-CalloutCard__Image {
    min-width: 16rem;
    padding: 1rem 2rem 1rem 0;
  }

  .Polaris-CalloutCard__Buttons {
    padding-top: 0.5rem;
  }

  .banner-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;

    &-text {
      color: #00bf64;
      font-weight: 700;
      font-size: 17px;
      line-height: 1.25;
    }

    img {
      width: 36px;
      height: 36px;
      object-fit: contain;
    }
  }
  .banner-content {
    padding-top: 0.25rem;
    color: #ffffff;

    &-item {
      padding-top: 0.25rem;
      font-size: 13px;
    }
  }
`;
