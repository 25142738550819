import { BgColorPalette } from '@/constants';
import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledSmsProgress = styled.div`
  .summary-progress {
    background-color: ${BgColorPalette.SECONDARY};
    border-radius: 0.25rem;
  }

  .summary-content {
    display: flex;

    .total-status-item {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-top: 0.5rem;
    }
  }
  @media only screen and (max-width: ${PolarisBreakPoint.MD}) {
    .summary-content {
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  .summary-content-left {
    width: 15%;
    min-width: 7rem;
  }

  .w-100 {
    width: 100%;
  }
`;
