import { Button, ButtonProps, Card, Icon, IconSource, Text, TextProps, Tooltip } from '@shopify/polaris';
import { Fragment, ReactElement, ReactNode, memo } from 'react';
import { CardStyled } from './styled';
import { CircleInformationMajor } from '@shopify/polaris-icons';

interface Props {
  title: ReactNode;
  tooltip?: ReactNode;
  children: any;
  display?: string;
  actionGroup?: Array<{
    content: string | ReactNode;
    onAction?: () => void;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
    loading?: boolean;
    icon?: ReactElement | IconSource;
    url?: string;
  }>;
  className?: string;
  id?: string;
  variantTitle?: TextProps['variant'];
}

const CustomCard = ({ ...props }: Props) => {
  return (
    <CardStyled style={{ display: props.display || 'block' }} className={props.className} id={props.id}>
      <Card padding={{ xl: '0' }}>
        <div className="custom-card-header">
          <Text variant={props.variantTitle || 'headingMd'} as="h6">
            {props.title}
            {props.tooltip && (
              <div className="information-icon">
                <Tooltip content={props.tooltip}>
                  <Icon source={CircleInformationMajor} tone="base" />
                </Tooltip>
              </div>
            )}
          </Text>
          {props.actionGroup &&
            props.actionGroup.map((item, index) => {
              return (
                <Fragment key={index}>
                  {typeof item.content === 'string' ? (
                    <Button key={index} variant={item.variant} url={item.url} onClick={item.onAction} {...item}>
                      {item.content}
                    </Button>
                  ) : (
                    item.content
                  )}
                </Fragment>
              );
            })}
        </div>
        <div className="custom-card-content">{props.children}</div>
      </Card>
    </CardStyled>
  );
};

export default memo(CustomCard);
