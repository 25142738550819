import { CustomCard } from '@/components';
import { useGetAnalyticsChartCourierQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { BarChart, DataSeries } from '@shopify/polaris-viz';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StyledTopCouriersByStatus } from './styled';

const TopCouriersByStatus = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartCourierQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const chartDataByStatus = useCallback(
    (status: 'delivered' | 'nonDelivered'): DataSeries[] => {
      if (data && data.data.topCourierByStatus.categories.length) {
        const { categories, series } = data.data.topCourierByStatus;
        return [
          {
            name: status === 'delivered' ? 'Delivered' : 'Non-Delivered',
            data: categories.map((item, index) => ({
              key: item,
              value: series[status][index],
            })),
          },
        ];
      }
      return [];
    },
    [data],
  );

  return (
    <StyledTopCouriersByStatus>
      <CustomCard
        title="TOP 3 COURIERS BY STATUS"
        tooltip="We rank the Top 3 based on the percentage of delivered orders they has fulfilled. 
The percentages show the proportion of delivered/non-delivered orders of each courier occupying in the total supported tracking"
      >
        {isLoading ? (
          <SkeletonBodyText lines={16} />
        ) : (
          <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="800">
            <div className="chart-left">
              <BarChart direction="horizontal" theme="Light" isAnimated data={chartDataByStatus('delivered')} />
            </div>
            <div className="chart-right">
              <BarChart direction="horizontal" theme="Light" isAnimated data={chartDataByStatus('nonDelivered')} />
            </div>
          </InlineGrid>
        )}
      </CustomCard>
    </StyledTopCouriersByStatus>
  );
};

export default TopCouriersByStatus;
