import { Banner, BannerProps } from '@shopify/polaris';
import { memo } from 'react';
import { StyledCustomBanner } from './styled';

interface CustomBannerProps extends BannerProps {
  isClose?: boolean;
  maxHeight?: string;
}

const CustomBanner = ({ isClose = false, maxHeight = '10rem', ...props }: CustomBannerProps) => {
  return (
    <StyledCustomBanner isClose={isClose} maxHeight={maxHeight}>
      <Banner {...props}>{props.children}</Banner>
    </StyledCustomBanner>
  );
};

export default memo(CustomBanner);
