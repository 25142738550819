import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const LayoutStyled = styled.div<{ maxWidth: string }>`
  @media only screen and (min-width: ${PolarisBreakPoint.LG}) {
    .Polaris-Page--fullWidth {
      max-width: ${(props) => props.maxWidth};
    }
  }

  .Polaris-Frame__Main {
    padding: 0;
  }

  .Polaris-Page-Header__Row {
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 1rem;

    .Polaris-Page-Header__TitleWrapper {
    }

    .Polaris-Page-Header__RightAlign {
      margin-bottom: 0;

      .Polaris-Page-Header__Actions {
        gap: 0.5rem;
        flex-wrap: wrap;
      }
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    .Polaris-Frame__Main {
      padding-top: 0;
      padding-inline: 0.5rem;
    }
  }
`;

export const StyledRow = styled.div<{ column: number; gap: string }>`
  display: grid;
  grid-template-columns: repeat(${({ column }) => column}, minmax(0, 1fr));
  gap: ${({ gap }) => gap};
`;

export const StyledColumn = styled.div<{ span: number }>`
  grid-column: span ${({ span }) => span} / span ${({ span }) => span};
`;
