import { CustomCard } from '@/components';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const PageViewByTime = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.pageView.categories.length) {
      const { categories, series } = data.data.pageView;
      return [
        {
          name: 'Page view',
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: series[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="PAGE VIEW BY TIME">
      <div style={{ height: '400px' }}>
        {isLoading ? <SkeletonBodyText lines={20} /> : <LineChart theme="Light" data={chartData} isAnimated />}
      </div>
    </CustomCard>
  );
};

export default PageViewByTime;
