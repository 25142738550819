import { CustomizationTypeTabs } from '@/constants/trackingPage';
import { ITopNavTabs } from '@/types/tab';
import ThemeAndStyles from './components/ThemeAndStyles';
import Languages from './components/Languages';
import CustomizeLink from './components/CustomizeLink';

export const customizationTypeTabsConfig: Array<ITopNavTabs> = [
  {
    id: CustomizationTypeTabs.Link,
    content: 'Link',
    component: <CustomizeLink />,
  },
  {
    id: CustomizationTypeTabs.ThemeAndStyles,
    content: 'Theme & Styles',
    component: <ThemeAndStyles />,
  },
  {
    id: CustomizationTypeTabs.Languages,
    content: 'Languages',
    component: <Languages />,
  },
];
