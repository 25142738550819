import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IDatePicker } from '@/types';
import { getSomeDaysAgo } from '@/helpers';
import { PageSize, SortDirection } from '@/constants/enum';

interface ITrackingLogsFilter {
  search: string;
  page: number;
  pageSize: PageSize;
  sortBy: string;
  sortOrder: SortDirection;
}

interface ITrackingLogsState {
  filter: ITrackingLogsFilter;
  titleDatePicker: string;
  datePicker: IDatePicker;
}

const initialState: ITrackingLogsState = {
  filter: {
    search: '',
    page: 1,
    pageSize: PageSize.Five,
    sortBy: '',
    sortOrder: SortDirection.ASC,
  },
  datePicker: {
    start: getSomeDaysAgo(30),
    end: new Date(),
  },
  titleDatePicker: 'Last 30 days',
};

export const trackingLogsSlice = createSlice({
  name: 'trackingLogs',
  initialState,
  reducers: {
    handleChangeTableFilter: (state: ITrackingLogsState, action: PayloadAction<ITrackingLogsFilter>) => {
      state.filter = action.payload;
    },
    handleDatePicker: (state, action: PayloadAction<IDatePicker>) => {
      state.datePicker = action.payload;
    },
    handleTitleDatePicker: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
  },
});

export const trackingLogsFilterSelector = createSelector(
  (state: RootState) => state.trackingLogs,
  (state) => state.filter,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.trackingLogs,
  (state) => state.datePicker,
);
export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.trackingLogs,
  (state) => state.titleDatePicker,
);

export default trackingLogsSlice;
