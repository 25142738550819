import { Card, InlineGrid, useBreakpoints } from '@shopify/polaris';
import { memo } from 'react';
import { BannerTemplateStyled } from './styled';

interface Props {
  src: string;
  children: React.ReactNode;
}
const BannerTemplate = ({ src, children }: Props) => {
  const { mdDown, smDown } = useBreakpoints();

  const renderLayout = () => {
    if (smDown) {
      return 1;
    }
    if (mdDown) {
      return 2;
    }
    return ['oneThird', 'twoThirds'];
  };

  return (
    <BannerTemplateStyled>
      <Card padding="0">
        <InlineGrid columns={renderLayout() as any}>
          <div className="banner-template-left">
            <img className="banner-img" src={src} alt="_blank" />
          </div>
          <div className="banner-template-right">{children}</div>
        </InlineGrid>
      </Card>
    </BannerTemplateStyled>
  );
};

export default memo(BannerTemplate);
