import { calcPercent, calcTotal, isLockFeature } from '@/helpers';
import { InlineGrid, SkeletonBodyText, Text } from '@shopify/polaris';
import { useGetSmsSummaryQuery } from '@/redux/api/api.caller';
import { useMemo } from 'react';
import { summaryConfig } from './config';
import { CustomCard } from '@/components';
import { StyledSmsProgress } from './styled';
import { AccountPlan } from '@/constants/enum';

const SmsProgress = () => {
  const isLock = isLockFeature([AccountPlan.Starter]);
  const { data } = useGetSmsSummaryQuery(undefined, { skip: isLock });
  const summary = useMemo(() => {
    if (data) {
      const status = summaryConfig.map((item) => {
        return {
          ...item,
          value: data.data.status[item.status as keyof typeof data.data.status],
        };
      });
      const totalSms = calcTotal(status.map((item) => item.value));
      return {
        status,
        totalSms,
      };
    }
  }, [data]);

  return (
    <StyledSmsProgress>
      <CustomCard title="Summary">
        {(data && summary) || isLock ? (
          <div className="summary-content">
            <div className="summary-content-left">
              <Text as="h6" variant="bodySm">
                Total SMS: <b>{data?.data.totalUsed.sms || 0}</b>
              </Text>
              <div className="mt-8">
                <Text as="h6" variant="bodySm">
                  Total cost: <b>${data?.data.totalUsed.cost || 0}</b>
                </Text>
              </div>
            </div>
            <div className="w-100">
              <div className="d-flex summary-progress">
                {summary &&
                  summary.status.length > 0 &&
                  summary.status.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: `${calcPercent(item.value, summary.totalSms)}%`,
                          backgroundColor: item.color,
                          height: 8,
                        }}
                      />
                    );
                  })}
              </div>
              <InlineGrid
                columns={{ xl: 5, lg: 3, md: 2, sm: 2, xs: 2 }}
                gap={{ xl: '400', lg: '100', md: '100', sm: '100', xs: '100' }}
              >
                {summary &&
                  summary.status.length > 0 &&
                  summary.status.map((item, index) => {
                    return (
                      <div key={index} className="total-status-item">
                        <div>{item.icon}</div>
                        <div>
                          <Text as="h6" variant="bodySm">
                            {item.title}
                          </Text>
                          <Text as="h6" variant="bodySm">
                            {item.value} SMS
                          </Text>
                        </div>
                      </div>
                    );
                  })}
              </InlineGrid>
            </div>
          </div>
        ) : (
          <div style={{ height: 58 }}>
            <SkeletonBodyText />
          </div>
        )}
      </CustomCard>
    </StyledSmsProgress>
  );
};

export default SmsProgress;
