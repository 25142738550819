import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const ControlBarStyled = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${BgColorPalette.PRIMARY};
  padding: 0 1.5rem;
  position: fixed;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  .Polaris-Button.Polaris-Button--disabled {
    background: rgba(255, 255, 255, 1);
  }
`;
