import SMSTemplates from './components/SMSTemplates';
import SmsCredit from './components/SmsCredit';
import SmsHistory from './components/SmsHistory';

export const tabs = [
  {
    id: 'smsCredit',
    content: 'SMS Credit',
    component: <SmsCredit />,
  },
  {
    id: 'orderStatus',
    content: 'SMS Templates',
    component: <SMSTemplates />,
  },
  {
    id: 'smsHistory',
    content: 'SMS History',
    component: <SmsHistory />,
  },
];
