import { isEmptyObject } from "./object";

/* eslint-disable no-useless-escape */
export const isUrlValid = (input: string) => {
  var res = input.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  if (res == null) return false;
  else return true;
};

export const isHexColor = (input: string) => {
  var res = input.match(/^#[0-9A-F]{6}$/i);
  if (res == null) return false;
  else return true;
};
type DirtyField<T> = Partial<Record<string, T[keyof T]>>;

export function checkDirtyField<T extends object>(
  initialValues: T,
  currentValues: T
): { dirtyField?: DirtyField<T>; isDirty: boolean } {
  const dirtyField: DirtyField<T> = {};

  Object.entries(initialValues).forEach(([key, initialValue]) => {
    const currentValue = currentValues[key as keyof T];

    if (currentValue !== undefined && initialValue !== currentValue) {
      dirtyField[key] = currentValue;
    }
  });

  return {
    dirtyField: isEmptyObject(dirtyField) ? undefined : dirtyField,
    isDirty: Object.keys(dirtyField).length > 0
  };
}

export const renderErrorValue = (
  errorKeys: Array<string>,
  key: string,
  errorText?: string
) => {
  const isHaveError = errorKeys.findIndex((error) => error === key) !== -1;
  if (isHaveError) {
    if (errorText) return errorText;
    return 'Enter correct url!';
  }
  return '';
};
