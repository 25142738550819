import { Divider, Text } from '@shopify/polaris';
import { StyledDetailCard } from './styled';
import { Fragment, ReactNode, memo } from 'react';
import { CustomCard, SkeletonText } from '@/components';

interface InfoCardProps {
  title: string;
  data?: { key: string; value: ReactNode }[];
  loading?: boolean;
}

const InfoCard = ({ title, data, loading }: InfoCardProps) => {
  return (
    <StyledDetailCard>
      <CustomCard title={title}>
        {data && data.length > 0
          ? data.map(({ key, value }, index) => (
              <Fragment key={key}>
                <div className="shipment-detail-row">
                  <Text variant="bodySm" as="h6">
                    {key}
                  </Text>
                  {loading ? <SkeletonText height="1.25rem" width="7.5rem" /> : <p>{value || '---'}</p>}
                </div>
                {data.length - 1 !== index && (
                  <div style={{ paddingBlock: 8 }}>
                    <Divider />
                  </div>
                )}
              </Fragment>
            ))
          : '---'}
      </CustomCard>
    </StyledDetailCard>
  );
};

export default memo(InfoCard);
