import { CustomCard } from '@/components';
import { useGetNPSAnalyticQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText, Text } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { RenderTooltipContentData } from '@shopify/polaris-viz/build/ts/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { netPromoterTooltip } from './config';

const NetPromoterScore = () => {
  const datePicker = useSelector(datePickerSelector);
  const { isLoading, data } = useGetNPSAnalyticQuery({
    toDate: datePicker.end.toISOString(),
    fromDate: datePicker.start.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.categories.length) {
      const { categories, detractorsSeries, passivesSeries, promotersSeries } = data.data;
      return [
        {
          name: 'Score',
          data: categories.map((item, index) => {
            const detractor = detractorsSeries[index];
            const passive = passivesSeries[index];
            const promoter = promotersSeries[index];
            const total = detractor + passive + promoter;
            return {
              key: item,
              value: total ? Number((((promoter - detractor) * 100) / total).toFixed(2)) : 0,
            };
          }),
        },
      ];
    }
    return [];
  }, [data]);

  const handleRenderTooltipContent = (dataContent: RenderTooltipContentData) => {
    const { activeIndex, title } = dataContent;
    if (data) {
      const { detractorsSeries, passivesSeries, promotersSeries } = data.data;
      const chartResponse = detractorsSeries[activeIndex] + passivesSeries[activeIndex] + promotersSeries[activeIndex];
      return (
        <div style={netPromoterTooltip[0]}>
          <Text as="h6" variant="headingSm">
            {title}
          </Text>
          <Text as="h6" variant="bodyMd">
            Score : {chartData[0].data[activeIndex].value}
          </Text>
          <Text as="h6" variant="bodyMd">
            Response : {chartResponse}
          </Text>
          <Text as="h6" variant="headingSm">
            <div style={netPromoterTooltip[1]} />
            Detractors : {detractorsSeries[activeIndex]}
          </Text>
          <Text as="h6" variant="headingSm">
            <div style={netPromoterTooltip[2]} />
            Passives : {passivesSeries[activeIndex]}
          </Text>
          <Text as="h6" variant="headingSm">
            <div style={netPromoterTooltip[3]} />
            Promoters : {promotersSeries[activeIndex]}
          </Text>
        </div>
      );
    }
    return undefined;
  };

  return (
    <CustomCard title="NET PROMOTER SCORE OVER TIME">
      <div style={{ height: '400px' }}>
        {isLoading ? (
          <SkeletonBodyText lines={20} />
        ) : (
          <LineChart
            theme="Light"
            data={chartData}
            isAnimated
            tooltipOptions={{ renderTooltipContent: handleRenderTooltipContent }}
          />
        )}
      </div>
    </CustomCard>
  );
};

export default NetPromoterScore;
