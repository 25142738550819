import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledScrollUp = styled.div`
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
  z-index: 1000000;

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    bottom: 5rem;
    right: 1.125rem;
  }

  svg {
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;
