import { ReactNode, memo } from 'react';
import { StyledDonutChartCustom } from './styled';

interface IDonutChartCustom {
  children: ReactNode;
}

const DonutChartCustom = ({ children }: IDonutChartCustom) => {
  return (
    <div style={{ height: '480px' }}>
      <StyledDonutChartCustom>{children}</StyledDonutChartCustom>
    </div>
  );
};

export default memo(DonutChartCustom);
