import styled from 'styled-components';

export const StyledTemplateButtons = styled.div`
  .btn-group {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
`;

export const StyledVariableList = styled.div`
  margin-top: 1rem;

  .variable-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .variable-badge {
      width: 12.5rem;
    }
  }
`;
