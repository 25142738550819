import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledEmailConnectProvider = styled.div`
  .email-connect-gmail {
    width: 25rem;
    margin: auto;
    text-align: center;

    .title {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      span {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    .Polaris-Divider {
      margin-block: 0.75rem;
    }

    .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
    }

    .Polaris-Text--root.Polaris-Text--headingMd.Polaris-Text--semibold {
      padding-block: 0.75rem;
    }

    .sign-out-btn {
      margin-top: 0.5rem;
      padding-block: 0.75rem;
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    .email-connect-gmail {
      width: 100%;
    }
  }

  .email-connect-other {
    .heading-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-heading {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    .content {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 2rem;
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    .email-connect-other {
      .content {
        gap: 1rem;
        padding: 1rem;
      }
    }
  }
`;
