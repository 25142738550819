import { Navigation } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { useLocation } from 'react-router-dom';
import { LINK } from '@/constants';
import getRouteList from '@/routes/routes';
import { StyledNavigation } from './styled';
import { isLockFeature } from '@/helpers/feature';
import { AccountPlan } from '@/constants/enum';
import { ItemProps } from '@shopify/polaris/build/ts/src/components/Navigation/types';

const Sidebar = () => {
  const { pathname } = useLocation();
  const isShowLockIcon = !isLockFeature([AccountPlan.Starter]);

  const topSidebarItems = getRouteList()
    .filter(({ hiddenInSidebar }) => !hiddenInSidebar)
    .map(({ name, path, icon, actionIcon }) => {
      let navigationItem: ItemProps = { url: path, label: name || '', icon };

      if (actionIcon) {
        navigationItem = { ...navigationItem, secondaryActions: [{ ...actionIcon }] };
      }

      return navigationItem;
    });

  const renderBottomSidebar = (): ItemProps[] => {
    const openHelpCenter = () => window.open(LINK.HELP_CENTER);

    return [
      {
        label: 'Help Center',
        icon: QuestionMarkMinor,
        onClick: openHelpCenter,
      },
    ];
  };

  return (
    <StyledNavigation isShowLockIcon={isShowLockIcon}>
      <Navigation location={pathname}>
        <Navigation.Section title="MENU" items={topSidebarItems} />
        <Navigation.Section items={renderBottomSidebar()} />
      </Navigation>
    </StyledNavigation>
  );
};

export default Sidebar;
