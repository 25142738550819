import { Card, Badge, Button, Divider, Modal, Text } from '@shopify/polaris';
import { StyledEmailConnectProvider } from './styled';
import { useState } from 'react';
import { ExitMajor } from '@shopify/polaris-icons';
import { useDisconnectEmailMutation, useGetNotificationAccountsQuery } from '@/redux/api/api.caller';
import { useAppDispatch } from '@/redux/store';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { EmailProvider } from '@/constants';
import { ErrorBanner } from '@/components';

const EmailConnectProvider = () => {
  const [isModalActive, setModalActive] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');
  const dispatch = useAppDispatch();
  const [signOut, { isLoading }] = useDisconnectEmailMutation();
  const { data } = useGetNotificationAccountsQuery();
  const emailAccount = data?.data?.email?.account;
  const disconnectText = emailAccount?.gateway === EmailProvider.Gmail ? 'sign out' : 'disconnect';

  const handleOpenModal = () => setModalActive(true);
  const handleCloseModal = () => {
    setApiError('');
    setModalActive(false);
  };

  const handleSignOut = () => {
    signOut({}).then((res) => {
      if ('data' in res && res.data && res.data.state === 1) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        handleCloseModal();
        if (apiError) {
          setApiError('');
        }
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  const EmailConnectGmail = () => (
    <div className="email-connect-gmail">
      <div className="title">
        <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/email/gmail_logo.png`} alt="Gmail Logo" width="24" />
        <Text variant="headingLg" as="h6">
          Gmail Account Connected
        </Text>
      </div>
      <Divider />
      <img src={emailAccount?.picture || ''} alt="Avatar" className="avatar" />
      <Text variant="headingMd" as="h6">
        {emailAccount?.senderName || ''}
      </Text>
      <div className="mt-8">
        <Badge tone="success">{emailAccount?.email || ''}</Badge>
      </div>
      <div className="sign-out-btn">
        <Button icon={ExitMajor} onClick={handleOpenModal}>
          Sign out
        </Button>
      </div>
    </div>
  );

  const EmailConnectOther = () => (
    <div className="email-connect-other">
      <div className="heading-title">
        <div className="left-heading">
          <Text variant="headingMd" as="h6">
            Email Account
          </Text>
          <Badge tone={emailAccount?.isVerified ? 'success' : 'critical'}>
            {emailAccount?.isVerified ? 'Connected' : 'Disconnected'}
          </Badge>
        </div>
        <Button onClick={handleOpenModal}>Disconnect</Button>
      </div>

      <div className="content">
        <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/email/gmail.png`} alt="Gmail Icon" width="50" />
        <Badge tone="info">{emailAccount?.email || ''}</Badge>
      </div>
    </div>
  );

  return (
    <StyledEmailConnectProvider>
      <Card>{emailAccount?.gateway === EmailProvider.Gmail ? <EmailConnectGmail /> : <EmailConnectOther />}</Card>

      <Modal
        open={isModalActive}
        onClose={handleCloseModal}
        title={`Are you sure you want to ${disconnectText}?`}
        primaryAction={{
          content: 'OK',
          destructive: true,
          onAction: handleSignOut,
          loading: isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
          {`Please note that if you ${disconnectText} of your account, our system would automatically switch the email notification feature
          to new version and you can not come back to the old version.`}
        </Modal.Section>
      </Modal>
    </StyledEmailConnectProvider>
  );
};

export default EmailConnectProvider;
