import { DeliveredIcon, OverQuotaIcon, QueuedIcon, SentIcon, UndeliveredIcon } from '@/icons';

export const summaryConfig = [
  {
    color: 'rgba(22, 166, 121, 1)',
    status: 'delivered',
    title: 'Delivered',
    icon: <DeliveredIcon />,
  },
  {
    color: 'rgba(42, 172, 187, 1)',
    status: 'sent',
    title: 'Sent',
    icon: <SentIcon />,
  },
  {
    color: 'rgba(248, 217, 144, 1)',
    status: 'queued',
    title: 'Queued',
    icon: <QueuedIcon />,
  },
  {
    color: 'rgba(242, 117, 34, 1)',
    status: 'overQuota',
    title: 'Over Quota',
    icon: <OverQuotaIcon />,
  },
  {
    color: 'rgba(197, 40, 12, 1)',
    status: 'undelivered',
    title: 'Undelivered',
    icon: <UndeliveredIcon />,
  },
];
