export enum BlacklistReplaceWith {
  EmptyLocation = 'emptyLocation',
  AnotherCity = 'anotherCity',
}

export enum AreaToReplaceWithType {
  Country = 'country',
  City = 'city',
}

export enum UpdatedBlacklistBtn {
  Enable = 'Enable',
  Save = 'Save',
}
