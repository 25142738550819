import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledBlacklisting = styled.div`
  position: relative;

  .top-section {
    .Polaris-Text--root.Polaris-Text--headingSm.Polaris-Text--semibold {
      text-transform: uppercase;
    }

    .another-city {
      .Polaris-Text--root.Polaris-Text--headingSm.Polaris-Text--semibold {
        margin-top: 1rem;
      }

      .group-textfield {
        display: flex;
        gap: 1rem;
        align-items: flex-start;

        .textfield-item {
          width: 50%;
        }
      }

      @media only screen and (max-width: ${PolarisBreakPoint.MD}) {
        .group-textfield {
          gap: 0;
          flex-wrap: wrap;

          .textfield-item {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const StyledTextField = styled.div`
  margin-block: 0.5rem;

  .tag-list {
    margin-top: 0.5rem;

    .Polaris-Tag {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;
