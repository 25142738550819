import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledSearchAndReplaceWords = styled.div`
  .replace-text-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    p {
      font-weight: 700;

      span {
        font-style: italic;
        margin-inline: 0.25rem;
        font-weight: 400;
      }
    }

    &:not(:first-child) {
      padding-top: 0.25rem;
    }

    &:not(:last-child) {
      padding-bottom: 0.25rem;
      border-bottom: 1px solid ${BgColorPalette.SECONDARY_HOVER};
    }
  }

  .no-replace-text {
    color: #6d7175;
  }
`;

export const StyledEditModal = styled.div`
  display: flex;
  gap: 0.5rem;

  .Polaris-Choice {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 0;
    padding-right: 1rem;
    gap: 0.625rem;
  }

  .textfield {
    width: 45%;
  }
`;
