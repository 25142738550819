import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AccountPlan, Subscription } from '@/constants/enum';

interface IUserPlan {
  plan: AccountPlan;
  subscription: Subscription;
  isOldPaidUser: boolean;
  isOldUser: boolean;
  isEnabledReview: boolean;
}

interface IChristmasBanner {
  startDate: string;
  endDate: string;
  formattedEndDate: Date;
  isShow: boolean;
  haveClosed: boolean;
}

export interface IPlanState {
  account: IUserPlan;
  christmasBanner: IChristmasBanner;
}

const initialState: IPlanState = {
  account: {
    plan: AccountPlan.Starter,
    subscription: Subscription.Monthly,
    isOldPaidUser: false,
    isOldUser: false,
    isEnabledReview: false,
  },
  christmasBanner: {
    startDate: '',
    endDate: '',
    formattedEndDate: new Date(),
    isShow: false,
    haveClosed: false,
  },
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    handleChangePlan: (state, action: PayloadAction<IUserPlan>) => {
      state.account = action.payload;
    },
    handleChangeChristmasBanner: (state, action: PayloadAction<IChristmasBanner>) => {
      state.christmasBanner = action.payload;
    },
  },
});

export const accountSelector = createSelector(
  (state: RootState) => state.plan,
  (state) => state.account,
);
export const christmasBannerSelector = createSelector(
  (state: RootState) => state.plan,
  (state) => state.christmasBanner,
);

export default planSlice;
