import styled from 'styled-components';

export const StyledEmailTemplateDetail = styled.div`
  .page-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    .Polaris-Select {
      width: 12rem;
    }
  }
`;
