import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledBadge = styled.div<{ status: string; bgColor: string }>`
  width: fit-content;

  .badge-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    background-color: ${({ bgColor }) => bgColor};
    border: ${BgColorPalette.PRIMARY};
    border-radius: 2rem;
    padding: 0.125rem 0.5rem;

    .badge-dot {
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${({ status }) => status};
      border-radius: 50%;
    }

    .badge-text {
      width: calc(100% - 0.5rem);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
