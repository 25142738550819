import styled from 'styled-components';

export const StyledProductCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;

  .item-info {
    display: flex;
    gap: 1rem;

    .item-name {
      margin: auto;
    }

    img {
      width: 4rem;
      height: 4rem;
      border-radius: 0.5rem;
      object-fit: cover;
    }
  }
`;
