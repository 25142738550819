import styled from 'styled-components';

interface StyledSkeletonTextProps {
  width?: string;
  height: string;
}

export const StyledSkeletonText = styled.div<StyledSkeletonTextProps>`
  .Polaris-SkeletonBodyText {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;
