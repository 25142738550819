import { Link, Select, Text } from '@shopify/polaris';
import { StyledTrackingLogsFilter } from './styled';
import { capitalizeFirstLetter } from '@/helpers';
import { useDispatch, useSelector } from 'react-redux';
import trackingLogsSlice, { datePickerSelector, trackingLogsFilterSelector } from '@/redux/features/trackingLogs.slice';
import { LINK, OrderStatusPalette, optionsPerPage } from '@/constants';
import { useGetTrackingLogsQuery } from '@/redux/api/api.caller';
import { useSearchParams } from 'react-router-dom';
import { tokenSelector } from '@/redux/features/auth.slice';
import { CustomBadge } from '@/components';

const TrackingLogsFilter = () => {
  const [searchParams] = useSearchParams();
  const trackingNumber = searchParams.get('tracking-number');
  const orderId = searchParams.get('order-id');
  const dispatch = useDispatch();
  const { shop } = useSelector(tokenSelector);
  const trackingLogsFilter = useSelector(trackingLogsFilterSelector);
  const { start, end } = useSelector(datePickerSelector);
  const { data } = useGetTrackingLogsQuery({
    orderId: orderId || '',
    fromDate: start.toISOString(),
    toDate: end.toISOString(),
    page: trackingLogsFilter.page,
    perPage: trackingLogsFilter.pageSize,
    trackingNumber: trackingNumber || '',
  });
  const order = data?.data?.order;

  const handleChangePerPage = (selected: string) => {
    dispatch(trackingLogsSlice.actions.handleChangeTableFilter({ ...trackingLogsFilter, page: 1, pageSize: Number(selected) }));
  };

  return (
    <StyledTrackingLogsFilter>
      <div className="title-info">
        <Text variant="headingSm" as="h6">
          Status:
          <CustomBadge
            summary={
              Number(order?.pushStatus) === 2
                ? 'Over quota'
                : capitalizeFirstLetter(String(order?.trackingSummary?.split('_').join(' ')))
            }
            status={
              Number(order?.pushStatus) === 2
                ? OrderStatusPalette.OVER_QUOTA
                : OrderStatusPalette[order?.trackingSummary?.toUpperCase() as keyof typeof OrderStatusPalette]
            }
          />
        </Text>
        <Text variant="headingSm" as="h6">
          Tracking Number:
          <span>
            <Link url={LINK.TRACKING_ORDER_NOW(shop, order?.trackingNumber || '')}>{order?.trackingNumber || ''}</Link>
          </span>
        </Text>
        <Text variant="headingSm" as="h6">
          Carrier: <span>{order?.trackingCompany}</span>
        </Text>
      </div>
      <Select
        label="Per page"
        labelInline
        options={optionsPerPage}
        value={String(trackingLogsFilter.pageSize)}
        onChange={handleChangePerPage}
      />
    </StyledTrackingLogsFilter>
  );
};

export default TrackingLogsFilter;
