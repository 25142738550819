import { useDispatch, useSelector } from "react-redux";
import { themeCollapsibleConfig } from "./config";
import trackingPageSlice, { collapsibleSelector } from "@/redux/features/trackingPage.slice";
import { CollapsibleTheme } from "@/constants/trackingPage";
import ContentCollapsible from "../ContentCollapsible";

const ThemeAndStyles = () => {
  const collapsible = useSelector(collapsibleSelector);
  const dispatch = useDispatch();

  const handleToggle = (id: CollapsibleTheme) => {
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        theme: {
          key: collapsible.theme.key === id ? '' : id,
          isCollapse: collapsible.theme.key !== id,
        },
      }),
    );
  };

  return (
    <>
      {themeCollapsibleConfig.map(({ id, title, component }) => {
        const { key, isCollapse } = collapsible.theme;

        return (
          <ContentCollapsible key={id} title={title} isOpen={isCollapse && key === id} onToggle={() => handleToggle(id)}>
            {component}
          </ContentCollapsible>
        );
      })}
    </>
  );
}

export default ThemeAndStyles
