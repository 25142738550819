import dayjs from 'dayjs';
import { StyledShipmentStepper } from './styled';
import { memo } from 'react';

interface ShipmentStepperProps {
  data: {
    time: string;
    title: string;
  }[];
}

const ShipmentStepper = ({ data }: ShipmentStepperProps) => {
  return (
    <StyledShipmentStepper>
      {data.map(({ time, title }, index) => (
        <li key={index}>
          <time />
          <span>{title}</span>
          <div className="time-info">{dayjs(time).format('MMM DD, YYYY hh:mm A')}</div>
        </li>
      ))}
    </StyledShipmentStepper>
  );
};

export default memo(ShipmentStepper);
