import { PATH } from '@/constants';
import { Card, IndexTable } from '@shopify/polaris';
import TrackingLogsFilter from './components/TrackingLogsFilter';
import { cleanObject } from '@/helpers';
import { useDispatch, useSelector } from 'react-redux';
import trackingLogsSlice, {
  datePickerSelector,
  titleDatePickerSelector,
  trackingLogsFilterSelector,
} from '@/redux/features/trackingLogs.slice';
import dayjs from 'dayjs';
import DatePicker from '@/components/DatePicker';
import { useGetTrackingLogsQuery } from '@/redux/api/api.caller';
import { useSearchParams } from 'react-router-dom';
import Layout from '@/components/Layout';
import { CustomTable } from '@/components';
import { useMemo } from 'react';

const TrackingLogs = () => {
  const [searchParams] = useSearchParams();
  const trackingNumber = searchParams.get('tracking-number');
  const orderId = searchParams.get('order-id');
  const dispatch = useDispatch();
  const trackingLogsFilter = useSelector(trackingLogsFilterSelector);
  const { start, end } = useSelector(datePickerSelector);
  const titleDatePicker = useSelector(titleDatePickerSelector);
  const unRequiredParams = cleanObject({
    sortBy: trackingLogsFilter.sortBy,
    sortDirection: trackingLogsFilter.sortBy ? trackingLogsFilter.sortOrder : '',
  });
  const { data, isFetching } = useGetTrackingLogsQuery({
    orderId: orderId || '',
    fromDate: start.toISOString(),
    toDate: end.toISOString(),
    page: trackingLogsFilter.page,
    perPage: trackingLogsFilter.pageSize,
    trackingNumber: trackingNumber || '',
    ...unRequiredParams,
  });
  const trackingLogs = data?.data?.trackingLogs || [];

  const handleApplyDatePicker = (start: Date, end: Date) => dispatch(trackingLogsSlice.actions.handleDatePicker({ start, end }));
  const handleSaveDatePickerTitle = (titleBtn: string) => dispatch(trackingLogsSlice.actions.handleTitleDatePicker(titleBtn));

  const handleClickPaginationBtn = (type: 'next' | 'prev') =>
    dispatch(
      trackingLogsSlice.actions.handleChangeTableFilter({
        ...trackingLogsFilter,
        page: type === 'prev' ? trackingLogsFilter.page - 1 : trackingLogsFilter.page + 1,
      }),
    );

  const rowMarkup = useMemo(() => {
    if (data && data.data && data.data.trackingLogs) {
      return data.data.trackingLogs.map((data, index) => (
        <IndexTable.Row id={data.id.toString()} key={data.id} position={index}>
          <IndexTable.Cell>{dayjs(data.createdAt).format('MMM DD, YYYY hh:mm A')}</IndexTable.Cell>
          <IndexTable.Cell>{data.city}</IndexTable.Cell>
          <IndexTable.Cell>{data.regionName}</IndexTable.Cell>
          <IndexTable.Cell>{data.countryName}</IndexTable.Cell>
          <IndexTable.Cell>{data.regionCode}</IndexTable.Cell>
          <IndexTable.Cell>{data.countryCode}</IndexTable.Cell>
          <IndexTable.Cell>{data.timeZone}</IndexTable.Cell>
          <IndexTable.Cell>{data.currencyCode}</IndexTable.Cell>
        </IndexTable.Row>
      ));
    }
    return [];
  }, [data]);

  return (
    <Layout
      backAction={{ url: PATH.SHIPMENT }}
      primaryAction={
        <DatePicker
          titleBtn={titleDatePicker}
          start={start}
          end={end}
          onSave={handleApplyDatePicker}
          setTitleBtn={handleSaveDatePickerTitle}
        />
      }
    >
      <Card>
        <CustomTable
          filter={<TrackingLogsFilter />}
          tableProps={{
            resourceName: {
              singular: 'tracking log',
              plural: 'tracking logs',
            },
            itemCount: trackingLogs.length,
            headings: [
              { title: 'Time' },
              { title: 'City' },
              { title: 'Region Name' },
              { title: 'Region Code' },
              { title: 'Country Name' },
              { title: 'Country Code' },
              { title: 'Time Zone' },
              { title: 'Currency' },
            ],
            selectable: false,
          }}
          skeletonLines={8}
          isLoading={isFetching}
          page={trackingLogsFilter.page}
          pageSize={trackingLogsFilter.pageSize}
          totalResult={data?.data?.totalResult || 0}
          paginationProps={{
            onPrevious: () => handleClickPaginationBtn('prev'),
            onNext: () => handleClickPaginationBtn('next'),
          }}
        >
          {rowMarkup}
        </CustomTable>
      </Card>
    </Layout>
  );
};

export default TrackingLogs;
