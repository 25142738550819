import { Link, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { tabsConfig } from './config';
import settingsSlice, { tabSelectedSelector } from '@/redux/features/settings.slice';
import Layout from '@/components/Layout';
import { CustomBanner, CustomPage } from '@/components';

const Settings = () => {
  const dispatch = useDispatch();
  const tabSelected = useSelector(tabSelectedSelector);
  const handleSelected = (value: Array<string>) => {
    dispatch(settingsSlice.actions.handleSelected(value[0]));
  };
  const navigateUpdateTheme = () => {
    dispatch(settingsSlice.actions.handleSelected('updateTheme'));
  };
  return (
    <Layout title="Settings">
      <CustomBanner isClose={tabSelected !== 'trackingLink'} tone="warning" title="Update the Script tag">
        <Text as="p" variant="bodySm">
          To use the Tracking link setting function, please update the script tag in&nbsp;
          <span className="link">
            <Link onClick={navigateUpdateTheme}>Settings</Link>
          </span>
        </Text>
      </CustomBanner>
      <CustomPage tabsConfig={tabsConfig} selected={[tabSelected]} onChange={handleSelected} selectedTab={tabSelected} />
    </Layout>
  );
};

export default Settings;
