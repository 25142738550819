import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledNotVerifiedPopup = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: ${BgColorPalette.OVERLAY};
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-container {
    text-align: center;
    background-color: white;
    border-radius: 1rem;
    width: 30rem;
    padding: 1rem 2rem;

    .cancel-icon {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    img {
      margin-bottom: 0.75rem;
      user-select: none;
    }

    .email-info {
      color: #44474a;
      margin-block: 1rem;
      font-weight: 500;

      span {
        font-size: 0.875rem;
        font-weight: 600;
        color: black;
      }
    }

    .description {
      color: #44474a;
      margin-bottom: 2rem;
      font-weight: 500;

      span {
        font-size: 0.875rem;
        font-weight: 600;
        color: black;
      }
    }
  }
`;
