import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Checkbox, InlineError, InlineGrid, Select } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { optionsDateFormat, optionsTimeFormat, progressBarConfig, shippingInfoConfig } from './config';
import { ProgressBar, ShippingInfo } from '@/constants/enum';
import { CustomCard } from '@/components';
import { checkDirtyField } from '@/helpers';
import { isNotChangeData } from '@/pages/TrackingPage/config';

const Content = () => {
  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      lookAndFeel: { content: oldContent },
    },
  } = useSelector(trackingPageSelector);
  const { content } = data.lookAndFeel;
  const handleShippingInfo = (value: ShippingInfo) => () => {
    const shippingInfo = [...data.lookAndFeel.content.shippingInfo];
    const index = shippingInfo.findIndex((item) => item === value);
    if (index === -1) {
      shippingInfo.push(value);
    } else {
      shippingInfo.splice(index, 1);
    }
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          content: {
            ...content,
            shippingInfo,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          isNotChangeData(oldContent.shippingInfo, shippingInfo) && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                show: shippingInfo.join(','),
              },
      }),
    );
  };
  const handleChangeFormat = (key: string) => (value: string) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          content: {
            ...content,
            format: {
              ...content.format,
              [key]: value,
            },
          },
        },
      }),
    );

    const { dirtyField: formatField } = checkDirtyField(oldContent.format, {
      ...content.format,
      [key]: value,
    });

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          !formatField && Object.entries(fieldsChange.general).length < 3
            ? undefined
            : {
                ...fieldsChange.general,
                ...formatField,
              },
      }),
    );
  };

  const handleProgressBar = (value: ProgressBar) => () => {
    const progressBar = [...data.lookAndFeel.content.progressBar];
    const index = progressBar.findIndex((item) => item === value);
    if (index === -1) {
      progressBar.push(value);
    } else {
      progressBar.splice(index, 1);
    }
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          content: {
            ...content,
            progressBar,
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        displayStatusBar: isNotChangeData(oldContent.progressBar, progressBar) ? undefined : progressBar.join(','),
      }),
    );
  };
  return (
    <>
      <CustomCard variantTitle="headingSm" title="Shipping info">
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap={'200'}>
          {shippingInfoConfig.map((item) => {
            return (
              <Checkbox
                key={item.value}
                label={item.label}
                checked={content.shippingInfo.includes(item.value)}
                onChange={handleShippingInfo(item.value)}
              />
            );
          })}
        </InlineGrid>
        {content.shippingInfo.length === 0 && (
          <div className="mt-8">
            <InlineError fieldID="shipping-info-error" message="Please choose at least one option" />
          </div>
        )}
      </CustomCard>

      <CustomCard variantTitle="headingSm" title="Date and time format" className="mt-8">
        <InlineGrid columns={1} gap={'200'}>
          <Select
            label=""
            labelHidden
            options={optionsDateFormat}
            onChange={handleChangeFormat('dateFormat')}
            value={content.format.dateFormat}
          />
          <Select
            label=""
            labelHidden
            options={optionsTimeFormat}
            onChange={handleChangeFormat('timeFormat')}
            value={content.format.timeFormat}
          />
        </InlineGrid>
      </CustomCard>

      <CustomCard variantTitle="headingSm" title="Progress bar" className="mt-8">
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap={'200'}>
          {progressBarConfig.map((item) => {
            return (
              <Checkbox
                key={item.value}
                label={item.label}
                checked={content.progressBar.includes(item.value)}
                onChange={handleProgressBar(item.value)}
              />
            );
          })}
        </InlineGrid>
        {content.progressBar.length === 0 && (
          <div className="mt-8">
            <InlineError fieldID="shipping-info-error" message="Please choose at least one option" />
          </div>
        )}
      </CustomCard>
    </>
  );
};

export default Content;
