import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const SwitchStyled = styled.div`
  .toggle-btn {
    transition: all 0.3s ease-in-out;
    display: flex;
    height: 12px;
    width: 20px;
    border: 2px solid ${BgColorPalette.PRIMARY};
    border-radius: 6px;
    position: relative;
    .circle {
      transition: all 0.3s ease-in-out;
      position: absolute;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      border: 2px solid ${BgColorPalette.PRIMARY};
      left: -2px;
      bottom: -2px;
    }
  }
  .contrary {
    background-color: ${BgColorPalette.PRIMARY};
    .circle {
      background-color: white;
      left: 6px;
    }
  }
`;
