export enum CustomizationTypeTabs {
  Link = 'link',
  ThemeAndStyles = 'theme&styles',
  Languages = 'languages',
}

export enum CollapsibleLink {
  FooterLink = 'footer_link',
  NoInfoOrder = 'no_info_order',
}

export enum CollapsibleTheme {
  ChooseTheme = 'choose_theme',
  LookupOptions = 'lookup_options',
  Content = 'content',
  Style = 'style',
  HTMLAndCSS = 'html&css',
}

export enum CollapsibleLanguages {
  TrackingStatus = 'tracking_status',
  TrackingForm = 'tracking_form',
  TrackingHistory = 'tracking_history',
  ProgressBar = 'progress_bar',
  OrderDetails = 'order details',
  Review = 'review',
  Footer = 'footer',
}

export enum TrackingPagePreviewFocus {
  NoFocus = '',
  TextABove = 'text_above',
  TextBelow = 'text_below',
  HtmlTop = 'html_top',
  HtmlBottom = 'html_bottom',
  TrackTitle = 'tracking_title',
  Order = 'order',
  TrackingNumber = 'tracking_number',
  PlaceHolderOrder = 'place_holder_order',
  Email = 'email',
  PlaceholderEmail = 'place_holder_email',
  Track = 'Track',
  Asset1 = 'asset1',
  Asset2 = 'asset2',
  Asset3 = 'asset3',
  PlaceholderTrackingNumber = 'place_holder_tracking_number',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  Pinterest = 'pinterest',
  privacy = 'privacy',
  return = 'return',
  support = 'support',
  term = 'term',
  Or = 'or',
}

export enum CustomizationPreviewBodyTabs {
  OrderId = 'order_id',
  TrackingNumber = 'tracking_number',
}
