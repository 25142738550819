import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IDatePicker } from '@/types';
import { getSomeDaysAgo } from '@/helpers';
import { PageSize, SortDirection } from '@/constants/enum';
import { ShipmentStatus } from '@/constants/shipment';
import { ITrackingDetail } from '@/types/shipment';

interface IShipmentFilter {
  statusIndex: number;
  status: ShipmentStatus;
  search: string;
  courier: string;
  page: number;
  pageSize: PageSize;
  sortBy: string;
  sortOrder: SortDirection;
}

interface IDisabledResyncBtn {
  time: Date;
  tooltip: string;
  detail: {
    id: string;
    time: Date;
  }[];
  detailTooltip: string;
}

export interface ICourierModal {
  open: boolean;
  value: string;
  id: Array<number>;
}

export interface IStatusModal {
  open: boolean;
  orderId: string;
  trackingNumber: string;
  status: ShipmentStatus;
  history: ITrackingDetail[];
  isSend: boolean;
}

export interface IShipmentState {
  filter: IShipmentFilter;
  titleDatePicker: string;
  datePicker: IDatePicker;
  disabledBtn: IDisabledResyncBtn;
  courierModal: ICourierModal;
  statusModal: IStatusModal;
}

const initialState: IShipmentState = {
  filter: {
    statusIndex: 0,
    status: ShipmentStatus.All,
    search: '',
    courier: 'all',
    page: 1,
    pageSize: PageSize.Five,
    sortOrder: SortDirection.DESC,
    sortBy: 'createdAt',
  },
  datePicker: {
    start: getSomeDaysAgo(30),
    end: new Date(),
  },
  titleDatePicker: 'Last 30 days',
  disabledBtn: {
    time: new Date(),
    tooltip: '',
    detail: [],
    detailTooltip: '',
  },

  courierModal: {
    open: false,
    value: '',
    id: [],
  },
  statusModal: {
    open: false,
    orderId: '',
    trackingNumber: '',
    status: ShipmentStatus.CourierNotSupported,
    history: [],
    isSend: false,
  },
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    handleChangeTableFilter: (state: IShipmentState, action: PayloadAction<IShipmentFilter>) => {
      state.filter = action.payload;
    },
    handleDatePicker: (state, action: PayloadAction<IDatePicker>) => {
      state.datePicker = action.payload;
    },
    handleTitleDatePicker: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
    handleSetDisabledBtnTime: (state, action: PayloadAction<IDisabledResyncBtn>) => {
      state.disabledBtn = action.payload;
    },
    handleChangeCourierModal: (state, action: PayloadAction<ICourierModal>) => {
      state.courierModal = action.payload;
    },
    handleChangeStatusModal: (state, action: PayloadAction<IStatusModal>) => {
      state.statusModal = action.payload;
    },
  },
});

export const shipmentFilterSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.filter,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.datePicker,
);
export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.titleDatePicker,
);
export const disabledBtnSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.disabledBtn,
);
export const courierModalSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.courierModal,
);
export const statusModalSelector = createSelector(
  (state: RootState) => state.shipment,
  (state) => state.statusModal,
);

export default shipmentSlice;
