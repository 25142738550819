import { FunctionComponent, SVGProps, memo } from 'react';
import { Button, Card, Text } from '@shopify/polaris';

export interface HelpCardProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  url?: string;
  onClick?: () => void;
}

const HelpCard = ({ title, icon, description, url, onClick }: HelpCardProps) => {
  return (
    <Card>
      <Button variant="plain" icon={icon} url={url} onClick={onClick}>
        {title}
      </Button>
      <Text as="h6" variant="bodySm">
        {description}
      </Text>
    </Card>
  );
};

export default memo(HelpCard);
