import styled from 'styled-components';

export const StyledShipmentOvertime = styled.div`
  .card-container {
    height: 100%;

    .Polaris-ShadowBevel {
      height: 100%;
    }
  }

  .shipment-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
`;
