import { SelectOption } from '@shopify/polaris';

export const dayInWeek = [
  {
    code: 2,
    label: 'Mon',
    labelFull: 'Monday',
    momentCode: 1,
  },
  {
    code: 3,
    label: 'Tue',
    labelFull: 'Tuesday',
    momentCode: 2,
  },
  {
    code: 4,
    label: 'Wed',
    labelFull: 'Wednesday',
    momentCode: 3,
  },
  {
    code: 5,
    label: 'Thu',
    labelFull: 'Thursday',
    momentCode: 4,
  },
  {
    code: 6,
    label: 'Fri',
    labelFull: 'Friday',
    momentCode: 5,
  },
  {
    code: 7,
    label: 'Sat',
    labelFull: 'Saturday',
    momentCode: 6,
  },
  {
    code: 8,
    label: 'Sun',
    labelFull: 'Sunday',
    momentCode: 7,
  },
];

export const orderDescriptionError = {
  PROCESSING_TIME_ERROR: 'Processing time is a number smaller than 1000',
  BUSINESS_DAYS_ERROR: 'At least one day has checked',
};

export const shippingMethodError = {
  TRANSIT_TIME: 'Transit time must be a number smaller than 1000',
  SHIPPING_METHOD: 'Choose at least one shipping method',
};

export const getDayLabelByDay = (day: number) => dayInWeek.find(({ code }) => code === day)?.labelFull;

const getRange = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);

export const cutoffTimeOptions: SelectOption[] = getRange(0, 23).map((value) => {
  const formattedValue = String(value).padStart(2, '0');
  return {
    value: formattedValue,
    label: `${formattedValue}:00`,
  };
});

export const timezoneOptions: SelectOption[] = getRange(-12, 12).map((value) => {
  const formattedValue = String(value).padStart(2, '+');
  return {
    value: formattedValue,
    label: `(GMT${formattedValue}) UTC`,
  };
});

export const defaultOrderCutoffAndProcessingTimes = [
  {
    title: 'Order cutoff time',
    value: '19:00 - (GMT+7) UTC',
  },
  {
    title: 'Additional processing time',
    value: '5 additional business days',
  },
  {
    title: 'Business days',
    value: [2, 3, 4, 5, 6].map((day) => getDayLabelByDay(day)).join(', '),
  },
];

export const defaultTransitTimes = [
  {
    id: 1,
    shop: '',
    shippingDestinationCountry: ['Rest of world'],
    shippingMethod: [
      {
        courier: ['Any shipping method'],
        first: '2',
        second: '7',
      },
    ],
  },
];
