import { AccountPlan, Subscription } from '@/constants/enum';

export interface IUpdatePlan {
  confirmationUrl: string;
}

export interface IUpdatePlanParams {
  plan: AccountPlan;
  subscription: Subscription;
}

export interface IUpdateDiscountCode {
  shop?: string;
  discountCode: string;
  plan: AccountPlan;
}

export enum DiscountType {
  Percent = 'percent',
  Fixed = 'fixed',
  Price = 'price',
  Feature = 'feature',
}
