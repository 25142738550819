import { Text } from '@shopify/polaris';
import { StyledProductCard } from './styled';
import { memo } from 'react';

interface ProductCardProps {
  image: string;
  title: string;
  quantity: string;
  price: string;
}

const ProductCard = ({ image, title, quantity, price }: ProductCardProps) => {
  return (
    <StyledProductCard>
      <div className="item-info">
        <img src={image || ''} alt={title} />
        <div className="item-name">
          <Text variant="headingSm" as="h6">
            {title}
          </Text>
          <span>{`${quantity} item`}</span>
        </div>
      </div>
      <Text variant="headingSm" as="h6">
        {price}
      </Text>
    </StyledProductCard>
  );
};

export default memo(ProductCard);
