import { CustomCard } from '@/components';
import { InlineGrid, TextField } from '@shopify/polaris';
import { footerLinkConfig } from './config';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { trackingFocusSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { checkDirtyField, isUrlValid, renderErrorValue, splitObjects } from '@/helpers';
import { TrackingPagePreviewFocus } from '@/constants/trackingPage';

const FooterLink = () => {
  const dispatch = useDispatch();
  const { fieldsChange, validation, value: data, oldValue } = useSelector(trackingPageSelector);
  const { footerLink } = data.configuration;
  const { footerLink: oldFooterLink } = oldValue.configuration;
  const focus = useSelector(trackingFocusSelector);

  const handleChange = (key: string) => (value: string) => {
    dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          footerLink: {
            ...footerLink,
            [key]: value,
          },
        },
      }),
    );
    const { dirtyField: policyField } = checkDirtyField(splitObjects(oldFooterLink, 0, 4), {
      ...splitObjects(footerLink, 0, 4),
      [key]: value,
    });
    const { dirtyField: socialField } = checkDirtyField(splitObjects(oldFooterLink, 4, Object.entries(oldFooterLink).length), {
      ...splitObjects(footerLink, 4, Object.entries(footerLink).length),
      [key]: value,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        footerLinks:
          !policyField && !socialField
            ? undefined
            : {
                social: socialField,
                policy: policyField,
              },
      }),
    );
  };

  const handleBlurTextField = (value: string, key: string) => {
    if (value && !isUrlValid(value)) {
      const isNotHaveKey = validation.findIndex((item) => item === key) === -1;
      if (isNotHaveKey) {
        dispatch(trackingPageSlice.actions.handleChangeValidation([...validation, key]));
      }
    } else {
      dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
    }
  };

  const handleFocus = (key: TrackingPagePreviewFocus) => {
    dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(key));
  };

  return (
    <>
      <CustomCard title="Social media profiles">
        <InlineGrid columns={1} gap="400">
          {footerLinkConfig.social.map((item) => {
            return (
              <TextField
                placeholder="URL profile"
                id={item.key}
                key={item.key}
                label={item.title}
                value={footerLink[item.key as keyof typeof footerLink]}
                onChange={handleChange(item.key)}
                onBlur={() => handleBlurTextField(footerLink[item.key as keyof typeof footerLink], item.key)}
                autoComplete="off"
                maxLength={255}
                showCharacterCount
                error={renderErrorValue(validation, item.key)}
                onFocus={() => handleFocus(item.id)}
                focused={focus === item.id}
              />
            );
          })}
        </InlineGrid>
      </CustomCard>

      <CustomCard className="mt-16" title="Policy">
        <InlineGrid columns={1} gap="400">
          {footerLinkConfig.policy.map((item) => {
            return (
              <div key={item.key}>
                <div>{item.title}</div>
                <div className="mt-8">
                  <TextField
                    key={item.key}
                    label={item.title}
                    labelHidden
                    placeholder={item.placeHolder}
                    value={footerLink[item.key as keyof typeof footerLink]}
                    onChange={handleChange(item.key)}
                    onBlur={() => handleBlurTextField(footerLink[item.key as keyof typeof footerLink], item.key)}
                    autoComplete="off"
                    maxLength={255}
                    showCharacterCount
                    error={renderErrorValue(validation, item.key)}
                    onFocus={() => handleFocus(item.id)}
                    focused={focus === item.id}
                  />
                </div>
              </div>
            );
          })}
        </InlineGrid>
      </CustomCard>
    </>
  );
};
export default FooterLink;
