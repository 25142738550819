import commonSlice, { toastSelector } from '@/redux/features/common.slice';
import { Toast } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

const CustomToast = (): JSX.Element => {
  const dispatch = useDispatch();
  const toast = useSelector(toastSelector);

  return toast.isOpen ? (
    toast.hasAction ? (
      <Toast
        duration={3000}
        content={toast.content}
        error={toast.error}
        action={{
          content: toast.contentAction,
          onAction: toast.onAction,
        }}
        onDismiss={() => {
          dispatch(
            commonSlice.actions.handleToast({
              content: '',
              isOpen: false,
              error: false,
            }),
          );
        }}
      />
    ) : (
      <Toast
        duration={3000}
        content={toast.content}
        error={toast.error}
        onDismiss={() => {
          dispatch(
            commonSlice.actions.handleToast({
              content: '',
              isOpen: false,
              error: false,
            }),
          );
        }}
      />
    )
  ) : (
    <></>
  );
};

export default CustomToast;
