import { CustomCard, UpgradeBanner } from '@/components';
import { AccountPlan, ProductRecommendType } from '@/constants/enum';
import { checkDirtyField, splitObjects } from '@/helpers';
import { isLockFeature } from '@/helpers/feature';
import { useGetProductRecommendQuery } from '@/redux/api/api.caller';
import { accountSelector } from '@/redux/features/plan.slice';
import trackingPageSlice, { trackingPageIsLoadingSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Autocomplete, Checkbox, Select, SkeletonBodyText, Tag, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNotChangeData } from '../../config';

interface IAutoCompleteTextFieldState {
  optionsSelected: Array<{
    value: string;
    label: string;
  }>;
  optionsFilter: Array<{
    value: string;
    label: string;
  }>;
  options: Array<{
    value: string;
    label: string;
  }>;
  baseOptions: {
    type: Array<{
      value: string;
      label: string;
    }>;
    tags: Array<{
      value: string;
      label: string;
    }>;
  };
}

const optionsConfig = [
  { label: 'All product', value: ProductRecommendType.AllProduct, isHasValue: false },
  { label: 'Product Type', value: ProductRecommendType.productType, isHasValue: true },
  { label: 'Product Tags', value: ProductRecommendType.Tags, isHasValue: true },
];

const ProductRecommendation = () => {
  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const plan = useSelector(accountSelector);
  const {
    fieldsChange,
    oldValue: {
      configuration: { productRecommendation: oldProductRecommendation },
    },
  } = useSelector(trackingPageSelector);
  const isLoading = useSelector(trackingPageIsLoadingSelector);
  const isLock = isLockFeature([AccountPlan.Starter]) && !plan.isOldPaidUser;
  const { productRecommendation } = data.configuration;
  const [isFocusAutoComplete, setIsFocusAutoComplete] = useState<boolean>(false);
  const productionRecommendData = useGetProductRecommendQuery(undefined, {
    skip: !isFocusAutoComplete || isLock,
  });
  const [state, setState] = useState<IAutoCompleteTextFieldState>({
    optionsSelected: [],
    baseOptions: {
      type: [],
      tags: [],
    },
    optionsFilter: [],
    options: [],
  });
  const [inputValue, setInputValue] = useState('');
  const updateText = (inputValue: string) => {
    setInputValue(inputValue);
    const optionsFilter = state.options.filter((option) => option.label.toUpperCase().includes(inputValue.toUpperCase()));
    setState({ ...state, optionsFilter });
  };
  useEffect(() => {
    if (productionRecommendData.data) {
      const { data } = productionRecommendData.data;
      const type = data.types.map((item) => {
        return { value: item, label: item };
      });
      const tags = data.tags.map((item) => {
        return { value: item, label: item };
      });
      setState({
        ...state,
        baseOptions: { type, tags },
        options: productRecommendation.type === ProductRecommendType.Tags ? tags : type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionRecommendData.data]);

  useEffect(() => {
    if (productRecommendation.type === ProductRecommendType.Tags) {
      setState({ ...state, options: state.baseOptions.tags });
    }
    if (productRecommendation.type === ProductRecommendType.productType) {
      setState({ ...state, options: state.baseOptions.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRecommendation.type]);

  const handleProductRecommendationField = (currentValue: {}) => {
    const { dirtyField: enableField } = checkDirtyField(splitObjects(oldProductRecommendation, 0, 1), {
      ...currentValue,
    });

    const { dirtyField: titleField } = checkDirtyField(splitObjects(oldProductRecommendation, 1, 2), {
      ...currentValue,
    });

    const { dirtyField: typeField } = checkDirtyField(splitObjects(oldProductRecommendation, 2, 3), {
      ...currentValue,
    });

    if (!enableField && !titleField && !typeField) {
      return undefined;
    }
    return {
      isActive: enableField?.enable,
      title: titleField?.title,
      type: typeField?.type,
    };
  };

  const handleChange = (key: string, apiKey: string) => (value: string | boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          productRecommendation: {
            ...productRecommendation,
            value: !handleProductRecommendationField({ ...productRecommendation, [key]: value })
              ? oldProductRecommendation.value
              : [],
            [key]: value,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        productRecommend: handleProductRecommendationField({
          ...productRecommendation,
          [key]: value,
        }),
      }),
    );
  };
  const handleChangeSelected = (value: Array<string>) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          productRecommendation: {
            ...productRecommendation,
            value,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        productRecommend:
          isNotChangeData(oldProductRecommendation.value, value) && Object.entries(fieldsChange.productRecommend).length < 3
            ? undefined
            : {
                ...fieldsChange.productRecommend,
                tags: productRecommendation.type === ProductRecommendType.Tags ? value : undefined,
                productType: productRecommendation.type === ProductRecommendType.productType ? value : undefined,
              },
      }),
    );
  };

  const handleRemoveTag = (value: string) => () => {
    const selected = [...data.configuration.productRecommendation.value];
    const index = selected.findIndex((item) => item === value);
    selected.splice(index, 1);
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          productRecommendation: {
            ...productRecommendation,
            value: selected,
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        productRecommend:
          isNotChangeData(oldProductRecommendation.value, selected) &&
          Object.entries(fieldsChange.productRecommend || {}).length < 3
            ? undefined
            : {
                ...fieldsChange.productRecommend,
                tags: productRecommendation.type === ProductRecommendType.Tags ? selected : undefined,
                productType: productRecommendation.type === ProductRecommendType.productType ? selected : undefined,
              },
      }),
    );
  };

  return (
    <>
      <CustomCard title="Product Recommendation">
        {isLoading ? (
          <SkeletonBodyText lines={13} />
        ) : (
          <div>
            <UpgradeBanner isOpen={!!isLock} usedFeature={AccountPlan.Basic} isHiddenTitle/>
            <Checkbox
              label="Enable product recommendation"
              checked={productRecommendation.enable}
              onChange={handleChange('enable', 'isActive')}
              disabled={isLock}
            />
            <div className="mt-16">
              <TextField
                label="Title"
                value={productRecommendation.title}
                onChange={handleChange('title', 'title')}
                autoComplete="off"
                maxLength={50}
                showCharacterCount
                disabled={isLock}
              />
            </div>
            <div className="mt-16">
              <Select
                label="Type"
                options={optionsConfig.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                onChange={handleChange('type', 'type')}
                value={productRecommendation.type}
                disabled={isLock}
              />
            </div>
            <div className="mt-16">
              {optionsConfig.find((item) => item.value === productRecommendation.type)?.isHasValue ? (
                <div>
                  <Autocomplete
                    options={state.options}
                    selected={productRecommendation.value}
                    onSelect={handleChangeSelected}
                    textField={
                      <div onFocus={() => setIsFocusAutoComplete(true)}>
                        <Autocomplete.TextField
                          onChange={updateText}
                          label="Select your type/tag for recommend products"
                          value={inputValue}
                          autoComplete="off"
                        />
                      </div>
                    }
                    allowMultiple
                    loading={productionRecommendData.isLoading}
                  />
                  <div className="d-flex mt-16">
                    {productRecommendation.value.length > 0 &&
                      productRecommendation.value.map((option) => {
                        return (
                          <div key={option} className="mr-8">
                            <Tag onRemove={handleRemoveTag(option)}>{option}</Tag>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </CustomCard>
    </>
  );
};

export default ProductRecommendation;
