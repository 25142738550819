import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IDatePicker } from '@/types';
import { getSomeDaysAgo } from '@/helpers';
import { PageSize, Receiver, SortDirection } from '@/constants/enum';
import { EmailTabs, SenderEmailType, TrackingStatus } from '@/constants';

interface ISenderInfo {
  type: SenderEmailType;
  account: { value: string; error: string; disable: boolean };
  name: string;
  isNotVerifiedPopupActive: boolean;
  isNoReplyModalActive: boolean;
  isDisconnectedModalActive: boolean;
}

interface IEmailTemplates {
  customer: IReceiverTemplate;
  merchant: IReceiverTemplate;
}

interface IReceiverTemplate {
  status: Array<string>;
  selectedStatus: TrackingStatus;
  subject: string;
}

interface IEmailHistory {
  datePicker: IDatePicker;
  search: string;
  status: TrackingStatus;
  page: number;
  pageSize: PageSize;
  sortBy: string;
  sortOrder: SortDirection;
}

export interface IEmailState {
  isShowPreviewNewVersion: boolean;
  isOpenNewVersionModal: boolean;
  haveShownVerifyEmailPopup: boolean;
  senderInfo: ISenderInfo;
  emailTemplates: IEmailTemplates;
  orderStatus: TrackingStatus;
  datePickerTitle: string;
  history: IEmailHistory;
  haveClosedBanner: boolean;
  emailTab: EmailTabs;
  emailTemplatesTab: Receiver;
}

const initialState: IEmailState = {
  isShowPreviewNewVersion: false,
  isOpenNewVersionModal: false,
  haveShownVerifyEmailPopup: false,
  senderInfo: {
    type: SenderEmailType.NoReply,
    account: { value: '', error: '', disable: false },
    name: '',
    isNotVerifiedPopupActive: false,
    isNoReplyModalActive: false,
    isDisconnectedModalActive: false,
  },
  emailTemplates: {
    customer: {
      status: [],
      selectedStatus: TrackingStatus.All,
      subject: '',
    },
    merchant: {
      status: [],
      selectedStatus: TrackingStatus.All,
      subject: '',
    },
  },
  orderStatus: TrackingStatus.Pending,
  datePickerTitle: 'Last 30 days',
  history: {
    datePicker: {
      start: getSomeDaysAgo(30),
      end: new Date(),
    },
    search: '',
    status: TrackingStatus.All,
    page: 1,
    pageSize: PageSize.Five,
    sortBy: 'updatedAt',
    sortOrder: SortDirection.DESC,
  },
  haveClosedBanner: false,
  emailTab: EmailTabs.EmailAccount,
  emailTemplatesTab: Receiver.Customer,
};

export const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    handleShowNewVersion: (state, action: PayloadAction<boolean>) => {
      state.isShowPreviewNewVersion = action.payload;
    },
    handleOpenNewVersionModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenNewVersionModal = action.payload;
    },
    handleShowVerifyEmail: (state, action: PayloadAction<boolean>) => {
      state.haveShownVerifyEmailPopup = action.payload;
    },
    handleChangeSenderInfo: (state, action: PayloadAction<ISenderInfo>) => {
      state.senderInfo = action.payload;
    },
    handleChangeEmailTemplates: (state, action: PayloadAction<IEmailTemplates>) => {
      state.emailTemplates = action.payload;
    },
    handleChangeOrderStatus: (state, action: PayloadAction<TrackingStatus>) => {
      state.orderStatus = action.payload;
    },
    handleChangeDatePickerTitle: (state, action: PayloadAction<string>) => {
      state.datePickerTitle = action.payload;
    },
    handleChangeEmailHistory: (state, action: PayloadAction<IEmailHistory>) => {
      state.history = action.payload;
    },
    handleCloseBanner: (state, action: PayloadAction<boolean>) => {
      state.haveClosedBanner = action.payload;
    },
    handleChangeEmailTab: (state, action: PayloadAction<EmailTabs>) => {
      state.emailTab = action.payload;
    },
    handleChangeEmailTemplatesTab: (state, action: PayloadAction<Receiver>) => {
      state.emailTemplatesTab = action.payload;
    },
  },
});

export const showNewVersionSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.isShowPreviewNewVersion,
);
export const openNewVersionModalSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.isOpenNewVersionModal,
);
export const showVerifyEmailSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.haveShownVerifyEmailPopup,
);
export const senderInfoSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.senderInfo,
);
export const emailTemplatesSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.emailTemplates,
);
export const orderStatusSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.orderStatus,
);
export const datePickerTitleSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.datePickerTitle,
);
export const emailHistorySelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.history,
);
export const haveClosedBannerSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.haveClosedBanner,
);
export const emailTabSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.emailTab,
);
export const emailTemplatesTabSelector = createSelector(
  (state: RootState) => state.email,
  (state) => state.emailTemplatesTab,
);

export default emailSlice;
