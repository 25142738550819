import styled from 'styled-components';

export const StyledEstimatedDeliveryDate = styled.div`
  position: relative;

  .group-btn {
    display: flex;
    gap: 1rem;
  }
`;
