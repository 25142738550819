import { PATH } from '@/constants';
import { BlockStack, Button } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import ShippingZone from '../ShippingZone';
import ShippingMethod from '../ShippingMethod';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { useGetEDDSettingsQuery, useUpdateTransitTimeMutation } from '@/redux/api/api.caller';
import { IUpdatedTransitTime } from '@/types/edd';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import Layout from '@/components/Layout';
import { ErrorBanner, UpgradeBanner } from '@/components';
import { AccountPlan } from '@/constants/enum';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { isLockFeature } from '@/helpers';

const TransitTimeEditorDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const eddState = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const { id } = useParams();
  const eddSettingData = useGetEDDSettingsQuery();
  const [updateTransitTime, updatedTransitTimeData] = useUpdateTransitTimeMutation();
  const transitTimeById = (eddSettingData.data?.data.transitTime || []).find((item) => item.id === Number(id || ''));

  const handleUpdateTransitTime = () => {
    const payload: IUpdatedTransitTime = {
      id: Number(id),
      shippingDestinationCountry: eddState.shippingZone.countriesCode,
      shippingMethod: eddState.shippingMethod.methodInfo,
    };
    updateTransitTime(payload).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        if (apiError) {
          setApiError('');
        }
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        navigate(PATH.TRACKING_PAGE);
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  useEffect(() => {
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          methodInfo: transitTimeById ? transitTimeById.shippingMethod : [],
        },
        shippingZone: {
          ...eddState.shippingZone,
          countriesCode: transitTimeById ? transitTimeById.shippingDestinationCountry : [],
        },
        transitTimes: eddSettingData.data?.data.transitTime || [],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitTimeById]);

  return (
    <Layout
      backAction={{ url: PATH.TRACKING_PAGE }}
      title="Estimated delivery date"
      primaryAction={{
        content: 'Update rule',
        disabled:
          eddState.shippingZone.countriesCode.length === 0 || eddState.shippingMethod.methodInfo.length === 0 || isLockedFeature,
        onAction: handleUpdateTransitTime,
        loading: updatedTransitTimeData.isLoading,
      }}
      secondaryActions={<Button url={PATH.TRACKING_PAGE}>Cancel</Button>}
    >
      <UpgradeBanner
        isOpen={[AccountPlan.Starter, AccountPlan.Basic].includes(plan.plan) && !plan.isOldPaidUser && !isActiveChristmasFeature}
        usedFeature={AccountPlan.Professional}
      />
      <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
      <BlockStack gap="400">
        <ShippingZone id={id} />
        <ShippingMethod />
      </BlockStack>
    </Layout>
  );
};

export default TransitTimeEditorDetail;
