import { TrackingPagePreviewFocus } from '@/constants/trackingPage';
import { FacebookIcon, InstagramIcon, PinterestIcon, TiktokIcon, TwitterIcon } from '@/icons';

export const leftFooterConfig = [
  {
    key: TrackingPagePreviewFocus.Facebook,
    icon: <FacebookIcon />,
  },
  {
    key: TrackingPagePreviewFocus.Twitter,
    icon: <TwitterIcon />,
  },
  {
    key: TrackingPagePreviewFocus.Instagram,
    icon: <InstagramIcon />,
  },
  {
    key: TrackingPagePreviewFocus.Tiktok,
    icon: <TiktokIcon />,
  },
  {
    key: TrackingPagePreviewFocus.Pinterest,
    icon: <PinterestIcon />,
  },
];

export const rightFooterConfig = [
  {
    key: TrackingPagePreviewFocus.privacy,
    text: 'privacy',
  },
  {
    key: TrackingPagePreviewFocus.return,
    text: 'return',
  },
  {
    key: TrackingPagePreviewFocus.support,
    text: 'support',
  },
  {
    key: TrackingPagePreviewFocus.term,
    text: 'term',
  },
];
