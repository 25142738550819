import { SVGProps } from 'react';

const DiamondIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m13 0-1 5-4 9 8-9.5L13 0ZM3 0l1 5 4 9-8-9.5L3 0Z" fill="#0D79A7" />
      <path d="m3 0 1 5 4 9 4-9 1-5H3Z" fill="#49A3C9" />
      <path d="M8 14 4 5l-4-.5L8 14ZM8 14l4-9 4-.5L8 14Z" fill="#07425B" />
      <path d="M8 5.5 4 5l4 9 4-9-4 .5Z" fill="#0D79A7" />
      <path d="m4 5 4-5 4 5-4.001.5L4 5Z" fill="#95D5F0" />
      <path d="M4 5 3 0h5L4 5ZM12 5l1-5H8l4 5Z" fill="#49A3C9" />
      <path d="M4 5 3 0 0 4.5 4 5ZM12 5l1-5 3 4.5-4 .5Z" fill="#0D79A7" />
    </svg>
  );
};

export default DiamondIcon;
