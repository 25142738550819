import { BlockStack, InlineGrid } from '@shopify/polaris';
import TrackingPageOverview from './components/TrackingPageOverview';
import PageViewByTime from './components/PageViewByTimeTracking';
import MarketingClick from './components/MarketingClick';
import ClickDistribution from './components/ClickDistribution';
import ProductRecommendation from './components/ProductRecommendation';
import MarketingAssets from './components/MarketingAssets';

const TrackingPageReport = () => {
  return (
    <BlockStack gap="400">
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap="400">
        <TrackingPageOverview />
        <PageViewByTime />
      </InlineGrid>
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
        <MarketingClick />
        <ClickDistribution />
        <ProductRecommendation />
        <MarketingAssets />
      </InlineGrid>
    </BlockStack>
  );
};

export default TrackingPageReport;
