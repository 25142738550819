import { FocusEventHandler, Key, memo } from 'react';
import { StyledColorPicker } from './styled';

interface ColorPickerProps {
  bgColor?: Key;
  value?: string | number | Array<string>;
  onChange?: FocusEventHandler<HTMLInputElement>;
}

const ColorPicker = ({ bgColor, value, onChange }: ColorPickerProps) => {
  return (
    <StyledColorPicker>
      <input className="color-picker" type="color" key={bgColor} defaultValue={value} onBlur={onChange} />
    </StyledColorPicker>
  );
};

export default memo(ColorPicker);
