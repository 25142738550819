import { CustomCard, UpgradeBanner } from '@/components';
import { camelCaseToStartCase, camelToSnakeCase, checkDirtyField, renderErrorValue, splitObjects } from '@/helpers';
import trackingPageSlice, {
  LanguageValue,
  collapsibleSelector,
  languageSelector,
  trackingFocusSelector,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { InlineGrid, Icon, Select, TextField, Tooltip } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { convertClinetKeyToApiKey, googleTranslatePositionOption, languageOptions, trackingFormConfig } from './config';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { LockMinor } from '@shopify/polaris-icons';
import { StyledAdvancedTranslationTitle } from './styled';
import { isLockFeature } from '@/helpers/feature';
import { AccountPlan } from '@/constants/enum';
import ContentCollapsible from '../ContentCollapsible';
import { CollapsibleLanguages, CustomizationPreviewBodyTabs, TrackingPagePreviewFocus } from '@/constants/trackingPage';
import Switch from '@/components/Switch';

const LanguageTrackingPage = () => {
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const trackingPage = useSelector(trackingPageSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const collapsible = useSelector(collapsibleSelector);
  const {
    language: { isCollapse, key },
  } = collapsible;
  const focus = useSelector(trackingFocusSelector);

  const renderAdvancedTranslationTitle = (title: string): any => (
    <StyledAdvancedTranslationTitle>
      {title}
      {isLockedFeature && (
        <Tooltip content="Upgrade to unlock this feature">
          <div className="link_container">
            <Icon source={LockMinor} tone="interactive" />
          </div>
        </Tooltip>
      )}
    </StyledAdvancedTranslationTitle>
  );

  const handleDispatchLanguageConfig = (key: string, value: string | boolean) => {
    const config = { ...language.languageConfig };
    dispatch(
      trackingPageSlice.actions.handleLanguageConfig({
        ...config,
        [key]: value,
      }),
    );
    const { dirtyField: languageConfigField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 0, 1), {
      ...splitObjects(config, 0, 1),
      [key]: value,
    });
    const { dirtyField: googleTranslateExtensionField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 1, 2), {
      ...splitObjects(config, 1, 2),
      [key]: value,
    });
    const { dirtyField: googleTranslatePositionField } = checkDirtyField(splitObjects(language.oldValue.languageConfig, 2, 3), {
      ...splitObjects(config, 2, 3),
      [key]: value,
    });
    dispatch(
      trackingPageSlice.actions.handleLanguageFieldsChange(
        !languageConfigField &&
          !googleTranslateExtensionField &&
          !googleTranslatePositionField &&
          Object.entries(language.fieldsChange).length < 4
          ? {}
          : {
              ...language.fieldsChange,
              language: languageConfigField?.language,
              googleTranslateExtension: googleTranslateExtensionField?.googleTranslateExtension,
              googleTranslatePosition: googleTranslatePositionField?.googleTranslatePosition,
            },
      ),
    );
  };

  const handleChangeLanguageConfig = (key: string) => (value: string | boolean) => {
    if (key === 'language' && !language.isOpenChooseLanguage) {
      dispatch(trackingPageSlice.actions.handleChangeOpenChooseLanguage(true));
    }
    handleDispatchLanguageConfig(key, value);
  };

  const handleChangeUseExtensionConfig = (key: string, value: boolean) => {
    handleDispatchLanguageConfig(key, value);
  };

  const changeKeyOfObject = (currentObject: Partial<Record<string, never>>, parentKey: string) => {
    const replacedObject = Object.keys(currentObject).map((key) => {
      const newKey =
        parentKey === 'trackingForm' || parentKey === 'footer'
          ? convertClinetKeyToApiKey[parentKey][key as keyof (typeof convertClinetKeyToApiKey)['trackingForm' | 'footer']]
          : camelToSnakeCase(key);
      return { [newKey]: currentObject[key] };
    });
    return replacedObject.reduce((a, b) => Object.assign({}, a, b));
  };
  const handleLanguageField = (currentValue: {}, parentKey: string) => {
    const { dirtyField: parentKeyField } = checkDirtyField(
      language.oldValue.value[parentKey as keyof typeof language.value],
      currentValue,
    );
    return !parentKeyField ? undefined : changeKeyOfObject(parentKeyField, parentKey);
  };

  const handleChange = (parentKey: string, key: string) => (value: string | boolean) => {
    const { fieldsChange } = language;
    dispatch(
      trackingPageSlice.actions.handleLanguageValue({
        ...language.value,
        [parentKey]: {
          ...language.value[parentKey as keyof LanguageValue],
          [key]: value,
        },
      }),
    );
    dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
    dispatch(
      trackingPageSlice.actions.handleLanguageFieldsChange({
        ...fieldsChange,
        [parentKey]: handleLanguageField(
          {
            ...language.value[parentKey as keyof LanguageValue],
            [key]: value,
          },
          parentKey,
        ),
      }),
    );
  };

  const handleBlurTextField = (key: string, value?: string) => {
    if (!value) {
      const isNotHaveKey = language.validation.findIndex((item) => item === key) === -1;
      if (isNotHaveKey) {
        dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([...language.validation, key]));
      }
    } else {
      dispatch(trackingPageSlice.actions.handleChangeValidationLanguage(language.validation.filter((item) => item !== key)));
    }
  };

  const handleToggle = (id: CollapsibleLanguages) => {
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        language: {
          key: collapsible.language.key === id ? '' : id,
          isCollapse: collapsible.language.key !== id,
        },
      }),
    );
  };

  const handleFocus = (key?: TrackingPagePreviewFocus, apiKey?: CustomizationPreviewBodyTabs) => {
    if (key) {
      dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(key));
    }
    if (trackingPage.value.lookAndFeel.chooseTheme === '2' && apiKey) {
      dispatch(trackingPageSlice.actions.handleChangeCustomizePreviewBodyTabs(apiKey));
    }
  };

  return (
    <>
      <CustomCard
        title="Google translate extension"
        actionGroup={[
          {
            content: (
              <Switch
                isActive={language.languageConfig.googleTranslateExtension}
                onClick={() =>
                  handleChangeUseExtensionConfig('googleTranslateExtension', !language.languageConfig.googleTranslateExtension)
                }
              ></Switch>
            ),
          },
        ]}
      >
        <>
          <InlineGrid columns={1} gap="400">
            <Select
              label=""
              labelHidden
              options={languageOptions}
              onChange={handleChangeLanguageConfig('language')}
              value={language.languageConfig.language}
            />
            <Select
              label=""
              labelHidden
              options={googleTranslatePositionOption}
              onChange={handleChangeLanguageConfig('googleTranslatePosition')}
              value={language.languageConfig.googleTranslatePosition}
            />
          </InlineGrid>
        </>
      </CustomCard>

      <CustomCard className="mt-16" title="Advanced translation">
        <>
          {/* tracking status */}
          <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} isHiddenTitle />
          <ContentCollapsible
            key={CollapsibleLanguages.TrackingStatus}
            title={renderAdvancedTranslationTitle('Tracking Status')}
            isOpen={isCollapse && key === CollapsibleLanguages.TrackingStatus}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.TrackingStatus)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.trackingStatus).map(([key, value]) => {
                return (
                  <TextField
                    maxLength={30}
                    key={key}
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('trackingStatus', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    showCharacterCount
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>
          {/* tracking form */}
          <ContentCollapsible
            key={CollapsibleLanguages.TrackingForm}
            isOpen={isCollapse && key === CollapsibleLanguages.TrackingForm}
            title={renderAdvancedTranslationTitle('Tracking Form')}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.TrackingForm)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(trackingFormConfig).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={value.maxLength}
                    label={value.label}
                    value={language.value.trackingForm[key as keyof typeof language.value.trackingForm]}
                    onChange={handleChange('trackingForm', key)}
                    onBlur={() =>
                      handleBlurTextField(key, language.value.trackingForm[key as keyof typeof language.value.trackingForm])
                    }
                    autoComplete="off"
                    showCharacterCount
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    onFocus={() => handleFocus(value.id, value.apiKey)}
                    focused={focus === value.id}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>
          {/* tracking history */}
          <ContentCollapsible
            key={CollapsibleLanguages.TrackingHistory}
            isOpen={isCollapse && key === CollapsibleLanguages.TrackingHistory}
            title={renderAdvancedTranslationTitle('Tracking History')}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.TrackingHistory)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.trackingHistory).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={40}
                    showCharacterCount
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('trackingHistory', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>

          {/* Progress bar */}
          <ContentCollapsible
            key={CollapsibleLanguages.ProgressBar}
            isOpen={isCollapse && key === CollapsibleLanguages.ProgressBar}
            title={renderAdvancedTranslationTitle('Progress Bar')}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.ProgressBar)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.progressBar).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={40}
                    showCharacterCount
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('progressBar', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>

          {/* Order detals*/}
          <ContentCollapsible
            key={CollapsibleLanguages.OrderDetails}
            isOpen={isCollapse && key === CollapsibleLanguages.OrderDetails}
            title={renderAdvancedTranslationTitle('Order details')}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.OrderDetails)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.orderDetails).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={40}
                    showCharacterCount
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('orderDetails', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>

          {/* Review*/}
          <ContentCollapsible
            key={CollapsibleLanguages.Review}
            isOpen={isCollapse && key === CollapsibleLanguages.Review}
            title={renderAdvancedTranslationTitle('Review')}
            variant="headingMd"
            onToggle={() => handleToggle(CollapsibleLanguages.Review)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.review).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={40}
                    showCharacterCount
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('review', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>

          {/* Footer*/}
          <ContentCollapsible
            key={CollapsibleLanguages.Footer}
            title={renderAdvancedTranslationTitle('Footer')}
            variant="headingMd"
            isOpen={isCollapse && key === CollapsibleLanguages.Footer}
            onToggle={() => handleToggle(CollapsibleLanguages.Footer)}
          >
            <InlineGrid columns={1} gap="400">
              {Object.entries(language.value.footer).map(([key, value]) => {
                return (
                  <TextField
                    key={key}
                    maxLength={40}
                    showCharacterCount
                    label={camelCaseToStartCase(key)}
                    value={value}
                    onChange={handleChange('footer', key)}
                    onBlur={() => handleBlurTextField(key, value)}
                    autoComplete="off"
                    disabled={isLockedFeature}
                    error={renderErrorValue(language.validation, key, `${camelCaseToStartCase(key)} is required `)}
                    onFocus={() => handleFocus(TrackingPagePreviewFocus[key as keyof typeof TrackingPagePreviewFocus])}
                    focused={focus === key}
                  />
                );
              })}
            </InlineGrid>
          </ContentCollapsible>
        </>
      </CustomCard>
    </>
  );
};

export default LanguageTrackingPage;
