import { AccountPlan } from '@/constants/enum';
import store, { RootState } from '@/redux/store';

export const isLockFeature = (features: Array<AccountPlan>) => {
  const {
    plan: {
      account: { plan },
    },
  } = store.getState() as RootState;

  return !!features.find((feature) => feature === plan);
};
