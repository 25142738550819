import { CustomCard, DonutChartCustom } from '@/components';
import { ShipmentByStatusPalette } from '@/constants';
import { noDataUI } from '@/constants/empty';
import { calcPercent } from '@/helpers';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, DonutChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ClickDistribution = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data && data.data.marketingClick.total) {
      const { marketingAsset, productRecommend, socialAccount, total } = data.data.marketingClick;
      return [
        {
          name: `Marketing Assets (${calcPercent(marketingAsset.total, total)}%)`,
          color: Object.values(ShipmentByStatusPalette)[0],
          data: [
            {
              key: 'Marketing Assets',
              value: marketingAsset.total,
            },
          ],
        },
        {
          name: `Product Recommendation (${calcPercent(productRecommend.total, total)}%)`,
          color: Object.values(ShipmentByStatusPalette)[1],
          data: [
            {
              key: 'Product Recommendation',
              value: productRecommend.total,
            },
          ],
        },
        {
          name: `Social Media Accounts (${calcPercent(socialAccount, total)}%)`,
          color: Object.values(ShipmentByStatusPalette)[2],
          data: [
            {
              key: 'Social Media Accounts',
              value: socialAccount,
            },
          ],
        },
      ];
    }
    return [];
  }, [data]);

  const renderUIByData = () => {
    if (isLoading) {
      return <SkeletonBodyText lines={25} />;
    }
    if (!chartData.length) {
      return noDataUI;
    }
    return (
      <DonutChartCustom>
        <DonutChart data={chartData} theme="Light" legendPosition="bottom" showLegend />
      </DonutChartCustom>
    );
  };

  return <CustomCard title="CLICK DISTRIBUTION">{renderUIByData()}</CustomCard>;
};

export default ClickDistribution;
