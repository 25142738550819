import { BlockStack, InlineGrid } from '@shopify/polaris';
import TopCouriersByShipments from './components/TopCouriersByShipments';
import TopCourierByTransitDay from './components/TopCourierByTransitDay';
import TopCouriersByStatus from './components/TopCouriersByStatus';

const CourierReport = () => {
  return (
    <BlockStack gap="400">
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
        <TopCouriersByShipments />
        <TopCourierByTransitDay />
      </InlineGrid>
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap="400">
        <TopCouriersByStatus />
      </InlineGrid>
    </BlockStack>
  );
};

export default CourierReport;
