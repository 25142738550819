import { EmailTabs } from '@/constants';
import EmailHistory from './components/EmailHistory';
import EmailTemplates from './components/EmailTemplates';

export const renderTabsConfig = (accountComponent: JSX.Element) => {
  return [
    {
      id: EmailTabs.EmailAccount,
      content: 'Email Account',
      component: accountComponent,
    },
    {
      id: EmailTabs.EmailTemplates,
      content: 'Email Templates',
      component: <EmailTemplates />,
    },
    {
      id: EmailTabs.EmailHistory,
      content: 'Email History',
      component: <EmailHistory />,
    },
  ];
};
