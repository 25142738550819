import { Link, LinkProps } from '@shopify/polaris';
import { memo } from 'react';
import { To, useNavigate } from 'react-router-dom';

interface InternalLinkProps extends LinkProps {
  path: To;
}

const InternalLink = ({ path, ...props }: InternalLinkProps) => {
  const navigate = useNavigate();

  return (
    <Link onClick={() => navigate(path)} {...props}>
      {props.children}
    </Link>
  );
};

export default memo(InternalLink);
