import { WidgetTrackingForm } from '@/constants/enum';

export const radioBtnConfig = [
  {
    label: 'Tracking number',
    value: WidgetTrackingForm.TrackingNumber,
    hiddenEmail: true,
  },
  {
    label: 'Order ID',
    value: WidgetTrackingForm.OrderId,
  },
  {
    label: 'Both tracking number and Order ID',
    value: WidgetTrackingForm.Both,
  },
];

export const orderLookupCode = '<div id="omega-order-lookup"></div>';
