import { CustomCard } from '@/components';
import { useGetReviewAnalyticQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { useAppSelector } from '@/redux/store';
import { SkeletonBodyText } from '@shopify/polaris';
import { BarChart, DataSeries } from '@shopify/polaris-viz';
import { useMemo } from 'react';

const PageViewByTime = () => {
  const datePicker = useAppSelector(datePickerSelector);
  const { data, isLoading } = useGetReviewAnalyticQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.startRating.categories.length) {
      const { categories, series } = data.data.startRating;
      return [
        {
          name: 'Page view',
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: series[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);

  return (
    <CustomCard title={`PAGE VIEW BY TIME (${data?.data.startRating.average || 0})`}>
      <div className="chart-container">
        {isLoading ? (
          <SkeletonBodyText lines={20} />
        ) : (
          <div style={{ height: '400px' }}>
            <BarChart theme="Light" data={chartData} isAnimated />
          </div>
        )}
      </div>
    </CustomCard>
  );
};

export default PageViewByTime;
