import styled from 'styled-components';

export const StyledContentLayout = styled.div`
  .main-content {
    .Polaris-Frame {
      min-height: calc(100vh - 5rem);
    }
  }

  .Polaris-FooterHelp {
    width: 100%;
    height: 2.5rem;
    margin-inline: 0;
  }
`;
