import { calcPercent, calcTotal, camelToRegularText } from '@/helpers';
import { useGetAnalyticsChartShipmentQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyledShipmentByStatus } from './styled';
import { CustomCard, DonutChartCustom } from '@/components';
import { EmptyState, SkeletonBodyText } from '@shopify/polaris';
import { DataSeries, DonutChart } from '@shopify/polaris-viz';
import { ShipmentByStatusPalette } from '@/constants';

const ShipmentByStatus = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartShipmentQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const isNoData: boolean = useMemo(() => {
    if (data) {
      return Object.values(data.data.shipmentByStatus).reduce((accumulator, currentValue) => accumulator + currentValue, 0) === 0;
    }
    return true;
  }, [data]);

  const shipmentByStatusInfo = useMemo(() => {
    if (data) {
      const { delivered, expired } = data.data.shipmentByStatus;
      const totalShipment = calcTotal(Object.values(data.data.shipmentByStatus));
      return [
        { name: 'Delivered', percent: `${calcPercent(delivered, totalShipment)}%` },
        {
          name: 'Non-Delivered',
          percent: `${calcPercent(totalShipment - delivered - expired, totalShipment)}%`,
        },
        { name: 'Courier Not Supported', percent: `${calcPercent(expired, totalShipment)}%` },
      ];
    }
    return [
      { name: 'Delivered', percent: `0%` },
      { name: 'Non-Delivered', percent: `0%` },
      { name: 'Courier Not Supported', percent: `0%` },
    ];
  }, [data]);

  const chartData: DataSeries[] = useMemo(() => {
    if (data && !isNoData) {
      const { shipmentByStatus } = data.data;
      const keyChart = Object.keys(shipmentByStatus);
      const valueChart = Object.values(shipmentByStatus);
      const totalShipment = calcTotal(Object.values(data.data.shipmentByStatus));
      return keyChart.map((item, index) => ({
        name: `${camelToRegularText(item)} (${calcPercent(valueChart[index], totalShipment)}%)`,
        color: Object.values(ShipmentByStatusPalette)[index],
        data: [
          {
            key: item,
            value: valueChart[index] as number,
          },
        ],
      }));
    }
    return [];
  }, [isNoData, data]);

  return (
    <StyledShipmentByStatus>
      <CustomCard title="SHIPMENT BY STATUS">
        <div className="chart-container">
          {isLoading ? (
            <div>
              <SkeletonBodyText lines={28} />
            </div>
          ) : (
            <div className="content-container">
              <div className="shipment-info-container">
                {shipmentByStatusInfo.map(({ name, percent }) => (
                  <div key={name} className="shipment-info">
                    {name} <span>{percent}</span>
                  </div>
                ))}
              </div>
              {isNoData ? (
                <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
                  <span style={{ fontSize: 18 }}>No data</span>
                </EmptyState>
              ) : (
                <DonutChartCustom>
                  <DonutChart data={chartData} theme="Light" legendPosition="bottom" showLegend />
                </DonutChartCustom>
              )}
            </div>
          )}
        </div>
      </CustomCard>
    </StyledShipmentByStatus>
  );
};

export default ShipmentByStatus;
