import styled from 'styled-components';

export const StyledColorPicker = styled.div`
  .color-picker {
    cursor: pointer;
    margin-top: 0.25rem;
    width: 100%;
    height: 2rem;
    border: none;
    outline: none;
    border-radius: 0.5rem;
  }
  .color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0.5rem;
  }
  .color-picker::-webkit-color-swatch {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0.5rem;
  }
`;
