import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface WelcomeState {
  step: number;
}

const initialState: WelcomeState = {
  step: 1,
};

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState,
  reducers: {
    handleChangeStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
  },
});

export const stepSelector = createSelector(
    (state: RootState) => state.welcome,
    (state) => state.step,
  );
  
  export default welcomeSlice;
