import { CustomCard } from '@/components';
import { TrackingMethods } from '@/constants/enum';
import { checkDirtyField, splitObjects } from '@/helpers';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Checkbox, InlineGrid, RadioButton } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

interface ITrackingMethods {
  label: string;
  value: TrackingMethods;
  visibleRequireEmail: boolean;
}

const trackingMethodsConfig: Array<ITrackingMethods> = [
  {
    label: 'Tracking Number',
    value: TrackingMethods.TrackingNumber,
    visibleRequireEmail: false,
  },
  {
    label: 'Order Id & Tracking Number',
    value: TrackingMethods.Both,
    visibleRequireEmail: true,
  },
  {
    label: 'Order Id',
    visibleRequireEmail: true,
    value: TrackingMethods.OrderId,
  },
  {
    label: 'Phone Number',
    visibleRequireEmail: false,
    value: TrackingMethods.PhoneNumber,
  },
  {
    label: 'Email',
    visibleRequireEmail: false,
    value: TrackingMethods.Email,
  },
];

const LookupOption = () => {
  const dispatch = useDispatch();

  const data = useSelector(trackingPageSelector).value;
  const { fieldsChange, oldValue } = useSelector(trackingPageSelector);
  const { lookupOption } = data.lookAndFeel;
  const {
    lookAndFeel: { lookupOption: oldLookupOption },
  } = oldValue;

  const handleChange = (requireEmail: boolean, value: string) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          lookupOption: {
            method: value as TrackingMethods,
            requireEmail,
          },
        },
      }),
    );
    const { dirtyField: methodField } = checkDirtyField(splitObjects(oldLookupOption, 0, 1), {
      ...splitObjects(lookupOption, 0, 1),
      method: value as TrackingMethods,
      requireEmail,
    });
    const { dirtyField: requireEmailField } = checkDirtyField(splitObjects(oldLookupOption, 1, 2), {
      ...splitObjects(lookupOption, 1, 2),
      method: value as TrackingMethods,
      requireEmail,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        trackingForm:
          !methodField && !requireEmailField
            ? undefined
            : {
                showMethods: value,
                requireEmail,
              },
      }),
    );
  };
  const handleCheckbox = (requireEmail: boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          lookupOption: {
            ...lookupOption,
            requireEmail,
          },
        },
      }),
    );
    const { dirtyField: methodField } = checkDirtyField(splitObjects(oldLookupOption, 0, 1), {
      ...splitObjects(lookupOption, 0, 1),
    });
    const { dirtyField: requireEmailField } = checkDirtyField(splitObjects(oldLookupOption, 1, 2), {
      ...splitObjects(lookupOption, 1, 2),
      requireEmail,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        trackingForm:
          !requireEmailField && !methodField
            ? undefined
            : {
                ...fieldsChange.trackingForm,
                requireEmail: requireEmail,
              },
      }),
    );
  };
  return (
    <CustomCard variantTitle="headingSm" title="Tracking method">
      <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap={'200'}>
        {trackingMethodsConfig.map((item) => {
          return (
            <RadioButton
              key={item.value}
              label={item.label}
              checked={item.value === lookupOption.method}
              onChange={() => handleChange(item.visibleRequireEmail, item.value)}
            />
          );
        })}
      </InlineGrid>
      <div className="mt-8">
        {trackingMethodsConfig.find((item) => item.value === lookupOption.method)?.visibleRequireEmail ? (
          <Checkbox label="Require email when tracking" checked={lookupOption.requireEmail} onChange={handleCheckbox} />
        ) : null}
      </div>
    </CustomCard>
  );
};

export default LookupOption;
