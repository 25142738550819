import { InlineGrid } from '@shopify/polaris';
import TotalShipmentOvertime from './components/TotalShipmentOvertime';
import Top3Destinations from './components/Top3Destinations';
import ShipmentByTransitDay from './components/ShipmentByTransitDay';
import ShipmentByStatus from './components/ShipmentByStatus';

const ShipmentReport = () => {
  return (
    <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
      <TotalShipmentOvertime />
      <Top3Destinations />
      <ShipmentByTransitDay />
      <ShipmentByStatus />
    </InlineGrid>
  );
};

export default ShipmentReport;
