import styled from 'styled-components';

export const StyledDetailCard = styled.div`
  .shipment-detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .Polaris-Text--root.Polaris-Text--headingSm.Polaris-Text--semibold {
      max-width: 35%;
    }

    p {
      max-width: 65%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
