import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSomeDaysAgo } from '@/helpers';
import { SortBy } from '@/types/reviewTable';
import { PageSize, SortDirection } from '@/constants/enum';

export interface IReviewFilter {
  search: string;
  perPage: PageSize;
  currentPage: number;
  time: {
    start: Date;
    end: Date;
  };
  rating?: string;
  display?: string;
  sort: SortDirection;
  sortBy: SortBy;
}

interface Props {
  filter: IReviewFilter;
  titleDatePicker: string;
  displayChange: Array<{ id: number; displayComment: boolean }>;
}

const initialState: Props = {
  titleDatePicker: 'Last 30 days',
  filter: {
    search: '',
    perPage: PageSize.Five,
    currentPage: 1,
    time: {
      start: getSomeDaysAgo(30),
      end: new Date(),
    },
    rating: '5',
    display: 'all',
    sort: SortDirection.DESC,
    sortBy: SortBy.createdAt,
  },
  displayChange: [],
};

export const reviewTableSlice = createSlice({
  name: 'reviewTable',
  initialState,
  reducers: {
    handleTable: (state, action: PayloadAction<IReviewFilter>) => {
      state.filter = action.payload;
    },
    handleTitleDatePicker: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
    handleDisplayChange: (state, action: PayloadAction<Array<{ id: number; displayComment: boolean }>>) => {
      state.displayChange = action.payload;
    },
  },
});

export const reviewFilterSelector = createSelector(
  (state: RootState) => state.reviewTable,
  (state) => state.filter,
);
export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.reviewTable,
  (state) => state.titleDatePicker,
);
export const displayChangeSelector = createSelector(
  (state: RootState) => state.reviewTable,
  (state) => state.displayChange,
);

export default reviewTableSlice;
