import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledStepper = styled.div`
  .stepper-container {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      background-color: ${BgColorPalette.PRIMARY};
      width: 0.125rem;
      height: 100%;
      top: 0px;
      left: 7px;
      z-index: 1;
    }

    .stepper-heading {
      display: flex;
      align-items: center;
      padding-block: 0.75rem;

      .circle,
      .checked {
        margin-right: 0.75rem;
        position: relative;
        z-index: 2;
        border-radius: 50%;
      }

      .circle {
        border: 1px solid ${BgColorPalette.PRIMARY};
        min-width: 1rem;
        min-height: 1rem;
        background-color: ${BgColorPalette.SECONDARY};
      }

      .checked {
        width: 1rem;
        height: 1rem;
        background-color: white;
      }
    }

    .stepper-body {
      margin-left: 2.5rem;
      border-radius: 0.5rem;
    }
  }
`;
