import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledDatePicker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ul.Polaris-Box.Polaris-Box--listReset {
    min-width: 8rem;
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    ul.Polaris-Box.Polaris-Box--listReset {
      width: 100%;
    }
  }

  .from-to-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1rem;

    .Polaris-Labelled--hidden {
      .Polaris-Connected {
        .Polaris-TextField {
          .Polaris-TextField__Input {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  .date-picker-btn-group {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
  }
`;
