import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './fetchBase';
import { IResponse, IResponseInfo } from '@/types';
import { TagTypesKey } from '@/constants/api';
import { getAppParams } from '@/helpers';
import { IDashboardShipment, IDashboardShipmentOvertime, IDashboardShipmentParams, IUserPlan } from '@/types/dashboard';
import {
  ICreateReviewTableParams,
  IGetReviewTable,
  IGetReviewTableParams,
  IUpdateDisplayReviewTableParams,
} from '@/types/reviewTable';
import {
  IShipmentChart,
  IChartParams,
  ICourierChart,
  IChartReview,
  IChartNPS,
  ITrackingPageAnalytics,
  IIntegrateGoogleAnalyticsPayload,
} from '@/types/analytics';
import {
  IShipmentCouriers,
  IShipmentDetail,
  IShipmentDetailParams,
  IShipmentSearch,
  IShipmentSearchParams,
  IShipmentTrackingLogs,
  ITrackingLogsParams,
  IUnsupportedTrackingPayload,
  IShipmentReSyncBody,
  // IShipmentReSyncDetail,
} from '@/types/shipment';
import { ICourierNamePayload, IProcessOldOrderPayload } from '@/types/order';
import { IUpdateDiscountCode, IUpdatePlan, IUpdatePlanParams } from '@/types/account';
import {
  IEmailAccountPayload,
  IEmailDisconnectParams,
  INotificationAccounts,
  INotificationLogs,
  INotificationLogsParams,
  IResetToDefaultPayload,
  ISendTestEmailPayload,
  ISesConnectPayload,
  ISmsCredit,
  ISmsHistory,
  ISmsOrderStatus,
  ISmsParams,
  ISmsPricing,
  ISmsSummary,
  ITemplateDetail,
  ITemplateDetailParams,
  ITemplateDetailPayload,
} from '@/types/notification';
import { IMediaUpload, IProductRecommendResponse, ITrackingPageResponse, ITranslation } from '@/types/trackingpage';
import { Language } from '@/constants/enum';
import {
  ICourier,
  ICourierMappingPage,
  ISettingParams,
  IGetOrderLookup,
  IIntegrationAvada,
  IIntegrationAvadaPayload,
  IGetTrackingLink,
} from '@/types/settings';
import {
  IBlacklisting,
  IBlacklistingPayload,
  ICityPayload,
  ICreatedBlacklistWordPair,
  IDeletedBlacklistWordPair,
  IUpdatedBlacklistWordPair,
} from '@/types/blacklisting';
import { ICreatedTransitTime, IDeletedTransitTime, IEddSettings, IEddSettingsPayload, IUpdatedTransitTime } from '@/types/edd';

export const apiCaller = createApi({
  reducerPath: 'apiCaller',
  refetchOnMountOrArgChange: 30,
  baseQuery: baseQuery(),
  tagTypes: [
    TagTypesKey.PLAN,
    TagTypesKey.DASHBOARD,
    TagTypesKey.REVIEW_TABLE,
    TagTypesKey.ANALYTICS,
    TagTypesKey.SHIPMENT,
    TagTypesKey.NOTIFICATION,
    TagTypesKey.SETTINGS,
    TagTypesKey.COURIER_MAPPING,
    TagTypesKey.BLACKLISTING,
    TagTypesKey.EDD,
    TagTypesKey.SMS,
    TagTypesKey.TRACKING_PAGE,
    TagTypesKey.TRACKING_LINK,
    TagTypesKey.ORDER_LOOKUP,
    TagTypesKey.WELCOME,
  ],
  endpoints: (builder) => ({
    // DASHBOARD
    getUserPlan: builder.query<IResponse<IUserPlan>, { screen?: string }>({
      query(params) {
        return {
          url: `dashboard/user-plan`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.PLAN }],
    }),
    getDashboardShipment: builder.query<IResponse<IDashboardShipment[]>, IDashboardShipmentParams>({
      query(params) {
        return {
          url: `dashboard/shipment`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
    }),
    getShipmentOvertime: builder.query<IResponse<IDashboardShipmentOvertime>, IDashboardShipmentParams>({
      query(params) {
        return {
          url: `dashboard/shipment-overtime`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.DASHBOARD }],
    }),

    // REVIEW TABLE
    getReviewTable: builder.query<IResponse<IGetReviewTable>, IGetReviewTableParams>({
      query(params) {
        return {
          url: `review-table/search`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.REVIEW_TABLE }],
    }),

    createReviewTable: builder.mutation<IResponse<undefined>, ICreateReviewTableParams>({
      query(params) {
        return {
          url: `review-table/create`,
          method: 'POST',
          body: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.REVIEW_TABLE }],
    }),

    updateDisplayReviewTable: builder.mutation<IResponse<undefined>, IUpdateDisplayReviewTableParams>({
      query(params) {
        return {
          url: `review-table/update`,
          method: 'POST',
          body: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.REVIEW_TABLE }],
    }),

    //ANALYTICS
    getAnalyticsChartShipment: builder.query<IResponse<IShipmentChart>, IChartParams>({
      query(params) {
        return {
          url: `analytic/chart/shipment`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.ANALYTICS }],
    }),
    getAnalyticsChartCourier: builder.query<IResponse<ICourierChart>, IChartParams>({
      query(params) {
        return {
          url: `analytic/chart/courier`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.ANALYTICS }],
    }),

    // SHIPMENT
    getShipmentSearch: builder.query<IResponse<IShipmentSearch>, IShipmentSearchParams>({
      query(params) {
        return {
          url: `shipment/search`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    getShipmentDetail: builder.query<IResponse<IShipmentDetail>, IShipmentDetailParams>({
      query(params) {
        return {
          url: `shipment/detail`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    getShipmentCouriers: builder.query<IResponse<IShipmentCouriers>, void>({
      query() {
        return {
          url: `shipment/couriers`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    getTrackingLogs: builder.query<IResponse<IShipmentTrackingLogs>, ITrackingLogsParams>({
      query(params) {
        return {
          url: `shipment/tracking-logs`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    setUnSupportedTracking: builder.mutation<IResponseInfo, IUnsupportedTrackingPayload>({
      query(params) {
        return {
          url: `shipment/set-unsupported-tracking`,
          method: 'POST',
          body: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    resyncShipmentDetail: builder.mutation<IResponseInfo, IShipmentReSyncBody>({
      query(body) {
        return {
          url: `shipment/resync`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),

    // ORDER
    updateCourierName: builder.mutation<IResponseInfo, ICourierNamePayload>({
      query(payload) {
        return {
          url: `order/update-courier`,
          method: 'PUT',
          body: getAppParams(payload),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),
    processOldOrder: builder.mutation<IResponseInfo, IProcessOldOrderPayload>({
      query(payload) {
        return {
          url: `order/process-old-order`,
          method: 'POST',
          body: getAppParams(payload),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SHIPMENT }],
    }),

    getReviewAnalytic: builder.query<IResponse<IChartReview>, IChartParams>({
      query(params) {
        return {
          url: `analytic/chart/review`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
    }),

    getNPSAnalytic: builder.query<IResponse<IChartNPS>, IChartParams>({
      query(params) {
        return {
          url: `analytic/chart/nps`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
    }),
    getTrackingPageAnalytics: builder.query<IResponse<ITrackingPageAnalytics>, IChartParams>({
      query(params) {
        return {
          url: `analytic/chart/tracking-page`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.ANALYTICS }],
    }),

    updatePlan: builder.mutation<IResponse<IUpdatePlan>, IUpdatePlanParams>({
      query(params) {
        return {
          url: `shop/change-plan`,
          method: 'POST',
          body: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.PLAN }],
    }),

    // MEDIA
    uploadMedia: builder.mutation<IResponse<void>, FormData>({
      query: (image) => {
        return {
          url: `media`,
          method: 'POST',
          body: image,
        };
      },
      invalidatesTags: [{ type: TagTypesKey.TRACKING_PAGE }],
    }),
    updateUploadMedia: builder.mutation<IResponseInfo, IMediaUpload>({
      query(params) {
        return {
          url: `media/update`,
          method: 'PUT',
          body: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.TRACKING_PAGE }],
    }),

    // NOTIFICATION
    getNotificationLogs: builder.query<IResponse<INotificationLogs>, INotificationLogsParams>({
      query(params) {
        return {
          url: `notification/logs/search`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
    }),

    getNotificationAccounts: builder.query<IResponse<INotificationAccounts>, void>({
      query() {
        return {
          url: `notification/accounts`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    disconnectEmail: builder.mutation<IResponseInfo, IEmailDisconnectParams>({
      query(params) {
        return {
          url: `notification/email/disconnect`,
          method: 'DELETE',
          params: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    connectSes: builder.mutation<IResponseInfo, ISesConnectPayload>({
      query(body) {
        return {
          url: `notification/email/ses/connect`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    updateEmailAccount: builder.mutation<IResponseInfo, IEmailAccountPayload>({
      query(body) {
        return {
          url: `notification/email/update`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    getTemplateDetail: builder.query<IResponse<ITemplateDetail>, ITemplateDetailParams>({
      query(params) {
        return {
          url: `notification/email/template/detail`,
          method: 'GET',
          params: getAppParams(params),
        };
      },
      providesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    updateTemplate: builder.mutation<IResponseInfo, ITemplateDetailPayload>({
      query(body) {
        return {
          url: `notification/email/template/update`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    sendTestEmail: builder.mutation<IResponseInfo, ISendTestEmailPayload>({
      query(body) {
        return {
          url: `notification/email/ses/send-test`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
    }),

    resetToDefault: builder.mutation<IResponseInfo, IResetToDefaultPayload>({
      query(body) {
        return {
          url: `notification/email/template/reset`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.NOTIFICATION }],
    }),

    // TRACKING PAGE
    getTrackingPage: builder.query<IResponse<ITrackingPageResponse>, void | null>({
      query: () => {
        return {
          url: `page/search`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.TRACKING_PAGE }],
    }),

    getProductRecommend: builder.query<IResponse<IProductRecommendResponse>, void>({
      query: () => {
        return {
          url: `product/get-product-recommend`,
          method: 'GET',
          params: getAppParams(),
        };
      },
    }),

    getTranslation: builder.query<IResponse<ITranslation>, { language: Language }>({
      query: (language) => {
        return {
          url: `page/get-translation`,
          method: 'GET',
          params: getAppParams(language),
        };
      },
    }),

    updateTranslation: builder.mutation<IResponse<void>, Object>({
      query: (data) => {
        return {
          url: `page/update-translation`,
          method: 'PUT',
          body: getAppParams(data),
        };
      },
    }),

    updateTrackingPage: builder.mutation<IResponse<void>, Object>({
      query: (data) => {
        return {
          url: `page/update-tracking-page-setting`,
          method: 'PUT',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.TRACKING_PAGE }],
    }),

    getListCourier: builder.query<
      IResponse<{
        total: number;
        couriers: Array<ICourier>;
      }>,
      ISettingParams.IGetListCourier
    >({
      query: (data) => {
        return {
          url: `setting/courier`,
          method: 'GET',
          params: getAppParams(data),
        };
      },
    }),

    updateCourierSetting: builder.mutation<IResponse<void>, ISettingParams.IUpdateCourierSetting>({
      query: (data) => {
        return {
          url: `setting/courier`,
          method: 'POST',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.COURIER_MAPPING }],
    }),

    getCourierMapping: builder.query<IResponse<ICourierMappingPage>, void>({
      query: () => {
        return {
          url: `setting/courier-mapping`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.COURIER_MAPPING }],
    }),
    updateCourierMapping: builder.mutation<IResponseInfo, ISettingParams.IUpdateCourierMapping>({
      query: (data) => {
        return {
          url: `setting/courier-mapping`,
          method: 'POST',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.COURIER_MAPPING }],
    }),

    deleteCourierMapping: builder.mutation<IResponse<void>, number>({
      query: (id) => {
        return {
          url: `setting/courier-mapping`,
          method: 'DELETE',
          params: getAppParams({ id }),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.COURIER_MAPPING }],
    }),

    // SETTINGS
    getIntegrationAvada: builder.query<IResponse<IIntegrationAvada>, void>({
      query() {
        return {
          url: `integration/avada`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.SETTINGS }],
    }),
    createIntegrationAvada: builder.mutation<IResponseInfo, IIntegrationAvadaPayload>({
      query(body) {
        return {
          url: `integration/avada`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SETTINGS }],
    }),
    deleteIntegrationAvada: builder.mutation<IResponseInfo, void>({
      query() {
        return {
          url: `integration/avada`,
          method: 'DELETE',
          params: getAppParams(),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SETTINGS }],
    }),

    getOrderLookup: builder.query<IResponse<IGetOrderLookup>, void>({
      query() {
        return {
          url: `setting/order-lookup`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.ORDER_LOOKUP }],
    }),

    updateOrderLookup: builder.mutation<IResponseInfo, ISettingParams.IUpdateOrderLookupParams>({
      query(data) {
        return {
          url: `setting/order-lookup`,
          method: 'PUT',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.ORDER_LOOKUP }],
    }),

    getTrackingLink: builder.query<IResponse<IGetTrackingLink>, void>({
      query() {
        return {
          url: `setting/tracking-link`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.TRACKING_LINK }],
    }),

    updateTrackingLink: builder.mutation<IResponse<void>, ISettingParams.IUpdateTrackingLinkParams>({
      query(data) {
        return {
          url: `setting/tracking-link`,
          method: 'PUT',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.TRACKING_LINK }],
    }),

    // SMS
    getSmsOrderStatus: builder.query<IResponse<ISmsOrderStatus>, void>({
      query() {
        return {
          url: `notification/sms/setting/detail`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.SMS }],
    }),

    updateSmsOrderStatus: builder.mutation<IResponse<{ balanceAlert: boolean }>, ISmsParams.IUpdateSmsOrderStatus>({
      query(data) {
        return {
          url: `notification/sms/setting/update`,
          method: 'PUT',
          body: getAppParams(data),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.SMS }],
    }),

    getListPricing: builder.query<IResponse<Array<ISmsPricing>>, void>({
      query() {
        return {
          url: `notification/sms/pricing/list`,
          method: 'GET',
          params: getAppParams(),
        };
      },
    }),

    getSmsHistory: builder.query<IResponse<ISmsHistory>, ISmsParams.IGetSmsHistoryParams>({
      query(data) {
        return {
          url: `notification/sms/history`,
          method: 'GET',
          params: getAppParams(data),
        };
      },
    }),

    getSmsSummary: builder.query<IResponse<ISmsSummary>, void>({
      query() {
        return {
          url: `notification/sms/summary`,
          method: 'GET',
          params: getAppParams(),
        };
      },
    }),

    getSmsChargeLink: builder.query<IResponse<{ url: string }>, number>({
      query(amount) {
        return {
          url: `notification/sms/charge`,
          method: 'GET',
          params: getAppParams({ amount }),
        };
      },
    }),

    getSmsCredit: builder.query<IResponse<ISmsCredit>, void>({
      query() {
        return {
          url: `notification/sms/credit`,
          method: 'GET',
          params: getAppParams(),
        };
      },
    }),

    // BLACKLISTING
    getBlacklisting: builder.query<IResponse<IBlacklisting>, void>({
      query() {
        return {
          url: `page/get-blacklist`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.BLACKLISTING }],
    }),

    updateBlacklisting: builder.mutation<IResponseInfo, IBlacklistingPayload>({
      query(body) {
        return {
          url: `page/update-blacklist`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.BLACKLISTING }],
    }),

    createBlacklistWordPair: builder.mutation<IResponseInfo, ICreatedBlacklistWordPair>({
      query(body) {
        return {
          url: `page/blacklist-wordpair`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.BLACKLISTING }],
    }),

    updateBlacklistWordPair: builder.mutation<IResponseInfo, IUpdatedBlacklistWordPair>({
      query(body) {
        return {
          url: `page/blacklist-wordpair`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.BLACKLISTING }],
    }),

    deleteBlacklistWordPair: builder.mutation<IResponseInfo, IDeletedBlacklistWordPair>({
      query(params) {
        return {
          url: `page/blacklist-wordpair`,
          method: 'DELETE',
          params: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.BLACKLISTING }],
    }),

    getListCountries: builder.query<IResponse<Array<string>>, void>({
      query() {
        return {
          url: `page/get-list-countries`,
          method: 'GET',
        };
      },
    }),

    getListCities: builder.query<IResponse<Array<string>>, ICityPayload>({
      query(params) {
        return {
          url: `page/get-list-cities`,
          method: 'GET',
          params: params,
        };
      },
    }),

    // EDD
    getEDDSettings: builder.query<IResponse<IEddSettings>, void>({
      query() {
        return {
          url: `page/edd-setting`,
          method: 'GET',
          params: getAppParams(),
        };
      },
      providesTags: [{ type: TagTypesKey.EDD }],
    }),

    updateEddSettings: builder.mutation<IResponseInfo, IEddSettingsPayload>({
      query(body) {
        return {
          url: `page/edd-setting`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.EDD }],
    }),

    createTransitTime: builder.mutation<IResponseInfo, ICreatedTransitTime>({
      query(body) {
        return {
          url: `page/transit-time`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.EDD }],
    }),

    updateTransitTime: builder.mutation<IResponseInfo, IUpdatedTransitTime>({
      query(body) {
        return {
          url: `page/transit-time`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.EDD }],
    }),

    deleteTransitTime: builder.mutation<IResponseInfo, IDeletedTransitTime>({
      query(params) {
        return {
          url: `page/transit-time`,
          method: 'DELETE',
          params: getAppParams(params),
        };
      },
      invalidatesTags: [{ type: TagTypesKey.EDD }],
    }),

    integrateGoogleAnalytics: builder.mutation<IResponseInfo, IIntegrateGoogleAnalyticsPayload>({
      query(body) {
        return {
          url: `page/integrate-ga`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
    }),

    updateDiscountCode: builder.mutation<IResponseInfo, IUpdateDiscountCode>({
      query(body) {
        return {
          url: `discount/apply-code`,
          method: 'PUT',
          body: getAppParams(body),
        };
      },
    }),

    sendGa4AdminAnalytics: builder.mutation<IResponseInfo, { eventName: string }>({
      query(body) {
        return {
          url: `analytic/ga4`,
          method: 'POST',
          body: getAppParams(body),
        };
      },
    }),

    checkStoreReturns: builder.query<{ url: string }, { urlParams: string }>({
      query(query) {
        return {
          url: `/shop/check-store-returns`,
          method: 'GET',
          params: getAppParams(query),
        };
      },
    }),
  }),
});

export const {
  useGetUserPlanQuery,
  useLazyGetUserPlanQuery,
  useGetDashboardShipmentQuery,
  useGetReviewTableQuery,
  useCreateReviewTableMutation,
  useUpdateDisplayReviewTableMutation,
  useGetAnalyticsChartShipmentQuery,
  useGetAnalyticsChartCourierQuery,
  useGetShipmentSearchQuery,
  useGetShipmentDetailQuery,
  useGetShipmentCouriersQuery,
  useGetTrackingLogsQuery,
  useSetUnSupportedTrackingMutation,
  useUpdateCourierNameMutation,
  useGetReviewAnalyticQuery,
  useGetNPSAnalyticQuery,
  useGetTrackingPageAnalyticsQuery,
  useProcessOldOrderMutation,
  useUpdatePlanMutation,
  useUploadMediaMutation,
  useUpdateUploadMediaMutation,
  useGetNotificationLogsQuery,
  useGetTrackingPageQuery,
  useGetProductRecommendQuery,
  useGetTranslationQuery,
  useLazyGetTranslationQuery,
  useUpdateTranslationMutation,
  useUpdateTrackingPageMutation,
  useGetNotificationAccountsQuery,
  useDisconnectEmailMutation,
  useConnectSesMutation,
  useUpdateEmailAccountMutation,
  useGetTemplateDetailQuery,
  useLazyGetTemplateDetailQuery,
  useUpdateTemplateMutation,
  useSendTestEmailMutation,
  useResetToDefaultMutation,
  useGetIntegrationAvadaQuery,
  useCreateIntegrationAvadaMutation,
  useDeleteIntegrationAvadaMutation,
  useGetListCourierQuery,
  useUpdateCourierSettingMutation,
  useGetCourierMappingQuery,
  useUpdateCourierMappingMutation,
  useDeleteCourierMappingMutation,
  useGetOrderLookupQuery,
  useUpdateOrderLookupMutation,
  useGetTrackingLinkQuery,
  useUpdateTrackingLinkMutation,
  useGetSmsOrderStatusQuery,
  useUpdateSmsOrderStatusMutation,
  useGetBlacklistingQuery,
  useUpdateBlacklistingMutation,
  useCreateBlacklistWordPairMutation,
  useUpdateBlacklistWordPairMutation,
  useDeleteBlacklistWordPairMutation,
  useGetListCountriesQuery,
  useGetListCitiesQuery,
  useGetSmsSummaryQuery,
  useGetSmsHistoryQuery,
  useGetListPricingQuery,
  useLazyGetSmsChargeLinkQuery,
  useGetSmsCreditQuery,
  useGetEDDSettingsQuery,
  useUpdateEddSettingsMutation,
  useUpdateTransitTimeMutation,
  useCreateTransitTimeMutation,
  useDeleteTransitTimeMutation,
  useIntegrateGoogleAnalyticsMutation,
  useResyncShipmentDetailMutation,
  useUpdateDiscountCodeMutation,
  useGetShipmentOvertimeQuery,
  useSendGa4AdminAnalyticsMutation,
  useCheckStoreReturnsQuery,
} = apiCaller;
