import { memo } from 'react';
import { BlockStack, Icon, Text, Tooltip } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import { StyledStatisticCard } from './styled';

interface StatisticCardProps {
  title: string;
  titleInfo: string;
  quantity: number;
}

const StatisticCard = ({ title, titleInfo, quantity }: StatisticCardProps) => {
  return (
    <StyledStatisticCard>
      <BlockStack gap="400">
        <Text variant="headingMd" as="h5">
          {title}
          <div className="title-info">
            <Tooltip content={titleInfo}>
              <Icon source={CircleInformationMajor} tone="base" />
            </Tooltip>
          </div>
        </Text>
        <Text variant="headingLg" as="h4">
          {quantity}
        </Text>
      </BlockStack>
    </StyledStatisticCard>
  );
};

export default memo(StatisticCard);
