import { Environment } from '@/constants';
import { CONSTANT } from '@/helpers';
import EmbedConfiguration from './components/EmbedConfiguration';
import LocalConfiguration from './components/LocalConfiguration';

const Configuration = () => {
  return process.env.REACT_APP_NODE_ENV !== Environment.Test && CONSTANT.isEmbedded ? (
    <EmbedConfiguration />
  ) : (
    <LocalConfiguration />
  );
};

export default Configuration;
