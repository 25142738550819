import { CustomCard } from '@/components';
import { BgColorPalette } from '@/constants';
import { useGetNPSAnalyticQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText } from '@shopify/polaris';
import { BarChart, DataSeries } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ResponsesOverTime = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetNPSAnalyticQuery({
    toDate: datePicker.end.toISOString(),
    fromDate: datePicker.start.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.categories.length) {
      const { categories, detractorsSeries, passivesSeries, promotersSeries } = data.data;
      return [
        {
          name: 'Promoters',
          color: `${BgColorPalette.INFO}`,
          data: categories.map((item, index) => ({
            key: item,
            value: promotersSeries[index],
          })),
        },
        {
          name: 'Passives',
          color: `${BgColorPalette.WARNING}`,
          data: categories.map((item, index) => ({
            key: item,
            value: passivesSeries[index],
          })),
        },
        {
          name: 'Detractors',
          color: `${BgColorPalette.CRITICAL}`,
          data: categories.map((item, index) => ({
            key: item,
            value: detractorsSeries[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="RESPONSES OVER TIME">
      <div style={{ height: '400px' }}>
        {isLoading ? <SkeletonBodyText lines={20} /> : <BarChart theme="Light" data={chartData} isAnimated type="stacked" />}
      </div>
    </CustomCard>
  );
};

export default ResponsesOverTime;
