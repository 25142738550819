import { CustomCard, ErrorBanner } from '@/components';
import { StyledSenderInfo } from './styled';
import { Badge, Button, Icon, Modal, RadioButton, Text, TextField, Tooltip } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import { SenderEmailType, SenderInfoAction } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import emailSlice, { senderInfoSelector, showVerifyEmailSelector } from '@/redux/features/email.slice';
import VerifiedEmailPopup from '../VerifiedEmailPopup';
import { useEffect, useState } from 'react';
import {
  useConnectSesMutation,
  useDisconnectEmailMutation,
  useGetNotificationAccountsQuery,
  useUpdateEmailAccountMutation,
} from '@/redux/api/api.caller';
import { IEmailAccountPayload, ISesConnectPayload } from '@/types/notification';
import { capitalizeFirstLetter } from '@/helpers';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { tokenSelector } from '@/redux/features/auth.slice';

const SenderInfo = () => {
  const [apiError, setApiError] = useState<{ connect: string; update: string; disconnect: string }>({
    connect: '',
    update: '',
    disconnect: '',
  });
  const dispatch = useDispatch();
  const globalState = useSelector(senderInfoSelector);
  const haveShownVerifyEmail = useSelector(showVerifyEmailSelector);
  const { shop } = useSelector(tokenSelector);
  const { data } = useGetNotificationAccountsQuery();
  const accountData = data?.data?.email?.account;
  const [createAccount, connectSesData] = useConnectSesMutation();
  const [disconnectAccount, disconnectEmailData] = useDisconnectEmailMutation();
  const [updateAccount, updateAccountData] = useUpdateEmailAccountMutation();

  const handleCloseNoReplyModal = () => {
    setApiError({
      ...apiError,
      disconnect: '',
    });
    dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, isNoReplyModalActive: false }));
  };
  const handleCloseDisconnectedModal = () => {
    setApiError({
      ...apiError,
      disconnect: '',
    });
    dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, isDisconnectedModalActive: false }));
  };
  const handleCloseVerifiedEmailPopup = () => {
    dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, isNotVerifiedPopupActive: false }));
    dispatch(emailSlice.actions.handleShowVerifyEmail(true));
  };

  const handleAfterDisconnect = (data: any) => {
    dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
    dispatch(emailSlice.actions.handleShowVerifyEmail(false));
    dispatch(
      emailSlice.actions.handleChangeSenderInfo({
        ...globalState,
        type: SenderEmailType.NoReply,
        account: { value: '', error: '', disable: false },
        isNotVerifiedPopupActive: false,
        isNoReplyModalActive: false,
        isDisconnectedModalActive: false,
      }),
    );
  };

  const handleChange = (value: string | boolean, key: string) => {
    setApiError({ connect: '', update: '', disconnect: '' });
    if (key === SenderEmailType.NoReply || key === SenderEmailType.YourEmail) {
      if (key === SenderEmailType.NoReply && accountData && !accountData.useDefaultEmail) {
        return dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, isNoReplyModalActive: true }));
      }
      return dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, type: key as SenderEmailType }));
    }
    dispatch(
      emailSlice.actions.handleChangeSenderInfo({
        ...globalState,
        [key]: key === 'account' ? { ...globalState.account, value, error: !value && 'Field is required' } : value,
      }),
    );
  };

  const handleEditOrDisconnectEmail = (type: SenderInfoAction) => {
    if (type === SenderInfoAction.Disconnect) {
      return dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, isDisconnectedModalActive: true }));
    }
    dispatch(
      emailSlice.actions.handleChangeSenderInfo({
        ...globalState,
        account: {
          ...globalState.account,
          disable: false,
          value: globalState.account.value,
        },
      }),
    );
  };

  const handleConfirmDisconnectEmail = () =>
    disconnectAccount({ useDefaultEmail: false }).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        setApiError({
          ...apiError,
          disconnect: '',
        });
        handleAfterDisconnect(data);
        return;
      }
      setApiError({
        ...apiError,
        disconnect: getErrorFromAPI(data),
      });
    });

  const handleChangeToNoReplyEmail = () => {
    disconnectAccount({ useDefaultEmail: true }).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        setApiError({
          ...apiError,
          disconnect: '',
        });
        handleAfterDisconnect(data);
        return;
      }
      setApiError({
        ...apiError,
        disconnect: getErrorFromAPI(data),
      });
    });
  };

  const handleSubmit = (type: SenderInfoAction) => {
    if (type === SenderInfoAction.Connect) {
      const connectedPayload: ISesConnectPayload = {
        email: globalState.account.value,
        senderName: globalState.name,
      };
      return createAccount(connectedPayload).then((data) => {
        if ('data' in data && data.data && data.data.state === 1) {
          setApiError({
            ...apiError,
            connect: '',
          });
          dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
          return;
        }
        setApiError({
          ...apiError,
          connect: getErrorFromAPI(data),
        });
      });
    }
    const updatedPayload: IEmailAccountPayload = {
      email: globalState.account.value,
      senderName: globalState.name,
    };
    updateAccount(updatedPayload).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        setApiError({
          ...apiError,
          update: '',
        });
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        dispatch(
          emailSlice.actions.handleChangeSenderInfo({
            ...globalState,
            account: { ...globalState.account, disable: !!data.data.state },
          }),
        );
        return;
      }
      setApiError({
        ...apiError,
        update: getErrorFromAPI(data),
      });
    });
  };

  useEffect(() => {
    if (accountData) {
      dispatch(
        emailSlice.actions.handleChangeSenderInfo({
          ...globalState,
          account: {
            ...globalState.account,
            value: accountData.useDefaultEmail ? '' : accountData.email || '',
            disable: !accountData.useDefaultEmail && !!accountData.email,
          },
          name: accountData.senderName,
          type: accountData.useDefaultEmail ? SenderEmailType.NoReply : SenderEmailType.YourEmail,
          isNotVerifiedPopupActive: !accountData.useDefaultEmail && !accountData.isVerified && !!accountData.email,
        }),
      );
      return;
    }
    dispatch(emailSlice.actions.handleChangeSenderInfo({ ...globalState, name: shop.split('.')[0] }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  return (
    <StyledSenderInfo>
      <CustomCard title="Sender info">
        <ErrorBanner isVisible={!!apiError.connect || !!apiError.update}>{apiError.connect || apiError.update}</ErrorBanner>
        <div className="sender-email">
          <div className="title">
            <Text variant="headingMd" as="h6">
              Sender email
            </Text>
            <Tooltip content="Any emails which are sent will come from this address">
              <Icon source={CircleInformationMajor} tone="base" />
            </Tooltip>
          </div>
          <div className="content">
            <RadioButton
              id={SenderEmailType.NoReply}
              name="senderEmail"
              label="no-reply@omegatheme.com"
              helpText={
                <>
                  We will be using the email address {<span className="no-reply">no-reply@omegatheme.com</span>} to send messages
                  to your customers.
                </>
              }
              checked={globalState.type === SenderEmailType.NoReply}
              onChange={handleChange}
            />
            <RadioButton
              id={SenderEmailType.YourEmail}
              name="senderEmail"
              label="Use your email as sender’s"
              helpText="Connect your own business mail which you use to send messages to your customers"
              checked={globalState.type === SenderEmailType.YourEmail}
              onChange={handleChange}
            />
            {globalState.type === SenderEmailType.YourEmail && (
              <div className="email-as-sender">
                <div className="your-email">
                  <div className="email-title">
                    Your email
                    {accountData && globalState.account.disable && !globalState.account.error && !accountData.useDefaultEmail && (
                      <Badge
                        progress={accountData.isVerified ? 'complete' : 'partiallyComplete'}
                        tone={accountData.isVerified ? 'success' : 'attention'}
                      >
                        {accountData.isVerified ? 'verified' : 'not verified'}
                      </Badge>
                    )}
                  </div>
                  <TextField
                    id="account"
                    label="Your email"
                    labelHidden
                    value={globalState.account.value}
                    onChange={handleChange}
                    autoComplete="off"
                    disabled={globalState.account.disable}
                    connectedRight={
                      accountData &&
                      accountData.email &&
                      !accountData.useDefaultEmail && (
                        <Button
                          onClick={() =>
                            handleEditOrDisconnectEmail(
                              accountData.isVerified ? SenderInfoAction.Disconnect : SenderInfoAction.Edit,
                            )
                          }
                        >
                          {accountData.isVerified
                            ? capitalizeFirstLetter(SenderInfoAction.Disconnect)
                            : capitalizeFirstLetter(SenderInfoAction.Edit)}
                        </Button>
                      )
                    }
                    error={globalState.account.error}
                    helpText={
                      accountData &&
                      !accountData.isVerified &&
                      globalState.account.disable &&
                      !globalState.account.error && (
                        <div className="not-verified">
                          <Icon source={CircleInformationMajor} tone="base" />
                          <span>Please verify this email via a link provided by checking your mail box</span>
                        </div>
                      )
                    }
                  />
                </div>
                <TextField
                  id="name"
                  label="Sender name"
                  value={globalState.name}
                  onChange={handleChange}
                  autoComplete="off"
                  maxLength={30}
                  showCharacterCount
                />
                <div className="submit-btn">
                  <Button
                    variant="primary"
                    loading={connectSesData.isLoading || updateAccountData.isLoading}
                    onClick={() =>
                      handleSubmit(
                        !accountData || (accountData && accountData.useDefaultEmail) || (accountData && !accountData.email)
                          ? SenderInfoAction.Connect
                          : SenderInfoAction.Edit,
                      )
                    }
                    disabled={!globalState.account.value}
                  >
                    {accountData?.isVerified ? 'Update' : 'Connect'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </CustomCard>

      <Modal
        open={globalState.isNoReplyModalActive}
        onClose={handleCloseNoReplyModal}
        title={
          <>
            Changing Email to <span style={{ textDecoration: 'underline' }}>no-reply@omegatheme.com</span>
          </>
        }
        primaryAction={{
          content: 'Yes, I do',
          onAction: handleChangeToNoReplyEmail,
        }}
        secondaryActions={[
          {
            content: 'No',
            onAction: handleCloseNoReplyModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError.disconnect}>{apiError.disconnect}</ErrorBanner>
          Changing your email address to <span style={{ textDecoration: 'underline' }}>no-reply@omegatheme.com</span> will also
          result in disconnecting your account from the app. Are you sure you want to proceed with this change?
        </Modal.Section>
      </Modal>

      <Modal
        open={globalState.isDisconnectedModalActive}
        onClose={handleCloseDisconnectedModal}
        title="Disconnect email"
        primaryAction={{
          content: 'Disconnect',
          destructive: true,
          onAction: handleConfirmDisconnectEmail,
          loading: disconnectEmailData.isLoading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseDisconnectedModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError.disconnect}>{apiError.disconnect}</ErrorBanner>
          Are you sure you want to disconnect this email account?
        </Modal.Section>
      </Modal>

      <VerifiedEmailPopup
        email={globalState.account.value}
        isOpen={globalState.isNotVerifiedPopupActive && !haveShownVerifyEmail}
        onClosePopup={handleCloseVerifiedEmailPopup}
      />
    </StyledSenderInfo>
  );
};

export default SenderInfo;
