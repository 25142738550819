import styled from 'styled-components';


export const StyledChartCustomTitle = styled.div<{ xTitle: string }>`
      height: 100%;
      position: relative;
      min-height: 400px;
      padding: 0.25rem 0 0;
      ._Chart_qgx6z_1 svg {
        padding-bottom: 1.25rem;
      }

      ._Container_3h6ct_1 {
        gap: 0.25rem;
        position: relative;
      }

      ._Container_3h6ct_1::before {
        font-family: Helvetica, Arial, sans-serif;
        content: ${({ xTitle }) => `"${xTitle}"`};
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: -24px;
        text-anchor: end;
        font-size: 12px;
        font-weight: 900;
        fill: rgb(55, 61, 63);
        right: 50%;
        transform: translateX(50%);
      }

      .y {
        font-family: Helvetica, Arial, sans-serif;
        position: absolute;
        z-index: 1;
        top: 35%;
        transform: rotate(-90deg) translateY(-110%);
        text-anchor: end;
        font-size: 11px;
        font-weight: 900;
        fill: rgb(55, 61, 63);
      }

`;


