import { StyledPreviewBody } from './styled';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, {
  collapsibleSelector,
  customizePreviewBodyTabsSelector,
  languageSelector,
  trackingFocusSelector,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { useGetTrackingPageQuery } from '@/redux/api/api.caller';
import {
  CollapsibleLanguages,
  CollapsibleTheme,
  CustomizationPreviewBodyTabs,
  CustomizationTypeTabs,
  TrackingPagePreviewFocus,
} from '@/constants/trackingPage';
import { InlineGrid, hsbToHex } from '@shopify/polaris';
import { useCallback } from 'react';
import { previewBodyFormInput, previewBodyImage, previewBodyTabsConfig } from './config';

interface IPreviewBodyProps {
  isDesktopScreen: boolean;
}

const PreviewBody = ({ isDesktopScreen }: IPreviewBodyProps) => {
  const versionImage = `?v=${Date.now()}`;
  const previewBodyTabs = useSelector(customizePreviewBodyTabsSelector);
  const focus = useSelector(trackingFocusSelector);
  const collapsible = useSelector(collapsibleSelector);
  const {
    value: { trackingForm },
  } = useSelector(languageSelector);
  const {
    value: {
      lookAndFeel: {
        style: {
          colorPicker: { btnColor },
        },
        chooseTheme,
      },
      configuration: {
        additionalContent: { textAbove },
      },
    },
  } = useSelector(trackingPageSelector);
  const isOrderIdTab = previewBodyTabs === CustomizationPreviewBodyTabs.OrderId;
  const isSpecialTheme = chooseTheme === '2';
  const { data } = useGetTrackingPageQuery();
  const dispatch = useDispatch();

  const handleChangeTabs = (key: CustomizationPreviewBodyTabs) => {
    dispatch(trackingPageSlice.actions.handleChangeCustomizePreviewBodyTabs(key));
  };

  const handleClick = (focus: TrackingPagePreviewFocus, tab?: CustomizationTypeTabs) => {
    dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(focus));
    if (tab) {
      dispatch(trackingPageSlice.actions.handleChangeCustomizeTypeTabs(tab));
      dispatch(
        trackingPageSlice.actions.handleChangeCollapsible({
          ...collapsible,
          language:
            tab === CustomizationTypeTabs.Languages
              ? { key: CollapsibleLanguages.TrackingForm, isCollapse: true }
              : { key: '', isCollapse: false },
          theme:
            tab === CustomizationTypeTabs.ThemeAndStyles
              ? { key: CollapsibleTheme.ChooseTheme, isCollapse: true }
              : { key: '', isCollapse: false },
        }),
      );
      return;
    }
    dispatch(trackingPageSlice.actions.handleChangeCustomizeTypeTabs(CustomizationTypeTabs.ThemeAndStyles));
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        theme: { key: CollapsibleTheme.HTMLAndCSS, isCollapse: true },
      }),
    );
  };

  const mediaSrcImage = useCallback(
    (key: string) => {
      if (data && versionImage) {
        const {
          lookAndFeel: { media },
        } = data.data;
        if (media[key as keyof typeof media].path && !media[key as keyof typeof media].path.includes(key)) {
          return `${media[key as keyof typeof media].baseUrl}${media[key as keyof typeof media].path}${versionImage}`;
        }
      }
      return `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/${key}.png`;
    },
    [data, versionImage],
  );

  const renderImage = (start: number, end: number) => {
    const imageGroup = previewBodyImage.slice(start, end);
    return imageGroup.map(({ key, imageFocus, height }) => {
      return (
        <img
          key={key}
          className={`${focus === imageFocus ? 'active' : ''}`}
          src={mediaSrcImage(key)}
          alt={key}
          style={{
            height,
            width: '100%',
            borderRadius: '1rem',
            objectFit: 'cover',
          }}
          onClick={() => handleClick(imageFocus, CustomizationTypeTabs.ThemeAndStyles)}
        />
      );
    });
  };

  const renderFormBodyGroup = (start: number, end: number) => {
    const formBodyGroup = previewBodyFormInput.slice(start, end);
    return formBodyGroup.map(({ htmlFor, labelFocus, inputFocus, valueLabel, valueInput }) => (
      <div key={htmlFor} className="omg-form-group">
        <label className="omg-form__label">
          <span
            className={`${focus === labelFocus ? 'active' : ''}`}
            onClick={() => handleClick(labelFocus, CustomizationTypeTabs.Languages)}
          >
            {trackingForm[valueLabel as keyof typeof trackingForm]}
          </span>
        </label>
        <div className="omg-form__control omg-form__control--text">
          <span
            className={`${focus === inputFocus ? 'active' : ''}`}
            onClick={() => handleClick(inputFocus, CustomizationTypeTabs.Languages)}
          >
            {trackingForm[valueInput as keyof typeof trackingForm]}
          </span>
        </div>
      </div>
    ));
  };

  const renderTrackButton = () => {
    return (
      <div className={`btn ${isSpecialTheme ? 'btn-warning btn-custom' : 'btn-track'}`}>
        <span
          className={`${focus === TrackingPagePreviewFocus.Track ? 'active' : ''}`}
          onClick={() => handleClick(TrackingPagePreviewFocus.Track, CustomizationTypeTabs.Languages)}
        >
          {trackingForm.track}
        </span>
      </div>
    );
  };

  const renderTitlePage = () => {
    return (
      <h1 className={`${isSpecialTheme ? 'h1 title-page' : 'omg-title'}`}>
        <span
          className={`${focus === TrackingPagePreviewFocus.TrackTitle ? 'active' : ''}`}
          onClick={() => handleClick(TrackingPagePreviewFocus.TrackTitle, CustomizationTypeTabs.Languages)}
        >
          {trackingForm.trackYourOrder}
        </span>
      </h1>
    );
  };

  const renderTheme = () => {
    if (isSpecialTheme) {
      return (
        <div className="omg">
          <div className="container-fluid omg-tracking-contain">
            <div className="rows header-page">
              <div className="col">{renderTitlePage()}</div>
            </div>
            <div className="row form-tracking gx-5" style={!isDesktopScreen ? { minHeight: '33.75rem', height: 'auto' } : {}}>
              <div
                className="col-sm-12 col-md-6"
                style={
                  !isDesktopScreen ? { flex: '0 0 auto', width: '100%', marginBottom: '1.25rem' } : { marginBottom: '1.25rem' }
                }
              >
                <div className="omg-section omg-section--form omg-tracking-form-tab">
                  <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: isDesktopScreen ? 2 : 1, xl: isDesktopScreen ? 2 : 1 }}>
                    {previewBodyTabsConfig.map(({ id }) => {
                      const content: string =
                        id === CustomizationPreviewBodyTabs.OrderId ? trackingForm.orderId : trackingForm.trackingNumber;
                      return (
                        <label
                          key={id}
                          className="tracking-form-label"
                          onClick={() => handleChangeTabs(id as CustomizationPreviewBodyTabs)}
                          style={id === previewBodyTabs ? { borderBottom: `2px solid ${hsbToHex(btnColor)}` } : {}}
                        >
                          {content}
                        </label>
                      );
                    })}
                  </InlineGrid>
                  <div style={{ padding: '0 1.25rem' }}>
                    <form
                      id={isOrderIdTab ? 'form-track-order-name' : 'form-track-tracking-number'}
                      className="omg-form"
                      style={!isDesktopScreen ? { padding: '0', height: '25rem' } : { height: '25rem' }}
                    >
                      <p className="form-text-color-white">
                        {isOrderIdTab
                          ? trackingForm.orderDescription
                          : 'Enter your tracking number in the box below to check the status of your order'}
                      </p>
                      {isOrderIdTab ? renderFormBodyGroup(0, 2) : renderFormBodyGroup(2, 3)}
                      {renderTrackButton()}
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-6"
                style={
                  !isDesktopScreen ? { flex: '0 0 auto', width: '100%', marginBottom: '1.25rem' } : { marginBottom: '1.25rem' }
                }
              >
                <div className="row asset-template" style={{ height: '100%' }}>
                  <div className="col-sm-6 col-6">{renderImage(0, 2)}</div>
                  <div className="col-sm-6 col-6">{renderImage(2, 3)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          <div className="omg-section omg-section--form">
            {renderTitlePage()}
            <div className="text-above">
              <span
                className={`${focus === TrackingPagePreviewFocus.TextABove ? 'active' : ''}`}
                onClick={() => handleClick(TrackingPagePreviewFocus.TextABove)}
                dangerouslySetInnerHTML={{ __html: textAbove }}
              />
            </div>
            <div
              className="omg-layout omg-tracking-form omg-layout--half"
              style={!isDesktopScreen ? { gridTemplateColumns: '1fr' } : {}}
            >
              <form id="form-track-order-name" className="omg-form" style={!isDesktopScreen ? { padding: '1.25rem' } : {}}>
                {renderFormBodyGroup(0, 2)}
                {renderTrackButton()}
              </form>
              <div className="omg-layout__divider" style={!isDesktopScreen ? { display: 'none' } : {}}>
                <span
                  className={`${focus === TrackingPagePreviewFocus.Or ? 'active' : ''}`}
                  onClick={() => handleClick(TrackingPagePreviewFocus.Or, CustomizationTypeTabs.Languages)}
                >
                  {trackingForm.or}
                </span>
              </div>
              <form id="form-track-tracking-number" className="omg-form" style={!isDesktopScreen ? { padding: '1.25rem' } : {}}>
                {renderFormBodyGroup(2, 3)}
                <div className="d-grid gap-2">{renderTrackButton()}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StyledPreviewBody>
      <div className="omg-layout">
        <div className="omg-section">{renderTheme()}</div>
      </div>
    </StyledPreviewBody>
  );
};

export default memo(PreviewBody);
