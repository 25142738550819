import styled from 'styled-components';
import { DeviceType } from './config';
import { BgColorPalette } from '@/constants';

interface StyledPreviewContentProps {
  $device: string;
  $isMdDown: boolean;
  $customCSS: string;
}

export const StyledPreviewContent = styled.div<StyledPreviewContentProps>`
  .right-content {
    width: ${({ $isMdDown }) => ($isMdDown ? '100%' : 'calc(100vw - 375px - 4px)')};
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .Polaris-ShadowBevel {
      width: ${({ $device }) => ($device === DeviceType.Desktop ? '100%' : '375px')};
      transition: all 0.4s ease;
    }

    .preview-navbar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem;

      .preview-btn {
        position: absolute;
        right: 1rem;
        top: 0.25rem;
      }
    }

    .preview-container {
      background-color: #e4e4e7;
      height: calc(100vh - 7rem);
      overflow: scroll;

      .text-above,
      .text-below,
      .html-top,
      .html-bottom {
        span {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          cursor: pointer;
          &:hover {
            outline: 1px ${BgColorPalette.FOCUS} dashed;
          }
          &.active {
            outline: 1px ${BgColorPalette.FOCUS} solid;
          }
        }
      }
    }
  }

  .preview-loading {
    padding: 1rem 0.5rem;
    height: calc(100vh - 7rem);

    .footer-loading {
      display: flex;
      justify-content: space-between;
      .Polaris-SkeletonTabs__Tabs:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }

  ${({ $customCSS }) => $customCSS}
`;
