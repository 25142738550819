import { CSSProperties, ReactNode, memo } from 'react';
import { StyledColumn } from './styled';

interface ColumnProps {
  span?: number;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const Column = ({ span = 1, children, style, className }: ColumnProps) => {
  return (
    <StyledColumn span={span} style={style} className={className}>
      {children}
    </StyledColumn>
  );
};

export default memo(Column);
