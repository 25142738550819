import { BgColorPalette } from '@/constants';
import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledTransitTimes = styled.div`
  .transit-times-item {
    &:not(:first-child) {
      padding-top: 0.5rem;
    }

    &:not(:last-child) {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid ${BgColorPalette.SECONDARY_HOVER};
    }

    .line-tile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      .detail-name {
        margin-left: 0.25rem;
        color: #6d7175;
      }

      .group-btn {
        display: flex;
        gap: 0.5rem;
      }
    }

    .line-description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.25rem;
      gap: 1rem;

      p {
        color: #6d7175;
      }

      .left {
        width: calc(100% - 12rem);
      }

      .right {
        max-width: 11rem;
      }
    }

    @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
      .line-description {
        .left {
          width: calc(100% - 7rem);
        }

        .right {
          max-width: 6rem;
        }
      }
    }
  }
`;
