import { useMemo, useRef, useState } from 'react';
import { StyledPreviewContent } from './styled';
import { DeviceType, deviceTypeConfig } from './config';
import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonTabs,
  Tooltip,
  hsbToHex,
  useBreakpoints,
} from '@shopify/polaris';

import { DesktopMajor, MobileMajor, ViewMajor } from '@shopify/polaris-icons';
import { LINK } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '@/redux/features/auth.slice';
import PreviewBody from './components/PreviewBody';
import PreviewFooter from './components/PreviewFooter';
import trackingPageSlice, {
  collapsibleSelector,
  trackingFocusSelector,
  trackingPageIsLoadingSelector,
  trackingPageSelector,
} from '@/redux/features/trackingPage.slice';
import { CollapsibleTheme, CustomizationTypeTabs, TrackingPagePreviewFocus } from '@/constants/trackingPage';
import { useOnClickOutside } from '@/hooks';
import GoogleTranslation from './components/GoogleTranslation';
import './index.css';

const PreviewContent = () => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [device, setDevice] = useState<DeviceType>(DeviceType.Desktop);
  const { mdDown } = useBreakpoints();
  const { shop } = useSelector(tokenSelector);
  const isLoading = useSelector(trackingPageIsLoadingSelector);
  const focus = useSelector(trackingFocusSelector);
  const collapsible = useSelector(collapsibleSelector);
  const {
    value: {
      configuration: {
        additionalContent: { customCss, htmlBottom, htmlTop, textAbove, textBelow },
      },
      lookAndFeel: {
        style: {
          btnRadius,
          colorPicker: { btnColor, btnNameColor, mainColor, textColor },
        },
        chooseTheme,
      },
    },
  } = useSelector(trackingPageSelector);
  const dispatch = useDispatch();

  const handleUpdateDeviceType = (id: DeviceType) => {
    setDevice(id);
  };

  const handleClick = (key: TrackingPagePreviewFocus) => {
    dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(key));
    dispatch(trackingPageSlice.actions.handleChangeCustomizeTypeTabs(CustomizationTypeTabs.ThemeAndStyles));
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        theme: { key: CollapsibleTheme.HTMLAndCSS, isCollapse: true },
      }),
    );
  };

  useOnClickOutside(previewRef, () => {
    dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(TrackingPagePreviewFocus.NoFocus));
  });

  const stylePreviewContent = useMemo(
    () => ({
      '--progress-color': hsbToHex(mainColor),
      '--button-track-color': hsbToHex(btnColor),
      '--color-text': hsbToHex(textColor),
      '--button-border-radius': `${btnRadius}px`,
      '--button-track-text-color': hsbToHex(btnNameColor),
      '--resize-app': '62.5',
    }),
    [btnColor, btnNameColor, btnRadius, mainColor, textColor],
  );

  const renderTheme = (chooseTheme: string) => {
    return (
      <div className="omg-main-content">
        {chooseTheme === '2' && (
          <div className="text-above">
            <span
              className={`${focus === TrackingPagePreviewFocus.TextABove ? 'active' : ''}`}
              onClick={() => handleClick(TrackingPagePreviewFocus.TextABove)}
              dangerouslySetInnerHTML={{ __html: textAbove }}
            />
          </div>
        )}
        <div className="html-top">
          <span
            className={`${focus === TrackingPagePreviewFocus.HtmlTop ? 'active' : ''}`}
            onClick={() => handleClick(TrackingPagePreviewFocus.HtmlTop)}
            dangerouslySetInnerHTML={{ __html: htmlTop }}
          />
        </div>
        <PreviewBody isDesktopScreen={device === DeviceType.Desktop} />
        <div className="text-below">
          <span
            className={`${focus === TrackingPagePreviewFocus.TextBelow ? 'active' : ''}`}
            onClick={() => handleClick(TrackingPagePreviewFocus.TextBelow)}
            dangerouslySetInnerHTML={{ __html: textBelow }}
          />
        </div>
        <div className="html-bottom">
          <span
            className={`${focus === TrackingPagePreviewFocus.HtmlBottom ? 'active' : ''}`}
            onClick={() => handleClick(TrackingPagePreviewFocus.HtmlBottom)}
            dangerouslySetInnerHTML={{ __html: htmlBottom }}
          />
        </div>
      </div>
    );
  };

  return (
    <StyledPreviewContent $device={device} $isMdDown={mdDown} $customCSS={customCss}>
      <Box borderColor="border" borderBlockEndWidth="025" borderInlineEndWidth="025" borderInlineStartWidth="025">
        <div className="right-content">
          <Card padding="0">
            <div ref={previewRef} className="preview-navbar">
              <ButtonGroup variant="segmented">
                {deviceTypeConfig.map(({ content, id }) => (
                  <Tooltip content={content} key={id}>
                    <Button
                      onClick={() => handleUpdateDeviceType(id as DeviceType)}
                      icon={id === DeviceType.Desktop ? DesktopMajor : MobileMajor}
                      variant={id === device && (id === DeviceType.Desktop || id === DeviceType.Mobile) ? 'primary' : undefined}
                    />
                  </Tooltip>
                ))}
              </ButtonGroup>
              <div className="preview-btn">
                <Tooltip content="Preview">
                  <Button icon={ViewMajor} url={LINK.CUSTOMER_SHOP(shop)} />
                </Tooltip>
              </div>
            </div>
            <Divider />

            {isLoading ? (
              <div className="preview-loading">
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={36} />
                  <div className="footer-loading">
                    <SkeletonTabs count={3} />
                    <SkeletonTabs count={3} />
                  </div>
                </BlockStack>
              </div>
            ) : (
              <div className="preview-container">
                <div className={chooseTheme === '2' ? 'omg-special' : 'omg-default'}>
                  <div
                    id="omg-app"
                    className="omg-app"
                    style={{
                      width: '100%',
                      position: 'relative',
                      ...stylePreviewContent,
                    }}
                  >
                    {renderTheme(chooseTheme)}
                    <GoogleTranslation />
                    <PreviewFooter isDesktopScreen={device === DeviceType.Desktop} />
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </Box>
    </StyledPreviewContent>
  );
};

export default PreviewContent;
