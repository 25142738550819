import { memo } from 'react';
import { StyledBadge } from './styled';
import { BgColorPalette } from '@/constants';

interface CustomBadgeProps {
  status: string;
  summary: string;
  bgColor?: string;
}

const CustomBadge = ({ status, summary, bgColor = BgColorPalette.SECONDARY }: CustomBadgeProps) => {
  return (
    <StyledBadge status={status} bgColor={bgColor}>
      <div className="badge-content">
        <span className="badge-dot" />
        <span className="badge-text">{summary}</span>
      </div>
    </StyledBadge>
  );
};

export default memo(CustomBadge);
