import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledCustomTable = styled.div`
  .Polaris-Pagination {
    .Polaris-ButtonGroup {
      justify-content: center;
      padding-block: 0.5rem;
    }
  }

  .no-data {
    padding-block: 3rem;
  }

  thead {
    tr {
      th {
        font-weight: 700;
        color: ${BgColorPalette.PRIMARY};
      }
    }
  }

  tbody {
    tr#loading {
      .Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first {
        opacity: 0;
      }
    }
  }
`;
