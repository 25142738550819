import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledPlanCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 1rem;
  height: 100%;
  width: 100%;
  .Polaris-Icon {
    margin: 0;
    height: 1.25rem;
    width: 1.25rem;
  }
  .plan-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .account-plan-details-btn {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    border-top: 1px solid ${BgColorPalette.SECONDARY};
    .Polaris-Badge {
      padding: 0.375rem 1rem;
    }
  }
  .account-benefit {
    display: flex;
    padding: 0.5rem 0;
    border-top: 1px solid ${BgColorPalette.SECONDARY};
  }
  .discuss-to-cs {
    border-top: 1px solid ${BgColorPalette.SECONDARY};
    display: flex;
    align-items: center;
    padding-block: 1rem;
  }
`;
