import styled from 'styled-components';

export const StyledCustomizeContent = styled.div<{ $isMdDown: boolean }>`
  .left-content {
    width: ${({ $isMdDown }) => ($isMdDown ? '100%' : '375px')};
    height: 100%;

    .customize-container {
      padding-block: 1rem;
      padding-inline: 0.5rem;
      height: calc(100vh - 6.5rem);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;
