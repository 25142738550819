import { TrackingStatus } from '@/constants';
import { transformOptions } from '@/helpers';
import { IndexFiltersProps, TabProps } from '@shopify/polaris';

export const sortOptions: IndexFiltersProps['sortOptions'] = [
  { label: 'Send time', value: 'updatedAt asc', directionLabel: 'Ascending' },
  { label: 'Send time', value: 'updatedAt desc', directionLabel: 'Descending' },
];

export const trackingStatusTab: TabProps[] = transformOptions(TrackingStatus).map((item) => ({
  id: item.value,
  content: item.label,
}));
