import styled from 'styled-components';

export const StyledCustomPage = styled.div<{ navResponsiveWidth: string }>`
  display: flex;

  .page-tabs {
    min-width: 15rem;

    .Polaris-ShadowBevel {
      position: fixed;
      min-width: 15rem;

      div.Polaris-Box {
        padding: 0;
      }
    }

    .tab-item {
      display: flex;

      .tab-label {
        margin-left: 0.5rem;
      }
    }
  }

  .page-content {
    position: relative;
    min-width: calc(100% - 15rem - 1rem);
    margin-left: 1rem;
  }

  @media only screen and (max-width: ${(props) => props.navResponsiveWidth}) {
    display: block;

    .page-tabs {
      width: 100%;

      .Polaris-ShadowBevel {
        position: relative;
      }
    }

    .page-content {
      margin-left: 0rem;
      margin-top: 1rem;
      min-width: 100%;
    }
  }
`;
