import { CustomBadge } from '@/components';
import { LINK, OrderStatusPalette } from '@/constants';
import { snakeToStartCase } from '@/helpers';
import { tokenSelector } from '@/redux/features/auth.slice';
import { ISmsHistoryItem } from '@/types/notification';
import { Badge, BlockStack, Button, Card, Link, Modal, Text } from '@shopify/polaris';
import dayjs from 'dayjs';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { sendStatusConfig } from '../SmsHistoryTable/config';
import { Tone } from '@shopify/polaris/build/ts/src/components/Badge';

interface SmsHistoryDetailProps {
  isOpen: boolean;
  onClose: () => void;
  data: ISmsHistoryItem | null;
}

const SmsHistoryDetail = ({ isOpen, onClose, data }: SmsHistoryDetailProps) => {
  const { shop } = useSelector(tokenSelector);
  const sendStatus = sendStatusConfig.find((item) => item.status === data?.sendStatus);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="SMS detail"
      footer={
        <div style={{ display: 'flex', justifyContent: 'end', width: '588px' }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
    >
      <Modal.Section>
        {data && (
          <BlockStack gap="400">
            <BlockStack gap="200">
              <Text as="h6" variant="bodyMd">
                To Customer: {data?.receiverContact}
              </Text>
              <Text as="h6" variant="bodyMd">
                Created at: {dayjs(data.createdAt).format('MMM DD, YYYY hh:mm A')}
              </Text>
              <Text as="h6" variant="bodyMd">
                Update at: {dayjs(data.updatedAt).format('MMM DD, YYYY hh:mm A')}
              </Text>
            </BlockStack>

            <BlockStack gap="200">
              <Text as="h6" variant="headingMd">
                SHIPMENT DETAILS
              </Text>
              <Text as="h6" variant="bodyMd">
                Order ID: <Link url={LINK.ORDER_DETAIL(shop, data.orderId)}>{data.orderId}</Link>
              </Text>
              <Text as="h6" variant="bodyMd">
                Tracking number: <Link url={LINK.TRACKING_ORDER_NOW(shop, data.trackingNumber)}>{data.trackingNumber}</Link>
              </Text>
              <Text as="h6" variant="bodyMd">
                <span className="d-flex">
                  Tracking status:{' '}
                  {data.trackingStatus ? (
                    <CustomBadge
                      summary={snakeToStartCase(data.trackingStatus)}
                      status={OrderStatusPalette[data.trackingStatus.toUpperCase() as keyof typeof OrderStatusPalette]}
                    />
                  ) : (
                    '---'
                  )}
                </span>
              </Text>
              <Text as="h6" variant="bodyMd">
                Courier: {data.trackingCompany}
              </Text>
              <Text as="h6" variant="bodyMd">
                Send status: <Badge tone={sendStatus?.badge as Tone}>{snakeToStartCase(sendStatus?.status || '')}</Badge>
              </Text>
              <Text as="h6" variant="bodyMd">
                Cost: {data.pricingClient ? `$${data.pricingClient}` : '---'}
              </Text>
              <Text as="h6" variant="bodyMd">
                Content:
              </Text>
              <Card>
                <Text as="h6" variant="bodyMd">
                  {data.content}
                </Text>
              </Card>
            </BlockStack>
          </BlockStack>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default memo(SmsHistoryDetail);
