import FooterLink from './components/FooterLink';
import NoInfoOrder from './components/NoInfoOrder';
import { CollapsibleLink } from '@/constants/trackingPage';

export const linkCollapsibleConfig = [
  {
    id: CollapsibleLink.FooterLink,
    title: 'Social & Policy',
    component: <FooterLink />,
  },
  {
    id: CollapsibleLink.NoInfoOrder,
    title: 'No info/Not found order',
    component: <NoInfoOrder />,
  },
];
