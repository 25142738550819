import styled from 'styled-components';

export const StyledTrackingLogsFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .title-info {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    .Polaris-Text--root.Polaris-Text--headingSm {
      margin-right: 3rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      span {
        font-weight: 400;
      }
    }
  }
`;
