import { ReactNode, memo } from 'react';
import { StyledStepper } from './styled';
import { CheckedCircleIcon } from '@/icons';

interface StepperProps {
  title: ReactNode;
  children?: ReactNode;
  checked?: boolean;
}

const Stepper = ({ children, title, checked = false }: StepperProps) => {
  return (
    <StyledStepper>
      <div className="stepper-container">
        <div className="stepper-heading">
          {checked ? (
            <div className="checked">
              <CheckedCircleIcon />
            </div>
          ) : (
            <div className="circle" />
          )}
          <span>{title}</span>
        </div>
        {children && <div className="stepper-body">{children}</div>}
      </div>
    </StyledStepper>
  );
};

export default memo(Stepper);
