import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledSenderInfo = styled.div`
  .sender-email {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    .title {
      width: 8rem;
    }

    .content {
      width: calc(100% - 8rem);
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.LG}) {
    .sender-email {
      flex-wrap: wrap;

      .content {
        width: 100%;
      }
    }
  }

  .sender-email {
    gap: 1rem;
    .title {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      svg {
        cursor: pointer;
      }
    }

    .Polaris-Connected {
      max-width: 30rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .Polaris-Choice__Descriptions {
        .no-reply {
          text-decoration: underline;
        }
      }

      .email-as-sender {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .your-email {
          .email-title {
            display: flex;
            gap: 0.5rem;
            margin-bottom: 0.25rem;
          }

          .not-verified {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .Polaris-Icon {
              width: 1rem;
              height: 1rem;

              svg {
                fill: #b98900;
              }
            }

            span {
              color: #916a00;
            }
          }

          .Polaris-Choice {
            margin-top: 0.25rem;
          }
        }

        .submit-btn {
          margin-top: 0.5rem;
        }
      }
    }
  }
`;
