import { Receiver } from '@/constants/enum';

export const tabsConfig = [
  {
    id: Receiver.Customer,
    content: 'To customer',
  },
  {
    id: Receiver.Merchant,
    content: 'To yourself',
  },
];
