import smsSlice, { tableSmsHistorySelector } from '@/redux/features/sms.slice';
import { ChoiceList, IndexFilters, useSetIndexFiltersMode } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { sortOptions, trackingStatusTab } from './config';
import { PageSize, SortDirection } from '@/constants/enum';
import { optionsPerPage } from '@/constants';

const SmsHistoryFilter = () => {
  const { mode, setMode } = useSetIndexFiltersMode();
  const dispatch = useDispatch();
  const smsFilter = useSelector(tableSmsHistorySelector);
  const selectedSmsHistoryTab = trackingStatusTab.findIndex((item) => item.id === smsFilter.trackingStatus) || 0;

  const handleOrderTable = (key: string) => (value: string[]) => {
    dispatch(
      smsSlice.actions.handleTable({
        ...smsFilter,
        [key]: value[0],
      }),
    );
  };

  const handleUpdateSort = (value: string[]) => {
    const formattedValue = value.toString().split(' ');
    dispatch(
      smsSlice.actions.handleTable({
        ...smsFilter,
        sortBy: formattedValue[0],
        sort: formattedValue[1].toUpperCase() as SortDirection,
      }),
    );
  };

  const handleInputSearch = (value: string) => {
      dispatch(smsSlice.actions.handleTable({ ...smsFilter, search: value }));
  };

  const handleClearAll = () => {
    dispatch(
      smsSlice.actions.handleTable({
        ...smsFilter,
        search: '',
        trackingStatus: 'all',
        perPage: PageSize.Five,
        currentPage: 1,
        sort: SortDirection.DESC,
        sortBy: 'updatedAt',
      }),
    );
  };

  const handleSelectTabs = (selectedTabIndex: number) => {
    dispatch(
      smsSlice.actions.handleTable({
        ...smsFilter,
        currentPage: 1,
        trackingStatus: trackingStatusTab.find((_, index) => index === selectedTabIndex)?.id || 'all',
      }),
    );
  };

  const appliedFilters = [
    {
      onRemove: () => handleOrderTable('perPage')(['5']),
      key: 'perPage',
      label: 'Per page: ' + smsFilter.perPage,
    },
  ];

  const filters = [
    {
      key: 'perPage',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={optionsPerPage}
          selected={[String(smsFilter.perPage)]}
          onChange={handleOrderTable('perPage')}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <IndexFilters
      mode={mode}
      setMode={setMode}
      cancelAction={{ onAction: () => {} }}
      canCreateNewView={false}
      sortOptions={sortOptions}
      sortSelected={[`${smsFilter.sortBy} ${smsFilter.sort.toLowerCase()}`]}
      onSort={handleUpdateSort}
      queryPlaceholder="Tracking number, Order ID"
      queryValue={smsFilter.search}
      onQueryChange={handleInputSearch}
      onQueryClear={() => handleInputSearch('')}
      appliedFilters={appliedFilters}
      filters={filters}
      onClearAll={handleClearAll}
      tabs={trackingStatusTab}
      selected={selectedSmsHistoryTab}
      onSelect={handleSelectTabs}
    />
  );
};

export default SmsHistoryFilter;
