import { IConfigTab } from '@/types/tab';
import EmailNotifications from './Email';
import { ChatMajor, EmailMajor } from '@shopify/polaris-icons';
import SMSNotifications from './SMS';
import { NotificationType } from '@/constants/notification';
import { capitalizeFirstLetter } from '@/helpers';

export const tabsConfig: Array<IConfigTab> = [
  {
    value: NotificationType.Email,
    label: capitalizeFirstLetter(NotificationType.Email),
    component: <EmailNotifications />,
    icon: EmailMajor,
  },
  {
    value: NotificationType.Sms,
    label: NotificationType.Sms.toUpperCase(),
    component: <SMSNotifications />,
    icon: ChatMajor,
  },
];
