import { CustomCard } from '@/components';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { SkeletonBodyText, Text } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const ProductRecommendation = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.marketingClick.productRecommend.categories.length) {
      const { categories, series } = data.data.marketingClick.productRecommend;
      return [
        {
          name: 'Product recommend',
          data: categories.map((subItem, index) => ({
            key: subItem,
            value: series[index],
          })),
        },
      ];
    }
    return [];
  }, [data]);
  return (
    <CustomCard
      title="PRODUCT RECOMMENDATION PERFORMANCE"
      tooltip={
        <>
          <Text as="h6" variant="headingSm">
            Product Recommendation Performance
          </Text>
          <p>We count the number of clicks to the Product Recommendation action over time</p>
        </>
      }
    >
      <div style={{ height: '480px' }}>
        {isLoading ? <SkeletonBodyText lines={25} /> : <LineChart theme="Light" data={chartData} isAnimated />}
      </div>
    </CustomCard>
  );
};

export default ProductRecommendation;
