import { leftFooterConfig, rightFooterConfig } from './config';
import { StyledPreviewFooter } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, {
  collapsibleSelector,
  languageSelector,
  trackingFocusSelector,
} from '@/redux/features/trackingPage.slice';
import { CollapsibleLanguages, CollapsibleLink, CustomizationTypeTabs, TrackingPagePreviewFocus } from '@/constants/trackingPage';

interface IPreviewFooter {
  isDesktopScreen: boolean;
}

const PreviewFooter = ({ isDesktopScreen }: IPreviewFooter) => {
  const {
    value: { footer },
  } = useSelector(languageSelector);
  const focus = useSelector(trackingFocusSelector);
  const dispatch = useDispatch();
  const collapsible = useSelector(collapsibleSelector);

  const handleClickFooterItem = (key: TrackingPagePreviewFocus, tab: CustomizationTypeTabs) => {
    dispatch(trackingPageSlice.actions.handleChangeTrackingPreviewFocus(key));
    dispatch(trackingPageSlice.actions.handleChangeCustomizeTypeTabs(tab));
    dispatch(
      trackingPageSlice.actions.handleChangeCollapsible({
        ...collapsible,
        language:
          tab === CustomizationTypeTabs.Languages
            ? { key: CollapsibleLanguages.Footer, isCollapse: true }
            : { key: '', isCollapse: false },
        text:
          tab === CustomizationTypeTabs.Link
            ? { key: CollapsibleLink.FooterLink, isCollapse: true }
            : { key: '', isCollapse: false },
      }),
    );
  };

  return (
    <StyledPreviewFooter>
      <div
        className="omg-footer-link justify-content-around"
        style={!isDesktopScreen ? { flexDirection: 'column', gridGap: '0.625rem', gap: '0.625rem' } : {}}
      >
        <div className="omg-social-media">
          {leftFooterConfig.map(({ key, icon }) => (
            <div className={`footer-focus${focus === key ? ' active' : ''}`} key={key}>
              <div
                className="omg-link"
                style={{ backgroundColor: 'rgb(8,8,8)' }}
                onClick={() => handleClickFooterItem(key, CustomizationTypeTabs.Link)}
              >
                {icon}
              </div>
            </div>
          ))}
        </div>
        <div className="omg-policy">
          {rightFooterConfig.map(({ key, text }) => (
            <div
              className={`omg-link${focus === key ? ' active' : ''}`}
              key={key}
              onClick={() => handleClickFooterItem(key, CustomizationTypeTabs.Languages)}
            >
              {footer[text as keyof typeof footer]}
            </div>
          ))}
        </div>
      </div>
    </StyledPreviewFooter>
  );
};

export default PreviewFooter;
