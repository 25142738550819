import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  CRUD,
  DisplayReview,
  GoogleTranslatePosition,
  Language,
  ProgressBar,
  ShippingInfo,
  TrackingMethods,
} from '@/constants/enum';
import { HSBColor } from '@shopify/polaris';
import { BlacklistReplaceWith, TrackingPageTabs, UpdatedBlacklistBtn } from '@/constants';
import { languages } from '@/pages/TrackingPage/components/Configuration/components/CustomizeContent/components/Languages/config';
import { IShippingMethod, ITransitTimes } from '@/types/edd';
import { ICourier } from '@/types/settings';
import {
  CollapsibleLanguages,
  CollapsibleLink,
  CollapsibleTheme,
  CustomizationPreviewBodyTabs,
  CustomizationTypeTabs,
  TrackingPagePreviewFocus,
} from '@/constants/trackingPage';

interface LanguageConfig {
  language: Language;
  googleTranslateExtension: boolean;
  googleTranslatePosition: GoogleTranslatePosition;
}

export interface LanguageValue {
  trackingStatus: {
    courierNotSupported: string;
    delivered: string;
    exception: string;
    failedAttempt: string;
    expired: string;
    inTransit: string;
    informationReceived: string;
    ordered: string;
    outForDelivery: string;
    pending: string;
  };
  trackingForm: {
    trackYourOrder: string;
    orderId: string;
    email: string;
    phoneNumber?: string;
    or: string;
    trackingNumber: string;
    track: string;
    placeholderTrackingNumber: string;
    placeholderOrderId: string;
    placeholderEmail: string;
    PlaceholderPhoneNumber?: string;
    orderNotFound: string;
    emptyEmail?: string;
    invalidEmail?: string;
    emptyTrackingNumber?: string;
    emptyOrderName?: string;
    emptyPhoneNumber?: string;
    orderDescription?: string;
  };
  trackingHistory: {
    shipmentInformation: string;
    shippingAddress: string;
    trackingNumber: string;
    shipmentHistory: string;
    carrier: string;
  };
  progressBar: {
    status: string;
  };
  orderDetails: {
    shipmentItems: string;
    product: string;
    totalPrice: string;
    itemSingular: string;
    itemPlural: string;
  };
  review: {
    buttonText: string;
    reviewTitle: string;
  };
  footer: {
    privacy: string;
    return: string;
    support: string;
    term: string;
  };
}

const englishConfig = languages[0];
interface ILookAndFeel {
  chooseTheme: string;
  lookupOption: {
    method: TrackingMethods;
    requireEmail: boolean;
  };
  content: {
    shippingInfo: Array<ShippingInfo>;
    format: {
      dateFormat: string;
      timeFormat: string;
    };
    progressBar: Array<ProgressBar>;
  };
  style: {
    colorPicker: {
      mainColor: HSBColor;
      textColor: HSBColor;
      btnColor: HSBColor;
      btnNameColor: HSBColor;
    };
    btnRadius: number;
  };
}

interface ILanguage {
  languageConfig: LanguageConfig;
  value: LanguageValue;
  oldValue: {
    languageConfig: LanguageConfig;
    value: LanguageValue;
  };
  fieldsChange: Object;
  validation: Array<string>;
  isOpenChooseLanguage: boolean;
}

interface IConfiguration {
  notInfoOrder: {
    isNoInfo: boolean;
    isNotFound: boolean;
    message: string;
  };

  customStatus: {
    isCustomStatus: boolean;
    statusName: string;
    activeAfter: string;
    status: string;
  };
  productRecommendation: {
    enable: boolean;
    title: string;
    type: string;
    value: Array<string>;
  };
  review: {
    settings: {
      isEnableReview: boolean;
      isDisabledDisplayReview: boolean;
      displayReviewOption: Array<DisplayReview>;
    };
    question_1: {
      value: string;
      oneStarTitle: string;
      fiveStarTitle: string;
      display: boolean;
      minReason: string;
      displayReasons: boolean;
      reasons: Array<string>;
    };
    question_2: {
      value: string;
      oneStarTitle: string;
      fiveStarTitle: string;
      display: boolean;
    };
    question_3: {
      value: string;
      oneStarTitle: string;
      fiveStarTitle: string;
      display: boolean;
    };
    additionalComment: { value: string; display: boolean };
    thankMessage: { value: string; display: boolean };
  };
  footerLink: {
    facebook: string;
    twitter: string;
    instagram: string;
    tiktok: string;
    pinterest: string;
    support: string;
    termPage: string;
    returnPage: string;
    privacyPage: string;
  };
  additionalContent: {
    textAbove: string;
    textBelow: string;
    htmlTop: string;
    htmlBottom: string;
    customCss: string;
  };
}

interface IBlacklisting {
  updatedBlacklistBtn: UpdatedBlacklistBtn;
  isEnableBtn: boolean;
  replaceWith: BlacklistReplaceWith;
  areasToBeReplaced: {
    selected: Array<string>;
    search: string;
  };
  areaToReplaceWith: {
    country: { selected: Array<string>; search: string };
    city: { selected: Array<string>; search: string };
  };
}

interface IEstimatedDeliveryDate {
  isEnableBtn: number;
  orderDescription: {
    isModalActive: boolean;
    cutoffTime: string;
    timezone: string;
    processingTime: { value: string; error: boolean };
    businessDays: { value: Array<number>; error: boolean };
  };
  shippingZone: {
    countriesCode: Array<string>;
  };
  shippingMethod: {
    modal: {
      type: CRUD;
      isActive: boolean;
      isCollapse: boolean;
      id?: number;
    };
    methodInfo: IShippingMethod[];
    search: string;
    from: string;
    to: string;
    courier: Array<string>;
    courierList: ICourier[];
  };
  transitTimes: ITransitTimes[];
}

interface ICollapsible {
  text: {
    key: CollapsibleLink | '';
    isCollapse: boolean;
  };
  theme: {
    key: CollapsibleTheme | '';
    isCollapse: boolean;
  };
  language: {
    key: CollapsibleLanguages | '';
    isCollapse: boolean;
  };
}

export interface ITrackingPageState {
  tabSelected: TrackingPageTabs;
  tabConfigurationSelected: string;
  trackingPage: {
    isLoading: boolean;
    value: {
      lookAndFeel: ILookAndFeel;
      configuration: IConfiguration;
    };
    oldValue: {
      lookAndFeel: ILookAndFeel;
      configuration: IConfiguration;
    };
    fieldsChange: any;
    validation: Array<string>;
  };
  language: ILanguage;
  blacklisting: IBlacklisting;
  edd: IEstimatedDeliveryDate;
  customizeTypeTabs: CustomizationTypeTabs;
  collapsible: ICollapsible;
  focus: TrackingPagePreviewFocus;
  customizePreviewBodyTabs: CustomizationPreviewBodyTabs;
}
// Define the initial state using that type
const initialState: ITrackingPageState = {
  tabSelected: TrackingPageTabs.LookAndFeel,
  tabConfigurationSelected: 'blacklist',
  trackingPage: {
    isLoading: false,
    value: {
      lookAndFeel: {
        chooseTheme: '1',
        lookupOption: {
          method: TrackingMethods.Both,
          requireEmail: true,
        },
        content: {
          shippingInfo: [
            ShippingInfo.Map,
            ShippingInfo.OrderDetail,
            ShippingInfo.ProgressBar,
            ShippingInfo.TrackingCompany,
            ShippingInfo.TrackingLogs,
          ],
          progressBar: [
            ProgressBar.Delivered,
            ProgressBar.InTransit,
            ProgressBar.OrderReady,
            ProgressBar.Ordered,
            ProgressBar.OutForDelivery,
          ],
          format: {
            dateFormat: 'MMMM Do YYYY',
            timeFormat: 'h:mm:ss a',
          },
        },
        style: {
          colorPicker: {
            mainColor: {
              hue: 1,
              brightness: 1,
              saturation: 1,
            },
            btnColor: {
              hue: 65,
              brightness: 1,
              saturation: 1,
            },
            btnNameColor: {
              hue: 120,
              brightness: 120,
              saturation: 120,
            },
            textColor: {
              hue: 120,
              brightness: 0,
              saturation: 1,
            },
          },
          btnRadius: 16,
        },
      },
      configuration: {
        notInfoOrder: {
          isNoInfo: false,
          isNotFound: false,
          message: '',
        },
        customStatus: {
          isCustomStatus: false,
          statusName: '',
          activeAfter: '1',
          status: '1',
        },
        productRecommendation: {
          enable: false,
          title: '',
          type: 'all',
          value: [],
        },
        review: {
          settings: {
            isEnableReview: false,
            isDisabledDisplayReview: true,
            displayReviewOption: [
              DisplayReview.StarRating,
              DisplayReview.Comment,
              DisplayReview.CustomerName,
              DisplayReview.ReviewTime,
              DisplayReview.Courier,
            ],
          },
          question_1: {
            value: 'How would you rate us?',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
            minReason: '2',
            reasons: ['Well packed', 'Fast delivers'],
            displayReasons: true,
          },
          question_2: {
            value: 'How likely would you recommend us to your friends and family?',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
          },
          question_3: {
            value: 'Your optional question!',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
          },

          additionalComment: { value: 'Share with us your experience and feelings', display: true },
          thankMessage: { value: 'Thank you for review message!', display: true },
        },
        footerLink: {
          facebook: '',
          twitter: '',
          instagram: '',
          tiktok: '',
          pinterest: '',
          support: '',
          returnPage: '',
          termPage: '',
          privacyPage: '',
        },
        additionalContent: {
          customCss: '',
          htmlBottom: '',
          htmlTop: '',
          textAbove: '',
          textBelow: '',
        },
      },
    },
    oldValue: {
      lookAndFeel: {
        chooseTheme: '1',
        lookupOption: {
          method: TrackingMethods.Both,
          requireEmail: true,
        },
        content: {
          shippingInfo: [
            ShippingInfo.Map,
            ShippingInfo.OrderDetail,
            ShippingInfo.ProgressBar,
            ShippingInfo.TrackingCompany,
            ShippingInfo.TrackingLogs,
          ],
          progressBar: [
            ProgressBar.Delivered,
            ProgressBar.InTransit,
            ProgressBar.OrderReady,
            ProgressBar.Ordered,
            ProgressBar.OutForDelivery,
          ],
          format: {
            dateFormat: 'MMMM Do YYYY',
            timeFormat: 'h:mm:ss a',
          },
        },
        style: {
          colorPicker: {
            mainColor: {
              hue: 1,
              brightness: 1,
              saturation: 1,
            },
            btnColor: {
              hue: 65,
              brightness: 1,
              saturation: 1,
            },
            btnNameColor: {
              hue: 120,
              brightness: 120,
              saturation: 120,
            },
            textColor: {
              hue: 120,
              brightness: 0,
              saturation: 1,
            },
          },
          btnRadius: 16,
        },
      },
      configuration: {
        notInfoOrder: {
          isNoInfo: false,
          isNotFound: false,
          message: '',
        },
        customStatus: {
          isCustomStatus: false,
          statusName: '',
          activeAfter: '1',
          status: '1',
        },
        productRecommendation: {
          enable: false,
          title: '',
          type: 'all',
          value: [],
        },
        review: {
          settings: {
            isEnableReview: false,
            isDisabledDisplayReview: true,
            displayReviewOption: [
              DisplayReview.StarRating,
              DisplayReview.Comment,
              DisplayReview.CustomerName,
              DisplayReview.ReviewTime,
              DisplayReview.Courier,
            ],
          },
          question_1: {
            value: 'How would you rate us?',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
            minReason: '2',
            reasons: ['Well packed', 'Fast delivers'],
            displayReasons: true,
          },
          question_2: {
            value: 'How likely would you recommend us to your friends and family?',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
          },
          question_3: {
            value: 'Your optional question!',
            oneStarTitle: '1',
            fiveStarTitle: '2',
            display: true,
          },

          additionalComment: { value: 'Share with us your experience and feelings', display: true },
          thankMessage: { value: 'Thank you for review message!', display: true },
        },
        footerLink: {
          facebook: '',
          twitter: '',
          instagram: '',
          tiktok: '',
          pinterest: '',
          support: '',
          returnPage: '',
          termPage: '',
          privacyPage: '',
        },
        additionalContent: {
          customCss: '',
          htmlBottom: '',
          htmlTop: '',
          textAbove: '',
          textBelow: '',
        },
      },
    },
    fieldsChange: {},
    validation: [],
  },
  language: {
    fieldsChange: {},
    languageConfig: {
      language: Language.Default,
      googleTranslateExtension: true,
      googleTranslatePosition: GoogleTranslatePosition.LeftTop,
    },
    oldValue: {
      value: {
        trackingStatus: {
          ...englishConfig.translations.trackingStatus,
        },
        trackingForm: {
          ...englishConfig.translations.trackingForm,
        },
        trackingHistory: {
          ...englishConfig.translations.trackingHistory,
        },
        footer: {
          ...englishConfig.translations.footer,
        },
        orderDetails: {
          ...englishConfig.translations.orderDetails,
        },
        progressBar: {
          ...englishConfig.translations.progressBar,
        },
        review: {
          ...englishConfig.translations.review,
        },
      },
      languageConfig: {
        language: Language.Default,
        googleTranslateExtension: true,
        googleTranslatePosition: GoogleTranslatePosition.LeftTop,
      },
    },
    value: {
      trackingStatus: {
        ...englishConfig.translations.trackingStatus,
      },
      trackingForm: {
        ...englishConfig.translations.trackingForm,
      },
      trackingHistory: {
        ...englishConfig.translations.trackingHistory,
      },
      footer: {
        ...englishConfig.translations.footer,
      },
      orderDetails: {
        ...englishConfig.translations.orderDetails,
      },
      progressBar: {
        ...englishConfig.translations.progressBar,
      },
      review: {
        ...englishConfig.translations.review,
      },
    },
    validation: [],
    isOpenChooseLanguage: false,
  },

  blacklisting: {
    updatedBlacklistBtn: UpdatedBlacklistBtn.Enable,
    isEnableBtn: false,
    replaceWith: BlacklistReplaceWith.EmptyLocation,
    areasToBeReplaced: {
      selected: [],
      search: '',
    },
    areaToReplaceWith: {
      country: { search: '', selected: [] },
      city: { search: '', selected: [] },
    },
  },
  edd: {
    isEnableBtn: 0,
    orderDescription: {
      isModalActive: false,
      cutoffTime: '',
      timezone: '',
      processingTime: { value: '', error: false },
      businessDays: { value: [], error: false },
    },
    shippingZone: {
      countriesCode: [],
    },
    shippingMethod: {
      modal: { type: CRUD.Read, isActive: false, isCollapse: false },
      methodInfo: [],
      search: '',
      from: '4',
      to: '7',
      courier: [],
      courierList: [],
    },
    transitTimes: [],
  },
  customizeTypeTabs: CustomizationTypeTabs.Link,
  collapsible: {
    text: { key: '', isCollapse: false },
    theme: { key: '', isCollapse: false },
    language: { key: '', isCollapse: false },
  },
  focus: TrackingPagePreviewFocus.NoFocus,
  customizePreviewBodyTabs: CustomizationPreviewBodyTabs.OrderId,
};

const trackingPageSlice = createSlice({
  name: 'trackingPage',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTabSelected: (state, action: PayloadAction<TrackingPageTabs>) => {
      state.tabSelected = action.payload;
    },
    handleTabConfigurationSelected: (state, action: PayloadAction<string>) => {
      state.tabConfigurationSelected = action.payload;
    },
    handleTrackingPageValue: (state, action: PayloadAction<{ lookAndFeel: ILookAndFeel; configuration: IConfiguration }>) => {
      state.trackingPage.value = action.payload;
    },
    handleTrackingPageOldValue: (state, action: PayloadAction<{ lookAndFeel: ILookAndFeel; configuration: IConfiguration }>) => {
      state.trackingPage.oldValue = action.payload;
    },

    handleTrackingPageFieldsChange: (state, action: PayloadAction<Object>) => {
      state.trackingPage.fieldsChange = action.payload;
    },

    handleTrackingPageIsloading: (state, action: PayloadAction<boolean>) => {
      state.trackingPage.isLoading = action.payload;
    },

    handleLanguageConfig: (state, action: PayloadAction<LanguageConfig>) => {
      state.language.languageConfig.language = action.payload.language;
      state.language.languageConfig.googleTranslateExtension = action.payload.googleTranslateExtension;
      state.language.languageConfig.googleTranslatePosition = action.payload.googleTranslatePosition;
    },
    handleLanguageValue: (state, action: PayloadAction<LanguageValue>) => {
      state.language.value = action.payload;
    },
    handleLanguageOldValue: (state, action: PayloadAction<{ value: LanguageValue; languageConfig: LanguageConfig }>) => {
      state.language.oldValue = action.payload;
    },
    handleLanguageFieldsChange: (state, action: PayloadAction<Object>) => {
      state.language.fieldsChange = action.payload;
    },

    handleChangeBlacklisting: (state, action: PayloadAction<IBlacklisting>) => {
      state.blacklisting = action.payload;
    },
    handleChangeEDD: (state, action: PayloadAction<IEstimatedDeliveryDate>) => {
      state.edd = action.payload;
    },
    handleChangeValidation: (state, action: PayloadAction<Array<string>>) => {
      state.trackingPage.validation = action.payload;
    },
    handleChangeValidationLanguage: (state, action: PayloadAction<Array<string>>) => {
      state.language.validation = action.payload;
    },
    handleChangeCustomizeTypeTabs: (state, action: PayloadAction<CustomizationTypeTabs>) => {
      state.customizeTypeTabs = action.payload;
    },
    handleChangeCollapsible: (state, action: PayloadAction<ICollapsible>) => {
      state.collapsible = action.payload;
    },
    handleChangeOpenChooseLanguage: (state, action: PayloadAction<boolean>) => {
      state.language.isOpenChooseLanguage = action.payload;
    },
    handleChangeTrackingPreviewFocus: (state, action: PayloadAction<TrackingPagePreviewFocus>) => {
      state.focus = action.payload;
    },
    handleChangeCustomizePreviewBodyTabs: (state, action: PayloadAction<CustomizationPreviewBodyTabs>) => {
      state.customizePreviewBodyTabs = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.tabSelected,
);
export const tabConfigurationSelectedSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.tabConfigurationSelected,
);

export const trackingPageSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.trackingPage,
);

export const trackingPageIsLoadingSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.trackingPage.isLoading,
);

export const languageSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.language,
);

export const blacklistingSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.blacklisting,
);

export const eddSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.edd,
);

export const customizeTypeTabsSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.customizeTypeTabs,
);

export const collapsibleSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.collapsible,
);

export const trackingFocusSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.focus,
);

export const customizePreviewBodyTabsSelector = createSelector(
  (state: RootState) => state.trackingPage,
  (state) => state.customizePreviewBodyTabs,
);

export default trackingPageSlice;
