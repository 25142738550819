import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IToken {
  shop: string;
  urlParams: string;
  hmac?: string;
  host?: string;
  session?: string;
  timestamp?: string;
  is_first?: boolean;
  email?: string;
  api_key?: string;
  scope?: string;
  role?: string;
}

export interface IAuthState {
  data: {
    token: IToken | null;
    isAuth: boolean;
    shop: string;
  };
  session: {
    token: string;
    expiredTime: number;
  };
}

const initialState: IAuthState = {
  data: {
    token: null,
    isAuth: false,
    shop: '',
  },
  session: {
    token: '',
    expiredTime: Date.now(),
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleAuth: (
      state,
      action: PayloadAction<{
        token: IToken | null;
        isAuth: boolean;
        shop: string;
      }>,
    ) => {
      state.data = action.payload;
    },
    handleGetSessionToken: (
      state,
      action: PayloadAction<{
        token: string;
        expiredTime: number;
      }>,
    ) => {
      state.session = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tokenSelector = createSelector(
  (state: RootState) => state.auth,
  (state) => state.data,
);
export const sessionTokenSelector = createSelector(
  (state: RootState) => state.auth,
  (state) => state.session,
);

export default authSlice;
