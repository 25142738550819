import { InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { OrderStatusPalette } from '@/constants';
import { useGetShipmentOvertimeQuery } from '@/redux/api/api.caller';
import { useSelector } from 'react-redux';
import { datePickerSelector } from '@/redux/features/dashboard.slice';
import { camelToRegularText, camelToSnakeCase } from '@/helpers';
import { CustomBadge, CustomCard } from '@/components';
import { useMemo } from 'react';
import { StyledShipmentOvertime } from './styled';

const ShipmentsOvertime = () => {
  const { start, end } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetShipmentOvertimeQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const transformedData = useMemo(() => {
    if (data && data.data) {
      const allData = Object.entries(data.data.statusSummary).map(([key, value]) => {
        return {
          key,
          value,
          color: OrderStatusPalette[camelToSnakeCase(key).toUpperCase() as keyof typeof OrderStatusPalette],
        };
      });
      return allData.filter((data) => data.key !== 'all');
    }
    return [];
  }, [data]);

  return (
    <StyledShipmentOvertime>
      <CustomCard title="Shipments over time" className="card-container">
        {isLoading ? (
          <SkeletonBodyText lines={11} />
        ) : (
          <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="500">
            {transformedData.length > 0 &&
              transformedData.map(({ key, color, value }, index) => (
                <div className="shipment-item" key={index}>
                  <CustomBadge summary={camelToRegularText(key)} status={color} />
                  <p>{value}</p>
                </div>
              ))}
          </InlineGrid>
        )}
      </CustomCard>
    </StyledShipmentOvertime>
  );
};

export default ShipmentsOvertime;
