export enum BgColorPalette {
  PRIMARY = 'rgba(48, 48, 48, 1)',
  PRIMARY_HOVER = 'rgba(26, 26, 26, 1)',
  PRIMARY_ACTIVE = 'rgba(26, 26, 26, 1)',
  SECONDARY = 'rgba(241, 241, 241, 1)',
  SECONDARY_HOVER = 'rgba(235, 235, 235, 1)',
  SECONDARY_ACTIVE = 'rgba(227, 227, 227, 1)',
  INFO = 'rgba(145, 208, 255, 1)',
  INFO_HOVER = 'rgba(81, 192, 255, 1)',
  INFO_ACTIVE = 'rgba(0, 148, 213, 1)',
  SUCCESS = 'rgba(41, 132, 90, 1)',
  SUCCESS_HOVER = 'rgba(19, 111, 69, 1)',
  SUCCESS_ACTIVE = 'rgba(12, 81, 50, 1)',
  WARNING = 'rgba(255, 184, 0, 1)',
  WARNING_HOVER = 'rgba(178, 132, 0, 1)',
  WARNING_ACTIVE = 'rgba(255, 214, 164, 1)',
  CRITICAL = 'rgba(229, 28, 0, 1)',
  CRITICAL_HOVER = 'rgba(181, 38, 11, 1)',
  CRITICAL_ACTIVE = 'rgba(142, 31, 11, 1)',
  OVERLAY = 'rgba(0, 0, 0, 0.5)',
  OVERLAY_2 = 'rgba(0, 0, 0, 0.3)',
  SCREEN_LOCK = 'rgba(255, 255, 255, 0.5)',
  FOCUS = '#2c6ecb'
}

export enum OrderStatusPalette {
  DELIVERED = '#58aa64',
  IN_TRANSIT = '#5499d8',
  OUT_FOR_DELIVERY = '#df7e01',
  INFORMATION_RECEIVED = '#2b4975',
  INFO_RECEIVED = '#2b4975',
  EXCEPTION = '#319da1',
  FAILED_ATTEMPT = '#ad3c28',
  EXPIRED = '#9759b8',
  PENDING = '#929eab',
  COURIER_NOT_SUPPORTED = '#f8bb26',
  OVER_QUOTA = '#d72c0e',
}

export enum ShipmentByStatusPalette {
  DELIVERED = '#58aa64',
  COURIER_NOT_SUPPORTED = '#f8bb26',
  EXCEPTION = '#d72c0e',
  EXPIRED = '#9759b8',
  FAILED_ATTEMPT = '#ad3c28',
  IN_TRANSIT = '#5499d8',
  INFORMATION_RECEIVED = '#2b4975',
  OTHERS = '#319da1',
  OUT_FOR_DELIVERY = '#df7e01',
  PENDING = '#929eab',
}
