import { CustomPage } from '@/components';
import Layout from '@/components/Layout';
import { tabsConfig } from './config';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSlice, notificationsTabSelectedSelector } from '@/redux/features/notifications.slice';
import { NotificationType } from '@/constants/notification';

const Notifications = () => {
  const dispatch = useDispatch();
  const tabSelected = useSelector(notificationsTabSelectedSelector);

  const handleChangeTab = (value: Array<NotificationType>) => {
    dispatch(notificationsSlice.actions.handleChangeTab(value[0]));
  };

  return (
    <Layout fullWidth maxWidth="75rem" title="Notifications">
      <CustomPage
        navResponsiveWidth="75rem"
        tabsConfig={tabsConfig}
        selected={[tabSelected]}
        onChange={handleChangeTab}
        selectedTab={tabSelected}
      />
    </Layout>
  );
};

export default Notifications;
