import styled from 'styled-components';

export const StyledFooter = styled.div`
  .Polaris-FooterHelp__Text {
    display: flex;
    align-items: center;
    gap: 5rem;

    .Polaris-Link {
      font-size: 0.75rem;
    }
  }
`;
