import { useDispatch, useSelector } from 'react-redux';
import { Badge, Link as PolarisLink, IndexTable } from '@shopify/polaris';
import emailSlice, { datePickerTitleSelector, emailHistorySelector } from '@/redux/features/email.slice';
import DatePicker from '@/components/DatePicker';
import EmailHistoryFilter from './components/EmailHistoryFilter';
import { getTrackingStatusLabelByValue } from './config';
import { capitalizeFirstLetter, cleanObject } from '@/helpers';
import { LINK, OrderStatusPalette } from '@/constants';
import { CustomBadge, CustomCard, CustomTable, ExternalLink } from '@/components';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useGetNotificationLogsQuery } from '@/redux/api/api.caller';
import { NotificationType } from '@/constants/notification';
import { Tone } from '@shopify/polaris/build/ts/src/components/Badge';
import EmailHistoryDetail from './components/EmailHistoryDetail';
import { StyledEmailHistory } from './styled';
import { useDebounce } from '@/hooks';

const EmailHistory = () => {
  const [modal, setModal] = useState<{ open: boolean; id?: number }>({ open: false });
  const dispatch = useDispatch();
  const datePickerTitle = useSelector(datePickerTitleSelector);
  const historyState = useSelector(emailHistorySelector);
  const debouncedSearch = useDebounce<string>(historyState.search);
  const unRequiredParams = cleanObject({
    search: debouncedSearch,
    trackingStatus: historyState.status,
    sortBy: historyState.sortBy,
    sortDirection: historyState.sortBy ? historyState.sortOrder : '',
  });
  const { data, isFetching } = useGetNotificationLogsQuery({
    type: NotificationType.Email,
    fromDate: historyState.datePicker.start.toISOString(),
    toDate: historyState.datePicker.end.toISOString(),
    page: historyState.page,
    perPage: historyState.pageSize,
    ...unRequiredParams,
  });

  const notificationLogs = data?.data?.result || [];

  const emailHistoryDetail = useMemo(() => {
    if (modal.id) {
      const detail = notificationLogs.find(({ id }) => id === modal.id);
      return [
        {
          key: 'To',
          value: capitalizeFirstLetter(detail?.receiver || ''),
        },
        {
          key: 'Update at',
          value: dayjs(detail?.updatedAt).format('MMM DD, YYYY hh:mm A'),
        },
        {
          key: 'Order ID',
          value: (
            <PolarisLink url={LINK.ORDER_DETAIL(detail?.shop || '', String(detail?.orderId || ''))}>
              {detail?.orderId}
            </PolarisLink>
          ),
        },
        {
          key: 'Tracking number',
          value: (
            <PolarisLink url={LINK.TRACKING_ORDER_NOW(detail?.shop || '', detail?.trackingNumber || '')}>
              {detail?.trackingNumber}
            </PolarisLink>
          ),
        },
        {
          key: 'Tracking status',
          value: getTrackingStatusLabelByValue(detail?.trackingStatus || '') || '',
        },
        {
          key: 'Courier',
          value: detail?.trackingCompany || '',
        },
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.id]);

  const handleOpenModal = (id: number) => setModal((prev) => ({ ...prev, open: true, id }));
  const handleCloseModal = () => setModal((prev) => ({ ...prev, open: false }));

  const handleApplyDatePicker = (start: Date, end: Date) =>
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({ ...historyState, datePicker: { ...historyState.datePicker, start, end } }),
    );
  const handleSaveDatePickerTitle = (titleDatePicker: string) =>
    dispatch(emailSlice.actions.handleChangeDatePickerTitle(titleDatePicker));

  const handleClickPaginationBtn = (type: 'next' | 'prev') =>
    dispatch(
      emailSlice.actions.handleChangeEmailHistory({
        ...historyState,
        page: type === 'prev' ? historyState.page - 1 : historyState.page + 1,
      }),
    );

  const rowMarkup = useMemo(() => {
    if (data && data.data && data.data.result) {
      const renderBadge = (status: number): { color: Tone; text: string } => {
        switch (status) {
          case 1:
            return {
              color: 'success',
              text: 'Done',
            };
          case 2:
            return {
              color: 'critical',
              text: 'Failed',
            };
          default:
            return {
              color: 'info',
              text: 'Processing',
            };
        }
      };

      return data.data.result.map((history, index) => (
        <IndexTable.Row
          id={history.id.toString()}
          key={history.id}
          position={index}
          rowType="data"
          onClick={() => history.id !== 0 && handleOpenModal(history.id)}
        >
          <IndexTable.Cell>
            <ExternalLink to={LINK.ORDER_DETAIL(history.shop || '', String(history.orderId))}>{history.orderId}</ExternalLink>
          </IndexTable.Cell>
          <IndexTable.Cell>{history.receiver}</IndexTable.Cell>
          <IndexTable.Cell>
            <ExternalLink to={LINK.TRACKING_ORDER_NOW(history.shop || '', history.trackingNumber)}>
              {history.trackingNumber}
            </ExternalLink>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {history.trackingStatus ? (
              <CustomBadge
                summary={getTrackingStatusLabelByValue(history.trackingStatus) || '---'}
                status={OrderStatusPalette[history.trackingStatus.toUpperCase() as keyof typeof OrderStatusPalette]}
              />
            ) : (
              '---'
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Badge tone={renderBadge(history.status).color}>{renderBadge(history.status).text}</Badge>
          </IndexTable.Cell>
          <IndexTable.Cell>{dayjs(history.updatedAt).format('MMM DD, YYYY hh:mm A')}</IndexTable.Cell>
        </IndexTable.Row>
      ));
    }
    return [];
  }, [data]);

  return (
    <StyledEmailHistory>
      <CustomCard
        title="Email History"
        actionGroup={[
          {
            content: (
              <DatePicker
                titleBtn={datePickerTitle}
                start={historyState.datePicker.start}
                end={historyState.datePicker.end}
                onSave={handleApplyDatePicker}
                setTitleBtn={handleSaveDatePickerTitle}
              />
            ),
          },
        ]}
      >
        <CustomTable
          filter={<EmailHistoryFilter />}
          tableProps={{
            resourceName: {
              singular: 'email history',
              plural: 'email histories',
            },
            itemCount: notificationLogs.length,
            headings: [
              { title: 'Order ID' },
              { title: 'Receiver' },
              { title: 'Tracking no' },
              { title: 'Tracking status' },
              { title: 'Send status' },
              { title: 'Send time' },
            ],
            selectable: true,
          }}
          skeletonLines={9}
          isLoading={isFetching}
          page={historyState.page}
          pageSize={historyState.pageSize}
          totalResult={data?.data?.totalResult || 0}
          paginationProps={{ onPrevious: () => handleClickPaginationBtn('prev'), onNext: () => handleClickPaginationBtn('next') }}
        >
          {rowMarkup}
        </CustomTable>
      </CustomCard>

      <EmailHistoryDetail isOpen={modal.open} onClose={handleCloseModal} emailHistoryDetail={emailHistoryDetail} />
    </StyledEmailHistory>
  );
};

export default EmailHistory;
