export const cardConfig = {
  overQuota: {
    title: 'Over Quota',
    label: 'Pending',
    backgroundColor: 'linear-gradient(90deg, #C6231F 4.26%, #F6534B 95.93%)',
  },
  remainingQuota: {
    title: 'Remaining Quota',
    label: 'based on price in USA',
    backgroundColor: 'linear-gradient(90deg, #008060 4.26%, #40CB6B 95.93%)',
  },
  usedSms: {
    backgroundColor: 'linear-gradient(90deg, #F8894F 4.26%, #FEB430 95.93%)',
  },
};

export const smsTextField = {
  amount: {
    min: 5,
    max: 10000,
    greater: 'Amount must be greater than or equal to $5',
    less: 'Amount must be less than or equal to $10.000',
  },
  amountSms: {
    min: 1,
    max: 1000000000,
    greater: 'Amount should be greater than or equal to 1',
    less: 'Amount should be less than or equal to 1B',
  },
};
