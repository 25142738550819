import { Modal, Text } from '@shopify/polaris';
import { memo } from 'react';
import { StyledEmailHistoryModal } from './styled';

interface EmailHistoryDetailProps {
  isOpen: boolean;
  onClose: () => void;
  emailHistoryDetail: Array<{
    key: string;
    value: string | JSX.Element;
  }>;
}

const EmailHistoryDetail = ({ isOpen, onClose, emailHistoryDetail }: EmailHistoryDetailProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Email history detail"
      secondaryActions={[
        {
          content: 'Close details',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <StyledEmailHistoryModal>
          {emailHistoryDetail.map((item) => (
            <div className="history-item" key={item.key}>
              <p className="title">{`${item.key}:`}</p>
              <Text variant="bodySm" as="h6">
                {item.value}
              </Text>
            </div>
          ))}
        </StyledEmailHistoryModal>
      </Modal.Section>
    </Modal>
  );
};

export default memo(EmailHistoryDetail);
