import { ChartCustomTitle, CustomCard } from '@/components';
import { ShipmentByStatusPalette } from '@/constants';
import { calcPercent } from '@/helpers';
import { useGetAnalyticsChartCourierQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { ColumnContentType, DataTable, Modal, SkeletonBodyText, TableData } from '@shopify/polaris';
import { DataSeries, LineChart } from '@shopify/polaris-viz';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const TopCourierByTransitDay = () => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const { end, start } = useSelector(datePickerSelector);
  const { data, isLoading } = useGetAnalyticsChartCourierQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const chartData: DataSeries[] = useMemo(() => {
    if (data && data.data.topCourierByDays.categories.length) {
      const { categories, series } = data.data.topCourierByDays;
      return series.map((item, index) => ({
        name: `${item?.name}`,
        color: Object.values(ShipmentByStatusPalette)[index],
        data: categories.map((subItem, index) => ({
          key: subItem,
          value: item.series[index],
        })),
      }));
    }
    return [];
  }, [data]);

  const tableData = useMemo(() => {
    if (data) {
      const { categories: categoriesData, series: seriesData } = data.data.topCourierByDays;
      const contentTypes = ['text', ...[...Array(seriesData.length)].map(() => 'text')];

      const headings = ['', ...seriesData.map(({ name }) => name)];

      const dataPercent = seriesData.map(({ series, count }) => series.map((value) => `${calcPercent(value, count)}%`));
      const rows = categoriesData.map((item, index) => {
        const dataByDay = dataPercent.map((data) => data[index]);
        return [item, ...dataByDay];
      });
      return { contentTypes, headings, rows };
    }
    return {};
  }, [data]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <CustomCard
      title="TOP 3 COURIERS BY IN TRANSIT DAY"
      tooltip="We rank this Top 3 based on the average transit day of the couriers
                  The percentages show the proportion of orders grouped by transit day and courier of the total supported tracking"
      actionGroup={[
        {
          content: 'Detail',
          onAction: handleOpenModal,
          variant: 'plain',
        },
      ]}
    >
      <div style={{ height: '420px', paddingBottom: '12px' }}>
        {isLoading ? (
          <SkeletonBodyText lines={22} />
        ) : (
          <>
            <ChartCustomTitle xTitle="Time" yTitle="">
              <LineChart data={chartData} theme="Light" isAnimated />
            </ChartCustomTitle>
            <Modal
              open={isOpenModal}
              onClose={handleCloseModal}
              title="Details"
              secondaryActions={[
                {
                  content: 'Close',
                  onAction: handleCloseModal,
                },
              ]}
            >
              <Modal.Section>
                <DataTable
                  columnContentTypes={tableData.contentTypes as ColumnContentType[]}
                  headings={tableData.headings as React.ReactNode[]}
                  rows={tableData.rows as TableData[][]}
                />
              </Modal.Section>
            </Modal>
          </>
        )}
      </div>
    </CustomCard>
  );
};

export default TopCourierByTransitDay;
