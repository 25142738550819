import { Collapsible, Divider, Icon, Text, TextProps } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { ReactNode, memo } from 'react';
import { CollapsibleStyled } from './styled';

interface ContentCollapsibleProps {
  title: ReactNode;
  children: ReactNode;
  variant?: TextProps['variant'];
  isOpen: boolean;
  onToggle: () => void;
  paddingTitle?: string;
  paddingChildren?: string;
}

const ContentCollapsible = ({
  title,
  children,
  variant,
  isOpen,
  onToggle,
  paddingTitle = '0.5rem',
  paddingChildren = '1rem'
}: ContentCollapsibleProps) => {
  return (
    <CollapsibleStyled
      $isOpen={isOpen}
      $paddingTitle={paddingTitle}
      $paddingChildren={paddingChildren}
    >
      <div
        className="header"
        onClick={onToggle}
        aria-expanded={isOpen}
        aria-controls="basic-collapsible"
      >
        <Text as="h6" variant={variant || 'headingSm'}>
          {title}
        </Text>
        <div
          style={{
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'all 0.5s'
          }}
        >
          <Icon source={ChevronRightMinor} tone="base" />
        </div>
      </div>
      <Divider />
      <Collapsible
        expandOnPrint
        open={isOpen}
        id="basic-collapsible"
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
      >
        <div className="children">{children}</div>
      </Collapsible>
    </CollapsibleStyled>
  );
};

export default memo(ContentCollapsible);
