import { ProtectedRoute } from '@/components';
import { Environment, PATH } from '@/constants';
import { convertQueryStringToObject } from '@/helpers';
import AdminLayout from '@/layouts';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import getRouteList from './routes';

function AppRoutes() {
  const { search } = useLocation();
  const navigate = useNavigate();

  // redirect to dashboard in localhost
  useEffect(() => {
    if (process.env.REACT_APP_ENV === Environment.Local && search.includes('shop')) {
      const searchToObject = convertQueryStringToObject(search);
      if (searchToObject?.hmac || searchToObject?.api_key) {
        navigate(PATH.DASHBOARD);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={PATH.DASHBOARD} />} />

      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<AdminLayout />}>
          {getRouteList().map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to={PATH.DASHBOARD} />} />
    </Routes>
  );
}

export default AppRoutes;
