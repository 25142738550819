import store from '@/redux/store';
import { convertQueryStringToObject, getLocalStorageItem } from '.';
import { pathHaveTab } from '@/constants';

export function getAppParams<T extends object>(params?: T) {
  const {
    auth: { data },
  } = store.getState() as any;
  let initParams;
  const search = convertQueryStringToObject(window.location.search);
  if (search && search.shop) {
    initParams = { shop: search.shop, urlParams: JSON.stringify(search) };
  } else {
    initParams = { shop: data.token?.shop, urlParams: data.token?.urlParams };
  }

  if (params) {
    return { ...initParams, ...params };
  }
  return initParams;
}

export const getUrlParameterJson = (): string => {
  var search = window.location.search.substring(1);
  if (search !== '') {
    var urlParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    });
    return JSON.stringify(urlParams);
  }
  return '';
};

const urlParams = getUrlParameterJson();

export const CONSTANT = {
  isEmbedded: urlParams !== '' ? JSON.parse(urlParams).embedded === '1' : false,
};

export const getTabByPath = (path: string) => {
  const pathInfo = pathHaveTab.find((item) => item.path === path);

  if (pathInfo) {
    const { storeKey, tabKey } = pathInfo;
    return String(JSON.parse(getLocalStorageItem(storeKey) || '{}')?.[tabKey]).replace(/"/g, '');
  }
  return '';
};
