import { calcPercent, calcTotal, renderIconDirection } from '@/helpers';
import { useGetTrackingPageAnalyticsQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { Card, InlineGrid, SkeletonBodyText, Text } from '@shopify/polaris';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyledTrackingPageOverview } from './styled';

const TrackingPageOverview = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetTrackingPageAnalyticsQuery({
    fromDate: datePicker.start.toISOString(),
    toDate: datePicker.end.toISOString(),
  });

  const overViewConfig = useMemo(() => {
    if (data && data.data.pageView && data.data.marketingClick) {
      const { marketingClick, pageView } = data.data;
      const totalPageView = calcTotal(pageView.series);
      const totalMarketingClick = calcTotal(marketingClick.series);
      return [
        {
          title: 'Page View',
          total: totalPageView,
          lastPeriod: pageView.lastPeriod,
          increase: totalPageView - pageView.lastPeriod,
        },
        {
          title: 'Marketing Click',
          total: totalMarketingClick,
          lastPeriod: marketingClick.lastPeriod,
          increase: totalMarketingClick - marketingClick.lastPeriod,
        },
        {
          title: 'Click through rate',
          total: calcPercent(totalMarketingClick, totalPageView),
          lastPeriod: calcPercent(marketingClick.lastPeriod, pageView.lastPeriod),
          increase: calcPercent(totalMarketingClick, totalPageView) - calcPercent(marketingClick.lastPeriod, pageView.lastPeriod),
          suffixes: '%',
        },
      ];
    }
    return [
      {
        title: 'Page View',
        total: 0,
        lastPeriod: 0,
        increase: 0,
      },
      {
        title: 'Marketing Click',
        total: 0,
        lastPeriod: 0,
        increase: 0,
      },
      {
        title: 'Click through rate',
        total: 0,
        lastPeriod: 0,
        increase: 0,
        suffixes: '%',
      },
    ];
  }, [data]);

  return (
    <StyledTrackingPageOverview>
      {isLoading ? (
        <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap="400">
          <Card>
            <SkeletonBodyText />
          </Card>
          <Card>
            <SkeletonBodyText />
          </Card>
          <Card>
            <SkeletonBodyText />
          </Card>
        </InlineGrid>
      ) : (
        <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap="400">
          {overViewConfig.map((item, index) => {
            return (
              <Card key={index}>
                <div className="analysis-container">
                  <Text as="h6" variant="headingMd">
                    {item.title}
                  </Text>
                  <div className="d-flex">
                    <Text as="h6" variant="headingMd">
                      {item.total}
                      {item.suffixes}
                    </Text>
                    {renderIconDirection(item.increase)}
                    <Text as="h6" variant="headingMd">
                      {Math.abs(item.increase)}
                      {item.suffixes}
                    </Text>
                  </div>
                  <Text as="h6" variant="headingSm">
                    Last period : {item.lastPeriod}
                    {item.suffixes}
                  </Text>
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      )}
    </StyledTrackingPageOverview>
  );
};

export default TrackingPageOverview;
