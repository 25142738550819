import { isLockFeature, snakeToStartCase } from '@/helpers';
import smsSlice, { tableSmsHistorySelector, titleDatePickerSelector } from '@/redux/features/sms.slice';
import { Badge, SkeletonBodyText, IndexTable } from '@shopify/polaris';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSmsHistoryQuery } from '@/redux/api/api.caller';
import { ISmsHistoryItem } from '@/types/notification';
import { tokenSelector } from '@/redux/features/auth.slice';
import { LINK, OrderStatusPalette } from '@/constants';
import { CustomBadge, CustomCard, CustomTable, ExternalLink } from '@/components';
import DatePicker from '@/components/DatePicker';
import { Tone } from '@shopify/polaris/build/ts/src/components/Badge';
import { StyledSmsHistory } from './styled';
import SmsHistoryFilter from '../SmsHistoryFilter';
import { sendStatusConfig } from './config';
import SmsHistoryDetail from '../SmsHistoryDetail';
import { useDebounce } from '@/hooks';
import { AccountPlan } from '@/constants/enum';

const SmsHistoryTable = () => {
  const dispatch = useDispatch();
  const isLock = isLockFeature([AccountPlan.Starter]);
  const table = useSelector(tableSmsHistorySelector);
  const { shop } = useSelector(tokenSelector);
  const titleDatePicker = useSelector(titleDatePickerSelector);
  const debouncedSearch = useDebounce<string>(table.search);
  const { data, isFetching } = useGetSmsHistoryQuery(
    {
      fromDate: table.datePicker.start.toISOString(),
      page: table.currentPage,
      perPage: Number(table.perPage),
      toDate: table.datePicker.end.toISOString(),
      search: debouncedSearch,
      sortBy: table.sortBy,
      sortDirection: table.sort,
      trackingStatus: table.trackingStatus === 'all' ? undefined : table.trackingStatus,
    },
    { skip: isLock },
  );
  const [modalState, setModalState] = useState<{ isOpen: boolean; item: ISmsHistoryItem | null }>({
    isOpen: false,
    item: null,
  });

  const handleDatePicker = (start: Date, end: Date) => {
    dispatch(smsSlice.actions.handleTable({ ...table, datePicker: { start, end } }));
  };

  const handleTitleBtn = (titleBtn: string) => {
    dispatch(smsSlice.actions.handleTitleDatePicker(titleBtn));
  };

  const handleCloseModal = () => {
    setModalState({
      isOpen: false,
      item: null,
    });
  };
  const handleOpenModal = (item: ISmsHistoryItem) => () => {
    setModalState({
      isOpen: true,
      item,
    });
  };

  const handleClickPaginationBtn = (type: 'next' | 'prev') => {
    dispatch(
      smsSlice.actions.handleTable({
        ...table,
        currentPage: type === 'prev' ? table.currentPage - 1 : table.currentPage + 1,
      }),
    );
  };

  const rowMarkup = useMemo(() => {
    if (data && data.data && data.data.result) {
      return data.data.result.map((sms, index) => {
        const sendStatus = sendStatusConfig.find((item) => item.status === sms.sendStatus);

        return (
          <IndexTable.Row id={sms.id.toString()} key={sms.id} position={index} rowType="data" onClick={handleOpenModal(sms)}>
            <IndexTable.Cell>
              <ExternalLink to={LINK.ORDER_DETAIL(shop, sms.orderId)}>{sms.orderId}</ExternalLink>
            </IndexTable.Cell>
            <IndexTable.Cell>{sms.receiver}</IndexTable.Cell>
            <IndexTable.Cell>{sms.receiverContact}</IndexTable.Cell>
            <IndexTable.Cell>
              <ExternalLink to={LINK.TRACKING_ORDER_NOW(shop, sms.trackingNumber)}>{sms.trackingNumber}</ExternalLink>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {sms.trackingStatus ? (
                <CustomBadge
                  summary={snakeToStartCase(sms.trackingStatus)}
                  status={OrderStatusPalette[sms.trackingStatus.toUpperCase() as keyof typeof OrderStatusPalette]}
                />
              ) : (
                '---'
              )}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Badge tone={sendStatus?.badge as Tone}>{snakeToStartCase(sendStatus?.status || '')}</Badge>
            </IndexTable.Cell>
            <IndexTable.Cell>{sms.pricingClient ? `$${sms.pricingClient}` : '---'}</IndexTable.Cell>
            <IndexTable.Cell>{dayjs(sms.createdAt).format('MMM DD, YYYY hh:mm A')}</IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <CustomCard
      title="SMS History"
      actionGroup={[
        {
          content: (
            <DatePicker
              titleBtn={titleDatePicker}
              setTitleBtn={handleTitleBtn}
              onSave={handleDatePicker}
              start={table.datePicker.start}
              end={table.datePicker.end}
            />
          ),
        },
      ]}
    >
      {data || isLock ? (
        <StyledSmsHistory>
          <CustomTable
            filter={<SmsHistoryFilter />}
            tableProps={{
              resourceName: {
                singular: 'SMS history',
                plural: 'SMS histories',
              },
              itemCount: data?.data.result.length || 0,
              headings: [
                { title: 'Order ID' },
                { title: 'Receiver' },
                { title: 'Recipient Phone' },
                { title: 'Tracking no' },
                { title: 'Tracking status' },
                { title: 'Send status' },
                { title: 'Cost' },
                { title: 'Send time' },
              ],
              selectable: true,
            }}
            skeletonLines={9}
            isLoading={isFetching}
            page={table.currentPage}
            pageSize={table.perPage}
            totalResult={data?.data.totalResult || 0}
            paginationProps={{
              onPrevious: () => handleClickPaginationBtn('prev'),
              onNext: () => handleClickPaginationBtn('next'),
            }}
          >
            {rowMarkup}
          </CustomTable>
        </StyledSmsHistory>
      ) : (
        <SkeletonBodyText lines={23} />
      )}

      <SmsHistoryDetail isOpen={modalState.isOpen} data={modalState.item} onClose={handleCloseModal} />
    </CustomCard>
  );
};

export default SmsHistoryTable;
