import { Alignment, WidgetSize } from './enum';

export const courierNameOptions = [
  { label: 'Custom', value: 'custom' },
  { label: 'DHL', value: 'DHL' },
  { label: 'Ali Express', value: 'Ali Express' },
  { label: 'Australia Post', value: 'Australia Post' },
  { label: 'Bpost', value: 'Bpost' },
  { label: 'Russian Post', value: 'Russian Post' },
  { label: 'Sweden Posten', value: 'Sweden Posten' },
  { label: 'Shippit', value: 'Shippit' },
  { label: 'Antilles Post', value: 'Antilles Post' },
  { label: 'Azerbaijan Post', value: 'Azerbaijan Post' },
  { label: 'Belarus Post', value: 'Belarus Post' },
  { label: 'YANWEN', value: 'YANWEN' },
  { label: 'Kerry eCommerce', value: 'Kerry eCommerce' },
  { label: 'Weaship', value: 'Weaship' },
  { label: 'DHL Global Forwarding', value: 'DHL Global Forwarding' },
  { label: 'Pitney Bowes', value: 'Pitney Bowes' },
  { label: 'EWS Profit Fields', value: 'EWS Profit Fields' },
  { label: 'UBon Express', value: 'UBon Express' },
  { label: 'Chukou1 Logistics', value: 'Chukou1 Logistics' },
  { label: 'Hermesworld', value: 'Hermesworld' },
  { label: 'Hermes Germany', value: 'Hermes Germany' },
  { label: 'New Zealand Post', value: 'New Zealand Post' },
  { label: 'FBA ES Swiship', value: 'FBA ES Swiship' },
  { label: '99minutos', value: '99minutos' },
  { label: 'Fedex', value: 'Fedex' },
  { label: 'USPS', value: 'USPS' },
  { label: 'TNT', value: 'TNT' },
  { label: 'DHL Express', value: 'DHL Express' },
  { label: 'China Post', value: 'China Post' },
  { label: 'DHL ECommerce', value: 'DHL ECommerce' },
  { label: 'DHL Global Mail Asia', value: 'DHL Global Mail Asia' },
  { label: 'Canada Post', value: 'Canada Post' },
  { label: 'CJ Packet', value: 'CJ Packet' },
  { label: 'Couriers Please express', value: 'Couriers Please express' },
  { label: 'DPD', value: 'DPD' },
  { label: 'Sendle', value: 'Sendle' },
  { label: 'Aramex', value: 'Aramex' },
  { label: 'China EMS (ePacket)', value: 'China EMS (ePacket)' },
  { label: 'UBI Smart Parcel', value: 'UBI Smart Parcel' },
  {
    label: 'Fastway New Zealand (Aramex)',
    value: 'Fastway New Zealand (Aramex)',
  },
  {
    label: 'Fastway Australia (Aramex)',
    value: 'Fastway Australia (Aramex)',
  },
  {
    label: 'Fastway Ireland (Aramex)',
    value: 'Fastway Ireland (Aramex)',
  },
  { label: 'Nexive', value: 'Nexive' },
  { label: 'Colissimo', value: 'Colissimo' },
  { label: 'NZ Post', value: 'NZ Post' },
  { label: 'Bring', value: 'Bring' },
  { label: 'Posten Norge', value: 'Posten Norge' },
  { label: 'Oman Post', value: 'Oman Post' },
  {
    label: 'Overseas Territory US Post',
    value: 'Overseas Territory US Post',
  },
  { label: 'Asendia USA', value: 'Asendia USA' },
  { label: 'Asendia UK', value: 'Asendia UK' },
  { label: 'Asendia Germany', value: 'Asendia Germany' },
];

export const optionsPerPage = [
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '75', value: '75' },
  { label: '100', value: '100' },
];

export const optionsRating = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const orderLookupOptions = {
  size: [
    {
      label: 'Large',
      value: WidgetSize.Large,
    },
    {
      label: 'Medium',
      value: WidgetSize.Medium,
    },
    {
      label: 'Small',
      value: WidgetSize.Small,
    },
  ],

  alignment: [
    {
      label: 'Center',
      value: Alignment.Center,
    },
    {
      label: 'Left',
      value: Alignment.Left,
    },
    {
      label: 'Right',
      value: Alignment.Right,
    },
  ],
};
