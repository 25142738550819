import ShipmentInfo from './components/ShipmentInfo';
import { LINK, PATH } from '@/constants';
import { capitalizeFirstLetter, getDayBetween } from '@/helpers';
import dayjs from 'dayjs';
import TimeAgo from 'react-timeago';
import InfoCard from './components/InfoCard';
import { useGetShipmentDetailQuery } from '@/redux/api/api.caller';
import { useParams } from 'react-router-dom';
import { calcSubtotalPrice } from '../../config';
import Layout from '@/components/Layout';
import { Column, Row } from '@/components';
import { Link, useBreakpoints } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { tokenSelector } from '@/redux/features/auth.slice';

const ShipmentDetail = () => {
  const { id } = useParams();
  const { shop } = useSelector(tokenSelector);
  const { data, isLoading } = useGetShipmentDetailQuery({ id: Number(id) }, { skip: !id });
  const shipment = data?.data?.shipment;
  const customer = data?.data?.shipment.customer;
  const { lgDown } = useBreakpoints();

  const shipmentInfoData = [
    {
      key: 'Courier',
      value: shipment?.trackingCompany,
    },
    {
      key: 'Tracking number',
      value: <Link url={LINK.TRACKING_ORDER_NOW(shop, shipment?.trackingNumber || '')}>{shipment?.trackingNumber}</Link>,
    },
    {
      key: 'Status',
      value: capitalizeFirstLetter((shipment?.trackingSummary || '').split('_').join(' ')),
    },
    {
      key: 'Customer waiting',
      value: `${getDayBetween(shipment?.createdAt || '', shipment?.lastUpdatedAt || '')} day`,
    },
    {
      key: 'Last carrier check',
      value: <TimeAgo date={shipment?.lastUpdatedAt || ''} />,
    },
  ];

  const orderInfoData = [
    {
      key: 'Order number',
      value: shipment?.name,
    },
    {
      key: 'Email',
      value: shipment?.email,
    },
    {
      key: 'Created at',
      value: dayjs(shipment?.createdAt).format('MMM DD, YYYY'),
    },
    {
      key: 'Number',
      value: shipment?.name,
    },
    {
      key: 'Shopify Order Id',
      value: shipment?.orderId,
    },
    {
      key: 'Subtotal price',
      value: calcSubtotalPrice(shipment?.lineItems || [], shipment?.currency),
    },
    {
      key: 'Order Note',
      value: shipment?.note,
    },
  ];

  const customerData = [
    {
      key: 'Name',
      value: `${customer?.first_name} ${customer?.last_name}`,
    },
    {
      key: 'Phone',
      value: customer?.phone || '---',
    },
    {
      key: 'Email',
      value: customer?.email,
    },
  ];

  const shippingAddressData = [
    {
      key: 'Address 1',
      value: shipment?.shippingAddress.address1,
    },
    {
      key: 'Address 2',
      value: shipment?.shippingAddress.address2,
    },
    {
      key: 'City',
      value: shipment?.shippingAddress.city,
    },
    {
      key: 'Province',
      value: shipment?.shippingAddress.province,
    },

    {
      key: 'Country',
      value: shipment?.shippingAddress.country,
    },
  ];

  return (
    <Layout backAction={{ url: PATH.SHIPMENT }} title={shipment?.trackingNumber || 'Shipment details'}>
      <Row column={lgDown ? 2 : 3}>
        <Column span={2}>
          <ShipmentInfo />
        </Column>
        <Column span={lgDown ? 2 : 1}>
          <InfoCard title="SHIPMENT INFORMATION" data={shipmentInfoData} loading={isLoading} />
          <div className="mt-16" />
          <InfoCard title="ORDER INFORMATION" data={orderInfoData} loading={isLoading} />
          <div className="mt-16" />
          <InfoCard title="CUSTOMER" data={customerData} loading={isLoading} />
          <div className="mt-16" />
          <InfoCard title="SHIPPING ADDRESS" data={shippingAddressData} loading={isLoading} />
        </Column>
      </Row>
    </Layout>
  );
};

export default ShipmentDetail;
