import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { InlineGrid, RadioButton } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import UploadAsset from './UploadAsset';
import { ChooseThemeStyled } from './styled';
import { CustomCard } from '@/components';
import { checkDirtyField, splitObjects } from '@/helpers';

const ChooseTheme = () => {
  const dispatch = useDispatch();
  const { fieldsChange, value: data, oldValue } = useSelector(trackingPageSelector);
  const { lookAndFeel } = data;
  const { lookAndFeel: oldLookAndFeel } = oldValue;

  const handleChange = (_: boolean, chooseTheme: string) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...lookAndFeel,
          chooseTheme,
        },
      }),
    );

    const { dirtyField: themeField } = checkDirtyField(splitObjects(oldLookAndFeel, 0, 1), {
      ...splitObjects(lookAndFeel, 0, 1),
      chooseTheme,
    });

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          !themeField && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                theme: themeField?.chooseTheme ? +themeField.chooseTheme : undefined,
              },
      }),
    );
  };

  return (
    <ChooseThemeStyled>
      <CustomCard variantTitle="headingSm" title="Theme">
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, xs: 2, sm: 2 }} gap="400">
          <RadioButton
            label={
              <div className="center">
                <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/theme_1.jpg`} alt="_blank" loading="lazy" />
              </div>
            }
            checked={lookAndFeel.chooseTheme === '1'}
            id="1"
            onChange={handleChange}
          />
          <RadioButton
            label={
              <div className="center">
                <img src={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/trackingpage/theme_2.jpg`} alt="_blank" />
              </div>
            }
            id="2"
            checked={lookAndFeel.chooseTheme === '2'}
            onChange={handleChange}
          />
        </InlineGrid>
      </CustomCard>
      {lookAndFeel.chooseTheme === '2' ? <UploadAsset /> : null}
    </ChooseThemeStyled>
  );
};

export default ChooseTheme;
