import { CollapsibleTheme } from '@/constants/trackingPage';
import ChooseTheme from './components/ChooseTheme';
import Style from './components/Style';
import LookupOption from './components/LookupOption';
import Content from './components/Content';
import AdditionalContent from './components/AdditionalContent';

export const themeCollapsibleConfig = [
  {
    id: CollapsibleTheme.ChooseTheme,
    title: 'Choose Theme',
    component: <ChooseTheme />,
  },
  {
    id: CollapsibleTheme.LookupOptions,
    title: 'Lookup Options',
    component: <LookupOption />,
  },
  {
    id: CollapsibleTheme.Content,
    title: 'Content',
    component: <Content />,
  },
  {
    id: CollapsibleTheme.Style,
    title: 'Style',
    component: <Style />,
  },
  {
    id: CollapsibleTheme.HTMLAndCSS,
    title: 'HTML & CSS',
    component: <AdditionalContent />,
  },
];
