import { ITopNavTabs } from '@/types/tab';

export enum DeviceType {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export const deviceTypeConfig: Array<ITopNavTabs> = [
  {
    id: DeviceType.Desktop,
    content: 'Desktop',
  },
  {
    id: DeviceType.Mobile,
    content: 'Mobile',
  },
];
