import styled from 'styled-components';

export const StyledShipmentByStatus = styled.div`
  .chart-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 550px;
    .content-container {
      height: 100%;
      .shipment-info-container {
        padding: 0 36px;
        .shipment-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-block: 0.125rem;
        }
      }
    }
  }
`;
