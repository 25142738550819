import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledImage = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0);

  .preview-text {
    display: none;
  }

  :hover {
    cursor: pointer;

    .preview-text {
      color: white;
      background-color: ${BgColorPalette.OVERLAY};
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;

      .Polaris-Icon {
        svg {
          fill: white;
        }
      }
    }
  }
`;

export const StyledImagePreview = styled.div<{ isOpen: boolean }>`
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  width: ${({ isOpen }) => (isOpen ? '100vw' : '0')};
  height: ${({ isOpen }) => (isOpen ? '100vh' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  bottom: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  left: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  right: ${({ isOpen }) => (isOpen ? '0' : '50%')};
  transition: all 0.4s ease;

  .preview-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${BgColorPalette.OVERLAY};

    img {
      max-width: 90%;
      max-height: 90%;
    }
  }

  .preview-header {
    position: absolute;
    inset: 0;
    height: 3rem;
    padding: 0 2rem;
    display: flex;
    justify-content: end;
    align-items: center;

    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
      background-color: ${BgColorPalette.OVERLAY};
      border-radius: 50%;
      opacity: 0.7;

      .Polaris-Icon {
        svg {
          fill: white;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
`;
