import { BgColorPalette } from '@/constants';
import { calcPercent, calcTotal } from '@/helpers';
import { useGetNPSAnalyticQuery } from '@/redux/api/api.caller';
import { datePickerSelector } from '@/redux/features/analytics.slice';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyledNPSReportOverview } from './styled';
import { BlockStack, Card, InlineGrid, SkeletonBodyText, Text } from '@shopify/polaris';

const NPSReportOverview = () => {
  const datePicker = useSelector(datePickerSelector);
  const { data, isLoading } = useGetNPSAnalyticQuery({
    toDate: datePicker.end.toISOString(),
    fromDate: datePicker.start.toISOString(),
  });

  const analyticsConfig = useMemo(() => {
    if (data) {
      const { detractorsSeries, promotersSeries, passivesSeries, total } = data.data;
      const promotersPercent = calcPercent(calcTotal(promotersSeries), total);
      const detractorsPercent = calcPercent(calcTotal(detractorsSeries), total);
      const passivePercent = calcPercent(calcTotal(passivesSeries), total);
      return [
        {
          title: 'NET PROMOTER SCORE',
          value: promotersPercent - detractorsPercent,
          color: `${BgColorPalette.INFO}`,
        },
        {
          title: 'PROMOTERS',
          value: promotersPercent,
          color: `${BgColorPalette.SUCCESS}`,
        },
        {
          title: 'PASSIVES',
          value: passivePercent,
          color: `${BgColorPalette.WARNING}`,
        },
        {
          title: 'DETRACTORS',
          value: detractorsPercent,
          color: `${BgColorPalette.CRITICAL}`,
        },
      ];
    }
    return [];
  }, [data]);

  const totalRespondent = useMemo(() => {
    if (data) {
      return data.data.total;
    }
    return 0;
  }, [data]);

  return (
    <StyledNPSReportOverview>
      <BlockStack gap="400">
        <Text as="h6" variant="headingMd">
          Total Respondent : {totalRespondent}
        </Text>
        {isLoading ? (
          <InlineGrid columns={{ xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }} gap="400">
            {new Array(4).fill(0).map((_, index) => {
              return (
                <Card key={index}>
                  <SkeletonBodyText />
                </Card>
              );
            })}
          </InlineGrid>
        ) : (
          <InlineGrid columns={{ xl: 4, lg: 4, md: 2, sm: 1, xs: 1 }} gap="400">
            {analyticsConfig.map((item, index) => {
              return (
                <div className="analytics-container" style={{ borderBottom: `0.75rem solid ${item.color}` }} key={index}>
                  <Text as="h6" variant="headingMd">
                    {item.title}
                  </Text>
                  <div className="mt-8">
                    <Text as="h6" variant="headingMd">
                      {item.value.toFixed(1)}%
                    </Text>
                  </div>
                </div>
              );
            })}
          </InlineGrid>
        )}
      </BlockStack>
    </StyledNPSReportOverview>
  );
};

export default NPSReportOverview;
