import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IDatePicker } from '@/types';
import { PageSize, SortDirection } from '@/constants/enum';
import { getSomeDaysAgo } from '@/helpers';

export interface IHistorySmsTable {
  search: string;
  trackingStatus: string;
  perPage: PageSize;
  currentPage: number;
  sort: SortDirection;
  sortBy: string;
  datePicker: IDatePicker;
}

export interface ISmsState {
  table: IHistorySmsTable;
  titleDatePicker: string;
  tabSelected: number;
  orderStatusTabSelected: number;
}

// Define the initial state using that type
const initialState: ISmsState = {
  tabSelected: 0,
  orderStatusTabSelected: 0,
  table: {
    search: '',
    trackingStatus: 'all',
    perPage: PageSize.Five,
    currentPage: 1,
    sort: SortDirection.DESC,
    sortBy: 'updatedAt',
    datePicker: {
      end: new Date(),
      start: getSomeDaysAgo(30),
    },
  },
  titleDatePicker: 'Last 30 days',
};

export const smsSlice = createSlice({
  name: 'smsHistory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleTable: (state, action: PayloadAction<IHistorySmsTable>) => {
      state.table = action.payload;
    },
    handleTitleDatePicker: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
    handleTabSelected: (state, action: PayloadAction<number>) => {
      state.tabSelected = action.payload;
    },
    handleOrderStatusTabSelected: (state, action: PayloadAction<number>) => {
      state.orderStatusTabSelected = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tableSmsHistorySelector = createSelector(
  (state: RootState) => state.sms,
  (state) => state.table,
);

export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.sms,
  (state) => state.titleDatePicker,
);

export const tabSelectedSelector = createSelector(
  (state: RootState) => state.sms,
  (state) => state.tabSelected,
);
export const orderStatusTabSelectedSelector = createSelector(
  (state: RootState) => state.sms,
  (state) => state.orderStatusTabSelected,
);

export default smsSlice;
