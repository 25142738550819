import { BgColorPalette } from '@/constants';
import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledUpdateTheme = styled.div`
  // hidden border of card when preview image
  .Polaris-ShadowBevel {
    --pc-shadow-bevel-z-index: 0 !important;
  }

  .code-template {
    font-size: 0.75rem;
    background-color: ${BgColorPalette.SECONDARY};
    padding: 0.5rem;

    .template-layout {
      display: flex;
      gap: 1rem;
    }
  }

  .content {
    padding-block: 1rem;
    .code-template {
      .code-content {
        margin-left: 2rem;
      }
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.SM}) {
    .content {
      .code-template {
        font-size: 0.625rem;
      }
    }
  }
`;
