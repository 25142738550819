import styled from 'styled-components';

export const StyledStatisticCard = styled.div`
  .Polaris-Text--root.Polaris-Text--headingMd {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    .title-info {
      cursor: pointer;
    }
  }

  .Polaris-Text--root.Polaris-Text--headingLg {
    text-align: center;
    margin-block: 0.25rem;
  }
`;
