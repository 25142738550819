import { CustomizationPreviewBodyTabs, TrackingPagePreviewFocus } from '@/constants/trackingPage';
import { IPreviewBodyTabs } from '@/types/tab';

export const previewBodyTabsConfig: IPreviewBodyTabs[] = [
  {
    id: CustomizationPreviewBodyTabs.OrderId,
  },
  {
    id: CustomizationPreviewBodyTabs.TrackingNumber,
  },
];

export const previewBodyFormInput = [
  {
    htmlFor: 'input-order-name',
    labelFocus: TrackingPagePreviewFocus.Order,
    inputFocus: TrackingPagePreviewFocus.PlaceHolderOrder,
    valueLabel: 'orderId',
    valueInput: 'placeholderOrderId',
  },
  {
    htmlFor: 'input-email',
    labelFocus: TrackingPagePreviewFocus.Email,
    inputFocus: TrackingPagePreviewFocus.PlaceholderEmail,
    valueLabel: 'email',
    valueInput: 'placeholderEmail',
  },
  {
    htmlFor: 'input-tracking-number',
    labelFocus: TrackingPagePreviewFocus.TrackingNumber,
    inputFocus: TrackingPagePreviewFocus.PlaceholderTrackingNumber,
    valueLabel: 'trackingNumber',
    valueInput: 'placeholderTrackingNumber',
  },
];

export const previewBodyImage = [
  {
    key: 'asset1',
    imageFocus: TrackingPagePreviewFocus.Asset1,
    height: '49%',
  },
  {
    key: 'asset2',
    imageFocus: TrackingPagePreviewFocus.Asset2,
    height: '49%',
  },
  {
    key: 'asset3',
    imageFocus: TrackingPagePreviewFocus.Asset3,
    height: '100%',
  },
];
