export const sendStatusConfig = [
  {
    status: 'delivered',
    badge: 'success',
  },
  {
    status: 'sent',
    badge: 'info',
  },
  {
    status: 'queued',
    badge: 'attention',
  },
  {
    status: 'failed',
    badge: 'critical',
  },
  {
    status: 'over_quota',
    badge: 'warning',
  },
  {
    status: 'undelivered',
    badge: '',
  },
];
