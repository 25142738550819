import { Modal } from '@shopify/polaris';
import { memo } from 'react';

interface ChristmasModalProps {
  isOpen: boolean;
  onClose: () => void;
  start: string;
  end: string;
}

const ChristmasModal = ({ isOpen, onClose, start, end }: ChristmasModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="30-Day free trial of advanced features"
      primaryAction={{
        content: "I've got it",
        onAction: onClose,
      }}
    >
      <Modal.Section>
        Congratulations! You've completed the 30-day free trial of the 3 features: Estimated delivery date, Blacklisting, and
        Advanced translation. The effective start date of the offer is <strong>{start}</strong>. The trial period will end on{' '}
        <strong>{end}</strong>
      </Modal.Section>
    </Modal>
  );
};

export default memo(ChristmasModal);
