import { StyledCrossSellReturnBanner } from './styled';
import { Box, CalloutCard, List } from '@shopify/polaris';
import { LINK } from '@/constants';

const CrossSellReturnBanner = () => {
  return (
    <StyledCrossSellReturnBanner>
      <CalloutCard
        title={
          <div className="banner-title">
            <img src={LINK.RETURN_DRIVE_LOGO} alt="return_drive_logo" />
            <div className="banner-title-text">
              <span>Synctrack</span>
              <br />
              <span>Returns & Exchanges</span>
            </div>
          </div>
        }
        illustration={LINK.RETURN_DRIVE_START_APP}
        primaryAction={{
          content: 'Start Returns app for free',
          url: LINK.RETURN_DRIVE_LINK_CROSS,
        }}
      >
        <div className="banner-content">
          <Box paddingBlockStart={'050'} paddingBlockEnd={'050'}>
            <span style={{ fontSize: '12.5px' }}>Simplify returns and exchanges hassle-free with just a few tap</span>
          </Box>
          <div className="banner-content-item">
            <List>
              <List.Item>50% discount on Returns app </List.Item>
              <List.Item>20% discount on Order Tracking app</List.Item>
            </List>
          </div>
        </div>
      </CalloutCard>
    </StyledCrossSellReturnBanner>
  );
};

export default CrossSellReturnBanner;
