import { Button, ButtonGroup, FullscreenBar, Modal, Text, useBreakpoints } from '@shopify/polaris';
import { StyledLocalConfiguration } from './styled';
import CustomToast from '@/components/Toast';
import { memo, useEffect, useState } from 'react';
import CustomizeContent from '../CustomizeContent';
import PreviewContent from '../PreviewContent';
import { isLockFeature, isUndefinedArray } from '@/helpers';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { languageSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/constants';
import {
  useGetTrackingPageQuery,
  useGetTranslationQuery,
  useLazyGetTranslationQuery,
  useUpdateTrackingPageMutation,
  useUpdateTranslationMutation,
} from '@/redux/api/api.caller';
import { AccountPlan, Language, ProductRecommendType, ProgressBar, ShippingInfo } from '@/constants/enum';
import { accountSelector } from '@/redux/features/plan.slice';
import { ITranslation } from '@/types/trackingpage';
import { hexToHSB } from '@/helpers/color';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';

interface ILocalConfigurationProps {
  onExitFullscreen?: () => void;
}

const LocalConfiguration = ({ onExitFullscreen }: ILocalConfigurationProps) => {
  const { mdDown } = useBreakpoints();
  const [apiError, setApiError] = useState<string>('');
  const trackingPage = useSelector(trackingPageSelector);
  const languages = useSelector(languageSelector);
  const isChangedField =
    !isUndefinedArray(Object.values(trackingPage.fieldsChange)) || !isUndefinedArray(Object.values(languages.fieldsChange));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getTrackingPage = useGetTrackingPageQuery();
  const [getTranslationLazy] = useLazyGetTranslationQuery();
  const getTranslation = useGetTranslationQuery(
    { language: languages.languageConfig.language },
    { skip: !languages.isOpenChooseLanguage },
  );
  const [updateTrackingPage, { isLoading }] = useUpdateTrackingPageMutation();
  const [updateTranslation, { isLoading: isLoadingUpdateTranslation }] = useUpdateTranslationMutation();
  const isLock = isLockFeature([AccountPlan.Starter]);
  const plan = useSelector(accountSelector);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);

  const handleToggleModal = (value: boolean) => {
    setOpenModal(value);
  };

  const handleDiscard = () => {
    handleToggleModal(false);
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...trackingPage.oldValue,
      }),
    );
    dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
    dispatch(trackingPageSlice.actions.handleChangeValidation([]));
    dispatch(
      trackingPageSlice.actions.handleLanguageValue({
        ...languages.oldValue.value,
      }),
    );
    dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
    dispatch(trackingPageSlice.actions.handleLanguageConfig({ ...languages.oldValue.languageConfig }));
    dispatch(trackingPageSlice.actions.handleChangeValidationLanguage([]));
  };

  const handleBackToTrackingPage = () => {
    handleDiscard();
    navigate(PATH.TRACKING_PAGE);
    onExitFullscreen?.();
  };

  const handleClickBackBtn = () => {
    if (isChangedField) {
      return handleToggleModal(true);
    }
    handleBackToTrackingPage();
  };

  const handleLanguageResponse = (data: ITranslation, isSetLanguageConfig: boolean = true) => {
    const {
      trackingStatus,
      trackingForm,
      trackingHistory,
      progressBar,
      orderDetails,
      review,
      footer,
      googleTranslateExtension,
      googleTranslatePosition,
      language: languageData,
    } = data;
    const value = {
      footer: { privacy: footer.privacyPage, return: footer.returnPage, support: footer.support, term: footer.termPage },
      orderDetails: {
        shipmentItems: orderDetails.shipment_items,
        product: orderDetails.product,
        totalPrice: orderDetails.total_price,
        itemSingular: orderDetails.item_singular,
        itemPlural: orderDetails.item_plural,
      },
      progressBar,
      review: { buttonText: review.button_text, reviewTitle: review.review_title },
      trackingForm: {
        trackYourOrder: trackingForm.title,
        orderId: trackingForm.order_id,
        email: trackingForm.email,
        phoneNumber: trackingForm.phone,
        or: trackingForm.or,
        trackingNumber: trackingForm.tracking_number,
        track: trackingForm.button,
        placeholderTrackingNumber: trackingForm.plh_tracking_number,
        placeholderOrderId: trackingForm.plh_order_id,
        placeholderEmail: trackingForm.plh_email,
        placeholderPhoneNumber: trackingForm.plh_phone,
        orderNotFound: trackingForm.order_not_found,
        emptyEmail: trackingForm.empty_email,
        invalidEmail: trackingForm.invalid_email,
        emptyTrackingNumber: trackingForm.empty_tracking_number,
        emptyOrderName: trackingForm.empty_order_name,
        emptyPhoneNumber: trackingForm.empty_phone_number,
        orderDescription: trackingForm.order_description,
      },
      trackingHistory: {
        shipmentInformation: trackingHistory.shipment_information,
        shippingAddress: trackingHistory.shipping_address,
        trackingNumber: trackingHistory.tracking_number,
        shipmentHistory: trackingHistory.shipment_history,
        carrier: trackingHistory.carrier,
      },
      trackingStatus: {
        ordered: trackingStatus.ordered,
        informationReceived: trackingStatus.information_received,
        inTransit: trackingStatus.in_transit,
        outForDelivery: trackingStatus.out_for_delivery,
        delivered: trackingStatus.delivered,
        courierNotSupported: trackingStatus.courier_not_supported,
        expired: trackingStatus.expired,
        failedAttempt: trackingStatus.failed_attempt,
        exception: trackingStatus.exception,
        pending: trackingStatus.pending,
      },
    };
    dispatch(
      trackingPageSlice.actions.handleLanguageValue({
        ...value,
      }),
    );
    if (isSetLanguageConfig) {
      dispatch(
        trackingPageSlice.actions.handleLanguageConfig({
          language: languageData as Language,
          googleTranslateExtension,
          googleTranslatePosition,
        }),
      );
      dispatch(
        trackingPageSlice.actions.handleLanguageOldValue({
          ...languages.oldValue,
          value,
          languageConfig: {
            language: languageData as Language,
            googleTranslateExtension,
            googleTranslatePosition,
          },
        }),
      );
    }
  };

  const fetchLanguageData = async () => {
    const { data } = await getTranslationLazy({ language: languages.languageConfig.language });
    if (data) {
      handleLanguageResponse(data.data);
    }
  };

  const handleUpdateLanguage = () => updateTranslation(languages.fieldsChange);

  const handleUpdateTrackingPage = () => updateTrackingPage(trackingPage.fieldsChange);

  const handleSave = async () => {
    try {
      const trackingPageRes: any =
        !isUndefinedArray(Object.values(trackingPage.fieldsChange)) && (await handleUpdateTrackingPage());
      const languageRes: any = !isUndefinedArray(Object.values(languages.fieldsChange)) && (await handleUpdateLanguage());
      if (trackingPageRes?.data?.state === 1 && languageRes?.data?.state === 1) {
        setApiError('');
        fetchLanguageData();
        dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
        dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
        dispatch(commonSlice.actions.handleToast(handleToastMutation(trackingPageRes || languageRes)));
        return;
      }
      if (languageRes?.data?.state === 1) {
        setApiError('');
        fetchLanguageData();
        dispatch(trackingPageSlice.actions.handleLanguageFieldsChange({}));
        dispatch(commonSlice.actions.handleToast(handleToastMutation(languageRes)));
        return;
      }
      if (trackingPageRes?.data?.state === 1) {
        setApiError('');
        dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
        dispatch(commonSlice.actions.handleToast(handleToastMutation(trackingPageRes)));
        return;
      }
      setApiError(getErrorFromAPI(trackingPageRes || languageRes));
    } catch (e) {}
  };

  useEffect(() => {
    dispatch(trackingPageSlice.actions.handleTrackingPageIsloading(getTrackingPage.isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.isLoading]);

  useEffect(() => {
    const data = getTrackingPage.data?.data;
    if (data) {
      const { lookAndFeel, configuration } = data;
      const trackingPageValue = {
        lookAndFeel: {
          chooseTheme: lookAndFeel.general.theme.toString(),
          lookupOption: {
            method: lookAndFeel.trackingForm.showMethods,
            requireEmail: lookAndFeel.trackingForm.requireEmail,
          },
          content: {
            shippingInfo: lookAndFeel.general.show.split(',') as Array<ShippingInfo>,
            format: {
              dateFormat: lookAndFeel.general.dateFormat,
              timeFormat: lookAndFeel.general.timeFormat,
            },
            progressBar: lookAndFeel.displayStatusBar.split(',') as Array<ProgressBar>,
          },
          style: {
            btnRadius: lookAndFeel.general.buttonBorder,
            colorPicker: {
              btnColor: hexToHSB(lookAndFeel.general.buttonColor),
              btnNameColor: hexToHSB(lookAndFeel.general.buttonTextColor),
              mainColor: hexToHSB(lookAndFeel.general.mainColor),
              textColor: hexToHSB(lookAndFeel.general.textColor),
            },
          },
        },
        configuration: {
          notInfoOrder: {
            isNoInfo: configuration.customMessage.trackUrl.isActive,
            isNotFound: configuration.customMessage.isReplaceMessage,
            message: configuration.customMessage.replaceMessage,
          },
          customStatus: {
            isCustomStatus: configuration.customStatus.enabled,
            activeAfter: configuration.customStatus.timeActive.toString(),
            status: configuration.customStatus.icon.toString(),
            statusName: configuration.customStatus.label,
          },
          productRecommendation: {
            enable: configuration.productRecommend.isActive,
            title: isLock && !plan.isOldPaidUser ? '' : configuration.productRecommend.title,
            type: isLock && !plan.isOldPaidUser ? ProductRecommendType.AllProduct : configuration.productRecommend.type,
            value:
              configuration.productRecommend.type === ProductRecommendType.productType
                ? configuration.productRecommend.productType
                : configuration.productRecommend.tags,
          },
          review: {
            settings: {
              isEnableReview: configuration.reviewQuestions.isActive,
              isDisabledDisplayReview: configuration.reviewQuestions.reviewSettings.isActive,
              displayReviewOption: configuration.reviewQuestions.reviewSettings.show,
            },
            question_1: {
              display: configuration.reviewQuestions.question_1.isDisplay,
              value: configuration.reviewQuestions.question_1.title,
              oneStarTitle: configuration.reviewQuestions.question_1.iconStatusBad?.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_1.iconStatusGood.toString(),
              displayReasons: configuration.reviewQuestions.question_1.isDisplayReason,
              minReason: configuration.reviewQuestions.question_1.limitLabelRequired?.toString(),
              reasons: configuration.reviewQuestions.question_1.labelQuestion,
            },
            question_2: {
              display: configuration.reviewQuestions.question_2.isDisplay,
              value: configuration.reviewQuestions.question_2.title,
              oneStarTitle: configuration.reviewQuestions.question_2.iconStatusBad.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_2.iconStatusGood.toString(),
            },
            question_3: {
              display: configuration.reviewQuestions.question_3.isDisplay,
              value: configuration.reviewQuestions.question_3.title,
              oneStarTitle: configuration.reviewQuestions.question_3.iconStatusBad.toString(),
              fiveStarTitle: configuration.reviewQuestions.question_3.iconStatusGood.toString(),
            },
            additionalComment: {
              display: configuration.reviewQuestions.additionalComment.isDisplay,
              value: configuration.reviewQuestions.additionalComment.title,
            },
            thankMessage: {
              display: configuration.reviewQuestions.thankMsg.isDisplay,
              value: configuration.reviewQuestions.thankMsg.title,
            },
          },
          footerLink: {
            ...configuration.footerLinks.policy,
            ...configuration.footerLinks.social,
          },
          additionalContent: {
            ...configuration.additional,
          },
        },
      };

      // configuration
      dispatch(trackingPageSlice.actions.handleTrackingPageValue(trackingPageValue));
      dispatch(trackingPageSlice.actions.handleTrackingPageOldValue(trackingPageValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingPage.data?.data]);

  useEffect(() => {
    fetchLanguageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getTranslation.data) {
      handleLanguageResponse(getTranslation.data.data, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTranslation]);

  return (
    <StyledLocalConfiguration $isMdDown={mdDown}>
      <FullscreenBar onAction={handleClickBackBtn}>
        <div className="nav-container">
          <Text variant="headingLg" as="p">
            Tracking page
          </Text>
          <ButtonGroup>
            <Button onClick={handleDiscard} disabled={!isChangedField}>
              Discard
            </Button>
            <Button
              variant="primary"
              loading={isLoading || isLoadingUpdateTranslation}
              disabled={!!trackingPage.validation.length || !!languages.validation.length || !isChangedField}
              onClick={handleSave}
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </FullscreenBar>
      <div className="content-container">
        <CustomizeContent error={apiError} />
        <PreviewContent />
      </div>

      {isChangedField && (
        <Modal
          open={isOpenModal}
          onClose={() => handleToggleModal(false)}
          size="small"
          title="Discard changes?"
          primaryAction={{
            content: 'Leave page',
            onAction: handleBackToTrackingPage,

            destructive: true,
          }}
          secondaryActions={[
            {
              content: 'Stay',
              onAction: () => handleToggleModal(false),
            },
          ]}
        >
          <Modal.Section>Are you sure you want to discard all unsaved changes?</Modal.Section>
        </Modal>
      )}

      <CustomToast />
    </StyledLocalConfiguration>
  );
};

export default memo(LocalConfiguration);
