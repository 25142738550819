import { SVGProps } from 'react';

const UndeliveredIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10.5" cy="10.5" r="10.5" fill="rgba(197, 40, 12, 1)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5605 11.0001L14.7801 8.13814C15.0733 7.87748 15.0733 7.45616 14.7801 7.1955C14.4868 6.93483 14.0129 6.93483 13.7196 7.1955L10.5 10.0574L7.28038 7.1955C6.98715 6.93483 6.51317 6.93483 6.21993 7.1955C5.92669 7.45616 5.92669 7.87748 6.21993 8.13814L9.43954 11.0001L6.21993 13.862C5.92669 14.1227 5.92669 14.544 6.21993 14.8047C6.36617 14.9347 6.55816 15 6.75016 15C6.94215 15 7.13414 14.9347 7.28038 14.8047L10.5 11.9427L13.7196 14.8047C13.8659 14.9347 14.0579 15 14.2498 15C14.4418 15 14.6338 14.9347 14.7801 14.8047C15.0733 14.544 15.0733 14.1227 14.7801 13.862L11.5605 11.0001Z"
        fill="white"
      />
    </svg>
  );
};

export default UndeliveredIcon;
