import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSomeDaysAgo } from '@/helpers';
import { IDatePicker } from '@/types';
import { ClosedBanners } from '@/constants/closedBanners';
export interface IDashboardState {
  titleDatePicker: string;
  datePicker: IDatePicker;
  haveClosedBanner: boolean;
  haveClosedOverQuotaBanner: boolean;
  haveClosedRecommendedApps: boolean;
  haveClosedConstantFeaturedBanner: { [key in ClosedBanners]?: boolean };
}
const initialState: IDashboardState = {
  datePicker: {
    start: getSomeDaysAgo(30),
    end: new Date(),
  },
  titleDatePicker: 'Last 30 days',
  haveClosedBanner: false,
  haveClosedOverQuotaBanner: false,
  haveClosedRecommendedApps: false,
  haveClosedConstantFeaturedBanner: {},
};

const dashboardPageSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handleDatePicker: (state, action: PayloadAction<IDatePicker>) => {
      state.datePicker = action.payload;
    },
    handleTitleDatePicker: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
    handleCloseBanner: (state, action: PayloadAction<boolean>) => {
      state.haveClosedBanner = action.payload;
    },
    handleCloseOverQuotaBanner: (state, action: PayloadAction<boolean>) => {
      state.haveClosedOverQuotaBanner = action.payload;
    },
    handleCloseRecommendedApps: (state, action: PayloadAction<boolean>) => {
      state.haveClosedRecommendedApps = action.payload;
    },
    handleCloseConstantFeatureBanner: (state, action: PayloadAction<ClosedBanners>) => {
      state.haveClosedConstantFeaturedBanner[action.payload] = true;
    },
  },
});

export const datePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.datePicker,
);
export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleDatePicker,
);
export const haveClosedBannerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.haveClosedBanner,
);
export const haveClosedOverQuotaBannerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.haveClosedOverQuotaBanner,
);
export const haveClosedRecommendedAppsSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.haveClosedRecommendedApps,
);

export const haveClosedConstantFeatureBannerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.haveClosedConstantFeaturedBanner,
);

export default dashboardPageSlice;
