import { BlockStack, InlineGrid } from '@shopify/polaris';
import PageViewByTime from './components/PageViewByTime';
import TotalReview from './components/TotalReview';
import AdditionalComment from './components/AdditionalComment';

const ReviewDashboard = () => {
  return (
    <BlockStack gap="400">
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap="400">
        <PageViewByTime />
      </InlineGrid>
      <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} gap="400">
        <TotalReview />
        <AdditionalComment />
      </InlineGrid>
    </BlockStack>
  );
};

export default ReviewDashboard;
