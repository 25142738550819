import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Alignment, WidgetSize, WidgetTrackingForm } from '@/constants/enum';

interface ICourierMapping {
  courierList: Array<string>;
}

interface ITrackingLink {
  replaceCourierLink: boolean;
  linkDescription: string;
  addLinkToOrder: boolean;
}

export interface IOrderLookup {
  widgeTitle: string;
  widgetTracking: WidgetTrackingForm;
  emailRequired: boolean;
  btnLabel: string;
  btnBackgroundColor: string;
  btnTextColor: string;
  btnRadius: number;
  widgetSize: WidgetSize;
  alignment: Alignment;
  isShowTrackingResult: boolean;
}

interface IIntegration {
  isCollapse: boolean;
  isConnected: boolean;
  appID: { value: string; error: string };
  secretKey: { value: string; error: string; isShow: boolean };
}

export interface ISettingsState {
  tabSelected: string;
  courierMapping: ICourierMapping;
  trackingLink: ITrackingLink;
  orderLookup: IOrderLookup;
  integration: IIntegration;
}

// Define the initial state using that type
const initialState: ISettingsState = {
  tabSelected: 'courierMapping',
  courierMapping: {
    courierList: [],
  },
  trackingLink: {
    replaceCourierLink: false,
    linkDescription: '',
    addLinkToOrder: false,
  },
  orderLookup: {
    widgeTitle: '',
    widgetTracking: WidgetTrackingForm.Both,
    emailRequired: false,
    btnLabel: 'Track',
    btnBackgroundColor: '#EA3E34',
    btnTextColor: '#FFFFFF',
    btnRadius: 10,
    widgetSize: WidgetSize.Medium,
    alignment: Alignment.Center,
    isShowTrackingResult: false,
  },
  integration: {
    isCollapse: false,
    isConnected: false,
    appID: { value: '', error: '' },
    secretKey: { value: '', error: '', isShow: false },
  },
};

export const settingsSlice = createSlice({
  name: 'settings',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSelected: (state, action: PayloadAction<string>) => {
      state.tabSelected = action.payload;
    },
    handleCourierMapping: (state, action: PayloadAction<ICourierMapping>) => {
      state.courierMapping = action.payload;
    },
    handleTrackingLink: (state, action: PayloadAction<ITrackingLink>) => {
      state.trackingLink = action.payload;
    },
    handleOrderLookup: (state, action: PayloadAction<IOrderLookup>) => {
      state.orderLookup = action.payload;
    },
    handleChangeIntegration: (state, action: PayloadAction<IIntegration>) => {
      state.integration = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const tabSelectedSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.tabSelected,
);

export const courierMappingSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.courierMapping,
);

export const trackingLinkSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.trackingLink,
);

export const orderLookupSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.orderLookup,
);

export const integrationSelector = createSelector(
  (state: RootState) => state.settings,
  (state) => state.integration,
);

export default settingsSlice;
