import { BannerTemplate } from '@/components';
import { LINK, PATH } from '@/constants';
import { tokenSelector } from '@/redux/features/auth.slice';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { BlockStack, Button, ButtonGroup, Text } from '@shopify/polaris';
import { ViewMinor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface ILocalBanner {
  onFullScreen?: () => void;
}

const LocalBanner = ({ onFullScreen }: ILocalBanner) => {
  const { shop } = useSelector(tokenSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trackingPage = useSelector(trackingPageSelector);

  const handleCustomizeTrackingPage = () => {
    navigate(PATH.TRACKING_PAGE_CONFIGURATION);
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...trackingPage.oldValue,
      }),
    );
    dispatch(trackingPageSlice.actions.handleTrackingPageFieldsChange({}));
    dispatch(trackingPageSlice.actions.handleChangeValidation([]));
    onFullScreen?.();
  };

  return (
    <BannerTemplate src="https://megamind-assets.s3.us-west-1.amazonaws.com/returnsdrive/returns-page/customize_return_page.png">
      <BlockStack gap="400">
        <Text variant="headingSm" as="h6">
          Customize your store branded tracking page
        </Text>
        <Text fontWeight="regular" as="p">
          Building a branding tracking portal to fit with your brand look & feel
          <br />
          Provide consistent brand and buyer experience
        </Text>
        <ButtonGroup>
          <Button icon={ViewMinor} url={LINK.CUSTOMER_SHOP(shop)}>
            Preview
          </Button>
          <Button variant="primary" onClick={handleCustomizeTrackingPage}>
            Customize
          </Button>
        </ButtonGroup>
      </BlockStack>
    </BannerTemplate>
  );
};

export default memo(LocalBanner);
