import styled from 'styled-components';

export const StyledTrackingPageOverview = styled.div`
  .analysis-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .arrow-icon {
      display: flex;
      align-items: center;
      margin: 0px 0.25rem;
    }
  }
  .Polaris-InlineGrid {
    .Polaris-ShadowBevel {
      height: 100%;
    }
  }
`;
