import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IDatePicker } from '@/types';
import { getSomeDaysAgo } from '@/helpers';
import { AnalyticsTabs } from '@/constants';

interface IModal {
  isOpen: boolean;
  value: string;
}

export interface IAnalyticsState {
  selectedTab: AnalyticsTabs;
  datePicker: IDatePicker;
  titleDatePicker: string;
  modal: IModal;
}

const initialState: IAnalyticsState = {
  selectedTab: AnalyticsTabs.ShipmentReport,
  datePicker: {
    start: getSomeDaysAgo(30),
    end: new Date(),
  },
  titleDatePicker: 'Last 30 days',
  modal: {
    isOpen: false,
    value: '',
  },
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    handleChangeTab: (state, action: PayloadAction<AnalyticsTabs>) => {
      state.selectedTab = action.payload;
    },
    handleChangeDatePicker: (state, action: PayloadAction<IDatePicker>) => {
      state.datePicker = action.payload;
    },
    handleChangeTitleBtn: (state, action: PayloadAction<string>) => {
      state.titleDatePicker = action.payload;
    },
    handleChangeModal: (state, action: PayloadAction<IModal>) => {
      state.modal = action.payload;
    },
  },
});

export const analyticsTabSelectedSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => state.selectedTab,
);
export const datePickerSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => state.datePicker,
);
export const titleDatePickerSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => state.titleDatePicker,
);
export const modalSelector = createSelector(
  (state: RootState) => state.analytics,
  (state) => state.modal,
);


