import { BgColorPalette } from '@/constants';

export const netPromoterTooltip = [
  {
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
  },
  {
    backgroundColor: `${BgColorPalette.CRITICAL}`,
    width: '0.75rem',
    height: ' 0.75rem',
    borderRadius: '50%',
    marginRight: '0.25rem',
    display: 'inline-block',
  },
  {
    backgroundColor: `${BgColorPalette.WARNING}`,
    width: '0.75rem',
    height: ' 0.75rem',
    borderRadius: '50%',
    marginRight: '0.25rem',
    display: 'inline-block',
  },
  {
    backgroundColor: `${BgColorPalette.SUCCESS}`,
    width: '0.75rem',
    height: ' 0.75rem',
    borderRadius: '50%',
    marginRight: '0.25rem',
    display: 'inline-block',
  },
];
