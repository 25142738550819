import commonSlice from '@/redux/features/common.slice';
import { Icon, Tooltip } from '@shopify/polaris';
import { ClipboardMinor } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch } from 'react-redux';

interface CopyIconProps {
  copiedText: string;
}

const CopyIcon = ({ copiedText }: CopyIconProps) => {
  const dispatch = useDispatch();

  const handleCopy = () => {
    navigator.clipboard.writeText(copiedText);
    dispatch(commonSlice.actions.handleToast({ isOpen: true, content: 'Copied' }));
  };

  return (
    <div style={{ cursor: 'pointer' }} onClick={handleCopy}>
      <Tooltip content="Copy">
        <Icon source={ClipboardMinor} tone="base" />
      </Tooltip>
    </div>
  );
};

export default memo(CopyIcon);
