import { memo } from 'react';
import { BlockStack, Button, Icon, Link, Text } from '@shopify/polaris';
import { StarFilledMinor } from '@shopify/polaris-icons';
import { DiamondIcon } from '@/icons';
import { useSendGa4AdminAnalyticsMutation } from '@/redux/api/api.caller';
import { StyledAppInfoCard } from './styled';
import { Environment } from '@/constants';

export interface AppInfoCardProps {
  logo: string;
  name: string;
  isBfs: boolean;
  rating: string;
  plan: string;
  description: string;
  link: string;
  eventName: string;
}

const AppInfoCard = ({ logo, name, isBfs, rating, plan, description, link, eventName }: AppInfoCardProps) => {
  const [sendGa4Analytics] = useSendGa4AdminAnalyticsMutation();

  const handleClickCrossSellLink = () => {
    if (process.env.REACT_APP_ENV === Environment.Production && eventName) {
      sendGa4Analytics({ eventName });
    }
    window.open(link, '_blank');
  };

  return (
    <StyledAppInfoCard>
      <div className="app-container">
        <div>
          <img src={logo} alt={name} width={50} />
        </div>
        <BlockStack gap="100">
          <Link onClick={handleClickCrossSellLink} monochrome removeUnderline>
            <Text as="h6" variant="headingSm">
              {name}
            </Text>
          </Link>
          {isBfs && (
            <div className="bfs-badge">
              <DiamondIcon />
              <span>Built for Shopify</span>
            </div>
          )}
          <div style={{ lineHeight: 1.4 }}>{description}</div>
          <div className="review-info">
            <div>
              <Icon source={StarFilledMinor} tone="warning" />
            </div>
            <Text as="p" variant="bodySm">
              {`${rating} - ${plan}`}
            </Text>
          </div>
        </BlockStack>
      </div>
      <div className="mt-2">
        <Button fullWidth url={link}>
          Try for free
        </Button>
      </div>
    </StyledAppInfoCard>
  );
};

export default memo(AppInfoCard);
