import { ProgressBar, ShippingInfo } from '@/constants/enum';

interface ICheckboxShippingInfo {
  label: string;
  value: ShippingInfo;
}
interface ICheckboxProgressBar {
  label: string;
  value: ProgressBar;
}
export const shippingInfoConfig: Array<ICheckboxShippingInfo> = [
  {
    label: 'Progress bar',
    value: ShippingInfo.ProgressBar,
  },
  {
    label: 'Order Detail',
    value: ShippingInfo.OrderDetail,
  },
  {
    label: 'Tracking logs',
    value: ShippingInfo.TrackingLogs,
  },
  {
    label: 'Map',
    value: ShippingInfo.Map,
  },
  {
    label: 'Tracking Company',
    value: ShippingInfo.TrackingCompany,
  },
];

export const progressBarConfig: Array<ICheckboxProgressBar> = [
  {
    label: 'Ordered',
    value: ProgressBar.Ordered,
  },
  {
    label: 'Order Ready',
    value: ProgressBar.OrderReady,
  },
  {
    label: 'In transit',
    value: ProgressBar.InTransit,
  },
  {
    label: 'Out for delivery',
    value: ProgressBar.OutForDelivery,
  },
  {
    label: 'Delivered',
    value: ProgressBar.Delivered,
  },
];

export const optionsDateFormat = [
  { label: 'December 24th 2020', value: 'MMMM Do YYYY' },
  { label: 'Dec 24th 20', value: 'MMM Do YY' },
  { label: '24 Dec 2020', value: 'D MMM YYYY' },
  { label: '24 Dec', value: 'D MMM' },
  { label: '24/12/2020', value: 'DD/MM/YYYY' },
  { label: '12/21/2020', value: 'MM/DD/YYYY' },
];
export const optionsTimeFormat = [
  { label: '3:06:00 pm', value: 'h:mm:ss a' },
  { label: '3:06 pm', value: 'h:mm a' },
  { label: '15:06:00', value: 'HH:mm:ss' },
  { label: '15:06', value: 'HH:mm' },
];
