import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledYourAccount = styled.div`
  .shipment-statistics {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 0.25rem;

    .Polaris-ProgressBar.Polaris-ProgressBar--sizeSmall.Polaris-ProgressBar--toneHighlight {
      .Polaris-ProgressBar__IndicatorAppearActive,
      .Polaris-ProgressBar__IndicatorAppearDone {
        background-color: ${BgColorPalette.WARNING};
      }
    }
  }
`;
