// import { PATH } from '@/constants';
import {
  Modal,
  TitleBar,
  // useAppBridge
} from '@shopify/app-bridge-react';
// import { Fullscreen } from '@shopify/app-bridge/actions';
// import { useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import LocalConfiguration from '../LocalConfiguration';

const EmbedConfiguration = () => {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  // const app = useAppBridge();
  // const fullscreen = Fullscreen.create(app);

  // const handleExitFullscreen = () => {
  //   fullscreen.dispatch(Fullscreen.Action.EXIT);
  // };

  // app.subscribe(Fullscreen.Action.EXIT, () => {
  //   navigate(PATH.TRACKING_PAGE);
  // });

  // window.onpopstate = () => {
  //   fullscreen.dispatch(Fullscreen.Action.EXIT);
  // };

  // useEffect(() => {
  //   if (pathname.includes(PATH.TRACKING_PAGE_CONFIGURATION)) {
  //     fullscreen.dispatch(Fullscreen.Action.ENTER);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Modal id="my-modal" variant="max">
      <LocalConfiguration />
      <TitleBar>
        <button variant="primary">Primary action</button>
        <button>Secondary action</button>
      </TitleBar>
    </Modal>
  );
};

export default EmbedConfiguration;
