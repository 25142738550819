import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledPreviewBody = styled.div`
  .title-page,
  .omg-title,
  .omg-form-group label,
  .omg-form__control--text,
  .btn.btn-custom,
  .btn.btn-track,
  .asset-template,
  .omg-layout__divider {
    span,
    img {
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      cursor: pointer;
      &:hover {
        outline: 1px ${BgColorPalette.FOCUS} dashed;
      }
      &.active {
        outline: 1px ${BgColorPalette.FOCUS} solid;
      }
    }
  }
  .btn.btn-custom {
    span {
      display: block;
      width: 90%;
      height: 70%;
    }
  }
  .btn.btn-track {
    span {
      display: block;
      width: 90%;
      height: 90%;
      text-align: center;
    }
  }

  .omg-form__control--text {
    span {
      display: block;
      padding: 0.05rem 0.5rem;
    }
  }

  .asset-template {
    img {
      padding: 0.25rem;
    }
  }
`;
