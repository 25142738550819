export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day + 1);
  return date;
};

export const getThisMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth());
  const lastDate = new Date();
  return {
    start: firstDate,
    end: lastDate,
  };
};

export const getLastSomeMonths = (month: number) => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};

export const getDayBetween = (start: string, end: string) => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  return Math.round((endTime - startTime) / (1000 * 60 * 60 * 24));
};

export const addHourQuantity = (hour: number) => new Date(new Date().getTime() + hour * 60 * 60 * 1000);
export const addMinuteQuantity = (minutes: number) => new Date(new Date().getTime() + minutes * 60 * 1000);
