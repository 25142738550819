import { optionsPerPage } from '@/constants';
import shipmentSlice, { shipmentFilterSelector } from '@/redux/features/shipment.slice';
import { ChoiceList, IndexFilters, TabProps, useSetIndexFiltersMode } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { camelToRegularText, camelToSnakeCase, getLabelFromValue } from '@/helpers';
import { useGetShipmentCouriersQuery, useGetShipmentSearchQuery } from '@/redux/api/api.caller';
import { ShipmentStatus } from '@/constants/shipment';
import { memo, useMemo } from 'react';
import { PageSize, SortDirection } from '@/constants/enum';
import { sortOptions } from './config';
import { IShipmentSearchParams } from '@/types/shipment';

interface IShipmentFilter {
  params: IShipmentSearchParams;
}

const ShipmentFilter = ({ params }: IShipmentFilter) => {
  const { mode, setMode } = useSetIndexFiltersMode();
  const dispatch = useDispatch();
  const shipmentFilter = useSelector(shipmentFilterSelector);
  const { data: shipmentData, isLoading: isShipmentLoading } = useGetShipmentSearchQuery(params);
  const { data: courierData, isLoading: isCourierLoading } = useGetShipmentCouriersQuery(undefined, { skip: mode === 'DEFAULT' });

  const handleUpdateSort = (value: string[]) => {
    const formattedValue = value.toString().split(' ');
    dispatch(
      shipmentSlice.actions.handleChangeTableFilter({
        ...shipmentFilter,
        sortBy: formattedValue[0],
        sortOrder: formattedValue[1].toUpperCase() as SortDirection,
      }),
    );
  };

  const courierOptions = useMemo(() => {
    if (courierData && courierData.data.couriers.length > 0) {
      return courierData.data.couriers.map((name) => ({ value: name, label: name }));
    }
    return [];
  }, [courierData]);

  const shipmentTabs: TabProps[] = useMemo(() => {
    if (shipmentData) {
      return Object.entries(shipmentData.data.statusSummary || {}).map(([key, value]) => {
        // const isShowQuantity = shipmentFilter.status === camelToSnakeCase(key) && value > 0;

        return {
          id: camelToSnakeCase(key),
          content: `${camelToRegularText(key)}`,
        };
      });
    }

    return [];
  }, [shipmentData]);

  const handleChangeTable = (key: string) => (value: string[]) => {
    dispatch(
      shipmentSlice.actions.handleChangeTableFilter({
        ...shipmentFilter,
        page: 1,
        [key]: value[0],
      }),
    );
  };

  const handleClearAll = () => {
    dispatch(
      shipmentSlice.actions.handleChangeTableFilter({
        ...shipmentFilter,
        statusIndex: 0,
        status: ShipmentStatus.All,
        search: '',
        courier: 'all',
        page: 1,
        pageSize: PageSize.Five,
        sortOrder: SortDirection.DESC,
        sortBy: 'createdAt',
      }),
    );
  };

  const handleSelectTabs = (selectedTabIndex: number) =>
    dispatch(
      shipmentSlice.actions.handleChangeTableFilter({
        ...shipmentFilter,
        page: 1,
        statusIndex: selectedTabIndex,
        status: shipmentTabs[selectedTabIndex]?.id as ShipmentStatus,
      }),
    );

  const handleChangeInputSearch = (value: string) =>
    dispatch(shipmentSlice.actions.handleChangeTableFilter({ ...shipmentFilter, page: 1, search: value }));

  const appliedFilters = [
    {
      onRemove: () => handleChangeTable('courier')(['all']),
      key: 'courier',
      label: `Courier: ${isCourierLoading ? 'All' : getLabelFromValue(shipmentFilter.courier, courierOptions)}`,
    },
    {
      onRemove: () => handleChangeTable('pageSize')([PageSize.Five.toString()]),
      key: 'pageSize',
      label: `Per page: ${shipmentFilter.pageSize}`,
    },
  ];

  const filters = [
    {
      key: 'courier',
      label: 'Courier',
      filter: (
        <ChoiceList
          title="Courier"
          titleHidden
          choices={courierOptions}
          selected={[shipmentFilter.courier]}
          onChange={handleChangeTable('courier')}
        />
      ),
      shortcut: true,
    },
    {
      key: 'pageSize',
      label: 'Per page',
      filter: (
        <ChoiceList
          title="Per page"
          titleHidden
          choices={optionsPerPage}
          selected={[String(shipmentFilter.pageSize)]}
          onChange={handleChangeTable('pageSize')}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <IndexFilters
      mode={mode}
      setMode={setMode}
      cancelAction={{ onAction: () => {} }}
      canCreateNewView={false}
      sortOptions={sortOptions}
      sortSelected={[`${shipmentFilter.sortBy} ${shipmentFilter.sortOrder.toLowerCase()}`]}
      onSort={handleUpdateSort}
      queryPlaceholder="Tracking number, Order name"
      onQueryChange={handleChangeInputSearch}
      onQueryClear={() => handleChangeInputSearch('')}
      appliedFilters={appliedFilters.filter(
        (item) => shipmentFilter[item.key as keyof typeof shipmentFilter].toString() !== 'all',
      )}
      queryValue={shipmentFilter.search}
      filters={filters}
      onClearAll={handleClearAll}
      tabs={shipmentTabs}
      loading={isShipmentLoading || isCourierLoading}
      selected={shipmentFilter.statusIndex}
      onSelect={handleSelectTabs}
    />
  );
};

export default memo(ShipmentFilter);
