import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const StyledTemplateHeading = styled.div`
  .status,
  .subject {
    display: flex;
    align-items: flex-start;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;

    .title {
      width: 5rem;
    }

    .content {
      width: calc(100% - 5rem);
    }
  }

  .save-btn {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: ${PolarisBreakPoint.LG}) {
    .status,
    .subject {
      .title {
        width: 4rem;
      }

      .content {
        width: calc(100% - 4rem);
      }
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.MD}) {
    .subject {
      gap: 0.5rem;
      flex-wrap: wrap;

      .content {
        width: 100%;
      }
    }
  }
`;
