import { Button, Checkbox, Collapsible, Modal, SkeletonBodyText, Tag, Text, TextField, useBreakpoints } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { AccountPlan, CRUD } from '@/constants/enum';
import { DeleteMinor, EditMinor, PlusMinor } from '@shopify/polaris-icons';
import { StyledShippingMethod, StyledShippingMethodModal } from './styled';
import { shippingMethodError } from '../../config';
import { IShippingMethod } from '@/types/edd';
import { ANY_SHIPPING_METHOD, REST_OF_WORLD } from '@/constants';
import { useGetEDDSettingsQuery, useGetListCourierQuery } from '@/redux/api/api.caller';
import { Column, CustomCard, Row } from '@/components';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { isLockFeature } from '@/helpers';
import { useEffect } from 'react';

const ShippingMethod = () => {
  const dispatch = useDispatch();
  const eddState = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;
  const { isLoading: isEddSettingLoading } = useGetEDDSettingsQuery();
  const { data, isLoading } = useGetListCourierQuery(
    { page: 1, perPage: 500 },
    { skip: !eddState.shippingMethod.modal.isCollapse },
  );
  const listCourier = data?.data?.couriers || [];
  const isRestOfWorld = eddState.shippingZone.countriesCode.some((code) => code === REST_OF_WORLD);
  const isAnyShippingMethod = eddState.shippingMethod.courier.some((courier) => courier === ANY_SHIPPING_METHOD);
  const { smDown } = useBreakpoints();

  useEffect(() => {
    if (data) {
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          shippingMethod: {
            ...eddState.shippingMethod,
            courierList: data.data.couriers,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isCheckedShippingMethod = (courier: string) =>
    eddState.shippingMethod.courier.some((item) => item.toLowerCase() === courier.toLowerCase());

  const isNotCheckedShippingMethodExcept = (courier: string) => {
    const shippingMethodExcept = eddState.shippingMethod.methodInfo
      .filter((_, index) => index !== eddState.shippingMethod.modal.id)
      .map((method) => method.courier)
      .flat(1);
    return shippingMethodExcept.some((item) => item.toLowerCase() === courier.toLowerCase());
  };

  const isDisableShippingMethod = (courier: string) => {
    const mergedArr = eddState.shippingMethod.methodInfo.map(({ courier }) => courier).flat(1);
    let removedCourier = [];
    if (eddState.shippingMethod.modal.type === CRUD.Update) {
      removedCourier = mergedArr.filter((item) => isNotCheckedShippingMethodExcept(item));
    } else {
      removedCourier = mergedArr.filter((item) => !isCheckedShippingMethod(item));
    }
    return removedCourier.some((item) => item.toLowerCase() === courier.toLowerCase());
  };

  const handleClickAddNewShippingMethodBtn = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          courier: [],
          from: '4',
          to: '7',
          search: '',
          courierList: listCourier,
          modal: { ...eddState.shippingMethod.modal, type: CRUD.Create, isActive: true },
        },
      }),
    );

  const handleCollapseShippingMethod = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          modal: { ...eddState.shippingMethod.modal, isCollapse: true },
        },
      }),
    );

  const handleRemoveTag = (courier: string) => {
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: { ...eddState.shippingMethod, courier: eddState.shippingMethod.courier.filter((day) => day !== courier) },
      }),
    );
    eddState.shippingMethod.courier.filter((day) => day !== courier);
  };

  const handleClickDeleteBtn = (index: number) =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          methodInfo: eddState.shippingMethod.methodInfo.filter((_, idx) => idx !== index),
        },
      }),
    );

  const handleClickEditBtn = (method: IShippingMethod, index: number) =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          courier: method.courier,
          from: method.first,
          to: method.second,
          courierList: listCourier,
          modal: { ...eddState.shippingMethod.modal, type: CRUD.Update, isActive: true, id: index },
        },
      }),
    );

  const handleCloseModal = () =>
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          courier: [],
          from: '4',
          to: '7',
          search: '',
          courierList: listCourier,
          modal: { ...eddState.shippingMethod.modal, type: CRUD.Read, isActive: false },
        },
      }),
    );

  const handleChangeOptions = (value: string | boolean, key: keyof typeof eddState.shippingMethod) => {
    if (typeof value === 'string') {
      const filteredCourier = listCourier.filter((item) => item.display_name.toLowerCase().includes(value.toLowerCase()));
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          shippingMethod: {
            ...eddState.shippingMethod,
            courierList: key === 'search' ? filteredCourier : eddState.shippingMethod.courierList,
            [key]: key === 'from' || key === 'to' ? String(Math.abs(Number(value))) : value,
          },
        }),
      );
    }
    if (typeof value === 'boolean') {
      const updatedCourier = value
        ? [...eddState.shippingMethod.courier, key]
        : eddState.shippingMethod.courier.filter((day) => day !== key);
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          shippingMethod: { ...eddState.shippingMethod, courier: updatedCourier },
        }),
      );
    }
  };

  const handleSubmitModalForm = () => {
    if (eddState.shippingMethod.modal.type === CRUD.Update) {
      const updatedShippingMethod: IShippingMethod[] = eddState.shippingMethod.methodInfo.map((method, index) => {
        if (eddState.shippingMethod.modal.id === index) {
          return {
            courier: eddState.shippingMethod.courier,
            first: eddState.shippingMethod.from,
            second: eddState.shippingMethod.to,
          };
        }
        return method;
      });
      return dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...eddState,
          shippingMethod: {
            ...eddState.shippingMethod,
            methodInfo: updatedShippingMethod,
            modal: { ...eddState.shippingMethod.modal, type: CRUD.Read, isActive: false },
          },
        }),
      );
    }
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...eddState,
        shippingMethod: {
          ...eddState.shippingMethod,
          methodInfo: [
            ...eddState.shippingMethod.methodInfo,
            {
              courier: eddState.shippingMethod.courier,
              first: eddState.shippingMethod.from,
              second: eddState.shippingMethod.to,
            },
          ],
          modal: { ...eddState.shippingMethod.modal, type: CRUD.Read, isActive: false },
        },
      }),
    );
  };

  const renderCollapsibleContent = () => {
    if (isLoading) {
      return (
        <div style={{ padding: '1rem 0' }}>
          <SkeletonBodyText lines={9} />
        </div>
      );
    }
    if (eddState.shippingMethod.courierList.length === 0) {
      return <p className="no-data">No result to display</p>;
    }
    return (
      <>
        {eddState.shippingMethod.courierList.map((item) => (
          <Checkbox
            key={item.id}
            id={item.display_name}
            label={item.display_name}
            checked={isCheckedShippingMethod(item.display_name)}
            onChange={handleChangeOptions}
            disabled={isDisableShippingMethod(item.display_name) || isLockedFeature}
          />
        ))}
      </>
    );
  };

  const renderShippingMethod = () => {
    if (isEddSettingLoading) {
      return <SkeletonBodyText lines={5} />;
    }
    if (eddState.shippingMethod.methodInfo.length === 0) {
      return <p className="no-data-text">You haven't created any shipping method & transit time yet</p>;
    }
    return (
      <>
        {eddState.shippingMethod.methodInfo.map((method, index) => (
          <div className="info-detail" key={`${method.courier}${method.first}${method.second}${index}`}>
            <Row column={smDown ? 1 : 3} style={{ width: '100%' }} gap={smDown ? '0.5rem' : '1rem'}>
              <Column span={smDown ? 1 : 2}>{method.courier.join(', ')}</Column>
              <Column style={{ marginBlock: 'auto' }}>{`${method.first} - ${method.second} business days`}</Column>
            </Row>
            <div className="group-btn">
              <Button icon={EditMinor} onClick={() => handleClickEditBtn(method, index)} disabled={isLockedFeature} />
              <Button
                icon={DeleteMinor}
                onClick={() => handleClickDeleteBtn(index)}
                disabled={isRestOfWorld || isLockedFeature}
              />
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <StyledShippingMethod>
        <CustomCard
          title="SHIPPING METHOD & TRANSIT TIME"
          actionGroup={[
            {
              content: 'Add new shipping method',
              variant: 'plain',
              icon: PlusMinor,
              onAction: handleClickAddNewShippingMethodBtn,
              disabled: isRestOfWorld || isLockedFeature,
            },
          ]}
        >
          <div className="info-title">
            <Row column={smDown ? 1 : 3} style={{ width: '100%' }}>
              <Column span={smDown ? 1 : 2}>
                <Text variant="headingSm" as="h6">
                  Shipping method
                </Text>
              </Column>
              {!smDown && (
                <Column>
                  <Text variant="headingSm" as="h6">
                    Transit time
                  </Text>
                </Column>
              )}
            </Row>
            <Text variant="headingSm" as="h6">
              Actions
            </Text>
          </div>
          {renderShippingMethod()}
        </CustomCard>
      </StyledShippingMethod>

      <Modal
        open={eddState.shippingMethod.modal.isActive}
        onClose={handleCloseModal}
        title={eddState.shippingMethod.modal.type === CRUD.Update ? 'Update Shipping Method' : 'New Shipping Method'}
        primaryAction={{
          content: eddState.shippingMethod.modal.type === CRUD.Update ? 'Update' : 'Save',
          disabled:
            Number(eddState.shippingMethod.from) >= 1000 ||
            Number(eddState.shippingMethod.to) >= 1000 ||
            eddState.shippingMethod.courier.length === 0,
          onAction: handleSubmitModalForm,
        }}
        secondaryActions={[
          {
            content: 'Discard',
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <StyledShippingMethodModal>
            <Text variant="headingSm" as="h6">
              Choose shipping method
            </Text>
            {eddState.shippingMethod.courier.length === 0 ? (
              <p className="text-description">{shippingMethodError.SHIPPING_METHOD}</p>
            ) : (
              eddState.shippingMethod.courier.map((courier) => (
                <Tag key={courier.toString()} onRemove={() => handleRemoveTag(courier)} disabled={isRestOfWorld}>
                  {courier}
                </Tag>
              ))
            )}
            <TextField
              label=""
              id="search"
              placeholder="Search: USPS, UPS..."
              value={eddState.shippingMethod.search}
              onChange={handleChangeOptions}
              onFocus={handleCollapseShippingMethod}
              autoComplete="off"
              disabled={isAnyShippingMethod || isLockedFeature}
            />
            <Collapsible
              open={eddState.shippingMethod.modal.isCollapse && !isAnyShippingMethod}
              id="shipping-collapsible"
              transition={{
                duration: '500ms',
                timingFunction: 'ease-in-out',
              }}
            >
              <div className="collapsible-content">{renderCollapsibleContent()}</div>
            </Collapsible>

            <div className="mt-16" />

            <Text variant="headingSm" as="h6">
              Transit times
            </Text>
            <p className="text-description">
              If you want to set up a single date, please select the time range of the transit time from 1 to 1
            </p>
            <div className="transit-time">
              <TextField
                label=""
                id="from"
                type="number"
                maxLength={3}
                max={999}
                autoComplete="off"
                value={JSON.stringify(Math.floor(Math.abs(Number(eddState.shippingMethod.from))))}
                onChange={handleChangeOptions}
                disabled={isLockedFeature}
              />
              <span>to</span>
              <TextField
                label=""
                id="to"
                type="number"
                maxLength={3}
                max={999}
                autoComplete="off"
                value={JSON.stringify(Math.floor(Math.abs(Number(eddState.shippingMethod.to))))}
                onChange={handleChangeOptions}
                disabled={isLockedFeature}
              />
              <span>business days.</span>
            </div>
            {(Number(eddState.shippingMethod.from) >= 1000 || Number(eddState.shippingMethod.to) >= 1000) && (
              <div className="transit-time-err">{shippingMethodError.TRANSIT_TIME}</div>
            )}
          </StyledShippingMethodModal>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default ShippingMethod;
