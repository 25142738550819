import { FileTypes } from '@/constants';
import dayjs from 'dayjs';

export const exportExtensionFile = (data: Blob, name: string, type?: FileTypes) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: type || FileTypes.XLSX,
    }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getExportFileName = (name: string, startDate?: Date, endDate?: Date) => {
  const formattedFileName = name.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-');
  const formattedDate = `_[${dayjs(startDate).format('MMM DD, YYYY')}]-[${dayjs(endDate).format('MMM DD, YYYY')}]`;
  return `${formattedFileName}${startDate && endDate ? formattedDate : ''}`;
};
