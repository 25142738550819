import { datePickerSelector } from '@/redux/features/dashboard.slice';
import { Card, InlineGrid, SkeletonBodyText } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { calcTotal } from '@/helpers';
import { useGetShipmentOvertimeQuery } from '@/redux/api/api.caller';
import { useMemo } from 'react';
import { PATH } from '@/constants';
import { CustomCard } from '@/components';
import { StyledSummaryBg } from './styled';
import StatisticCard from './components';

const OrderStatistics = () => {
  const { end, start } = useSelector(datePickerSelector);
  const { data, isFetching } = useGetShipmentOvertimeQuery({ fromDate: start.toISOString(), toDate: end.toISOString() });

  const shipmentStatistics = useMemo(() => {
    if (data) {
      const { all, delivered, failedAttempt, exception, expired } = data.data.statusSummary;
      return [
        {
          title: 'Total Shipment',
          total: all,
          titleInfo: 'Number of orders you have shipped',
        },
        {
          title: 'Total Delivered',
          total: delivered,
          titleInfo: 'Number of orders delivered successfully',
        },
        {
          title: 'Pressing Orders',
          total: calcTotal([failedAttempt, exception, expired]),
          titleInfo: 'Number of orders might need your extra attention (incl. failed attempt, exception, expired)',
        },
      ];
    }
    return [];
  }, [data]);

  return (
    <CustomCard title="Summary">
      <StyledSummaryBg>
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
          {isFetching
            ? [0, 1, 2].map((item) => (
                <Card key={item}>
                  <SkeletonBodyText lines={4} />
                </Card>
              ))
            : shipmentStatistics.length > 0 &&
              shipmentStatistics.map(({ title, total, titleInfo }) => (
                <Card key={title}>
                  <StatisticCard title={title} quantity={total} titleInfo={titleInfo} />
                </Card>
              ))}
        </InlineGrid>
      </StyledSummaryBg>
    </CustomCard>
  );
};

export default OrderStatistics;
