import { CustomCard } from '@/components';
import { CustomStatusStyled } from './styled';
import { Checkbox, RadioButton, SkeletonBodyText, TextField } from '@shopify/polaris';
import { configStatus } from './config';
import { useDispatch, useSelector } from 'react-redux';
import trackingPageSlice, { trackingPageIsLoadingSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { checkDirtyField, renderErrorValue, splitObjects } from '@/helpers';

const CustomStatus = () => {
  const isLoading = useSelector(trackingPageIsLoadingSelector);
  const dispatch = useDispatch();
  const { fieldsChange, validation, value: data, oldValue } = useSelector(trackingPageSelector);
  const { customStatus } = data.configuration;
  const { customStatus: oldCustomStatus } = oldValue.configuration;

  const handleCustomStatusField = (currentValue: {}) => {
    const { dirtyField: customStatusField } = checkDirtyField(splitObjects(oldCustomStatus, 0, 1), {
      ...currentValue,
    });

    const { dirtyField: activeAfterField } = checkDirtyField(splitObjects(oldCustomStatus, 1, 2), {
      ...currentValue,
    });

    const { dirtyField: statusNameField } = checkDirtyField(splitObjects(oldCustomStatus, 3, 4), {
      ...currentValue,
    });

    const { dirtyField: statusField } = checkDirtyField(splitObjects(oldCustomStatus, 2, 3), {
      ...currentValue,
    });

    if (!customStatusField && !activeAfterField && !statusNameField && !statusField) {
      return undefined;
    }
    return {
      ...fieldsChange.customStatus,
      enabled: customStatusField?.isCustomStatus,
      label: statusNameField?.statusName,
      timeActive: activeAfterField?.activeAfter ? +activeAfterField.activeAfter : undefined,
      icon: statusField?.status ? +statusField.status : undefined,
    };
  };

  const handleChange = (key: string, apiKey: string, isNumber?: boolean) => (value: string | boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          customStatus: {
            ...customStatus,
            [key]: isNumber ? Math.min(30, +value).toString() : value,
          },
        },
      }),
    );

    if (key === 'statusName') {
      dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== key)));
    }

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customStatus: handleCustomStatusField({
          ...customStatus,
          [key]: isNumber ? Math.min(30, +value).toString() : value,
        }),
      }),
    );
  };
  const handleChangeStatus = (value: string) => () => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          customStatus: {
            ...customStatus,
            status: value,
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customStatus: handleCustomStatusField({
          ...customStatus,
          status: value,
        }),
      }),
    );
  };

  const handleBlurStatusName = () => {
    if (!customStatus.statusName) {
      const isNotHaveKey = validation.findIndex((item) => item === 'statusName') === -1;
      if (isNotHaveKey) {
        dispatch(trackingPageSlice.actions.handleChangeValidation([...validation, 'statusName']));
      }
    } else {
      dispatch(trackingPageSlice.actions.handleChangeValidation(validation.filter((item) => item !== 'statusName')));
    }
  };

  return (
    <CustomStatusStyled>
      <CustomCard title="Custom Status">
        {isLoading ? (
          <SkeletonBodyText lines={13} />
        ) : (
          <>
            <Checkbox
              label="Enable custom status"
              checked={customStatus.isCustomStatus}
              onChange={handleChange('isCustomStatus', 'enabled')}
            />
            <div className="mt-16">
              <TextField
                maxLength={25}
                showCharacterCount
                label="Status Name"
                value={customStatus.statusName}
                onChange={handleChange('statusName', 'label')}
                onBlur={() => handleBlurStatusName()}
                autoComplete="off"
                error={renderErrorValue(validation, 'statusName', `Status Name is required`)}
              />
            </div>
            <div className="mt-16">
              <TextField
                label="Active after"
                value={customStatus.activeAfter.toString()}
                onChange={handleChange('activeAfter', 'timeActive', true)}
                autoComplete="off"
                min={0}
                max={30}
                type="number"
                suffix={Number(customStatus.activeAfter) > 1 ? 'days' : 'day'}
              />
            </div>
            <div className="mt-16">
              <div className="status-icon-label">Status icon</div>
              <div className="status-radio-container">
                {configStatus.map((item) => {
                  return (
                    <div key={item.value} className="status-radio" onClick={handleChangeStatus(item.value)}>
                      <img src={item.src} alt={`status ${item.value}`} />
                      <div className="mt-8">
                        <RadioButton
                          label=""
                          labelHidden
                          checked={customStatus.status === item.value}
                          id={item.value}
                          name={item.value}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </CustomCard>
    </CustomStatusStyled>
  );
};

export default CustomStatus;
