import { UPLOAD } from '@/constants';
import { DropZone, Icon, InlineError, Text } from '@shopify/polaris';
import { memo, useEffect, useRef, useState } from 'react';
import { UploadFileStyled } from './styled';
import { ImageWithTextOverlayMajor } from '@shopify/polaris-icons';
import { validImageTypes } from './config';
import useEventListener from '@/hooks/useEventListener';
import { useSelector } from 'react-redux';
import { collapsibleSelector, trackingFocusSelector } from '@/redux/features/trackingPage.slice';

interface Props {
  onSave: (file: File) => void;
  label: string;
  imageError: { key: string; message: string };
  children?: any;
  url: string;
  percentByHeight?: number;
  keyFile?: string;
}

function UploadFile({ ...props }: Props) {
  const [url, setUrl] = useState('');
  const [error, setError] = useState<string>('');
  const [width, setWidth] = useState<number>(0);
  const squareRef = useRef<HTMLInputElement>(null);
  const collapsible = useSelector(collapsibleSelector);
  const focus = useSelector(trackingFocusSelector);

  const handleDropZoneDrop = (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const src = window.URL.createObjectURL(file);
      setUrl(src);
      setError('');
      props.onSave(file);
    }
  };

  const validateFile = (file: File) => {
    if (file.size > UPLOAD.maxSize) {
      setError('File too large');
      setUrl(props.url);
    }
    if (!validImageTypes.includes(file.type)) {
      setError('Unsupported format');
      setUrl(props.url);
    }
    return file.size < UPLOAD.maxSize && validImageTypes.includes(file.type);
  };

  const uploadedFiles = <img className="preview-image" src={url} alt="_blank" width="100%" height="100%" loading="lazy" />;

  const handleSize = () => {
    setWidth(squareRef.current?.offsetWidth || 0);
  };

  useEventListener('resize', handleSize);

  useEffect(() => {
    setUrl(props.url);
  }, [props.url]);

  useEffect(() => {
    handleSize();
  }, [collapsible.theme]);

  return (
    <UploadFileStyled height={props.percentByHeight ? `${props.percentByHeight * width}px` : '9rem'}>
      <DropZone
        customValidator={validateFile}
        label={props.label}
        onDrop={handleDropZoneDrop}
        variableHeight
        accept="image/gif',image/jpeg,image/png,image/jpg"
        type="image"
      >
        <div className={`border-frame${focus === props.keyFile ? ' active' : ''}`} ref={squareRef}>
          <div className="preview-text">
            <Icon source={ImageWithTextOverlayMajor} tone="base" />
            <Text as="h6" variant="bodyMd">
              Upload
            </Text>
          </div>
          {uploadedFiles}
        </div>
      </DropZone>
      {(error || (props.imageError.message && props.imageError.key === props.keyFile)) && (
        <InlineError message={error || props.imageError.message} fieldID="upload-image" />
      )}
      {props.children}
    </UploadFileStyled>
  );
}

export default memo(UploadFile);
