import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledShippingZone = styled.div`
  .rest-of-world {
    padding-bottom: 0.25rem;
    padding-inline: 0.5rem;
  }

  .collapsible-content {
    display: flex;
    flex-direction: column;
    padding-inline: 2.5rem;
    height: 12.5rem;
    overflow-y: auto;
    border-bottom: 1px solid ${BgColorPalette.SECONDARY_ACTIVE};

    .Polaris-Choice {
      padding-block: 0.5rem;
    }
  }
`;
