import { SVGProps } from 'react';

const DeliveredIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10.5" cy="11" r="10.5" fill="#008060" />
      <path
        d="M13.3875 7.38107C13.3228 7.32985 13.2486 7.29195 13.1692 7.26955C13.0897 7.24715 13.0067 7.24071 12.9247 7.25059C12.8428 7.26047 12.7636 7.28647 12.6918 7.3271C12.62 7.36774 12.5569 7.42219 12.5062 7.48732L8.13125 13.1123L5.9875 10.4998C5.93721 10.4329 5.87399 10.3767 5.80159 10.3347C5.72919 10.2927 5.64908 10.2656 5.56602 10.2551C5.48296 10.2447 5.39864 10.251 5.31807 10.2737C5.23749 10.2964 5.16231 10.3351 5.09698 10.3875C5.03164 10.4398 4.9775 10.5048 4.93774 10.5785C4.89799 10.6521 4.87345 10.733 4.86557 10.8164C4.85769 10.8997 4.86663 10.9838 4.89187 11.0636C4.91711 11.1435 4.95813 11.2174 5.0125 11.2811L7.61875 14.5186C7.67757 14.5911 7.75189 14.6495 7.83626 14.6895C7.92062 14.7295 8.01287 14.7501 8.10625 14.7498C8.20536 14.7542 8.3041 14.735 8.3943 14.6937C8.48451 14.6524 8.56358 14.5902 8.625 14.5123L13.5187 8.26232C13.5686 8.19609 13.6049 8.12059 13.6253 8.04022C13.6457 7.95985 13.6499 7.87622 13.6377 7.79421C13.6255 7.71219 13.5971 7.63342 13.5541 7.56249C13.5112 7.49156 13.4545 7.42989 13.3875 7.38107ZM16.5125 7.38107C16.4478 7.32985 16.3736 7.29195 16.2942 7.26955C16.2147 7.24715 16.1317 7.24071 16.0497 7.25059C15.9678 7.26047 15.8886 7.28647 15.8168 7.3271C15.745 7.36774 15.6819 7.42219 15.6312 7.48732L11.2562 13.1123L10.875 12.6436L10.0875 13.6561L10.775 14.5123C10.8338 14.5848 10.9081 14.6432 10.9925 14.6833C11.0769 14.7233 11.1691 14.7439 11.2625 14.7436C11.3564 14.7431 11.4489 14.7216 11.5333 14.6804C11.6177 14.6393 11.6918 14.5797 11.75 14.5061L16.6437 8.25607C16.6927 8.19003 16.7281 8.11499 16.748 8.03525C16.7678 7.95551 16.7718 7.87263 16.7596 7.79135C16.7474 7.71008 16.7193 7.632 16.6769 7.5616C16.6345 7.4912 16.5786 7.42986 16.5125 7.38107Z"
        fill="white"
      />
      <path
        d="M8.44364 11.6619L9.24989 10.6494L9.12489 10.4994C9.07554 10.4311 9.01288 10.3734 8.94066 10.3299C8.86845 10.2864 8.78818 10.2579 8.70468 10.2463C8.62118 10.2346 8.53619 10.2399 8.45481 10.2619C8.37343 10.284 8.29734 10.3222 8.23114 10.3744C8.16693 10.426 8.11356 10.4898 8.07407 10.562C8.03459 10.6343 8.00978 10.7137 8.00107 10.7956C7.99235 10.8775 7.99991 10.9603 8.02331 11.0392C8.0467 11.1182 8.08547 11.1918 8.13739 11.2557L8.44364 11.6619Z"
        fill="white"
      />
    </svg>
  );
};

export default DeliveredIcon;
