import { Receiver } from '@/constants/enum';

export const tabs = [
  {
    id: Receiver.Customer,
    content: 'To customer',
  },
  {
    id: Receiver.Merchant,
    content: 'To yourself',
  },
];

export const statusConfig = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'informationReceived',
    label: 'Information received',
  },
  {
    value: 'inTransit',
    label: 'In transit',
  },
  {
    value: 'outForDelivery',
    label: 'Out for delivery',
  },
  {
    value: 'delivered',
    label: 'Delivered',
  },
  {
    value: 'exception',
    label: 'Exception',
  },
  {
    value: 'failedAttempt',
    label: 'Failed attempt',
  },
  {
    value: 'expired',
    label: 'Expired',
  },
];
