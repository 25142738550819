import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const OrderLookupStyled = styled.div`
  .Polaris-Labelled__LabelWrapper {
    margin-top: 0.5rem;
  }

  .code-container {
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: ${BgColorPalette.SECONDARY};

    .get-code-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      line-height: 1.5rem;
    }
  }

  .show-tracking-result {
    display: flex;
    align-items: center;
  }
`;
