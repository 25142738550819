import { ChatMajor, SendMajor, SmileyHappyMajor } from '@shopify/polaris-icons';
import { HelpCardProps } from './components';
import { handleOpenChat } from '@/helpers';
import { LINK } from '@/constants';

export const helpCenterData: HelpCardProps[] = [
  {
    icon: SendMajor,
    title: 'Get Email Support',
    description: `Email us and we'll get back to you as soon as possible.`,
    url: 'mailto:support@omegatheme.com',
  },
  {
    icon: ChatMajor,
    title: 'Start Live Chat',
    description: 'Talk to us directly via live chat to get help with your question.',
    onClick: handleOpenChat,
  },
  {
    icon: SmileyHappyMajor,
    title: 'Order Tracking Help Docs',
    description: 'Find a solution for your problem with Order Tracking documents and tutorials.',
    url: LINK.HELP_CENTER,
  },
];
