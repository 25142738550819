import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IToast } from '@/types';
import { ICourier } from '@/types/settings';

export interface ICommonState {
  toast: IToast;
  listCourier: Array<ICourier>;
  haveClosedPopup: boolean | 'first-time';
}

const toast: IToast = {
  hasAction: undefined,
  error: false,
  content: '',
  isOpen: false,
  contentAction: 'undo',
  onAction: () => {},
};

// Define the initial state using that type
const initialState: ICommonState = {
  toast: toast,
  listCourier: [],
  haveClosedPopup: 'first-time',
};

export const commonSlice = createSlice({
  name: 'common',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleToast: (state, action: PayloadAction<IToast>) => {
      state.toast = action.payload;
    },
    handleListCourier: (state, action: PayloadAction<Array<ICourier>>) => {
      state.listCourier = action.payload;
    },
    handleClosePopup: (state, action: PayloadAction<boolean>) => {
      state.haveClosedPopup = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const toastSelector = createSelector(
  (state: RootState) => state.common,
  (state) => state.toast,
);
export const listCourierSelector = createSelector(
  (state: RootState) => state.common,
  (state) => state.listCourier,
);
export const haveClosedPopupSelector = createSelector(
  (state: RootState) => state.common,
  (state) => state.haveClosedPopup,
);

export default commonSlice;
