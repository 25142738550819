import { PolarisBreakPoint } from '@/constants/enum';
import styled from 'styled-components';

export const CustomStatusStyled = styled.div`
  width: 100%;
  img {
    width: 40px;
    height: 40px;
  }

  .status-icon-label {
    margin-bottom: 0.25rem;
  }

  .status-radio-container {
    display: flex;
    gap: 3rem;
    flex-wrap: wrap;

    .status-radio {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: ${PolarisBreakPoint.LG}) {
    .status-radio-container {
      gap: 1.5rem;
    }
  }
`;
