import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const StyledRecentActivities = styled.div`
  .card-container {
    height: 100%;

    .Polaris-ShadowBevel {
      height: 100%;
    }
  }

  .activities-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .content-loading {
    height: 12.25rem;
    margin-top: 0.75rem;
  }

  .activity-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .activity-item {
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: end;
      gap: 0.5rem;
      flex-wrap: wrap;
      background-color: ${BgColorPalette.SECONDARY};
      padding: 0.5rem;
    }
  }

  .no-activities-data {
    text-align: center;
    padding: 1rem;

    img {
      width: 8rem;
      height: 8rem;
    }
  }
`;
