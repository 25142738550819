import { CustomCard, UpgradeBanner } from '@/components';
import CourierMapping from './components/CourierMapping';
import CourierSettings from './components/CourierSettings';
import { CourierMappingStyled } from './styled';
import { AccountPlan } from '@/constants/enum';
import { BlockStack } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { accountSelector } from '@/redux/features/plan.slice';

const CourierMappingSettings = () => {
  const plan = useSelector(accountSelector);

  return (
    <CourierMappingStyled>
      <BlockStack gap="400">
        <CourierSettings />

        <CustomCard title="Courier Mapping">
          <UpgradeBanner
            isOpen={[AccountPlan.Starter, AccountPlan.Basic].includes(plan.plan) && !plan.isOldPaidUser}
            usedFeature={AccountPlan.Professional}
            isHiddenTitle
          />
          <BlockStack gap="200">
            <CourierMapping />
          </BlockStack>
        </CustomCard>
      </BlockStack>
    </CourierMappingStyled>
  );
};

export default CourierMappingSettings;
