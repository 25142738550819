import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const CardStyled = styled.div`
  width: 100%;
  .Polaris-ShadowBevel {
    width: 100%;
    z-index: inherit;
  }
  .custom-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid ${BgColorPalette.SECONDARY_HOVER};
    padding: 0.75rem 1rem 0.5rem;
    flex-wrap: wrap;

    .Polaris-Text--root.Polaris-Text--headingMd {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      .information-icon {
        cursor: pointer;
      }
    }
  }

  .custom-card-content {
    padding: 1rem;
    .Polaris-ShadowBevel {
      height: 100%;
      .indicator-filter-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
`;
