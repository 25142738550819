import { HSBColor, hexToRgb, rgbToHsb } from '@shopify/polaris';

export const hexToHSB = (color: string): HSBColor => {
  const rbgColor = hexToRgb(color);
  const hsbColor = rgbToHsb(rbgColor);
  return hsbColor;
};

export const utf8Tob64 = (str: string) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};
