import { SVGProps } from 'react';

const SentIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="10.5" r="10.5" fill="#17A2B8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19926 14.5006L5.64926 11.9506C5.31691 11.6182 5.31691 11.081 5.64926 10.7487C5.98161 10.4163 6.51881 10.4163 6.85116 10.7487L8.75176 12.6493L14.1051 6.54715C14.4111 6.19015 14.9474 6.14935 15.3027 6.4545C15.6597 6.7605 15.7014 7.296 15.3954 7.65215L9.44536 14.4521C9.29151 14.6323 9.06881 14.7394 8.83251 14.7488C8.57496 14.7496 8.35821 14.6604 8.19926 14.5006Z"
        fill="white"
      />
    </svg>
  );
};

export default SentIcon;
