import styled from 'styled-components';

export const StyledSubscriptionPlans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .account-subscription-btn {
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

  .discount-code {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    .discount-badge {
      cursor: pointer;
      padding: 2px 8px;
      border-radius: 0.5rem;
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      background: rgba(0, 0, 0, 0.1);

      .discount-text {
        font-weight: 700;
      }
    }
  }

  .w-100 {
    width: 100%;
  }
`;
