import { SVGProps } from 'react';

const OverQuotaIcon = ({ ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0C4.7103 0 0 4.7103 0 10.5C0 16.2897 4.7103 21 10.5 21C16.2897 21 21 16.2897 21 10.5C21 4.7103 16.2897 0 10.5 0ZM9.45 6.3C9.45 5.7201 9.9201 5.25 10.5 5.25C11.0799 5.25 11.55 5.7201 11.55 6.3V10.5C11.55 11.0799 11.0799 11.55 10.5 11.55C9.9201 11.55 9.45 11.0799 9.45 10.5V6.3ZM10.5 15.75C11.0799 15.75 11.55 15.2799 11.55 14.7C11.55 14.1201 11.0799 13.65 10.5 13.65C9.9201 13.65 9.45 14.1201 9.45 14.7C9.45 15.2799 9.9201 15.75 10.5 15.75Z"
        fill="#FF763C"
      />
    </svg>
  );
};

export default OverQuotaIcon;
