import { Button, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { SwitchStyled } from './styled';

interface Props {
  isActive: boolean;
  onClick: () => void;
  loading?: boolean;
  disable?: boolean;
  tooltip?: string;
}

const getClassNameToggleBtn = (isActive: boolean) => {
  if (isActive) return 'toggle-btn contrary';
  return 'toggle-btn';
};
const toggleButton = (className: string) => {
  return (
    <div className={className}>
      <div className="circle" />
    </div>
  );
};

const Switch = ({ tooltip = 'status', ...props }: Props) => {
  const renderTooltipContentToggleButton = (isActive: boolean) => {
    return isActive ? `Disable ${tooltip}` : `Enable ${tooltip}`;
  };

  return (
    <SwitchStyled>
      <Tooltip content={renderTooltipContentToggleButton(props.isActive)}>
        <Button
          onClick={props.onClick}
          icon={props.loading ? <></> : toggleButton(getClassNameToggleBtn(props.isActive))}
          loading={props.loading}
          disabled={props.disable}
        />
      </Tooltip>
    </SwitchStyled>
  );
};

export default memo(Switch);
