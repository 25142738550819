import styled from 'styled-components';

export const StyledStatusModal = styled.div`
  .tracking-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 1rem;
  }

  .tracking-history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }

  .no-data {
    text-align: center;
    padding-block: 1rem;
  }

  .custom-date-picker {
    border: 1px solid #898f94;
    height: 2.125rem;
    border-radius: 0.5rem;
  }
`;
