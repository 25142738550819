import { SkeletonBodyText, Text } from '@shopify/polaris';
import { StyledRecentActivities } from './styled';
import { capitalizeFirstLetter } from '@/helpers';
import { OrderStatusPalette, PATH } from '@/constants';
import { CustomBadge, CustomCard } from '@/components';
import { useGetDashboardShipmentQuery } from '@/redux/api/api.caller';

const NoActivitiesData = () => (
  <div className="no-activities-data">
    <img src="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" alt="No data" />
    <p>No recent activities</p>
  </div>
);

const RecentActivities = () => {
  const { data, isLoading } = useGetDashboardShipmentQuery({});

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="content-loading">
          <SkeletonBodyText lines={10} />
        </div>
      );
    }
    if (data && (data.state === 0 || data.data.length === 0)) {
      return <NoActivitiesData />;
    }
    return (
      <div className="activity-list">
        {data &&
          data.data.map(({ name, trackingSummary }, index) => (
            <div className="activity-item" key={index}>
              <p>{name}</p>
              <CustomBadge
                bgColor="white"
                summary={capitalizeFirstLetter(trackingSummary.split('_').join(' '))}
                status={OrderStatusPalette[trackingSummary.toUpperCase() as keyof typeof OrderStatusPalette]}
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <StyledRecentActivities>
      <CustomCard className="card-container" title="Recent Activities" tooltip="Your orders that have recently updated">
        <div className="activities-title">
          <Text variant="headingSm" as="h6">
            #Order
          </Text>
          <Text variant="headingSm" as="h6">
            Status
          </Text>
        </div>
        {renderContent()}
      </CustomCard>
    </StyledRecentActivities>
  );
};

export default RecentActivities;
