import ChristmasHeadingBanner from '@/pages/Dashboard/components/ChristmasHeadingBanner';
import planSlice, { christmasBannerSelector } from '@/redux/features/plan.slice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ChristmasBanner = () => {
  const dispatch = useDispatch();
  const christmasBanner = useSelector(christmasBannerSelector);

  const handleCloseBanner = () => {
    dispatch(
      planSlice.actions.handleChangeChristmasBanner({
        ...christmasBanner,
        haveClosed: true,
      }),
    );
  };

  return <ChristmasHeadingBanner isClose={christmasBanner.haveClosed} onClose={handleCloseBanner} />;
};

export default ChristmasBanner;
