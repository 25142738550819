import { CustomCard, ErrorBanner, UpgradeBanner } from '@/components';
import { Button, InlineGrid, RadioButton, SkeletonBodyText, Text } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { StyledBlacklisting } from './styled';
import trackingPageSlice, { blacklistingSelector } from '@/redux/features/trackingPage.slice';
import { AreaToReplaceWithType, BlacklistReplaceWith, LINK, UpdatedBlacklistBtn } from '@/constants';
import { useGetBlacklistingQuery, useUpdateBlacklistingMutation } from '@/redux/api/api.caller';
import AreasToBeReplacedTextField from './components/AreasToBeReplacedTextField';
import AreaToReplaceWithTextField from './components/AreaToReplaceWithTextField';
import SearchAndReplaceWords from './components/SearchAndReplaceWords';
import { useEffect, useMemo, useState } from 'react';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { cleanObject } from '@/helpers';
import { NoteMajor } from '@shopify/polaris-icons';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { AccountPlan } from '@/constants/enum';
import { isLockFeature } from '@/helpers/feature';

const Blacklisting = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<{ enable: string; save: string }>({ enable: '', save: '' });
  const blacklisting = useSelector(blacklistingSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const { data, isLoading } = useGetBlacklistingQuery(undefined, { skip: isLockedFeature });
  const [updateBlacklist, updatedBlacklistData] = useUpdateBlacklistingMutation();

  const blacklistingBtnText = useMemo(() => {
    if (isLoading) {
      return 'Loading...';
    }
    if (blacklisting.isEnableBtn) {
      return 'Disable';
    }
    return 'Enable';
  }, [blacklisting.isEnableBtn, isLoading]);

  const handleChangeReplaceWith = (type: string) => {
    setApiError({
      ...apiError,
      save: '',
    });
    dispatch(trackingPageSlice.actions.handleChangeBlacklisting({ ...blacklisting, replaceWith: type as BlacklistReplaceWith }));
  };

  const handleUpdateBlacklisting = (type: UpdatedBlacklistBtn) => {
    dispatch(trackingPageSlice.actions.handleChangeBlacklisting({ ...blacklisting, updatedBlacklistBtn: type }));
    const payload =
      type === UpdatedBlacklistBtn.Enable
        ? { enabled: !blacklisting.isEnableBtn }
        : {
            replaceWith: blacklisting.replaceWith,
            areasToBeReplaced: blacklisting.areasToBeReplaced.selected,
            areaToReplaceWith: cleanObject({
              country: blacklisting.areaToReplaceWith.country.selected.toString(),
              city: blacklisting.areaToReplaceWith.city.selected.toString(),
            }),
          };
    updateBlacklist(payload).then((data) => {
      if ('data' in data && data.data && data.data.state === 1) {
        type === UpdatedBlacklistBtn.Enable ? setApiError({ ...apiError, enable: '' }) : setApiError({ ...apiError, save: '' });
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        return;
      }
      type === UpdatedBlacklistBtn.Enable
        ? setApiError({ ...apiError, enable: getErrorFromAPI(data) })
        : setApiError({
            ...apiError,
            save: getErrorFromAPI(data),
          });
    });
  };

  useEffect(() => {
    if (data && data.data && data.data.areaToReplaceWith && data.data.areasToBeReplaced) {
      dispatch(
        trackingPageSlice.actions.handleChangeBlacklisting({
          ...blacklisting,
          isEnableBtn: data.data.enabled,
          replaceWith: data.data.replaceWith,
          areasToBeReplaced: { ...blacklisting.areasToBeReplaced, selected: data.data.areasToBeReplaced },
          areaToReplaceWith: {
            ...blacklisting.areaToReplaceWith,
            country: {
              ...blacklisting.areaToReplaceWith.country,
              selected: data.data.areaToReplaceWith.country ? [data.data.areaToReplaceWith.country] : [],
            },
            city: {
              ...blacklisting.areaToReplaceWith.city,
              selected: data.data.areaToReplaceWith.city ? [data.data.areaToReplaceWith.city] : [],
            },
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <StyledBlacklisting>
        <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} />
        <CustomCard
          title="Blacklisting"
          actionGroup={[
            {
              content: blacklistingBtnText,
              variant: !blacklisting.isEnableBtn ? 'primary' : undefined,
              loading: blacklisting.updatedBlacklistBtn === UpdatedBlacklistBtn.Enable && updatedBlacklistData.isLoading,
              disabled: isLoading || isLockedFeature,
              onAction: () => handleUpdateBlacklisting(UpdatedBlacklistBtn.Enable),
            },
          ]}
        >
          <ErrorBanner isVisible={!!apiError.enable}>{apiError.enable}</ErrorBanner>
          <Button variant="plain" icon={NoteMajor} url={LINK.BLACKLISTING_DOCS}>
            View document
          </Button>
        </CustomCard>

        <CustomCard
          className="mt-16"
          title="Replace locations"
          actionGroup={[
            {
              content: 'Save',
              variant: 'primary',
              loading: blacklisting.updatedBlacklistBtn === UpdatedBlacklistBtn.Save && updatedBlacklistData.isLoading,
              disabled:
                (blacklisting.replaceWith === BlacklistReplaceWith.AnotherCity &&
                  !blacklisting.areasToBeReplaced.selected.length) ||
                isLockedFeature,
              onAction: () => handleUpdateBlacklisting(UpdatedBlacklistBtn.Save),
            },
          ]}
        >
          {isLoading ? (
            <SkeletonBodyText lines={10} />
          ) : (
            <>
              <div className="top-section">
                <ErrorBanner isVisible={!!apiError.save}>{apiError.save}</ErrorBanner>
                <div className="mb-4">
                  <Text as="h6" variant="headingSm">
                    Replace with
                  </Text>
                </div>
                <InlineGrid
                  columns={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                  gap={{ xs: '100', sm: '400', md: '800', lg: '1200', xl: '1600' }}
                >
                  <RadioButton
                    id={BlacklistReplaceWith.EmptyLocation}
                    label="Empty location"
                    name="replacementOption"
                    checked={blacklisting.replaceWith === BlacklistReplaceWith.EmptyLocation}
                    onChange={(_, id) => handleChangeReplaceWith(id)}
                  />
                  <RadioButton
                    id={BlacklistReplaceWith.AnotherCity}
                    label="Another city"
                    name="replacementOption"
                    checked={blacklisting.replaceWith === BlacklistReplaceWith.AnotherCity}
                    onChange={(_, id) => handleChangeReplaceWith(id)}
                  />
                </InlineGrid>
                <AreasToBeReplacedTextField />
                {blacklisting.replaceWith === BlacklistReplaceWith.AnotherCity && (
                  <div className="another-city">
                    <Text variant="headingSm" as="h6">
                      Search and replace with city, country*
                    </Text>
                    <div className="group-textfield">
                      <div className="textfield-item">
                        <AreaToReplaceWithTextField type={AreaToReplaceWithType.Country} />
                      </div>
                      <div className="textfield-item">
                        <AreaToReplaceWithTextField type={AreaToReplaceWithType.City} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </CustomCard>

        <SearchAndReplaceWords />
      </StyledBlacklisting>
    </>
  );
};

export default Blacklisting;
